import React from 'react'

const defaultAvatars = [
  "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
  "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  'https://images.unsplash.com/photo-1532417344469-368f9ae6d187?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  'https://images.unsplash.com/photo-1611432579699-484f7990b127?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920',
  'https://images.unsplash.com/photo-1566492031773-4f4e44671857?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  'https://images.unsplash.com/photo-1513910367299-bce8d8a0ebf6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  'https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
  'https://images.unsplash.com/photo-1601576084861-5de423553c0f?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920',
  'https://images.unsplash.com/photo-1642736468880-89d8a6de3fbe?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920'
]

function getRandomAvatars(count: number, pool: string[]){
  count = Math.min(count, pool.length)
  let arr = []
  for (let i = 0; i < count; i++) {
    const random = Math.floor(Math.random() * (pool.length))
    arr.push(pool.splice(random, 1)[0])
  }

  return arr
}



function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

interface Props {
  total_count?: number
  avatars: string[]
  countClass?: string
  color?: string
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl',

}

const AvatarGroup:React.FC<Props> = ({ total_count, color, avatars, countClass, size }) => {
  let dimensions = `h-10 w-10`
  let text_size = 'text-sm';
  let space = '-space-x-4'
  countClass = countClass || (color ? '' : 'bg-blue-500');
  if(size === 'xs'){
    dimensions = `h-8 w-8`
    text_size = 'text-sm';
  } else if(size === 'sm'){
    dimensions = `h-9 w-9`
    text_size = 'text-sm';
  } else if (size === 'lg'){
    dimensions = `h-12 w-12`
    text_size = 'text-base';
  } else if (size === 'xl'){
    dimensions = `h-16 w-16`
    text_size = 'text-xl';
    space = '-space-x-7'
  }

  let style: React.CSSProperties = {};

  if(color){
    style.backgroundColor = color
  }

  if(avatars.length === 0 && total_count && total_count > 4){
    avatars = getRandomAvatars(4, [...defaultAvatars]);
  }

  return (
    <div className={`flex ${space} flex-shrink-0`}>
      {avatars.map((src, index) =>
        <div
          key={`avatarGroup-${index}`}
          className={`inline-block ${dimensions} rounded-full ring-2 ring-white bg-center bg-no-repeat bg-cover`}
          style={{backgroundImage: `url(${src})`}}
        />
      )}
      {
        total_count ?
          <div className={classNames(
            "inline-flex justify-center items-center rounded-full text-white font-semibold ring-2 ring-white",
            dimensions,
            text_size,
            countClass,
          )}
          style={style}>
            {total_count - avatars.length}+
          </div>
      :''
      }

  </div>)
}

export default AvatarGroup
