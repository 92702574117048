import React, {Fragment, useCallback, useRef, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useNavigate } from "react-router-dom";
import {XMarkIcon} from '@heroicons/react/24/outline'
import * as Yup from "yup";
import {FieldArray, Form, Formik, FormikHelpers} from "formik";
import {classNames, getParseImageFromServer} from "../../helpers";
import ImageUploaderInput, {ImageUploaderHelpers} from "../Elements/ImageUploaderInput";
import {useAddInstructors} from "../../data/client/instructor.api";
import InstructorSelectorInput from "../Elements/InstructorSelectorInput";
import InputField from "../Elements/InputField";
import RadioField from "../Elements/RadioField";
import SwitchField from "../Elements/SwitchField";
import {useCreateCourse, useUpdateCourse} from "../../data/client/course.api";
import {useCreateSession} from "../../data/client/session.api";
import {PlusIcon} from "@heroicons/react/24/solid";
import {parseInstructor} from "../../data/client/factories";

interface Props {
  user: User,
  open: boolean,
  course?: Course
  organization: Organization
  //alert: AlertDialog
  //userMemberships: Membership[]
  setOpen:  React.Dispatch<React.SetStateAction<boolean>>
  //selectedOrganization: Organization | undefined,
  //setSelectedOrganization:  React.Dispatch<React.SetStateAction<Organization | undefined>>
}

const ManageSessionSchema = Yup.object().shape({
  starts_at: Yup.date().min(new Date(), "Pick a time that is not in the past").when("open_for_registration", {
    is: true,
    then: Yup.date().min(new Date(), "Pick a time that is not in the past").required("Required to open this event for registration")
  }),
  meeting_link: Yup.string().when("open_for_registration", {
    is: true,
    then: Yup.string().required("Required to open this event for registration")
  }),
  external_registration_link: Yup.string(),
  open_for_registration: Yup.boolean(),
  status: Yup.string().oneOf(["NOT_READY", "CLOSED", "OPEN"]),
  instructors: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
        featured: Yup.boolean(),
        membership: Yup.string()
      })
    ).when("open_for_registration", {
      is: true,
      then: Yup.array().of(
        Yup.object().shape({
          id: Yup.string(),
          featured: Yup.boolean(),
          membership: Yup.string()
        })
      ).min(1,"Required to open this event for registration")
    }),
  pre_survey_link: Yup.string(),
  post_survey_link: Yup.string()
});

const ManageModuleSchema = Yup.object().shape({
  duration: Yup.number().required("Required"),
  sessions: Yup.array().of(ManageSessionSchema)
});

const ManageCourseSchema = Yup.object().shape({
  title: Yup.string()
    .min(10, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Required'),
  description: Yup.string()
    .min(2, 'Too Short!')
    .max(400, 'Too Long!')
    .required('Required'),
  tagline: Yup.string().max(150, 'Too Long!'),
  cover: Yup.mixed().nullable(),
  event_type: Yup.string().oneOf(['MEETUP', 'COURSE', "SERIES" ]).required(),
  highlights: Yup.array().of(Yup.string().required("This field can't be empty")).nullable(),
  modules: Yup.array().of(ManageModuleSchema)
});

interface SessionValues {
  id?: string
  starts_at: string
  meeting_link: string
  external_registration_link: string
  open_for_registration: boolean
  //status: "NOT_READY" | "CLOSED" | "OPEN" | string
  instructors: { id: string, featured: boolean, membership: string, instructor: Instructor }[]
  pre_survey_link: string
  post_survey_link: string
}

interface ModuleValues {
  module_id?: string
  course_module_id?: string
  duration: number
  sessions: SessionValues[]
}

interface Values {
  title: string,
  description: string,
  tagline: string
  cover: string
  event_type: 'MEETUP' | "COURSE" | 'SERIES' |string
  highlights: string[]
  modules: ModuleValues[]
}

const parseInitialValues = (course: Partial<Course> = {}): Values => {
  const { title, description, tagline, cover, event, course_modules, highlights } = course

  const modules = Array.isArray(course_modules) ? course_modules.map((course_modules) => ({
    module_id: course_modules.module.id || "",
    course_module_id: course_modules.id || "",
    duration: course_modules.module.duration || 60,
    sessions: (course_modules.sessions || []).map((session) => ({
      id: session.id,
      starts_at: session.starts_at,
      meeting_link: session.meeting_link,
      open_for_registration: false,
      external_registration_link: session.external_registration_link || "",
      //status: "NOT_READY" | "CLOSED" | "OPEN" | string
      instructors: (session.instructors || []).map((instructor) => ({
        id: instructor.id || "",
        featured: instructor.featured || false,
        membership: instructor.membership?.id || "",
        instructor: parseInstructor(instructor)
      })),
      pre_survey_link: session.pre_survey_link || "",
      post_survey_link: session.post_survey_link || ""
    }))
  })) : [{
    module_id: "",
    course_module_id: "",
    duration: 60,
    sessions: [
      {
        starts_at: "",
        meeting_link: "",
        open_for_registration: false,
        external_registration_link: "",
        //status: "NOT_READY" | "CLOSED" | "OPEN" | string
        instructors: [],
        pre_survey_link: "",
        post_survey_link: ""
      }
    ]
  }];
  //const { module, sessions, i } = Array.isArray(course_modules) ? course_modules[0] : { module: {}, sessions: []}
  return {
    title: title || "",
    description: description || "",
    tagline: tagline || "",
    cover: getParseImageFromServer('course_covers',cover || ""),
    event_type: event || "MEETUP",
    highlights: highlights || [],
    modules
  }
}


const ManageEvent: React.FC<Props> = ({ user, open, setOpen, course, organization}) => {
  const [minDate] = useState(new Date().toISOString())
  const [currentTab, setCurrentTab] = useState(0)
  const [currentSessionTab, setCurrentSessionTab] = useState(0)
  const [, setProgress] = useState(0);
  const navigate = useNavigate();

  const { mutateAsync: createCourse } = useCreateCourse()
  const { mutateAsync: updateCourse } = useUpdateCourse()
  const { mutateAsync: createSessions } = useCreateSession()
  const { mutateAsync: addInstructor } = useAddInstructors()

  const onCreateEvent = useCallback(async (values: Values, { setStatus } : FormikHelpers<Values>) => {
    const { title, description, tagline, cover, event_type, highlights, modules } = values
    //setStatus({value: ''})
    try{
      setProgress(5);
      //create course, modules, & course_modules
      let new_course = await createCourse({
        course: { title, description, tagline, cover, event: event_type, highlights, organization: organization },
        course_modules: modules.map((module, order) => ({module: { cover, description, duration: 60, tagline, title }, order })),
      });

      setProgress(50);
      //create sessions and instructors if available
      modules.forEach((module, order) => {
        module.sessions.forEach(async (session) => {
          const {instructors, starts_at, meeting_link, open_for_registration, external_registration_link, post_survey_link, pre_survey_link} = session;
          const new_course_module = new_course.course_modules.find((course_module) => course_module.order === order)
          if(new_course_module) {
            let new_sessions = await createSessions({
              sessions: [{
                meeting_link, pre_survey_link, post_survey_link, external_registration_link,
                starts_at: new Date(starts_at).toISOString(),
                status: open_for_registration ? "OPEN" : "NOT_READY",
                course: new_course,
                module: new_course_module.module,
                course_module: new_course_module
              }]
            })

            await addInstructor({
              instructors: instructors.map((instructorFormData) => ({
                course: new_course,
                course_module: new_course_module,
                featured: instructorFormData.featured,
                organization: instructorFormData.instructor.organization,
                membership: { id: instructorFormData.membership },
                user: instructorFormData.instructor.user,
                module: new_course_module.module,
                session: new_sessions[0]
              }))
            })
          }
        })
      })
      setProgress(100);
      navigate(`/course/${new_course.slug}`)

    } catch(e: any){
      setStatus({value: 'submit_error',  message: e.message })
    }

  }, [user, setProgress])

  const onUpdatedEvent = useCallback(async (values: Values, { setStatus } : FormikHelpers<Values>) => {
    const { title, description, tagline, cover, event_type, highlights } = values
    //setStatus({value: ''})
    if(!course) return
    try{
      setProgress(5);
      await updateCourse({
        course: {
          id: course.id,
          title: course.title !== title ? title : undefined,
          description: course.description !== description ? description : undefined,
          tagline: course.tagline !== tagline ? tagline : undefined,
          cover: course.cover !== cover ? cover : undefined,
          event: course.event !== event_type ? event_type : undefined,
          highlights: course.highlights !== highlights ? highlights: undefined
        },
      });

      setProgress(100);
      onClose()

    } catch(e: any){
      setStatus({value: 'submit_error',  message: e.message })
    }
  }, [user, course, setProgress])
  const uploaderRef = useRef<ImageUploaderHelpers>();

  const onClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const onAfterClose = useCallback(() => {
    setCurrentTab(0)
    setCurrentSessionTab(0)
    uploaderRef.current?.reset()
  }, [setCurrentTab, setCurrentSessionTab, uploaderRef])

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => onAfterClose()} appear>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <div className="p-6 text-3xl text-blue font-bold ">{course ? "Edit Event" : "Create an Event"}</div>
              <button
                type="button"
                className="absolute top-4 right-4 text-white lg:text-gray-400 hover:text-gray-300 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8 z-100"
                onClick={() => onClose()}
              >
                <span className="sr-only ">Close</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              <Formik
                initialValues={parseInitialValues(course || {})}
                onSubmit={course ? onUpdatedEvent : onCreateEvent}
                onReset={() => {
                  uploaderRef.current?.reset()
                }}
                validationSchema={ManageCourseSchema}
              >
                {({ status, errors, isValid, isSubmitting,  resetForm, values }) => (
                  <>
                    <Form className="bg-gradient-to-r from-gray-50 to-white" action="#" method="POST">
                      {/*Overview, Modules Tabs*/}
                      <>
                        <FieldArray
                          name="modules"
                          render={modulesArrayHelpers => (
                            <>
                              <div>
                                <div className="sm:hidden">
                                  <label htmlFor="tabs" className="sr-only">
                                    Select a tab
                                  </label>
                                  {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                                  <select
                                    id="tabs"
                                    name="tabs"
                                    className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    defaultValue={currentTab}
                                  >
                                    <option key={`option-module-0`} value={0}>
                                      Overview
                                    </option>
                                    {(values.modules || []).map((tab, index) => (
                                      <option key={`option-module-${index + 1}`} value={index + 1}>
                                        {values.event_type === "SERIES" ?  `Module ${index + 1}` :  'Sessions'}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="hidden sm:block">
                                  <div className="border-b border-gray-100">
                                    <nav className="-mb-px flex space-x-8 px-6" aria-label="Tabs">
                                      <div
                                        key={`overview`}
                                        onClick={() => { setCurrentTab(0) } }
                                        className={classNames(
                                          currentTab === 0
                                            ? 'border-blue text-blue'
                                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                          'whitespace-nowrap border-b-2 py-4 px-1 uppercase text-sm font-semibold'
                                        )}
                                        aria-current={currentTab === 0 ? 'page' : undefined}
                                      >
                                        {`Overview`}
                                      </div>
                                      {(values.modules || []).map((modules, index) => (
                                        <div
                                          key={`module-${index + 1}`}
                                          onClick={() => { setCurrentTab(index + 1); setCurrentSessionTab(0)}}
                                          className={classNames(
                                            (index + 1) === currentTab
                                              ? 'border-blue text-blue'
                                              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                            'whitespace-nowrap border-b-2 py-4 px-1 uppercase text-sm font-semibold'
                                          )}
                                          aria-current={index + 1 === currentTab ? 'page' : undefined}
                                        >
                                          {values.event_type === "SERIES" ?  `Module ${index + 1}` :  'Sessions'}
                                        </div>
                                      ))}
                                      { values.event_type == "SERIES" && <button
                                        type="button"
                                        onClick={() => modulesArrayHelpers.push({  module_id: "", course_module_id:  "", duration: 60, sessions: []})}
                                        className="relative inline-flex items-center gap-x-1.5 rounded-md bg-blue my-2.5 px-3 py-1.5 uppercase text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                      >
                                        <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                        Add Module
                                      </button>}
                                    </nav>
                                  </div>
                                </div>
                              </div>
                              <div className={classNames(currentTab !== 0 ? "hidden": "", "px-6 py-2")}>
                                <div className="grid grid-cols-12 gap-y-1 gap-x-6">
                                  <InputField id="title" name="title" labelText="Title" />
                                  <InputField id="tagline" name="tagline" labelText="Tagline" />
                                  <div className="col-span-6">
                                    <InputField containerClassName="w-full" id="description" as="textarea" rows={6} name="description" labelText="Description" description="A description for your Event." />

                                    <RadioField containerClassName="w-full" options={['MEETUP', 'COURSE'/*, "SERIES"*/]}
                                                id="event_type" name="event_type" labelText="Event Type" description="What kind of event is this?" />
                                  </div>
                                  <div className="col-span-6">
                                    <div className="mt-6 flex-grow lg:mt-0 lg:ml-6 lg:flex-grow-0 lg:flex-shrink-0">
                                      <p className="text-sm font-medium text-gray-700 text-center" aria-hidden="true">
                                        Cover
                                        <span className="ml-2 text-sm text-gray-400">
                                  Upload a cover image for your event.
                                </span>
                                      </p>
                                      <div className="create-event_cover relative overflow-hidden mx-auto w-full h-52 bg-gray-200 rounded-lg">
                                        <ImageUploaderInput imagePreviewHeight={208} imageCropAspectRatio={"16:10"}
                                                            layout="integrated" ref={uploaderRef} initialValue={getParseImageFromServer("images", course?.cover || "" )} bucket="images" id="cover" name="cover" />
                                      </div>
                                    </div>
                                  </div>
                                  <FieldArray
                                    name="highlights"
                                    render={arrayHelpers => (
                                      <>
                                        <label htmlFor="highlights" className="col-span-12 block text-sm font-medium text-gray-800">
                                          Questions
                                          <span className="ml-2 text-sm text-gray-400">What questions will this event answer?</span>
                                        </label>
                                        {values.highlights.map((highlight, index) => (
                                          <InputField className="relative flex mt-1 bg-white block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus-within:outline-none focus-within:ring-blue-300 focus-within:border-blue-300 sm:text-sm"
                                                      errorClassName="text-xs w-full block text-left text-red-500 italic input-error"
                                                      key={index} id={`highlights.${index}`} name={`highlights.${index}`} >
                                            <div/>
                                            <button
                                              type="button" onClick={() => arrayHelpers.remove(index)}
                                              className="absolute uppercase right-0 top-0 inline-flex items-center gap-x-1.5 hover:bg-gray-100 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 border-l border-gray-300 bg-gray-50"
                                            >
                                              <XMarkIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                              Remove
                                            </button>
                                          </InputField>
                                        ))}
                                        <button
                                          type="button"
                                          onClick={() => arrayHelpers.push("")}
                                          className="col-span-12 uppercase justify-center mb-6 mt-2 bg-gray-50 hover:bg-gray-100  border-gray-200 inline-flex items-center rounded-md border px-4 py-2 text-sm font-bold text-black shadow-sm hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                                        >
                                          <PlusIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                                          Add A Question
                                        </button>
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              {values.modules.map((module, module_index) =>
                                <FieldArray name={`modules.${module_index}.sessions`} render={sessionArrayHelper =>
                                  <div className={classNames(currentTab !== module_index + 1 ? "hidden": "", "px-6")}>
                                    <div className="mt-1 mb-2">
                                      <div className="sm:hidden">
                                        <label htmlFor="tabs" className="sr-only">
                                          Select a tab
                                        </label>
                                        <select
                                          id="tabs"
                                          name="tabs"
                                          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                          defaultValue={currentSessionTab}
                                        >
                                          {module.sessions.map((session, index) => (
                                            <option key={`session-option-${index}`}>Session {index+1}</option>
                                          ))}
                                        </select>
                                      </div>
                                      <div className="hidden sm:block">
                                        <div className="">
                                          <nav className="-mb-px flex space-x-8" aria-label="Session-Tabs">
                                            {module.sessions.map((session, index) => (
                                              <div
                                                key={session.id}
                                                onClick={() => setCurrentSessionTab(index)}
                                                className={classNames(
                                                  currentSessionTab === index
                                                    ? 'border-blue text-blue'
                                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                  'whitespace-nowrap border-b-2 pt-4 pb-2 px-1 uppercase text-xs font-semibold'
                                                )}
                                                aria-current={currentSessionTab === index ? 'page' : undefined}
                                              >
                                                Session {index + 1}
                                              </div>
                                            ))}
                                            <button
                                              type="button"
                                              onClick={() => sessionArrayHelper.push({
                                                starts_at: "",
                                                meeting_link: "",
                                                open_for_registration: false,
                                                //status: "NOT_READY" | "CLOSED" | "OPEN" | string
                                                instructors: []
                                              })}
                                              className="relative inline-flex items-center gap-x-1.5 rounded-md bg-blue mt-2.5 px-3 py-1.5 uppercase text-xs font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                            >
                                              <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                              Add Session
                                            </button>
                                          </nav>
                                        </div>
                                      </div>
                                    </div>
                                    {module.sessions.map((session, session_index) =>
                                      <div className={classNames(currentSessionTab !== session_index ? "hidden": "", "grid grid-cols-12 gap-y-1 gap-x-6 pt-4")}>
                                        <InputField
                                          id={`modules.${module_index}.sessions.${session_index}.meeting_link`}
                                          name={`modules.${module_index}.sessions.${session_index}.meeting_link`}
                                          labelText="Meeting Link" description="A Zoom or Teams link for your event" />
                                        <div className="col-span-12 sm:col-span-12">
                                          <InstructorSelectorInput
                                            id={`modules.${module_index}.sessions.${session_index}.instructors`}
                                            name={`modules.${module_index}.sessions.${session_index}.instructors`}
                                            selector={`modules.${module_index}.sessions.${session_index}.instructors`} />
                                        </div>
                                        <InputField containerClassName="col-span-6" type="datetime-local" min={minDate} placeholder="mm/dd/yyyy h:mm"
                                                    id={`modules.${module_index}.sessions.${session_index}.starts_at`}
                                                    name={`modules.${module_index}.sessions.${session_index}.starts_at`}
                                                    labelText="Date & Time" description="When does your event start?" />
                                        <SwitchField containerClassName="col-span-6"
                                                     id={`modules.${module_index}.sessions.${session_index}.open_for_registration`}
                                                     name={`modules.${module_index}.sessions.${session_index}.open_for_registration`}
                                                     labelText="Open for Registration" description="Turn this on if the event is open for registration." />
                                        <InputField containerClassName="col-span-6"
                                          id={`modules.${module_index}.sessions.${session_index}.pre_survey_link`}
                                          name={`modules.${module_index}.sessions.${session_index}.pre_survey_link`}
                                          labelText="Pre-Event Survey Link" description="A survey link provided to attendees before the session" />
                                        <InputField containerClassName="col-span-6"
                                          id={`modules.${module_index}.sessions.${session_index}.post_survey_link`}
                                          name={`modules.${module_index}.sessions.${session_index}.post_survey_link`}
                                          labelText="Post-Event Survey Link" description="A survey link provided to attendees after the session" />
                                        <InputField
                                          id={`modules.${module_index}.sessions.${session_index}.external_registration_link`}
                                          name={`modules.${module_index}.sessions.${session_index}.external_registration_link`}
                                          labelText="External Registration Link" description="Provide a link if users should register on another website such as Eventbrite" />
                                      </div>
                                    )}
                                  </div>
                                } />
                              )}
                            </>
                          )} />
                        <span className="text-sm w-full block flex justify-center items-center text-red-500 italic input-error" >
                          {status?.value === 'submit_error' && status?.message ? status.message : ''}
                        </span>
                        <div className="mt-4 py-4 px-4 flex justify-end sm:px-6 border-t border-gray-200">
                          <div>
                            <button
                            type="button"
                            onClick={() => {resetForm(); onClose(); }}
                            className="btn btn-primary-white-border">
                              Cancel
                            </button>
                          </div>
                          <div className={classNames(course || currentTab > 0 ? "" : "hidden")}>
                            <button
                              type="submit"
                              className={classNames("animate__animated animate__fast",
                                isSubmitting ? "pointer-events-none opacity-50" :"",
                                !isValid ? 'animate__shakeX': '',
                                "ml-5 btn btn-primary")}
                            >
                              Save
                            </button>
                          </div>
                          <div className={classNames(course || currentTab > 0 ? "hidden" : "")}>
                            <button
                              type="button"
                              onClick={() => setCurrentTab(1)}
                              className={classNames(
                                "ml-5 btn btn-primary",

                              )}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                        </>
                    </Form>
                  </>
                )}
              </Formik>

            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ManageEvent