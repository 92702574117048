export default function EditPassword() {
  return (
    <form action="#" method="POST" className="bg-gradient-to-r from-gray-50 to-white">
      <div className="py-6 px-4 sm:p-12 lg:pb-8">
        <div>
          <h2 className="text-3xl leading-10 font-semibold text-blue-500">Password</h2>
          <p className="mt-1 text-sm text-gray-500">
            Reset your account password.
          </p>
        </div>

        <div className="mt-6 grid grid-cols-12 gap-6">

          <div className="col-span-12 sm:col-span-12">
            <label htmlFor="username" className="block text-sm font-medium text-gray-700">
              Old Password
            </label>
            <div className="mt-1 rounded-md shadow-sm flex">
              <input
                type="password"
                name="old_password"
                id="old_password"
                autoComplete="old_password"
                className="focus:ring-sky-500 focus:border-sky-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
              />
            </div>
          </div>

          <div className="col-span-12 sm:col-span-12">
            <label htmlFor="company" className="block text-sm font-medium text-gray-700">
              New Password
            </label>
            <input
              type="password"
              name="new_password"
              id="new_password"
              autoComplete="new_password"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
        </div>
      </div>

      {/* Privacy section */}
      <div className="pt-6 divide-y divide-gray-200">
        <div />
        <div className="mt-4 py-4 px-4 flex justify-end sm:px-6">
          <button
            type="button"
            className="btn btn-primary-white-border"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-5 btn btn-primary"
          >
            Reset Password
          </button>
        </div>
      </div>
    </form>
  )
}
