import React, {useCallback} from "react";
import { classNames } from "../../helpers";
import {Formik, Form, FormikHelpers } from "formik";
import AuthInputField from "./AuthInputField";
import * as Yup from 'yup';
import 'yup-phone'
import AuthPasswordField from "./AuthPasswordField";
import AuthCheckBoxField from "./AuthCheckBoxField";
import {useNavigate, Link} from "react-router-dom";
import {supabase} from "../../api_supabase";
import {AuthError} from "@supabase/supabase-js";

const RegisterSchema = (simpleRegistration: boolean) => Yup.object().shape({
  first_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  last_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  /*phone: Yup.string().test('test-phone', 'Invalid phone number', (value) => {
  if (value) return Yup.string().phone('US', true).isValidSync(value);
  return true;
}),*/
  password: simpleRegistration ? Yup.string() : Yup.string()
    .min(2, 'Password should be at least 6 characters!')
    .max(50, 'Too Long!')
    .required('Required'),
  accepted_terms: Yup.boolean().required().oneOf([true], ' ')
});

interface Values {
  first_name: string,
  last_name: string,
  email: string
  //phone: string
  password: string
  accepted_terms: boolean
}

interface Props {
  alert: AlertDialog
  user: User
  refreshUser: (args?: any) => Promise<any>
  buttonText?: string
  simpleRegistration?: boolean
  autoLogin?: boolean
  onAfterRegister?: () => any
  redirectTo?: string
}

const Register: React.FC<Props> = ({ refreshUser, buttonText, onAfterRegister , simpleRegistration = false, alert, autoLogin= true, redirectTo = ""}) => {
  const navigate = useNavigate()

  const handleSubmit =  useCallback(async (values: Values, { setFieldError, setStatus, setSubmitting, resetForm } : FormikHelpers<Values>) => {
    setStatus({value: ''})
    try {
      const { error: signUpError } = await supabase.auth.signUp({
        email: values.email, password: values.password,
        options: {
          data: {
            first_name: values.first_name, last_name: values.last_name
          }
        }
      })

      if(signUpError) throw signUpError;
      if(simpleRegistration){
        const { data, error: signInError } = await supabase.auth.signInWithOtp({
          email: values.email,
          options: {
            emailRedirectTo: redirectTo,
            shouldCreateUser: true
          }
        })
        if(signUpError) throw signInError;

      } else {
        const { error: signInError } = await supabase.auth.signInWithPassword({
          email: values.email, password: values.password
        })
        if(signUpError) throw signInError;
      }

      await refreshUser()

      if(onAfterRegister){
        onAfterRegister()
      } else {
        setTimeout(() => navigate('/dashboard'), 100)
      }
    } catch (e){
      if(e instanceof AuthError ){
        setStatus({value: 'submit_error',  message: e.message })
      } else {
        setStatus({value: 'submit_error',  message: "Server Error" })
      }
      setSubmitting(false)

    }
  }, [navigate, onAfterRegister, alert, simpleRegistration, refreshUser])

  return (
    <Formik initialValues={{
      first_name: '',
      last_name: '',
      email: '',
      //phone: '',
      password: '',
      accepted_terms: false
    }} onSubmit={handleSubmit} validationSchema={RegisterSchema(simpleRegistration)}>
      {({ isSubmitting, status }) => (
        <>
          <div className="sm:mx-auto mb-6 auth_header">
            <h2 className="text-center text-gray-400 text-4xl">Sign Up</h2>
            <h6 className="text-center text-sm uppercase mt-4 py-2 font-semibold text-blue-500">Learn Skills. Get Hired.</h6>
          </div>
          <Form className="gap-0 gap-x-2 grid grid-cols-12" action="#" method="POST">
            <AuthInputField name="first_name" id="register_first_name" placeholder="First Name" containerClassName="col-span-6"
                            type="text" autoComplete="given-name" className="input input-secondary"/>
            <AuthInputField name="last_name" id="register_last_name" placeholder="Last Name" containerClassName="col-span-6"
                            type="text" autoComplete="family-name" className="input input-secondary"/>
            <AuthInputField name="email" id="register_email" placeholder="Email Address"
                            type="email" autoComplete="email" className="input input-secondary"/>
            {/*<AuthInputField name="phone" id="register_phone" placeholder="Phone Number"
                            type="phone" autoComplete="tel" className="input input-secondary"/>*/}
            {!simpleRegistration && <AuthPasswordField name="password" id="register_password" placeholder="Password"
                               autoComplete="new-password" className="input input-secondary"/>}
            <AuthCheckBoxField id="register_accepted_terms" name="accepted_terms">
              <div className="-mt-1 text-sm pl-2">
                I accept the <Link to="/terms" target="_blank" rel="noopener noreferrer" className="font-bold underline hover:text-orange-500">Terms and Conditions</Link> and have read the <Link to="/privacy" target="_blank" rel="noopener noreferrer" className="font-bold underline hover:text-orange-500">Privacy Policy</Link> and <Link to="/cookie" target="_blank" rel="noopener noreferrer" className="font-bold underline hover:text-orange-500">Cookie Policy</Link>.
              </div>
            </AuthCheckBoxField>
            <div className="col-span-12 mb-6">
              <span className="text-sm w-full block flex justify-center items-center text-red-500 italic input-error" >
              {status?.value === 'submit_error' && status?.message ? status.message : ''}
              </span>
              <button
                type="submit"
                className={
                  classNames(
                    "animate__animated animate__fast",
                    isSubmitting ? "pointer-events-none opacity-50" :"",
                    status?.value === "submit_error" ? 'animate__shakeX': '',
                    "text-center font-bold font-button text-sm text-white uppercase px-6 py-3 w-full flex justify-center border border-transparent rounded-full shadow-sm bg-blue-500 hover:bg-blue-600 outline-none focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300"
                  )}
              >
                {buttonText || "Create Account"}
              </button>
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default Register
