import React, {useState} from 'react'
import { Link as ScrollLink } from 'react-scroll'
import {ChevronRightIcon} from '@heroicons/react/24/solid'
import {FaPencil, FaRegCalendar} from 'react-icons/fa6'
import {getRelativeDate} from "../../helpers";
import AvatarGroup from "../../components/Elements/AvatarGroup";
import ManageEvent from "./ManageEvent";
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

interface Props {
  course: Course
  session: Session
  sessions: Session[]
  theme: Theme
  user: User
  isAdmin: boolean
}

const CourseHeader: React.FC<Props> = ({course, theme, session, user, isAdmin, sessions}) => {
  const [openCreateEvent, setOpenCreateEvent] = useState(false)
  if(!course.id || !session.id) return <div className="relative bg-gradient-to-r from-blue-500 to-blue-900 overflow-hidden h-screen"/>

  let sessionDate = getRelativeDate(new Date(session.starts_at));
  return (
    <>
      {user && isAdmin && <ManageEvent course={course} organization={course.organization} user={user} open={openCreateEvent} setOpen={setOpenCreateEvent} />}
      <div className="relative overflow-hidden pt-16">
        <div className="absolute inset-0 bg-cover h-full" style={{backgroundImage: `url(${course.cover})`}}/>
        <div className="absolute h-full w-full inset-0 flex">
          <div
            className="h-full overflow-hidden"
            style={{
              width: `${theme.img_bg_color_split[0]/12*100}%`,
              opacity: theme.img_bg_color_opacity,
              backgroundImage: `linear-gradient(to right, ${theme.img_bg_color_from}, ${theme.img_bg_color_from})`
            }}
          />
          <div
            className="h-full overflow-hidden"
            style={{
              width: `${theme.img_bg_color_split[1]/12*100}%`,
              opacity: theme.img_bg_color_opacity,
              backgroundImage: `linear-gradient(to right, ${theme.img_bg_color_from}, ${theme.img_bg_color_to})`
            }}
          />
          <div
            className="h-full overflow-hidden right-0"
            style={{
              width: `${theme.img_bg_color_split[2]/12*100}%`,
              opacity: theme.img_bg_color_opacity,
              backgroundImage: `linear-gradient(to right, ${theme.img_bg_color_to}, ${theme.img_bg_color_to})`
            }}
          />
        </div>
        <div
          className="absolute h-full w-full inset-0 overflow-hidden bg-black"
          style={{
            opacity: theme.img_bg_color_darkness
          }}
        />

        <div className="relative pt-6 pb-16 sm:pb-24">
          <main className="mt-8 sm:mt-8">
            <div className="mx-auto max-w-7xl">
              <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:max-w-none lg:col-span-7 lg:text-left lg:flex lg:items-center lg:justify-start">
                  <div>
                    <div className="flex">
                      <div className="mb-3.5 inline-flex items-center text-white bg-black/20 rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200">
                      <span className={classNames(
                        "px-3 py-0.5 pt-1 text-white text-xs font-semibold leading-5 uppercase tracking-wide rounded-full bg-opacity-40",
                        sessionDate.colorClassName
                      )}>{sessionDate.status === 'today' ? `Event ${sessionDate.status}` : `${sessionDate.status} Event` }
                      </span>
                        <span className="ml-4 text-sm mr-2 sentence-case">{sessionDate.description}</span>
                        <ChevronRightIcon className="ml-2 w-5 h-5 text-gray-500 hidden" aria-hidden="true" />
                      </div>

                    </div>
                    <h1 className="text-2xl tracking-tight font-extrabold text-white sm:leading-none lg:text-4xl">
                      {course.title}
                    </h1>
                    <span className="text-2xl tracking-tight text-white sm:leading-none lg:text-3xl">{course.tagline}</span>
                    <p className="mt-3 text-base text-white sm:mt-5 sm:text-lg lg:text-lg xl:text-lg">
                      {course.description}
                    </p>
                    <div className="text-white/70 pt-3">
                      <FaRegCalendar className="inline mr-2 mb-1 h-4" />
                      <span className="text-sm">{new Intl.DateTimeFormat('en-US', { weekday: "long", day: "numeric", month: "long", year: "numeric", hour: 'numeric', hour12: true, timeZoneName: 'short'}).format(new Date(session.starts_at))}</span>
                    </div>
                    <h3 className="uppercase font-semibold text-white pt-3">Hosted By</h3>
                    <div className="flex pt-3 space-x-3">
                      {session.instructors.map((instructor) =>
                        <div className="flex bg-white/20 rounded-xl py-3 px-3">
                          <div className="relative mr-8">
                            <div className="h-16 w-16 bg-cover bg-center rounded-full" style={{backgroundImage: `url(${instructor.user?.profile?.image})`}}/>
                            <div className="h-10 w-10 bg-cover bg-center absolute -top-1 -right-6 rounded-full" style={{backgroundImage: `url(${instructor.membership?.organization?.logo})`}}/>
                          </div>
                          <div className="flex flex-col justify-center">
                            <div className="text-white font-semibold text-xl leading-4">{`${instructor.user?.profile?.first_name} ${instructor.user?.profile?.last_name}`}</div>
                            <div className="text-white text-sm">{`${instructor.membership?.title}, ${instructor.membership?.organization?.name}`}</div>
                          </div>
                        </div>
                      )}
                    </div>


                    {course.attended > 0 ? <div className="mt-5 w-full sm:mx-auto sm:max-w-lg lg:ml-0">
                      <div className="flex items-center">
                        <AvatarGroup size="lg" avatars={[]} total_count={course.attended} color={theme.hired_count_bg_color} />
                        <span className="text-sm text-white uppercase tracking-wide font-semibold ml-5">
                          {course.attended} {sessionDate.status === 'archived' ? "Attended" : "Attending"} this Event
                        </span>
                      </div>
                    </div> : ""}

                  </div>
                </div>
                <div className="px-6 mt-16 sm:mt-24 lg:mt-0 lg:col-span-5 flex flex-col items-end justify-between">
                  <div className="ml-auto">
                    {user && isAdmin && <div className="mb-2.5 lg:col-span-3">
                      <button
                        onClick={() => setOpenCreateEvent(true)}
                        className="tile-btn w-64 text-white opacity-20 bg-white/20 border border-white hover:bg-white/40 hover:opacity-100 items-center"
                        style={{
                          transition: "all .15s ease" }}
                      >
                        <FaPencil className="mr-1 h-3" />
                        Edit Event
                      </button>
                    </div>}
                  </div>

                  <div className={classNames(
                    course.highlights.length > 0 ? "bg-white" : "bg-transparent",
                    "bg-white sm:max-w-lg sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden")}>
                    <div className="px-4 py-8 sm:px-10">

                      <div>
                        {course.highlights.length > 0 ? <div className="mt-6"><h3
                          //style={{color: theme.menu_text_color}}
                          className="pb-1 uppercase font-button text-lg font-bold text-black">THIS EQUIPT EVENT ANSWERS:</h3>
                        <ul className="text-base py-5 pl-4 list-disc">
                          {course.highlights.map((highlight, index) => <li key={`highlight-${index}`} className="pb-3 text-gray-600">{highlight}</li>)}
                        </ul></div> : ""}
                        <ScrollLink
                          to="attend"
                          //style={{backgroundColor: theme.cta_bg_color, color: theme.cta_text_color}}
                          spy={true} smooth={true} duration={500}
                          className="w-full btn btn-color bg-blue text-white"
                        >
                          {"Register Now"}
                        </ScrollLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )

}

export default CourseHeader
