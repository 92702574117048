import React, {useState, useCallback} from 'react'
import Footer from "../components/Footer";
import {useMutation} from "@apollo/client";
import { classNames } from "../helpers";
import { CheckIcon } from '@heroicons/react/24/solid'
import {REQUEST_UPDATE_SUBSCRIPTION_MUTATION} from "../data/client/user.api";
import NewUserLaunchRegistration from "../components/NewUserLaunchRegistration";

const plans = [
  {
    title: 'Access',
    featured: false,
    description: 'All your essential business finances, taken care of.',
    mainFeatures: [
      { id: 1, value: 'Meetup Membership' },
      { id: 2, value: 'Free Workshops' },
      { id: 3, value: 'Limited Access to Community' },
    ],
  },
  {
    title: 'Community',
    featured: true,
    description: 'The best financial services for your thriving business.',
    priceMonthly: 19,
    priceYearly: 220,
    mainFeatures: [
      { id: 1, value: 'Workshop slides and exercises ' },
      { id: 2, value: 'Workshop recordings' },
      { id: 3, value: 'Discounts on Premium Intensive Bootcamps' },
      { id: 4, value: 'Access to Discord Community' },
      { id: 5, value: 'Mentorship: Access to group and individual Office Hour sessions' },
    ],
  }
]

interface Props {
  user: User
  alert: AlertDialog
}

const Launch: React.FC<Props> = ({ user, alert}) => {
  const [ showModal, setShowModal ] = useState(false)
  const [ redirectToPay, setRedirectToPay ] = useState(false)
  const [requestUpdateSubscription] = useMutation(REQUEST_UPDATE_SUBSCRIPTION_MUTATION);
  const onRegister = useCallback(async () => {

    setShowModal(false)
    if(redirectToPay){
      let response = await requestUpdateSubscription({ variables: { user: user.id, subscription_level: 10, referrer: window.location.href  }});
      window.location.href = response.data.requestUpdateSubscription.message
    } else {
      alert({ title: "Thanks for Signing Up"})
    }

  }, [requestUpdateSubscription, setShowModal, redirectToPay])

  const onSubmit = useCallback(async () => {
    setRedirectToPay(false)
    setShowModal(true)
  }, [setRedirectToPay, setShowModal])

  const onSubmitForSubscription = useCallback(async () => {
    setRedirectToPay(true)
    setShowModal(true)
  }, [setRedirectToPay, setShowModal])

  return (
    <>
      <NewUserLaunchRegistration alert={alert} showModal={showModal} setShowModal={setShowModal} onRegister={onRegister} user={user} refreshUser={() => Promise.resolve()} />
      <div className="bg-gray-50">
        <div className="relative bg-blue-500">
          {/* Overlapping background */}
          <div aria-hidden="true" className="hidden absolute bg-gray-50 w-full h-6 bottom-0 lg:block" />

          <div className="relative max-w-2xl mx-auto pt-16 px-4 text-center sm:pt-32 sm:px-6 lg:max-w-7xl lg:px-8">
            <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-6xl">
              <span className="block lg:inline">Simple pricing,</span>
              <span className="block lg:inline">no commitment.</span>
            </h1>
            <p className="mt-4 text-xl text-blue-100">
              We bring you experienced tech professionals from top-tier companies via our live virtual classroom. They’ll help you discover the best careers, build your professional network, and gain the right skills
            </p>
          </div>

          <h2 className="sr-only">Plans</h2>

          {/* Cards */}
          <div className="relative mt-8 max-w-2xl mx-auto px-4 pb-8 sm:mt-12 sm:px-6 lg:max-w-7xl lg:px-8 lg:pb-0">
            {/* Decorative background */}
            <div
              aria-hidden="true"
              className="hidden absolute top-4 bottom-6 left-8 right-8 inset-0 bg-blue-600 rounded-tl-lg rounded-tr-lg lg:block"
            />

            <div className="relative space-y-6 lg:space-y-0 lg:grid lg:grid-cols-2">
              {plans.map((plan) => (
                <div
                  key={plan.title}
                  className={classNames(
                    plan.featured ? 'bg-white ring-2 ring-blue-600 shadow-md' : 'bg-blue-600 lg:bg-transparent',
                    'pt-6 px-6 pb-3 rounded-lg lg:px-8 lg:pt-12'
                  )}
                >
                  <div>
                    <h3
                      className={classNames(
                        plan.featured ? 'text-blue-500' : 'text-white',
                        'text-sm font-semibold uppercase tracking-wide'
                      )}
                    >
                      {plan.title}
                    </h3>
                    <div className="flex flex-col items-start sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start">
                      <div className="mt-3 flex items-center">
                        {plan.priceMonthly ? <>
                            <p
                          className={classNames(
                            plan.featured ? 'text-blue-500' : 'text-white',
                            'text-4xl font-extrabold tracking-tight'
                          )}
                        >
                          ${plan.priceMonthly}
                            </p>
                            <div className="ml-4">
                              <p className={classNames(plan.featured ? 'text-gray-700' : 'text-white', 'text-sm')}>
                                USD / mo
                              </p>
                            </div>
                          </> :
                          <>
                            <p
                            className={classNames(
                              plan.featured ? 'text-blue-500' : 'text-white',
                              'text-4xl font-extrabold tracking-tight'
                            )}>
                            Free
                          </p>
                          </>
                        }
                      </div>
                      <button
                        onClick={plan.priceMonthly ? onSubmitForSubscription : onSubmit}
                        className={classNames(
                          plan.featured
                            ? 'bg-blue-500 text-white hover:bg-blue-600'
                            : 'bg-white text-blue-500 hover:bg-blue-50',
                          'mt-6 w-full inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full'
                        )}
                      >
                        {plan.priceMonthly ? `Reserve Your Spot`: ` Join ${plan.title}`}
                      </button>
                    </div>
                  </div>
                  <h4 className="sr-only">Features</h4>
                  <ul
                    role="list"
                    className={classNames(
                      plan.featured
                        ? 'border-gray-200 divide-gray-200'
                        : 'border-blue-400 divide-blue-400/75',
                      'mt-7 border-t divide-y lg:border-t-0'
                    )}
                  >
                    {plan.mainFeatures.map((mainFeature) => (
                      <li key={mainFeature.id} className="py-3 flex items-center">
                        <CheckIcon
                          className={classNames(
                            plan.featured ? 'text-blue-400' : 'text-blue-200',
                            'w-5 h-5 flex-shrink-0'
                          )}
                          aria-hidden="true"
                        />
                        <span
                          className={classNames(
                            plan.featured ? 'text-gray-600' : 'text-white',
                            'ml-3 text-sm font-medium'
                          )}
                        >
                        {mainFeature.value}
                      </span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-blue w-full h-64 -mt-6"/>
        </div>
      </div>
      <Footer className="bg-white text-black w-full"/>
  </>
  )

}

export default Launch
