import {ErrorMessage, FieldAttributes, useField} from "formik";
import React from "react";
import {classNames} from "../../helpers";
import {Switch} from "@headlessui/react";

interface SwitchFieldProps extends FieldAttributes<any> {
  id: string
  name: string
  as?: "textarea"
  placeholder?: string
  containerClassName?: string
  labelClassName?: string
  labelText: JSX.Element | string
  description?: string
  //errorClassName?: string
}

const SwitchField: React.FC<SwitchFieldProps> = (
  { name,
    id,
    as,
    placeholder,
    containerClassName = "col-span-12",
    className = "h-4 w-4 border-gray-300 text-orange-500 focus:ring-orange-500",
    labelClassName = "block text-sm font-medium text-gray-800",
    labelText,
    description,
    children, ...fieldProps }) => {
  const [, meta, helpers] = useField({ name, id, placeholder, ...fieldProps});
  return(
    <ul className={containerClassName}>
      <Switch.Group as="li" className="flex items-center justify-between">
        <div className="flex flex-col">
          <Switch.Label as="label" htmlFor={id} className={labelClassName} passive>
            {labelText}
          </Switch.Label>
          {description && <Switch.Description className="text-sm text-gray-500">
            {description}
          </Switch.Description>}
        </div>
        <Switch
          checked={meta.value}
          onChange={(checked) => helpers.setValue(checked) }
          className={classNames(
            meta.value ? 'bg-blue-500' : 'bg-gray-200',
            'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-300'
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              meta.value ? 'translate-x-5' : 'translate-x-0',
              'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
            )}
          />
        </Switch>
      </Switch.Group>
      <span className="text-xs h-6 w-full block text-left text-red-500 italic input-error" >
        <ErrorMessage name={name}/>
      </span>
    </ul>
  )
}

export default SwitchField
