import React, {useState} from 'react'
import "./Landing.css"
import stockPhoto from "../../assets/img/stock-1.jpg"
import headerImage from "../../assets/img/initiative-bg.jpg"
import {ReactComponent as EquiptLogo} from '../../assets/img/logo-white.svg'
import TeacherJidePhoto from '../../assets/img/teacher-jide.jpg'
import TeacherHubertPhoto from '../../assets/img/teacher-hubert.jpeg'

import { Link as ScrollLink, Element as ScrollElement } from 'react-scroll'


import {Navigation} from 'swiper'

import { Swiper, SwiperSlide } from 'swiper/react'

import "swiper/css";
import "swiper/css/navigation"
import {ReactComponent as AccentureLogo} from "../../assets/img/company-accenture.svg";
import {ReactComponent as NikeLogo} from "../../assets/img/company-Nike.svg";
import {ReactComponent as GoogleLogo} from "../../assets/img/company-google-long.svg";
import {ReactComponent as StashLogo} from '../../assets/img/company-stash.svg'
import {ReactComponent as CapitalOneLogo} from '../../assets/img/company-capitalone.svg'
import SponsoredEventTile from "../../components/Landing/SponsoredEventTile";

import {FaFacebook, FaLinkedinIn } from 'react-icons/fa'
import {SiTrustpilot} from 'react-icons/si'
import {classNames} from "../../helpers";
import SubscriberForm from "../../components/Landing/SubscriberForm";
import RequestRecordingModal from "../../components/Landing/RequestRecordingModal";
import {useFetchAirTableCourses} from "../../data/client/course.api";

const profilePictures = {
  female: [
    'https://images.unsplash.com/photo-1532417344469-368f9ae6d187?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    'https://images.unsplash.com/photo-1611432579699-484f7990b127?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920',
    "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    'https://images.unsplash.com/photo-1590955256762-e60f6e08122a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80',
    "https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80"
  ],
  male: [
    'https://images.unsplash.com/photo-1566492031773-4f4e44671857?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    'https://images.unsplash.com/photo-1513910367299-bce8d8a0ebf6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    'https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    'https://images.unsplash.com/photo-1601576084861-5de423553c0f?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920',
    'https://images.unsplash.com/photo-1642736468880-89d8a6de3fbe?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920',
    "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    "https://images.unsplash.com/photo-1522529599102-193c0d76b5b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80",
    "https://images.unsplash.com/photo-1629425733761-caae3b5f2e50?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80",
    "https://images.unsplash.com/photo-1544725391-7aefff7d6d4c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80",
    "https://images.unsplash.com/photo-1564564244660-5d73c057f2d2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80"
  ]
}

interface Props {
  user: User
  alert: AlertDialog
}

const navigation = {
  solutions: [
    { name: 'Marketing', href: '#' },
    { name: 'Analytics', href: '#' },
    { name: 'Commerce', href: '#' },
    { name: 'Insights', href: '#' },
  ],
  support: [
    { name: 'Pricing', href: '#' },
    { name: 'Documentation', href: '#' },
    { name: 'Guides', href: '#' },
    { name: 'API Status', href: '#' },
  ],
  company: [
    { name: 'About', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'Jobs', href: '#' },
    { name: 'Press', href: '#' },
    { name: 'Partners', href: '#' },
  ],
  legal: [
    { name: 'Claim', href: '#' },
    { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' },
  ],


  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/joinequipt',
      icon: (props: React.SVGProps<SVGSVGElement>) => <FaFacebook {...props} />,
    },
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/joinequipt',
      icon: (props: React.SVGProps<SVGSVGElement>) => <FaLinkedinIn {...props} />,
    },
    {
      name: 'TrustPilot',
      href: 'https://www.trustpilot.com/review/joinequipt.com',
      icon: (props: React.SVGProps<SVGSVGElement>) => <SiTrustpilot {...props} />,
    },
  ],
}

const questions = [
  {
    question: "What is the event format and topics?",
    answer: "These events are 60 minute and can be an interactive presentations or a Q&A-based session."
  },
  {
    question: "Where does this take place and how many people attend?",
    answer: "Run on zoom with a typical audience of 30 – 100 job seeking tech professionals"
  },
  {
    question: "What topics are discussed and what information is disclosed?",
    answer: "Speakers and presentations share their advice, insights, skills, career opportunities, etc. Proprietary or sensitive company information is not shared."
  },
  {
    question: "What is my time commitment as a speaker?",
    answer: "We work with your schedule! Sessions are 60-minutes and can take place from Monday to Friday at an hour slot that works best for you. Overall it takes 1.5 hours of your time (the 60-minute session and one 30-minute prep call)"
  },
  {
    question: "By participating, how do I benefit?",
    answer: "Recruit : Share your company’s jobs and build your talent community\n" +
      "• Give Back: Support diverse and skilled job-seeking tech professionals and students\n" +
      "• Skills: Build your public speaking, leadership, mentoring skills, and professional brand"
  },
]

const story = [
  {
    question: "Who are we, and what are we doing?",
    answer: "We are diverse professionals who have built successful careers in tech and finance. We've progressed in these industries by building our skills and by finding mentors that propelled us forward. Now, we are using technology to replicate our path & scale upskilling, networking, and mentoring for others. \n\n" +
      "Our mission is to put the power of the community at the center of job hunting and recruiting. We make it easy and fun for companies to find and nurture great talent and for job seekers to build their skills and networks and find great career opportunities through intimate virtual live workshops"
  },
  {
    question: "What does this cause mean to us?",
    answer: "The Equipt for Success Initiative is our chance to give back . We’ve mobilized our innovative professional networking platform to contribute to connecting talent to job opportunities during this challenging time in the tech industry."
  },
  {
    question: "What does it mean to our community?",
    answer: "For companies & startups, it means you can give back during a turbulent time in the industry. You can mobilize your greatest assets – your people & skills to hire professionals impacted by layoffs or help them find their next opportunity.\n\n" +
      "For talent, it means you can get access to professionals, recruiters, and advice that is typically out of reach."
  },
  {
    question: "What’s our impact?",
    answer: "We've run over a hundred hours of live workshops with professionals and recruiters at companies like Nike, EY, Google, and Accenture. These sessions have helped over  8,000  professionals explore possibilities, build their networks, and find new opportunities. "
  }
]

const testimonials = [
  {
    "name": "AJ Jimenez",
    "title": <>Senior Recruiter, <b>Dow Jones</b></>,
    "picture": profilePictures.male[0],
    "quote": `I was glad to see how interactive the community was! They are all talented and have the experience to get hired anywhere.`
  },
  {
    "name": "Rebecca",
    "title": <>Equipt Student</>,
    "picture": profilePictures.female[6],
    "quote": `I attended "Lessons from an Accenture Tech Consultant" yesterday and just wanted to say thank you. It was an amazing presentation, and I learned so much.`
  },
  {
    "name": "Ryley",
    "title": <>Equipt Student</>,
    "picture": profilePictures.female[0],
    "quote": `You’ve given me access to professionals at companies that are typically out of reach.`
  },
  {
    "name": "Joshua",
    "title": <>Equipt Student</>,
    "picture": profilePictures.male[6],
    "quote": `These workshops are wholesome, thank you!`
  }
]

const LandingInitiative: React.FC<Props> = () => {
  const [recordingsRequested, setRecordingsRequested] = useState<Set<string>>(new Set([]));
  const [requestRecording, setRequestRecording] = useState("");
  const { data: upcomingEvents } = useFetchAirTableCourses(
    {
      key: "upcomingEvents",
      filterByFormula: 'AND(DATETIME_DIFF(NOW(), date, "hours") <= 1,{shown} = TRUE())',
      sort: [{ field: "date", direction:"asc" }]});
  const { data: pastEvents } = useFetchAirTableCourses(
    {
      key: "pastEvents",
      filterByFormula: 'AND(DATETIME_DIFF(NOW(), date, "hours") > 1,{shown} = TRUE())',
      sort: [{ field: "date", direction:"desc" }]});

  return (
    <>
      <RequestRecordingModal recordingToRequest={requestRecording} setRecordingToRequest={setRequestRecording}
                             onRequestSuccess={(courseTitle: string) => {setRecordingsRequested((recordingsRequested => {
                               console.log(recordingsRequested);
                               return new Set([...Array.from(recordingsRequested), courseTitle])
                             })) }} formKey="xzbqbeba" />
      <section title="header" className="relative">
        <div className="absolute inset-0 bg-cover h-full bg-center" style={{backgroundImage: `url('${headerImage}')`}}/>
        <div className="absolute h-full w-full inset-0 flex bg-gray-700/60" />
        <div className="relative inset-0 pt-16 flex flex-col min-h-screen">
          <div className="flex flex-col grow justify-center my-28 px-5 md:px-0">
            <div className="w-full max-w-3xl mx-auto text-center">
              <h6 className="content_subtitle text-orange-500">Supporting Tech’s Most Valuable Asset</h6>
              <h1 className="content_page_heading text-white">Equipt for Success <br />
              <span className="text-highlighted from-orange-400/50 to-orange-400/20 ">Initiative</span></h1>
            </div>
            <div className="w-full max-w-2xl mx-auto text-center">
              <div className="text-xl leading-6 text-white">
                We’re helping tech talent impacted by recent layoffs get hired by connecting them with recruiters & leaders from leading companies and startups in live 60 minute online workshops.
              </div>
              <ScrollLink
                to="schedule"
                spy={true} smooth={true} duration={900}
                className="btn btn-color w-full max-w-md bg-orange mx-auto mt-20"
              >
                Get Started
              </ScrollLink>
            </div>
          </div>
          <div className="text-gray-300 border-t border-2 border-white bg-gray-50/90 py-5 md:pt-10 md:pb-8 ">
            <h6 className="text-gray-400 text-sm font-bold uppercase block text-center font-heading mb-1 px-4 lg:px-0">Supported by Recruiters & Tech Leaders from leading companies & startups</h6>
            <div className="flex items-center justify-around opacity-80 max-w-screen-2xl mx-auto text-gray-400/70 h-20">
              <CapitalOneLogo className="w-12 md:w-20 lg:w-32 mb-2" />
              <GoogleLogo  className="w-12 md:w-20 lg:w-24 h-16"/>
              <StashLogo className="w-16 md:w-28 lg:w-40 h-16" />
              <AccentureLogo className="w-16 md:w-24 lg:w-36 mb-4" />
              <NikeLogo  className="w-12 md:w-20 lg:w-24 mb-2" />
            </div>
          </div>
        </div>
      </section>
      <section title="about" className="bg-white py-40 flex items-center min-h-screen-90">
        <div className="max-w-screen-2xl w-full mx-auto flex items-center justify-center flex-wrap">
          <div className="lg:w-1/2 w-full flex items-center">
            <div className="mx-auto">
              <div className="relative w-72 h-72 sm:w-96 sm:h-96 md:w-xl md:h-xl lg:w-96 lg:h-96 xl:w-xl xl:h-xl">
                <div className="absolute inset-0 rounded-3xl w-full h-full drop-shadow-md -rotate-12 bg-blue-300" />
                <div className="absolute inset-0 rounded-3xl w-full h-full drop-shadow-md rotate-6 bg-blue-500" />
                <div className="absolute inset-0 bg-cover rounded-3xl w-full h-full drop-shadow-md -rotate-3" style={{backgroundImage: `url(${stockPhoto})`}} />
              </div>
            </div>
          </div>
          <div className="text-left lg:w-1/2 w-full flex items-center lg:mb-20 mt-20">
            <div className="lg:max-w-lg mx-auto px-10 md:p-0">
              <h6 className="text-blue content_subtitle">Who we are and what we do</h6>
              <h1 className="content_heading-gray pr-18">We believe in the <span className="text-highlighted from-orange-500/30 to-orange-500/20">power of community</span></h1>
              <div className="content_body-gray pr-10">
                Equipt is a live & interactive professional networking platform that transforms how companies and professionals connect and discover career opportunities. <br /><br />
                We’ve launched <b>Equipt for Success</b> to help tech professionals impacted by layoffs quickly find their next career opportunity. We do this by connecting talent to recruiters & leaders at leading companies & startups across the country in live one-hour group workshops.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section title="stats" className="bg-orange-500 px-5 md:p-0">
        <div className="max-w-screen-2xl mx-auto py-10 lg:py-20">
          <div className="text-white font-bold block text-xl lg:text-4xl leading-none text-center">Get Help & Hired through our Live Workshops</div>
          <div className="content_body text-white/60 text-center">
            Join collaborative sessions on resume & portfolio reviews, immigration support & more
          </div>
          <div className="flex mt-5 lg:mt-10">
            <div className="w-1/3 text-center">
              <div className="text-white/50 uppercase font-heading font-bold block text-4xl md:text-7xl">60</div>
              <div className="text-white uppercase font-heading font-bold block text-xs md:text-lg leading-none -mt-1">minute <br/> sessions</div>
            </div>
            <div className="w-1/3 text-center">
              <div className="text-white/50 uppercase font-heading font-bold block text-4xl md:text-7xl">30+</div>
              <div className="text-white uppercase font-heading font-bold block text-xs md:text-lg leading-none -mt-1">companies <br/> presenting</div>
            </div>
            <div className="w-1/3 text-center">
              <div className="text-white/50 uppercase font-heading font-bold block text-4xl md:text-7xl">50</div>
              <div className="text-white uppercase font-heading font-bold block text-xs md:text-lg leading-none -mt-1">attendees <br/>per session</div>
            </div>
          </div>
        </div>
      </section>
      <section title="topics" className="bg-white flex items-center pt-40 min-h-screen-70 px-5">
        <div className="max-w-7xl mx-auto text-center">
          <h6 className="text-blue content_subtitle">What you can expect</h6>
          <h1 className="content_heading-gray"> Workshops that open up <span className="text-highlighted from-orange-500/30 to-orange-500/20 ">opportunities</span></h1>
          <div className="content_body max-w-3xl mx-auto">
            We’re constantly crowdsourcing workshop topics from our attendees to make sure they help you find success. Some of our topics include
          </div>
          <div className="flex items-center justify-center mt-10 flex-wrap gap-3 lg:gap-6">
            <div className="bg-blue-500 text-white rounded-full uppercase px-5 lg:px-10 p-2 lg:py-3 font-semibold text-base lg:text-lg">Tech Talks</div>
            <div className="bg-blue-700 text-white rounded-full uppercase px-5 lg:px-10 p-2 lg:py-3 font-semibold text-base lg:text-lg">Founder Story</div>
            <div className="bg-blue-500 text-white rounded-full uppercase px-5 lg:px-10 p-2 lg:py-3 font-semibold text-base lg:text-lg">Interview Prep</div>
            <div className="bg-blue-300 text-white rounded-full uppercase px-5 lg:px-10 p-2 lg:py-3 font-semibold text-base lg:text-lg">Career Skills</div>
            <div className="bg-blue-500 text-white rounded-full uppercase px-5 lg:px-10 p-2 lg:py-3 font-semibold text-base lg:text-lg">Resume Reviews</div>
            <div className="bg-blue-500 text-white rounded-full uppercase px-5 lg:px-10 p-2 lg:py-3 font-semibold text-base lg:text-lg">Visa & Immigration Advice</div>
            <div className="bg-blue-300 text-white rounded-full uppercase px-5 lg:px-10 p-2 lg:py-3 font-semibold text-base lg:text-lg">Diverse Talent Support</div>
            <div className="bg-blue-500 text-white rounded-full uppercase px-5 lg:px-10 p-2 lg:py-3 font-semibold text-base lg:text-lg">Portfolio Critique</div>
            <div className="bg-blue-700 text-white rounded-full uppercase px-5 lg:px-10 p-2 lg:py-3 font-semibold text-base lg:text-lg">Job Hunting Strategies</div>
            <div className="bg-blue-300 text-white rounded-full uppercase px-5 lg:px-10 p-2 lg:py-3 font-semibold text-base lg:text-lg">Career Fairs</div>
          </div>
        </div>
      </section>
      <section title="testimonials" className="bg-gradient-to-b from-white to-gray-100 flex items-center py-40 min-h-screen-70 overflow-x-hidden">
        <div className="max-w-7xl mx-auto text-left w-full">
          <div className="px-5 md:px-12">
            <h6 className="text-blue content_subtitle">Feedback</h6>
            <h1 className="content_heading-gray">What our community <br className="hidden sm:inline-block lg:hidden" /> <span className="text-highlighted from-orange-500/30 to-orange-500/20 ">is saying</span></h1>
            <div className="content_body max-w-4xl">
              The feedback from our community has been strong.
            </div>
          </div>
          <div className="mt-10 max-w-lg">
            <Swiper
              navigation={true}
              spaceBetween={30}
              modules={[Navigation]}
              speed={500}
              className="past-event-swiper"
            >
              {testimonials.map(({ name, title, picture, quote }) =>
                <SwiperSlide className="p-12">
                  {({ isActive }) => (
                    <div className={classNames(isActive ? "bg-orange-500 text-white":"bg-white text-gray-500",
                          isActive ? "shadow-2xl -translate-y-3" : "shadow-lg",
                          "transition ease-in-out duration-500",
                        "max-w-lg h-64 rounded-2xl p-5 flex flex-col relative shadow-xl")}>
                      <div className={classNames(isActive ? "text-gray-800/10":"text-gray-500/10", "absolute font-heading font-normal")} style={{fontSize: "400px", lineHeight:'300px'}}>“</div>
                      <div className="text-lg md:text-xl italic p-2">{quote}</div>
                      <div className="flex mt-auto">
                        <div
                          className="h-10 w-10 rounded-full inline bg-center bg-cover"
                          style={{backgroundImage: `url(${picture})`}}
                        />
                        <div className="leading-none flex flex-col justify-center pl-2">
                          <div className="font-semibold mb-.5">{name}</div>
                          <div className="font-light opacity-80">{title}</div>
                        </div>
                      </div>
                    </div>
                  )}
                </SwiperSlide>
              )}
            </Swiper>
            {upcomingEvents.length === 0 ? <div /> : "" }
          </div>
        </div>
      </section>
      <section title="schedule" className="bg-white flex py-40 min-h-screen">
        <ScrollElement name="schedule" className="max-w-5xl mx-auto text-center px-5 xl:px-0">
          <h6 className="text-blue content_subtitle">Workshop Schedule</h6>
          <h1 className="text-gray-600 content_heading-gray">
            Attend Free weekly live <br className="hidden md:block"/> <span className="text-highlighted from-orange-500/30 to-orange-500/20 ">one-hour</span> workshops</h1>
          <div className="content_body-gray mx-auto max-w-3xl">
            Every week there will be 1-2 free events you can attend. Sign up here to receive updates on new events as we add them. Browse workshops below that are open for registration.
          </div>
          <SubscriberForm formKey="xpzeavwr" />
          <div className="flex items-center justify-center mt-10 flex-wrap gap-6">
            <div className="uppercase font-heading w-full text-left font-bold -mb-5 text-gray-400 text-lg">Upcoming Workshops</div>
            {upcomingEvents.map((course, index) => <SponsoredEventTile hasPast={false}  key={`event-tile-${index}`} course={course} company={course.organization} />)}
            {upcomingEvents.length === 0 ? <div /> : "" }
          </div>
        </ScrollElement>
      </section>
      <section title="past events" className="bg-gray-100 flex py-40 min-h-screen overflow-x-hidden">
        <div className="max-w-5xl w-full mx-auto">
          <div className="content_body-gray mx-auto max-w-3xl text-center">In case you missed it, check out some of our past events</div>
            <div className="mt-10 max-w-xl">
              <Swiper
                navigation={true}
                modules={[Navigation]}
                speed={500}
                className="past-event-swiper"
              >
                {pastEvents.map((course, index) =>
                  <SwiperSlide className="px-10 md:px-12 md:w-xl">
                    {({ isActive }) => (
                      <SponsoredEventTile
                        hasPast={true} isRequested={recordingsRequested.has(course.title)} isActive={isActive} key={`event-tile-${index}`} course={course} company={course.organization}
                        onRequest={(courseTitle: string) => setRequestRecording(courseTitle)} />
                    )}
                  </SwiperSlide>
                )}
              </Swiper>
              {pastEvents.length === 0 ? <div /> : "" }
            </div>
        </div>
      </section>
      <section title="company cta" className="bg-gray-100 z-1 px-5">
        <div className="max-w-7xl mx-auto bg-blue drop-shadow-lg rounded-2xl p-10 md:p-20 text-center relative -mb-56 z-1 flex-col lg:flex-row flex items-center">
          <div className="leading-none text-white font-bold font-heading text-4xl xl:text-5xl uppercase text-center lg:text-left">Want to Help and Hire <br/> Laid-Off Tech Professionals?</div>
          <div className="lg:pl-28">
            <div className="text-blue-100 mx-auto content_body lg:mb-2 my-5">
              If you’re a professional or recruiter we’d be excited to have you speak at our next workshop! Sessions are 60 minutes and are free for attendees. We take care of all the logistics and use a scalable workshop format that allows many professionals to benefit from your advice and expertise.</div>
            <a href="https://bit.ly/EquiptXSupport" target="_blank" className="rounded-none btn btn-secondary-white mx-auto max-w-lg w-full">Run a Session</a>
          </div>
        </div>
      </section>
      <section title="faq" className="py-48 bg-blue-700">
        <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:pb-8 lg:max-w-7xl lg:pt-24 lg:px-8">
          <h2 className="text-3xl font-extrabold text-white tracking-tight opacity-80 text-center lg:text-left">Frequently Asked Questions</h2>
          {/*<p
            className="mt-4 max-w-3xl text-lg text-white opacity-50">
            60-minute learning session led by industry experts all follow the same basic format. Attending this class will unlock an opportunity to engage with the instruction and apply for jobs
          </p>*/}
          <div className="mt-12 grid grid-cols-1 gap-4 md:gap-6 md:grid-cols-2 lg:grid-cols-2 lg:gap-8">
            {questions.map(({ question, answer}) => (
              <div className="bg-white/5 rounded-xl p-7">
                <div>
                  <h3 className="text-lg font-medium text-white">{question}</h3>
                  <p className="mt-2 text-base text-white opacity-50">{answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section title="our story" className="pb-20 pt-32 lg:pt-48 bg-gray-700">
        <div className="max-w-5xl mx-auto text-center">
          <h6 className="text-orange-500 content_subtitle">about us</h6>
          <h1 className="text-white content_heading">our story</h1>
          <div className="flex py-10">
            <div className="basis-1/2">
              <img className="mx-auto sm-40 h-40 sm:h-64 sm:w-64 md:h-80 md:w-80 lg:h-96 lg:w-96 rounded-full" src={TeacherHubertPhoto} alt="" />
              <div className="py-5 md:text-xl text-white/60"><b>Hubert Dagbo<span className="hidden md:inline">,</span></b> <br className="md:hidden"/> Cofounder</div>
            </div>
            <div className="basis-1/2">
              <img className="mx-auto sm-40 h-40 sm:h-64 sm:w-64 md:h-80 md:w-80 lg:h-96 lg:w-96 rounded-full" src={TeacherJidePhoto} alt="" />
              <div className="py-5 md:text-xl text-white/60"><b>Jide Osan<span className="hidden md:inline">,</span></b> <br className="md:hidden"/>  Cofounder</div>
            </div>

          </div>
          <div className="lg:mt-12 text-left px-10 lg:px-20">
            {story.map(({ question, answer}) => (
              <div className="py-4">
                <div>
                  <h3 className="text-lg font-medium text-white">{question}</h3>
                  <p className="mt-2 text-base text-white opacity-50">{answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section title="final cta" className="bg-gray-700 z-1 p-5">
        <div className="max-w-7xl mx-auto bg-orange drop-shadow-lg rounded-2xl p-10 lg:p-20 text-center relative -mb-56 z-1 text-center">
          <div className="leading-none text-white font-bold font-heading text-3xl lg:text-5xl uppercase mb-6">Interested in working with us?</div>
            <div className="text-white/80 mx-auto content_body mb-6 lg:px-32">
              We’re building a live & interactive professional networking platform that transforms how companies and talent connect. If you’d like to learn more, we'd love to hear from you.
            </div>
            <a href="https://calendly.com/hubert-dagbo/30min?back=1&month=2021-07" target="_blank" className="rounded-none btn btn-secondary-white mx-auto w-full md:w-96">Contact Us</a>

        </div>
      </section>
      <footer className="bg-gray-800 relative pt-56" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="grid grid-cols-3 gap-8">
            <div className="col-span-3 md:col-span-2 lg:col-span-1">
              <EquiptLogo className="logo-white-orange h-12 w-auto" />
              <div className="text-white/60 text-lg font-light mt-2">Transforming how companies <br /> and professionals connect</div>
              {/*<div className="flex justify-between text-white uppercase font-heading font-bold mt-8 text-sm">
                <span>Companies</span>
                <span>Members</span>
                <span>Partners</span>
              </div>*/}
            </div>
            <div className="col-start-3 col-span-1">
              {/*<h3 className="text-base font-medium text-white uppercase font-heading font-bold">Subscribe to our newsletter</h3>
              <p className="mt-4 text-blue-200 text-lg font-semibold">
                The latest news, articles, and resources, sent to your inbox weekly.
              </p>
              <form className="mt-4 sm:flex sm:max-w-md">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  type="email"
                  name="email-address"
                  id="email-address"
                  autoComplete="email"
                  required
                  className="w-full min-w-0 appearance-none rounded-l-full border border-transparent bg-white py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:border-white focus:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  placeholder="Enter your email"
                />
                <div className="mt-3 rounded-md sm:mt-0 -ml-5 sm:flex-shrink-0">

                  <button className="rounded-none btn btn-color bg-orange">Subscribe</button>
                </div>
              </form>*/}
            </div>
          </div>
          <div className="mt-8 border-t border-white/60 pt-8 md:flex md:items-center md:justify-between">
            <div className="flex space-x-6 md:order-2">
              {navigation.social.map((item) => (
                <a key={item.name} href={item.href} className="text-white/60 hover:text-white/20">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
            <p className="mt-8 text-base text-white/60 md:order-1 md:mt-0">
              &copy; 2022 Equipt, Inc. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
  </>
  )

}

export default LandingInitiative
