import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import './index.css';
import App from './App';
import { ApolloProvider } from "@apollo/client";
import { HelmetProvider } from "react-helmet-async"
import { apolloApi } from "./api"
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { hotjar } from 'react-hotjar';
hotjar.initialize(parseFloat(process.env.REACT_APP_HOTJAR_ID || ''), 6);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  integrations: [
    new BrowserTracing(),
    new Sentry.Integrations.Breadcrumbs({
      console: false
    })
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

window.Intercom("boot", {
  api_base: process.env.REACT_APP_INTERCOM_API,
  app_id: process.env.REACT_APP_INTERCOM_ID
});

const rootElement = document.getElementById('root')
if (!rootElement) throw new Error('Failed to find the root element');
const queryClient = new QueryClient();
createRoot(rootElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={apolloApi}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </ApolloProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
