import './Settings.css'
import React from 'react'
import {Link, useLocation, Outlet} from 'react-router-dom';
import {
  //CogIcon,
  //KeyIcon,
  UserCircleIcon,
  AcademicCapIcon,
  ArrowPathIcon
} from '@heroicons/react/24/outline'


const subNavigation = [
  { name: 'Profile', href: '/settings', icon: UserCircleIcon },
  { name: 'Organizations', href: '/settings/organizations', icon: AcademicCapIcon },
  { name: 'Subscriptions', href: '/settings/subscriptions', icon: ArrowPathIcon },
  //{ name: 'Account', href: '/settings/account', icon: CogIcon },
  //{ name: 'Password', href: '/settings/password', icon: KeyIcon },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}
interface Props {

}

const Settings: React.FC<Props> = () => {

  let { pathname: currentPage } =  useLocation();
  return (
    <main className="relative bg-transparent">
      <header className="relative py-10 pt-28">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-5xl font-bold text-white pt-30">Settings</h1>
        </div>
      </header>
      <div className="max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8 bg-transparent">
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
            <aside className="py-6 lg:col-span-3">
              <nav className="space-y-1 pt-10">
                {subNavigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      item.href === currentPage
                        ? 'bg-blue-100/70 border-blue-500 text-blue-700 hover:bg-blue-50 hover:text-blue-700'
                        : 'border-transparent bg-gray-50 text-gray-900 hover:bg-gray-200 hover:text-gray-900',
                      'group border-l-4 px-3 py-2 pl-8 flex items-center font-semibold'
                    )}
                    aria-current={item.href === currentPage ? 'page' : undefined}
                  >
                    <item.icon
                      className={classNames(
                        item.href === currentPage
                          ? 'text-blue-500 group-hover:text-blue-500'
                          : 'text-gray-400 group-hover:text-gray-500',
                        'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    <span className="truncate">{item.name}</span>
                  </Link>
                ))}
              </nav>
            </aside>
            <div className="divide-y divide-gray-200 lg:col-span-9 lg:col-span-9">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Settings
