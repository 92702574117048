 /* Created by JideOsan on 10/11/2022. */
import gql from "graphql-tag";
import {
  MutationFunction,
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import {supabase, supahelpers} from "../../api_supabase";
import {parseProfile} from "./factories";

 export const UserFragment = `
created
id
updated
bio
email
first_name
image
last_name
location
privacy_available_to_hire
slug
social_linkedin
social_discord
stripe_customer_id
subscription_level
tagline
username
accepted_terms
`;

export const GET_USER_QUERY_BY_ID = (id: string) => gql`
  {
    user(user:{ id: "${id}" }) {
      ${UserFragment}
    }
  }
`;

export const FIND_USER_QUERY = (id: string, bio: string, email: string, first_name: string, image: string, last_name: string, location: string, privacy_available_to_hire: boolean, slug: string, social_linkedin: string, social_discord: string, subscription_level: number, tagline: string, username: string) => gql`
  {
    users(user:{ id: "${id}", bio: "${bio}", email: "${email}", first_name: "${first_name}", image: "${image}", last_name: "${last_name}", location: "${location}", privacy_available_to_hire: "${privacy_available_to_hire}", slug: "${slug}", social_linkedin: "${social_linkedin}", social_discord: "${social_discord}", subscription_level: "${subscription_level}", tagline: "${tagline}", username: "${username}" }) {
      ${UserFragment}
    }
  }
`;

export const CREATE_USER_MUTATION = gql`
  mutation UserCreate($bio: String, $email: String, $first_name: String, $image: String, $last_name: String, $location: String, $privacy_available_to_hire: Boolean, $slug: String, $social_linkedin: String, $social_discord: String, $stripe_customer_id: String, $subscription_level: Float, $tagline: String, $username: String){
    userCreate(user:{ bio: $bio, email: $email, first_name: $first_name, image: $image, last_name: $last_name, location: $location, privacy_available_to_hire: $privacy_available_to_hire, slug: $slug, social_linkedin: $social_linkedin, social_discord: $social_discord, stripe_customer_id: $stripe_customer_id, subscription_level: $subscription_level, tagline: $tagline, username: $username }) {
      ${UserFragment}
    }
  }
`;

 export const CREATE_PASSWORDLESS_USER_MUTATION = gql`
  mutation UserCreate($bio: String, $email: String, $first_name: String, $image: String, $last_name: String, $location: String, $privacy_available_to_hire: Boolean, $slug: String, $social_linkedin: String, $social_discord: String, $stripe_customer_id: String, $subscription_level: Float, $tagline: String, $username: String, $accepted_terms: String!, $phone: String, $auto_login: Boolean){
    userCreatePasswordless(user:{ bio: $bio, email: $email, first_name: $first_name, image: $image, last_name: $last_name, location: $location, privacy_available_to_hire: $privacy_available_to_hire, slug: $slug, social_linkedin: $social_linkedin, social_discord: $social_discord, stripe_customer_id: $stripe_customer_id, subscription_level: $subscription_level, tagline: $tagline, username: $username, accepted_terms: $accepted_terms, phone: $phone}, auto_login: $auto_login) {
      ${UserFragment}
    }
  }
`;

export const DELETE_USER_MUTATION = gql`
  mutation UserDelete($id: ID!){
     userDelete(user:{ id: $id }) {
      ${UserFragment}
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UserUpdate($application: String!, $bio: String, $email: String, $first_name: String, $image: String, $last_name: String, $location: String, $privacy_available_to_hire: Boolean, $slug: String, $social_linkedin: String, $social_discord: String, $stripe_customer_id: String, $subscription_level: Float, $tagline: String, $username: String){
    userUpdate(user:{ application: $string, bio: $string, email: $string, first_name: $string, image: $string, last_name: $string, location: $string, privacy_available_to_hire: $boolean, slug: $string, social_linkedin: $string, social_discord: $string, stripe_customer_id: $string, subscription_level: $number, tagline: $string, username: $string }) {
      ${UserFragment}
    }
  }
`;

 export const REQUEST_UPDATE_SUBSCRIPTION_MUTATION = gql`
  mutation RequestUpdateSubscription($user: ID!, $subscription_level: Float!, $referrer: String) {
    requestUpdateSubscription(user: {id: $user, subscription_level: $subscription_level}, referrer: $referrer){
      status
      message
    }
  }
`;

 interface UpdateProfileProps {
   profile: RequireOnly<Profile, "id">,
   user: RequireOnly<User, "id">
 }

 export function useUpdateProfile(): UseMutationResult<Profile, Error, UpdateProfileProps> {
   const updateProfile: MutationFunction<Profile, UpdateProfileProps> = async({ profile}) => {
     const { created, id, updated, image, ...profileData } = profile

     if(image?.indexOf("tmp/") === 0){
       await supahelpers.functions.updateImage({
         table_name: "profiles",
         column_name: "image",
         image: image || "",
         bucket: 'avatars',
         id: id
       })
     }

     const { data: profiles } = await supabase
       .from('profiles')
       .update(profileData)
       .eq('id', id).select();

     return ((profiles || []).map((profile) => parseProfile(profile)))[0];
   }

   const queryClient = useQueryClient()

   return useMutation({
     mutationFn: updateProfile,
     onSuccess: () => {
       queryClient.invalidateQueries({ queryKey: ["appUser"]})
     }
   })
 }