import React, { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { LOGIN_MUTATION } from "../../data/client/authentication.api";
import { classNames } from "../../helpers";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import AuthInputField from "./AuthInputField";
import AuthPasswordField from "./AuthPasswordField";
import {parseUser} from "../../data/client/factories";
import {supabase} from "../../api_supabase";

interface Values {
  email: string
  password: string
}

interface Props {
  user: User,
  refreshUser: (args?: any) => Promise<any>
  buttonText?: string
  onLogin?: (user: User) => Promise<any>
}

const Login: React.FC<Props> = ({ refreshUser,  buttonText, onLogin}) => {
  const [login] = useMutation(LOGIN_MUTATION);

  const handleSubmit =  useCallback(async (values: Values, { setFieldError, setStatus } : FormikHelpers<Values>) => {
    setStatus('')
    let userData;
    try {
      const { error: signInError } = await supabase.auth.signInWithPassword({email: values.email, password: values.password })
      if(signInError) throw signInError;
      await refreshUser();
    } catch (e: any){
      setStatus('submit_error')
      setFieldError("email", "Invalid Credentials")
      setFieldError("password", "Invalid Credentials")
    }
    if(userData && onLogin) await onLogin(parseUser(userData))
  }, [login, onLogin])

  return (
    <Formik initialValues={{email: '', password: '' }} onSubmit={handleSubmit} >
      {({ isSubmitting, status }) => (
        <>
          <div className="sm:mx-auto w-full mb-6 auth_header">
            <h2 className="text-center text-gray-400 text-4xl">Login</h2>
            <h6 className="text-center text-sm uppercase mt-4 py-2 font-semibold text-orange-500">Welcome back!</h6>
          </div>
          <Form className="gap-0 gap-x-2 grid grid-cols-12 input-hide-errors" action="#" method="POST">
            <AuthInputField name="email" id="register_email" placeholder="Email Address"
                            type="email" autoComplete="email" className="input input-secondary"/>
            <AuthPasswordField name="password" id="register_password" placeholder="Password"
                               autoComplete="current-password" className="input input-secondary"/>
            <div className="flex items-center justify-center col-span-12 mb-5">
              <div className="flex items-center">
                <Field
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-3.5 w-3.5 text-blue-500 focus:ring-blue-300 border-gray-300 rounded"
                />
                <label htmlFor="remember-me" className="ml-2 block text-xs text-gray-400 uppercase">
                  Remember me
                </label>
              </div>
            </div>
            <div className="col-span-12">
              <button
                type="submit"
                className={
                  classNames(
                    "animate__animated animate__fast",
                    isSubmitting ? "pointer-events-none opacity-50" :"",
                    status === "submit_error" ? 'animate__shakeX': '',
                    "text-center font-bold font-button text-sm text-white uppercase px-6 py-3 w-full flex justify-center border border-transparent rounded-full shadow-sm bg-brand-primary hover:bg-orange-600 outline-none focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-300"
                  )}
              >
                {buttonText || "Sign in" }
              </button>
              <span className="text-sm h-10 w-full block flex justify-center items-center text-red-500 italic" >
            <ErrorMessage name="email"/>
          </span>
            </div>
          </Form>
        </>
      )}
  </Formik>
  )
}

export default Login
