/* Created  on 7/25/2023. */
import gql from "graphql-tag";
import {
  MutationFunction,
  QueryFunction, useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from "@tanstack/react-query";
import {supabase} from "../../api_supabase";
import {parseMembership, parseInstructor, parseProfile, parseUser, parseOrganization} from "./factories";

export const instructorFragment = `
created
id
updated
course {
	created
	id
	updated
	attended
	cover
	seo_image
	organization {
		created
		id
		updated
		admin {
			created
			id
			updated
			accepted_terms
			email
			privacy_available_to_hire
			stripe_customer_id
			subscription_level
			username 
		}
		approval_process
		approval_key
		benefits
		bio
		description
		hired
		logo
		logo_alt
		location
		name
		slug
		social_linkedin
		tagline
		theme
		type
		values 
	}
	description
	event
	highlights
	membership_type
	slug
	tagline
	title 
}
course_module {
	created
	id
	updated
	available
	course {
		created
		id
		updated
		attended
		cover
		seo_image
		organization {
			created
			id
			updated
			admin {
				created
				id
				updated
				accepted_terms
				email
				privacy_available_to_hire
				stripe_customer_id
				subscription_level
				username 
			}
			approval_process
			approval_key
			benefits
			bio
			description
			hired
			logo
			logo_alt
			location
			name
			slug
			social_linkedin
			tagline
			theme
			type
			values 
		}
		description
		event
		highlights
		membership_type
		slug
		tagline
		title 
	}
	module {
		created
		id
		updated
		cover
		description
		duration
		skills
		tagline
		title 
	}
	order 
}
featured
organization {
	created
	id
	updated
	admin {
		created
		id
		updated
		accepted_terms
		email
		privacy_available_to_hire
		stripe_customer_id
		subscription_level
		username 
	}
	approval_process
	approval_key
	benefits
	bio
	description
	hired
	logo
	logo_alt
	location
	name
	slug
	social_linkedin
	tagline
	theme
	type
	values 
}
membership {
	created
	id
	updated
	membership_type
	organization {
		created
		id
		updated
		admin {
			created
			id
			updated
			accepted_terms
			email
			privacy_available_to_hire
			stripe_customer_id
			subscription_level
			username 
		}
		approval_process
		approval_key
		benefits
		bio
		description
		hired
		logo
		logo_alt
		location
		name
		slug
		social_linkedin
		tagline
		theme
		type
		values 
	}
	user {
		created
		id
		updated
		accepted_terms
		email
		privacy_available_to_hire
		stripe_customer_id
		subscription_level
		username 
	}
	accepted
	rejected 
}
user {
	created
	id
	updated
	accepted_terms
	email
	privacy_available_to_hire
	stripe_customer_id
	subscription_level
	username 
}
module {
	created
	id
	updated
	cover
	description
	duration
	skills
	tagline
	title 
}
session {
	created
	id
	updated
	course {
		created
		id
		updated
		attended
		cover
		seo_image
		organization {
			created
			id
			updated
			admin {
				created
				id
				updated
				accepted_terms
				email
				privacy_available_to_hire
				stripe_customer_id
				subscription_level
				username 
			}
			approval_process
			approval_key
			benefits
			bio
			description
			hired
			logo
			logo_alt
			location
			name
			slug
			social_linkedin
			tagline
			theme
			type
			values 
		}
		description
		event
		highlights
		membership_type
		slug
		tagline
		title 
	}
	course_module {
		created
		id
		updated
		available
		course {
			created
			id
			updated
			attended
			cover
			seo_image
			organization {
				created
				id
				updated
				admin {
					created
					id
					updated
					accepted_terms
					email
					privacy_available_to_hire
					stripe_customer_id
					subscription_level
					username 
				}
				approval_process
				approval_key
				benefits
				bio
				description
				hired
				logo
				logo_alt
				location
				name
				slug
				social_linkedin
				tagline
				theme
				type
				values 
			}
			description
			event
			highlights
			membership_type
			slug
			tagline
			title 
		}
		module {
			created
			id
			updated
			cover
			description
			duration
			skills
			tagline
			title 
		}
		order 
	}
	files
	meeting_link
	module {
		created
		id
		updated
		cover
		description
		duration
		skills
		tagline
		title 
	}
	registrations
	seats
	starts_at
	status 
}
`;

export const GET_INSTRUCTOR_QUERY_BY_ID = (id: string) => gql`
  {
    instructor(instructor:{ id: "${id}" }) {
      ${instructorFragment}
    }
  }
`;

export const FIND_INSTRUCTOR_QUERY = (id: string, course: Course, course_module: CourseModule, featured: boolean, organization: Organization, membership: Membership, user: User, module: Module, session: Session) => gql`
  {
    instructors(instructor:{ id: "${id}", course: "${course}", course_module: "${course_module}", featured: "${featured}", organization: "${organization}", membership: "${membership}", user: "${user}", module: "${module}", session: "${session}" }) {
      ${instructorFragment}
    }
  }
`;

export const CREATE_INSTRUCTOR_MUTATION = gql`
  mutation InstructorCreate($course: IdInput!, $course_module: IdInput!, $featured: Boolean, $organization: IdInput, $membership: IdInput, $user: IdInput!, $module: IdInput!, $session: IdInput){
    instructorCreate(instructor:{ course: $course, course_module: $course_module, featured: $featured, organization: $organization, membership: $membership, user: $user, module: $module, session: $session }) {
      ${instructorFragment}
    }
  }
`;

export const DELETE_INSTRUCTOR_MUTATION = gql`
  mutation InstructorDelete($id: ID!){
     instructorDelete(instructor:{ id: $id }) {
      ${instructorFragment}
    }
  }
`;

export const UPDATE_INSTRUCTOR_MUTATION = gql`
  mutation InstructorUpdate($course: IdInput!, $course_module: IdInput!, $featured: Boolean, $organization: IdInput, $membership: IdInput, $user: IdInput!, $module: IdInput!, $session: IdInput){
    instructorUpdate(instructor:{ course: $string, course_module: $string, featured: $boolean, organization: $string, membership: $string, user: $string, module: $string, session: $string }) {
      ${instructorFragment}
    }
  }
`;

export function useFindNewInstructors({ organization_id, search = "" }: { organization_id?: string, search: string }): UseQueryResult<Instructor[]> {
  const fetchInstructors: QueryFunction<Instructor[]> = async () => {
    if(!organization_id && search == "") return [];
    let query = supabase
      .from('profiles')
      .select('first_name, last_name, image, user(id, memberships(id, membership_type, organization( id, logo, name)))')
      .gte("user.memberships.membership_type", 3)

    if(organization_id) query = query.eq('user.memberships.organization.id', organization_id);
    if(search !== "") query = query.ilike('full_name', `%${search}%`)

    const { data } = await query;
    const instructors: Instructor[] = [];
    (data || []).forEach((profile) => {
      let user = (profile.user ? (Array.isArray(profile.user) ? profile.user[0] : profile.user) : { id: '', memberships: []});
      let memberships = (user.memberships ? (Array.isArray(user.memberships) ? user.memberships : [user.memberships]) : [])
      return memberships.forEach((membership) => {
        let organization = membership.organization ? (Array.isArray(membership.organization) ? membership.organization[0] : membership.organization) : { id: '', logo: '', name: ''}
        instructors.push(parseInstructor({
          id: '',
          user: parseUser({
            id: user.id,
            profile: parseProfile({ ...profile, user: undefined })
          }),
          membership: parseMembership(membership),
          organization: parseOrganization(organization)
        }))
      })
    });

    return instructors;
  }

  return useQuery<Instructor[]>({
    queryKey: ["instructor_memberships", organization_id, search ],
    queryFn: fetchInstructors,
    initialData: []
  })
}

export function useFetchMemberships({ user_id }: { user_id: string }): UseQueryResult<Membership[]> {

  const fetchMemberships: QueryFunction<Membership[]> = async () => {
    const { data } = await supabase
      .from('memberships')
      .select('*, organization( id, logo, location, name, type, description)')
      .eq("user", user_id);

    return (data || []).map((membership) => parseMembership(membership));
  }

  return useQuery<Membership[]>({
    queryKey: ["user_memberships", user_id ],
    queryFn: fetchMemberships,
  })
}

export function useAddInstructors(): UseMutationResult<Instructor[], Error, { instructors: CreateInstructorServerModel[] }> {
  const addInstructor: MutationFunction<Instructor[], { instructors: CreateInstructorServerModel[] }> = async(variables) => {
    const { instructors: newInstructors } = variables

    const dataToInsert = newInstructors.map((newInstructor) => {
      const {course, course_module, membership, module, organization, user, session, ...instructor} = newInstructor
      return {
        ...instructor,
        organization: organization?.id,
        user: user.id,
        course: course?.id,
        course_module: course_module?.id,
        membership: membership?.id,
        module: module?.id,
        session: session?.id
      }
    });

    const { data } = await supabase
      .from('instructors')
      .insert(dataToInsert).select();
    return ((data || []).map((instructor) => parseInstructor(instructor)));
  }

  return useMutation({
    mutationFn: addInstructor
  })
}

export function useDeleteMemberships(): UseMutationResult<Membership, Error, { id: string }> {
  const addMembership: MutationFunction<Membership,  { id: string }> = async({ id }) => {
    const { data } = await supabase
      .from('memberships')
      .delete()
      .match({'id': id}).select();
    return ((data || []).map((membership) => parseMembership(membership)))[0];
  }

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: addMembership,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user_memberships"]})
    }
  })
}
