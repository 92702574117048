/* Created by JideOsan on 10/11/2022. */
import gql from "graphql-tag";
import {api, supabase} from "../../api_supabase";
import {Mutation, Sessions} from "../../gql/graphql";
import {parseServerSession} from "./factories.gql";
import {MutationFunction, useMutation, UseMutationResult, useQuery} from "@tanstack/react-query";
import {parseSession} from "./factories";

export const SessionFragment = `
created
id
updated
course {
	id
}
course_module {
	created
	id
	updated
	available
	course {
		id
	}
	module {
		id
	}
	order
	subscription_level 
}
files
modules {
	created
	id
	updated
	cover
	description
	duration
	skills
	tagline
	title 
}
registrations
seats
starts_at
status
`;

export const GET_SESSION_QUERY_BY_ID = (id: string) => gql`
  {
    session(session:{ id: "${id}" }) {
      ${SessionFragment}
    }
  }
`;

export const FIND_SESSION_QUERY = (id: string, additional_instructors: string[], course: Course, course_module: CourseModule, files: string[], instructor: User, module: Module, registrations: number, seats: number, starts_at: string, status: string) => gql`
  {
    sessions(session:{ id: "${id}", additional_instructors: "${additional_instructors}", course: "${course}", course_module: "${course_module}", files: "${files}", instructor: "${instructor}", module: "${module}", registrations: "${registrations}", seats: "${seats}", starts_at: "${starts_at}", status: "${status}" }) {
      ${SessionFragment}
    }
  }
`;

export const FIND_SESSION_BY_COURSE = (course: string) => gql`
  {
    sessions(session:{ course: "${course}" }) {
      created
      id
      updated
      additional_instructors
      registrations
      seats
      starts_at
      status
      instructor {
        created
        id
        updated
        bio
        email
        first_name
        image
        last_name
        location
        privacy_available_to_hire
        slug
        social_linkedin
        subscription_level
        social_discord
        tagline
        username 
      }
      course_module {
	      id
	    }
    }
  }
`;

export const CREATE_SESSIONS_MUTATION = gql`
  mutation InsertIntosessionsCollection($objects: [sessionsInsertInput!]!) {
    insertIntosessionsCollection(objects: $objects) {
      affectedCount
      records {
        ${SessionFragment}
      }
    }
  }
`;

export const DELETE_SESSION_MUTATION = gql`
  mutation SessionDelete($id: ID!){
     sessionDelete(session:{ id: $id }) {
      ${SessionFragment}
    }
  }
`;

export const UPDATE_SESSION_MUTATION = gql`
  mutation SessionUpdate($application: String!, $additional_instructors: [String], $course: IdInput!, $course_module: IdInput, $files: [String], $instructor: IdInput!, $module: IdInput!, $registrations: Float, $seats: Float, $starts_at: String, $status: String){
    sessionUpdate(session:{ application: $string, additional_instructors: $array, course: $string, course_module: $string, files: $array, instructor: $string, module: $string, registrations: $number, seats: $number, starts_at: $string, status: $string }) {
      ${SessionFragment}
    }
  }
`;

interface UseCreateSessionProps {
  sessions: RequireOnly<Session, "course" | "course_module" | "module">[]
}

export function useFetchRecentSessions({ key, limit, sort_by }: { key?: string, limit?: number, sort_by?: string }) {

  async function fetchSessions(): Promise<Session[]> {

    let query = supabase
      .from('sessions')
     .select('*, course(id, cover, title, slug), instructors!instructors_session_fkey(id, featured, user(id, profiles(id, image, tagline, first_name, last_name)), memberships!instructor_membership_organization_user(id, organization(id, name, logo)))');
    if(limit) query = query.limit(limit)
    if(sort_by) query = query.order(sort_by, { ascending: false })

    const { data } = await query;
    return (data || []).map((session) => {
      return parseSession(session);
    });
  }

  let queryKey = ["recent_sessions"];
  if(key) queryKey.push(key);

  return useQuery<Session[]>({
    queryKey,
    queryFn: fetchSessions,
    initialData: []
  })
}

export function useGetSessions({ key, limit, sort_by, course }: { key?: string, limit?: number, sort_by?: string, course?: string }) {

  async function fetchSessions(): Promise<Session[]> {

    let query = supabase
      .from('sessions')
      .select('*, course_module(*), course(id, cover, title, slug), instructors!instructors_session_fkey(id, featured, user(id, profiles(id, image, tagline, first_name, last_name)), memberships!instructor_membership_organization_user(id, organization(id, name, logo)))');
    if(course) query.eq("course", course)
    if(limit) query = query.limit(limit)
    if(sort_by) query = query.order(sort_by, { ascending: false })

    const { data } = await query;
    return (data || []).map((session) => {
      return parseSession(session);
    });
  }

  let queryKey = ["sessions"];
  if(key) queryKey.push(key);

  return useQuery<Session[]>({
    queryKey,
    queryFn: fetchSessions,
    initialData: []
  })
}


export function useCreateSession(): UseMutationResult<Session[], Error, UseCreateSessionProps>{
  const createSession: MutationFunction<Session[], UseCreateSessionProps> = async({ sessions }) => {
    return api.request<Mutation>(CREATE_SESSIONS_MUTATION, {
      objects: sessions.map(({ course, course_module, module, ...session }) => {
        return { course: course.id, module: module.id, course_module: course_module.id, ...session }
      })
    })
      .then(({insertIntosessionsCollection  }) => {
          const { records } = insertIntosessionsCollection || { records: [] };
          return records.map((record: Sessions) => parseServerSession(record))
        }
      );
  }

  return useMutation({
    mutationFn: createSession
  })
}