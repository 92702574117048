import './Authentication.css'
import {useLocation} from "react-router-dom"
import React, { useEffect } from "react";

function getTermlyId (page: string){
  if(page === 'privacy') return "9c073fcb-abe4-4864-b6c9-0158085a9cdf"
  if(page === 'terms') return "90b5db76-9e87-4b7f-8af9-cef0f2944416"
  if(page === 'cookie') return "ad36cd9c-9619-4e15-a779-cd83433c105f"
  return ""
}

const Legal: React.FC = () => {
  let location = useLocation();
  let termlyId = getTermlyId(location.pathname.split('/')[1])

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'termly-jssdk';
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <div className="max-w-7xl mx-auto px-0 -ml-3 md:-ml-2 lg:ml-auto lg:px-1 mt-14">
      {/* @ts-ignore */}
      <div name="termly-embed" data-id={termlyId} data-type="iframe"/>
    </div>
  )
}

export default Legal
