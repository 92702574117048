import React from 'react'
import "./Landing.css"
import {ReactComponent as EquiptLogo} from '../../assets/img/logo-white.svg'
import TeacherJidePhoto from '../../assets/img/teacher-jide.jpg'
import TeacherHubertPhoto from '../../assets/img/teacher-hubert.jpeg'
import GirlSitting from '../../assets/img/girl-sitting.jpg';
import ZoomCallImage from '../../assets/img/bg-zoom.jpg'

import {ReactComponent as ChatIcon} from '../../assets/img/icons/comments-solid.svg'
import {ReactComponent as PeopleIcon} from '../../assets/img/icons/people-group-solid.svg'
import {ReactComponent as HandshakeIcon} from '../../assets/img/icons/handshake-solid.svg'
import {ReactComponent as GlobeIcon} from '../../assets/img/icons/globe-solid.svg'


import {Navigation} from 'swiper'

import { Swiper, SwiperSlide } from 'swiper/react'

import "swiper/css";
import "swiper/css/navigation"
import {ReactComponent as DowJonesLogo} from "../../assets/img/company-dowjones.svg";
import {ReactComponent as NikeLogo} from "../../assets/img/company-Nike.svg";
import {ReactComponent as MetaLogo } from "../../assets/img/company-meta.svg"
import {ReactComponent as GoogleLogo} from "../../assets/img/company-google-long.svg";
import {ReactComponent as ElsevierLogo} from '../../assets/img/company-elsevier-text.svg'
import {ReactComponent as ModyfiLogo} from '../../assets/img/company-modyfi.svg'
import {ReactComponent as BlackrockLogo} from '../../assets/img/company-blackrock.svg'
import {ReactComponent as EYLogo} from '../../assets/img/company-EY.svg'
import {ReactComponent as MckinseyLogo} from '../../assets/img/company-Mckinsey-new.svg'
import {ReactComponent as VelocityLogo} from "../../assets/img/company-velocity.svg";
import {ReactComponent as AlcornLogo} from "../../assets/img/company-alcorn.svg";

import {ReactComponent as TechnicallyLogo} from "../../assets/img/company-technically-logo.svg";
import {ReactComponent as AfroTechLogo} from "../../assets/img/company-afrotech.svg";
import {ReactComponent as TechstarsLogo} from "../../assets/img/company-techstars.svg"
import {ReactComponent as GVSLogo} from "../../assets/img/company-gsv.svg"
import {ReactComponent as MedaLogo} from "../../assets/img/company-meda.svg"

import FeaturedEventTile from "../../components/Landing/FeaturedEventTile";
import {ReactComponent as NotionLogo} from "../../assets/img/company-notion.svg";
import {FaFacebook, FaLinkedinIn, FaInstagram } from 'react-icons/fa'
import {classNames} from "../../helpers";
//import RequestRecordingModal from "../../components/Landing/RequestRecordingModal";
import {useFetchAirTableCourses} from "../../data/client/course.api";
import {CheckIcon} from "@heroicons/react/24/solid";
import {BsThreeDots} from 'react-icons/bs'
import {Link} from "react-router-dom";

const profilePictures = {
  female: [
    'https://images.unsplash.com/photo-1532417344469-368f9ae6d187?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    'https://images.unsplash.com/photo-1611432579699-484f7990b127?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920',
    "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    'https://images.unsplash.com/photo-1590955256762-e60f6e08122a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    "https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80",
    "https://images.unsplash.com/photo-1606335192038-f5a05f761b3a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80",
    "https://images.unsplash.com/photo-1573497161161-c3e73707e25c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
    "https://images.unsplash.com/photo-1600195075976-6c1d19c956d5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80",
    "https://images.unsplash.com/photo-1573496799515-eebbb63814f2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80"
  ],
  male: [
    'https://images.unsplash.com/photo-1566492031773-4f4e44671857?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    'https://images.unsplash.com/photo-1513910367299-bce8d8a0ebf6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    'https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    'https://images.unsplash.com/photo-1601576084861-5de423553c0f?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920',
    'https://images.unsplash.com/photo-1642736468880-89d8a6de3fbe?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920',
    "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    "https://images.unsplash.com/photo-1522529599102-193c0d76b5b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80",
    "https://images.unsplash.com/photo-1629425733761-caae3b5f2e50?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80",
    "https://images.unsplash.com/photo-1544725391-7aefff7d6d4c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80",
    "https://images.unsplash.com/photo-1564564244660-5d73c057f2d2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80"
  ]
}

interface Props {
  //user: User
  //alert: AlertDialog
}

const headerImage = "https://images.unsplash.com/photo-1520881363902-a0ff4e722963?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2970&q=80"

const plans = [
  {
    title: 'Member',
    featured: false,
    description: 'All your essential business finances, taken care of.',
    priceMonthly: undefined,
    priceYearly: undefined,
    link: 'register',
    mainFeatures: [
      { id: 1, value: 'Live Workshops with industry leaders and recruiters' },
      { id: 2, value: 'Workshop Recordings' },
      { id: 3, value: 'Equipt Discord Community' },
      { id: 4, value: 'Job Board' },
    ],
  },
  {
    title: 'Striver',
    featured: true,
    description: 'The best financial services for your thriving business.',
    priceMonthly: 19,
    priceYearly: 220,
    link: 'register',
    mainFeatures: [
      { id: 1, value: 'Everything in the Free Plan' },
      { id: 2, value: 'Small group coaching sessions' },
      { id: 3, value: 'Collaborative small group sessions such as coding projects & resume reviews' },
      { id: 4, value: 'Exclusive small group workshops' },
    ],
  },
  {
    title: 'Innovator',
    featured: false,
    description: 'The best financial services for your thriving business.',
    priceMonthly: 30,
    priceYearly: 350,
    link: 'register',
    mainFeatures: [
      { id: 1, value: 'Everything in the Striver Plan' },
      { id: 2, value: 'One-on-One Monthly Coaching Calls with Leaders or Guest Speakers' },
      { id: 3, value: 'Early Access to new features' },
    ],
  }
]

const navigation = {
  solutions: [
    { name: 'Marketing', href: '#' },
    { name: 'Analytics', href: '#' },
    { name: 'Commerce', href: '#' },
    { name: 'Insights', href: '#' },
  ],
  support: [
    { name: 'Pricing', href: '#' },
    { name: 'Documentation', href: '#' },
    { name: 'Guides', href: '#' },
    { name: 'API Status', href: '#' },
  ],
  company: [
    { name: 'About', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'Jobs', href: '#' },
    { name: 'Press', href: '#' },
    { name: 'Partners', href: '#' },
  ],
  legal: [
    { name: 'Claim', href: '#' },
    { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' },
  ],


  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/joinequipt',
      icon: (props: React.SVGProps<SVGSVGElement>) => <FaFacebook {...props} />,
    },
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/joinequipt',
      icon: (props: React.SVGProps<SVGSVGElement>) => <FaLinkedinIn {...props} />,
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/joinequipt',
      icon: (props: React.SVGProps<SVGSVGElement>) => <FaInstagram {...props} />,
    },
  ],
}

const testimonials = [
  {
    "name": "AJ Jimenez",
    "title": <>Senior Recruiter, <b>Dow Jones</b></>,
    "picture": profilePictures.male[0],
    "quote": `I was glad to see how interactive the community was! They are all talented and have the experience to get hired anywhere.`
  },
  {
    "name": "Rebecca",
    "title": <>Equipt Student</>,
    "picture": profilePictures.female[5],
    "quote": `I attended "Lessons from an Accenture Tech Consultant" yesterday and just wanted to say thank you. It was an amazing presentation, and I learned so much.`
  },
  {
    "name": "Ryley",
    "title": <>Equipt Student</>,
    "picture": profilePictures.female[0],
    "quote": `You’ve given me access to professionals at companies that are typically out of reach.`
  },
  {
    "name": "Joshua",
    "title": <>Equipt Student</>,
    "picture": profilePictures.male[6],
    "quote": `These workshops are wholesome, thank you!`
  },
  {
    "name": "Steven",
    "title": "Data Science Bootcamp Student",
    picture: profilePictures.male[5],
    quote: "I come to Equipt for the stories, the support network and actionable insights!"
  }
]


const channelPartnersBenefits = [
  {
    header: "Drive Employer Engagement",
    description: "Meet companies and startups through live learning experiences",
    icon: <HandshakeIcon />, //handshake
    link: ""
  },
  {
    header: "Increase Jobs Placements",
    description: "Connect with recruiters to discover the right opportunities ",
    icon: <PeopleIcon/>, //people
    link: ""
  },
  {
    header: "Provide Additional Support",
    description: "Access support and accountability for projects & job search ",
    icon: <ChatIcon />, //Question/AnswerChat
    link: ""
  },
  {
    header: "Grow Strong Networks",
    description: "Establish and grow with professionals that like to pay it forward",
    icon: <GlobeIcon />, //Globe
    link: ""
  },
]

const networkSample = [
  {
    title: "Path to Software Seniority",
    description: <>Proven strategies to become <br/> a Senior Software Engineer</>,
    photo: profilePictures.male[3],
    logo: <GoogleLogo className="h-5 md:h-7 lg:h-9"/>
  },
  {
    title: "Negotiate with Influence",
    description: <> The art and science of <br/> closing the opportunity</>,
    photo: profilePictures.male[7],
    logo: <VelocityLogo className="h-5 md:h-7 lg:h-9"/>
  },
  {
    title: "Career Hacking",
    description: <>Proven and practical <br/> networking strategies</>,
    photo: profilePictures.male[4],
    logo: <EYLogo className="h-5 md:h-7 lg:h-9 w-full"/>
  },
  {
    title: "Lean UX Design Principles",
    description: <>From deciphering the <br/> problem space to ideation</>,
    photo: profilePictures.female[4],
    logo: <ElsevierLogo className="h-3 md:h-4 lg:h-6 w-full"/>
  },

  {
    title: "The Agile Mindset",
    description: <>A methodology for developing <br className="hidden lg:inline"/> innovative products and services</>,
    photo: profilePictures.female[3],
    logo: <MckinseyLogo className="h-7 md:h-10 lg:h-14 w-full"/>
  },
  {
    title: "Master the Code",
    description: <>Lessons from a Senior <br/> Software Developer</>,
    photo: profilePictures.female[7],
    logo: <NikeLogo className="h-5 md:h-6 lg:h-9"/>
  },
  {
    title: "Intelligence Isn’t Enough",
    description: <>A Black Professional's <br/> Guide to Thriving in Tech</>,
    photo: profilePictures.female[8],
    logo: <BlackrockLogo className="h-3 md:h-4 lg:h-9"/>
  },
  {
    title: "Future of Web Graphics",
    description: <>WebAssembly, Rust, <br/> and WebGPU</>,
    photo: profilePictures.male[6],
    logo: <ModyfiLogo className="h-5 md:h-6 lg:h-9"/>
  },

  {
    title: "Foreign Talent in Tech",
    description: <>Advice on US Visas, <br/> Job Hunting and Recruiting</>,
    photo: profilePictures.female[6],
    logo: <AlcornLogo className="h-5 w-full"/>
  },
  {
    title: "Master the Code",
    description: <>Lessons from a Senior <br/> Software Developer</>,
    photo: profilePictures.male[8],
    logo: <></>
  },
  {
    title: "Intelligence Isn’t Enough",
    description: <>A Black Professional's <br/> Guide to Thriving in Tech</>,
    photo: profilePictures.female[2],
    logo: <></>
  },
  {
    title: "Future of Web Graphics",
    description: <>WebAssembly, Rust, <br/> and WebGPU</>,
    photo: profilePictures.male[9],
    logo: <></>
  }
]

interface ChatBubbleProps {
  className?:string
  picture:string,
  name:string,
  title:string,
  timestamp:string,
  comment:string,
  company?: JSX.Element
  companybg?: string
}

const ChatBubble: React.FC<ChatBubbleProps> = ({ picture, name, title, timestamp, comment, company, companybg, className }) =>
  <div className={classNames("bg-white shadow-xl rounded-lg md:rounded-xl relative p-1.5 md:p-3 flex items-center", className || "")}>
    <div className="bg-cover bg-center relative h-9 w-9 md:h-12 md:w-12 shrink-0 rounded-full m-1" style={{backgroundImage: `url(${picture})`}}>
      {company &&
      <div className={classNames("absolute rounded-full p-1.5 h-6 w-6 sm:h-8 sm:w-8 -bottom-2 -right-2", companybg || "" )}>
        {company}
      </div>
      }
    </div>
    <div className="pl-2.5 sm:pl-5 flex flex-col justify-between">
      <div className="text-sm md:text-base font-bold leading-none">{name}<span className="ml-1 opacity-50 font-semibold text-2xs md:text-xs">{title}</span></div>
      <div className="text-2xs md:text-xs leading-none my-1">{comment}</div>
      <div className="text-2xs text-xs italic opacity-50">{timestamp}</div>
    </div>
    <div className="absolute top-2 right-3"><BsThreeDots className="opacity-50" /></div>
  </div>;

const LandingHome: React.FC<Props> = () => {
  const { data: featuredEvents } = useFetchAirTableCourses(
    {
      key: "featuredEvents",
      filterByFormula: 'AND({featured} = TRUE(), {shown} = TRUE())',
      sort: [{ field: "date", direction:"asc" }]});
  return (
    <>
      <section title="header" className="relative">
        <div className="absolute inset-0 bg-cover h-full bg-center" style={{backgroundImage: `url('${headerImage}')`}}/>
        <div className="absolute h-full w-full inset-0 flex bg-blue-800/50" />
        <div className="relative inset-0 pt-16 flex flex-col min-h-screen">
          <div className="max-w-8xl w-full mx-auto grow">
            <div className="flex flex-col grow justify-center my-28 px-5 md:px-0 xl:w-1/2 mx-auto  text-center">

              <div className="w-full max-w-3xl mx-auto">
                <h1 className="content_page_heading text-white">Together we can achieve <span className="text-highlighted from-orange-400/50 to-orange-400/20 ">more</span></h1>
              </div>
              <div className="w-full max-w-2xl mx-auto">
                <div className="text-xl leading-6 text-white">
                  Equipt is your professional development community to network, learn, and collaborate with professionals and recruiters through live online workshops and events.
                </div>
                <Link
                  to="register"
                  className="btn btn-color w-full max-w-sm bg-orange mt-5 mx-auto"
                >
                  Join Us Today
                </Link>
              </div>
            </div>
          </div>
          <div className="text-gray-300 border-t border-white bg-blue-800/50 py-5 md:pt-10 md:pb-8 ">
            <h6 className="text-white text-sm font-bold uppercase block text-center font-heading mb-4 px-4 lg:px-0">You’re in Good Company</h6>
            <div className="flex items-center justify-around opacity-80 max-w-screen-2xl mx-auto text-white/70 h-20">
              <NikeLogo  className="w-12 md:w-20 lg:w-24 mb-2" />
              <MetaLogo className="w-12 md:w-20 lg:w-32 mb-2" />
              <DowJonesLogo className="w-12 md:w-20 lg:w-20 mt-2" />
              <GoogleLogo  className="w-12 md:w-20 lg:w-24 h-16"/>
              <ModyfiLogo className="w-16 md:w-28 lg:w-40 h-16" />
            </div>
          </div>
        </div>
      </section>
      <section title="events" className="bg-white flex pt-40 overflow-x-hidden">
        <div className="max-w-7xl w-full mx-auto text-center">
          <h6 className="text-blue uppercase font-heading block font-bold text-xl p-1">Network & Learn <br className="md:inline"/> through Live Events </h6>
          <h1 className="text-gray-600 content_heading-gray leading-none">Build a stronger network <br/> <span className="text-highlighted from-blue-500/30 to-blue-500/20 ">one story at a time</span></h1>
          <div className="text-black content_body pt-6 leading-7 px-5 lg:px-32">
            We help you build an engaging professional network and learn hard and soft skills from industry leaders and recruiters. We do this through live virtual workshops, meetups, and events on topics like resume reviews & interview prep, careers skills & product innovations, leadership, and more.
          </div>
          <div className="mt-10 max-w-xl">
            <Swiper
              modules={[Navigation]}
              navigation={true}
              speed={500}
              initialSlide={1}
              loop={true}
              className="past-event-swiper"
            >
              {featuredEvents.map((course, index) =>
                <SwiperSlide className="px-10 md:px-12 md:w-xl">
                  {({ isActive }) => (
                    <FeaturedEventTile
                      hasPast={false} isActive={isActive} key={`event-tile-${index}`} course={course} company={course.organization}/>
                  )}
                </SwiperSlide>
              )}
            </Swiper>
            {featuredEvents.length === 0 ? <div /> : "" }
          </div>
          <div className="text-gray-400 py-24 mt-20">
            <h6 className="text-xl md:text-lg font-bold uppercase block text-center font-heading mb-1 px-4 lg:px-0 pb-10">As Featured In</h6>
            <div className="flex flex-col md:flex-row items-center justify-around opacity-80 max-w-screen-2xl mx-auto gap-y-10">
              <a target="_blank" href="https://technical.ly/startups/equipt-for-success-tech-layoffs/" className="hover:opacity-50">
                <TechnicallyLogo className="w-40 md:w-20 lg:w-24 xl:w-40" />
              </a>
              <a target="_blank" href="https://afrotech.com/equipt-for-success-initiative" className="hover:opacity-50">
                <AfroTechLogo className="w-40 md:w-20 lg:w-32 xl:w-40" />
              </a>
              <a target="_blank" href="https://www.techstars.com/newsroom/introducing-techstars-chicago-2021" className="hover:opacity-50">
                <TechstarsLogo className="w-40 md:w-20 lg:w-32 xl:w-40" />
              </a>
              <a target="_blank" href="https://www.bizjournals.com/twincities/news/2021/08/04/meda-announces-million-dollar-challenge-finalists.html" className="hover:opacity-50">
                <MedaLogo  className="w-40 md:w-20 lg:w-24 xl:w-36"/>
              </a>
              <a target="_blank" href="https://www.asugsvsummit.com/gsv-cup-elite-200" className="hover:opacity-50">
                <GVSLogo className="w-40 md:w-28 lg:w-40 xl:w-40" />
              </a>
            </div>
          </div>
        </div>
      </section>
      <section title="about" className="bg-gradient-to-b from-white via-gray-50 to-gray-50  py-40 flex items-center min-h-screen-90">
        <div className="max-w-screen-2xl w-full mx-auto flex items-center justify-center flex-wrap px-5">
          <div className="lg:w-1/2 w-full flex items-center">
            <div className="mx-auto">
              <div className="relative">
                <img src={GirlSitting} className="relative z-10 w-72 md:w-96 rounded-xl"  alt="student sitting"/>
                <div className="absolute inset-0 -top-5 z-1 rounded-xl w-full h-full drop-shadow-md -rotate-6 bg-orange-300" />
                <div className="absolute inset-0 z-1 rounded-xl w-full h-full drop-shadow-md rotate-6 bg-blue-500" />
                <div className="absolute z-100 top-5 -left-2 sm:-left-10 w-full sm:w-96">
                  <ChatBubble
                  className=""
                  picture={profilePictures.female[9]}
                  name="Lexi"
                  title="Equipt Student"
                  timestamp="Wednesday, 10:24 AM"
                  comment="Allison, How can I be successful working at a startup?"
                />
                </div>
                <div className="absolute z-100 bottom-5 -right-5 sm:-right-10 xl:-right-20 w-full sm:w-96">
                  <ChatBubble
                    className=""
                    picture={profilePictures.female[10]}
                    name="Allison"
                    title="Senior Engineer, Notion"
                    timestamp="Wednesday, 10:25 AM"
                    company={<NotionLogo className="text-white w-full h-full"/>}
                    companybg="bg-black"
                    comment="If you are willing to go the extra mile to find things that create value, you can create a job for yourself."
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="text-left lg:w-1/2 w-full flex items-center lg:mb-20 mt-20">
            <div className="lg:max-w-lg w-full mx-auto sm:px-10 md:p-0 text-center lg:text-left">
              <h6 className="text-blue content_subtitle">We foster an environment for collaboration</h6>
              <h1 className="content_heading-gray pr-18">Go beyond a <br/>
                <span className="text-highlighted from-orange-500/30 to-orange-500/20">connection request</span></h1>
              <div className="content_body-gray lg:pr-10">
                Collaborate with professionals and recruiters that like to pay it forward. Work on projects together, get feedback on your resume, apply for jobs, or join a talent community. We encourage paying it forward so you can get the support you need.
                <Link
                  to="register"
                  className="btn btn-color w-80 bg-blue my-8 mx-auto lg:ml-0"
                >
                  Join Your community today
                </Link>
                <div className="text-sm italic leading-none max-w-sm mx-auto lg:ml-0">
                  {testimonials[2].quote}
                </div>
                <div className="flex mt-auto py-2 justify-center lg:justify-end">
                  <div
                    className="h-8 w-8 rounded-full inline bg-center bg-cover"
                    style={{backgroundImage: `url(${testimonials[2].picture})`}}
                  />
                  <div className="leading-none flex justify-center items-center pl-2 text-xs sm:text-sm uppercase text-gray-400">
                    <span className="font-semibold mb-.5">{testimonials[2].name},</span>
                    <span className="opacity-80 ml-1">{testimonials[2].title}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section title="stats" className="bg-black px-5 md:p-0 bg-center relative">
        <div className="absolute inset-0 bg-center bg-cover opacity-20" style={{backgroundImage:`url(${ZoomCallImage})`}}/>
        <div className="max-w-screen-2xl mx-auto py-10 lg:py-20 z-10 relative">
          <div className="text-white font-bold block text-xl lg:text-4xl leading-none text-center">Join the fast growing network of like minded professionals</div>
          <div className="content_body text-white/60 text-center">
            You can expect to learn from a wide range of professionals while building meaningful connections with context
          </div>
          <div className="flex mt-5 lg:mt-10">
            <div className="w-1/3 text-center">
              <div className="text-orange-500 font-heading font-bold block text-4xl md:text-7xl">100+</div>
              <div className="text-white uppercase font-heading font-bold block text-xs md:text-lg leading-none -mt-1">Events & <br/> workshops</div>
            </div>
            <div className="w-1/3 text-center">
              <div className="text-orange-500 uppercase font-heading font-bold block text-4xl md:text-7xl">50+</div>
              <div className="text-white uppercase font-heading font-bold block text-xs md:text-lg leading-none -mt-1">companies <br/> & starups</div>
            </div>
            <div className="w-1/3 text-center">
              <div className="text-orange-500 uppercase font-heading font-bold block text-4xl md:text-7xl">15k+</div>
              <div className="text-white uppercase font-heading font-bold block text-xs md:text-lg leading-none -mt-1">students & <br/> professionals</div>
            </div>
          </div>
        </div>
      </section>
      <section title="network" className="bg-white flex items-center pt-40 pb-40 min-h-screen-70 px-5">
        <div className="max-w-7xl mx-auto text-center">
          <h6 className="text-blue content_subtitle">Turn Insights into Action</h6>
          <h1 className="content_heading-gray">A professional networking <br/> platform with a <span className="text-highlighted from-orange-500/30 to-orange-500/20 ">human touch</span></h1>
          <div className="content_body max-w-3xl mx-auto pb-10">
            With Equipt, you’ll develop new relationships, skills, and opportunities through live or self-paced learning experiences led by leaders and recruiters from your industry. They’ll share wisdom, experience, and actionable insights.
          </div>
          <div className="grid grid-cols-3 md:grid-cols-4 gap-1 sm:gap-4 md:gap-10 relative">
            {networkSample.map(({ photo, title, logo, description }) => <div className="flex flex-col justify-start items-center">
              <div className="w-20 h-20 lg:w-36 lg:h-36 rounded-full bg-cover bg-center" style={{backgroundImage: `url(${photo})`}}/>
              <div className="h-8 md:h-16 lg:h-auto flex flex-col items-center justify-around">
                <div className="font-bold text-xs md:text-base xl:text-xl leading-none mt-2">{title}</div>
                <div className="text-xs lg:text-sm leading-none hidden md:inline">{description}</div>
              </div>
              <div className="text-gray-300 mt-3 overflow-hidden">{logo}</div>
            </div>)}
            <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-white via-white w-full h-72 flex flex-col justify-end"/>
          </div>
          <div className="w-full">
            <div className="relative mt-10">
              <blockquote>
                <div className="max-w-3xl mx-auto text-center">
                  <p className="text-xl leading-9 text-gray-500 italic">
                    &ldquo;I attended "Lessons from an Accenture Tech Consultant" yesterday and just wanted to say thank you. It was an amazing presentation, and I learned so much.&rdquo;
                  </p>
                </div>
                <footer className="mt-2">
                  <div className="md:flex md:items-center md:justify-center">
                    <div className="md:flex-shrink-0">
                      <div
                        className="mx-auto h-10 w-10 rounded-full bg-cover bg-center"
                        style={{backgroundImage: `url(${testimonials[1].picture})`}}
                      />
                    </div>
                    <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                      <div className="text-base font-medium text-gray-700">{testimonials[1].name}</div>

                      <svg className="hidden md:block mx-1 h-5 w-5 text-orange" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M11 0h3L9 20H6l5-20z" />
                      </svg>

                      <div className="text-base font-medium text-gray-500 font-semibold">{testimonials[1].title}</div>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
        </div>
      </section>
      <section title="plans" className="bg-blue flex items-center pt-40 pb-40 min-h-screen-70 px-5">
        <div className="max-w-screen-lg mx-auto text-center w-full">
          <h6 className="text-blue-300 content_subtitle">Access knowledge & networks</h6>
          <h1 className="content_heading text-white">Membership</h1>
          <div className="content_body text-white/80 max-w-3xl mx-auto pb-10">
            We believe access to knowledge and networks are the keys to success in the workplace. Our base membership is free, so you can grow an engaging professional community and supercharge your career
          </div>
          <div className="relative mt-8 max-w-2xl flex lg:flex-row flex-col justify-center items-center mx-auto sm:mt-12 lg:max-w-screen-lg w-full divide-x divide-blue-500">
            {plans.map((plan, index) => (
              <div
                key={plan.title}
                className={classNames(
                  plan.featured ? 'bg-white shadow-md rounded-xl' : 'bg-gray-900/20',
                   !plan.featured && index === 0 ? "rounded-xl rounded-r-none" : "",
                  !plan.featured && index + 1 === plans.length ? "rounded-xl rounded-l-none" : "",
                  'p-7 lg:max-w-xs w-full'
                )}
              >
                <div>
                  <h3
                    className={classNames(
                      plan.featured ? 'text-orange-400' : 'text-blue-200',
                      'text-base font-semibold uppercase tracking-wide text-left'
                    )}
                  >
                    {plan.title}
                  </h3>
                  <div className="flex flex-col items-start sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start">
                    <div className="mt-3 flex items-end">
                      {plan.priceMonthly ? <>
                          <p
                            className={classNames(
                              plan.featured ? 'text-orange-500' : 'text-white',
                              'text-5xl font-bold tracking-tight uppercase font-heading'
                            )}
                          >
                            ${plan.priceMonthly}
                          </p>
                          <div className="mb-.5">
                            <p className={classNames(plan.featured ? 'text-orange-400 font-regular' : 'text-white', 'text-base')}>
                              /MONTH
                            </p>
                          </div>
                        </> :
                        <>
                          <p
                            className={classNames(
                              plan.featured ? 'text-blue-500' : 'text-white',
                              'text-5xl font-bold tracking-tight uppercase font-heading'
                            )}>
                            Free
                          </p>
                        </>
                      }
                    </div>
                  </div>
                </div>
                <h4 className="sr-only">Features</h4>
                <ul
                  role="list"
                  className="mt-7"
                >
                  {plan.mainFeatures.map((mainFeature) => (
                    <li key={mainFeature.id} className="py-3 flex items-center">
                      <CheckIcon
                        className={classNames(
                          plan.featured ? 'text-orange-400' : 'text-blue-200',
                          'w-5 h-5 flex-shrink-0'
                        )}
                        aria-hidden="true"
                      />
                      <span
                        className={classNames(
                          plan.featured ? 'text-gray-600' : 'text-white',
                          'ml-3 text-sm font-medium text-left'
                        )}
                      >
                    {mainFeature.value}
                  </span>
                    </li>
                  ))}
                </ul>
                <Link
                  to={plan.link}
                  className={classNames(
                    plan.featured
                      ? 'bg-orange-500 text-white hover:bg-orange-600s'
                      : 'bg-white text-blue-500 hover:bg-blue-50',
                    'uppercase font-button font-bold mt-6 w-full inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full'
                  )}
                >
                  {plan.priceMonthly ? `Sign Up Now`: ` Sign up for free`}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section title="channel-partners" className="bg-white py-40 flex items-center min-h-screen-90">
        <div className="max-w-screen-2xl w-full mx-auto flex items-center justify-center flex-wrap px-5">
          <div className="text-left lg:w-1/2 w-full flex items-center lg:mb-20 lg:mt-20 text-center lg:text-left">
            <div className="lg:max-w-lg mx-auto sm:px-10 md:p-0">
              <h6 className="text-orange content_subtitle">WE PARTNER WITH UNIVERSITIES & BOOTCAMPS</h6>
              <h1 className="content_heading-gray md:pr-18">Supercharge <br/> your student<br/>
                <span className="text-highlighted from-blue-500/30 to-blue-500/20">community</span></h1>
              <div className="content_body-gray md:pr-10">
                As the demand for tech professionals continues to grow, our platform complements the work that coding bootcamps and universities are doing to equip their students with the skills and networks they need to succeed.
                <a href="https://calendly.com/hubert-dagbo/30min?back=1&month=2021-07" target="_blank"
                  className="btn btn-color w-80 bg-orange my-8 mx-auto lg:ml-0"
                >
                  Schedule a Call with Us
                </a>
                <div className="text-sm italic leading-none max-w-sm mx-auto lg:ml-0">
                  {testimonials[4].quote}
                </div>
                <div className="flex mt-auto py-2 justify-center lg:justify-start">
                  <div
                    className="h-8 w-8 rounded-full inline bg-center bg-cover"
                    style={{backgroundImage: `url(${testimonials[4].picture})`}}
                  />
                  <div className="leading-none flex justify-center items-center pl-2 text-xs sm:text-sm uppercase text-gray-400">
                    <span className="font-semibold mb-.5">{testimonials[4].name},</span>
                    <span className="opacity-80 ml-1">{testimonials[4].title}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 w-full flex items-center mt-10 lg:mt-0">
            <div className="grid grid-cols-1 md:grid-cols-2 grid-rows-2 gap-y-10 gap-x-16 px-2 xl:px-16">
              {channelPartnersBenefits.map(({ header, icon, description}) =>
                <div className="flex lg:flex-col items-center">
                  <div className="bg-blue-500/10 h-16 w-16 rounded-lg flex items-center justify-center shrink-0 mr-2 lg:mr-0">
                    {icon}
                  </div>
                  <div>
                    <div className="font-bold lg:mt-2">{header}</div>
                    <div className="text-sm">{description}</div>
                  </div>
                </div>
              )}
            </div>
          </div>

        </div>
      </section>

      <section title="company cta" className="bg-white z-1 px-5">
        <div className="max-w-7xl mx-auto bg-blue drop-shadow-lg rounded-2xl p-10 lg:p-20 text-center relative -mb-56 z-1 text-center">
          <div className="leading-none text-white font-bold font-heading text-3xl lg:text-5xl uppercase mb-6">Are you a Recruiter or Hiring Manager?</div>
          <div className="text-white/80 mx-auto content_body mb-6 lg:px-32">
            Schedule a call to learn more about our employer branding and hiring solutions
          </div>
          <a href="https://calendly.com/hubert-dagbo/30min?back=1&month=2021-07" target="_blank" className="rounded-none btn btn-secondary-white mx-auto w-full md:w-96">Contact Us</a>
          <div className="w-full">
            <div className="relative mt-4 p-5 mx-auto inline-block rounded-lg w-5/6">
              <blockquote>
                <div className="text-center">
                  <p className="text-base leading-5 text-white italic">
                    {testimonials[0].quote}
                  </p>
                </div>
                <footer className="mt-2">
                  <div className="md:flex md:items-center md:justify-center">
                    <div className="md:flex-shrink-0">
                      <div
                        className="mx-auto h-10 w-10 rounded-full bg-cover bg-center"
                        style={{backgroundImage: `url(${testimonials[0].picture})`}}
                      />
                    </div>
                    <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                      <div className="text-base font-medium text-white/70">{testimonials[0].name}</div>

                      <svg className="hidden md:block mx-1 h-5 w-5 text-white/20" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M11 0h3L9 20H6l5-20z" />
                      </svg>

                      <div className="text-base font-medium text-white/70 font-semibold">{testimonials[0].title}</div>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
        </div>
      </section>
      <section title="our vision" className="bg-blue-800 pt-72 py-40 flex items-center min-h-screen-90">
        <div className="max-w-screen-2xl w-full mx-auto flex items-center justify-center flex-wrap px-5">
          <div className="text-left lg:w-1/2 w-full flex items-center lg:mb-20 mt-20">
            <div className="lg:max-w-lg mx-auto sm:px-10 md:p-0 text-center lg:text-left">
              <h6 className="text-white/30 content_subtitle">Our Vision</h6>
              <h1 className="uppercase font-heading font-bold block leading-none text-3xl md:text-4xl mb-6 text-white pr-18">
                To Foster Collaboration that enables society to reach its full potential
                <br/></h1>
              <div className="content_body lg:pr-10 text-white/60">
                <p className="mt-5">
                  We founded Equipt in 2021 to level the playing field for talent and reimagine how they find fulfillment and success in their careers.
                </p>
                <p className="mt-5">
                  We broke into Finance and Tech by hustling to find mentors who taught us practical skills and tapped their networks to help us find career opportunities. Now, we're building Equipt to create a scalable solution that empowers professionals to build fulfilling careers and companies to build their workforces of the future.
                </p>
                <p className="mt-5 font-semibold text-white/80">
                  We believe that sharing knowledge is the most powerful tool to enable potential, drive impact, and deliver change.
                  <br/>
                </p>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 w-full flex justify-center">
            <div className="flex py-10">
              <div className="text-center z-10">
                <img className="mx-auto sm-40 h-40 sm:h-64 sm:w-64 xl:h-80 xl:w-80 rounded-full" src={TeacherHubertPhoto} alt="" />
                <div className="py-5 text-base xl:text-xl text-white/60"><b>Hubert Dagbo<span className="hidden md:inline">,</span></b> <br className="md:hidden"/> Cofounder</div>
              </div>
              <div className="text-center -ml-10">
                <img className="mx-auto sm-40 h-40 sm:h-64 sm:w-64 xl:h-80 xl:w-80 rounded-full" src={TeacherJidePhoto} alt="" />
                <div className="py-5 text-base  xl:text-xl text-white/60"><b>Jide Osan<span className="hidden md:inline">,</span></b> <br className="md:hidden"/>  Cofounder</div>
              </div>

            </div>
          </div>

        </div>
      </section>
      <footer className="bg-blue-900 relative" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="grid grid-cols-3 gap-8">
            <div className="col-span-3 md:col-span-2 lg:col-span-1">
              <EquiptLogo className="logo-white-orange h-12 w-auto" />
              <div className="text-white/60 text-lg font-light mt-2">Transforming how companies <br /> and professionals connect</div>
              {/*<div className="flex justify-between text-white uppercase font-heading font-bold mt-8 text-sm">
                <span>Companies</span>
                <span>Members</span>
                <span>Partners</span>
              </div>*/}
            </div>
            <div className="col-start-3 col-span-1">
              {/*<h3 className="text-base font-medium text-white uppercase font-heading font-bold">Subscribe to our newsletter</h3>
              <p className="mt-4 text-blue-200 text-lg font-semibold">
                The latest news, articles, and resources, sent to your inbox weekly.
              </p>
              <form className="mt-4 sm:flex sm:max-w-md">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  type="email"
                  name="email-address"
                  id="email-address"
                  autoComplete="email"
                  required
                  className="w-full min-w-0 appearance-none rounded-l-full border border-transparent bg-white py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:border-white focus:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  placeholder="Enter your email"
                />
                <div className="mt-3 rounded-md sm:mt-0 -ml-5 sm:flex-shrink-0">

                  <button className="rounded-none btn btn-color bg-orange">Subscribe</button>
                </div>
              </form>*/}
            </div>
          </div>
          <div className="mt-8 border-t border-white/60 pt-8 md:flex md:items-center md:justify-between">
            <div className="flex space-x-6 md:order-2">
              {navigation.social.map((item) => (
                <a key={item.name} href={item.href} className="text-white/60 hover:text-white/20">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
            <p className="mt-8 text-base text-white/60 md:order-1 md:mt-0">
              &copy; 2022 Equipt, Inc. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
  </>
  )

}

export default LandingHome
