import {BuildingLibraryIcon, BuildingOffice2Icon, UserGroupIcon, AcademicCapIcon } from "@heroicons/react/20/solid";

export default function OrganizationBadge({ type }: RequireOnly<Organization, "type">) {
  return (
    <>
      {type === "COMPANY" && <span className="inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium bg-gray-100 text-gray-800">
        <BuildingOffice2Icon className='w-3 mr-1'/>
        Company
      </span>}

      {type === "BOOTCAMP" && <span className="inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium capitalize bg-blue-100 text-blue-800">
        <BuildingLibraryIcon className='w-3 mr-1'/>
        Bootcamp
      </span>}

      {type === "GROUP" && <span className="inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium capitalize bg-green-100 text-green-800">
        <UserGroupIcon className='w-3 mr-1'/>
        Group
      </span>}

      {type === "UNIVERSITY" && <span className="inline-flex items-center rounded-full  px-2.5 py-0.5 text-xs font-medium capitalize bg-yellow-100 text-yellow-900">
        <AcademicCapIcon className='w-3 mr-1'/>
        University
      </span>}
    </>
  )
}