import React, {useCallback, useEffect} from "react";
import {supabase} from "../../api_supabase";

interface Props {
  refreshUser: (args?: any) => Promise<any>
  user: User
}

const Logout: React.FC<Props> = ({refreshUser, user}) => {
  const logoutUser = useCallback(async () => {
    if(user.id){
      await supabase.auth.signOut();
      refreshUser()
    }
  }, [user, refreshUser])

  useEffect(() => {
    logoutUser()
  })

  return <div />;
}

export default Logout
