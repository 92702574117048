import React from 'react'
import AvatarGroup from "../Elements/AvatarGroup";
import {classNames, ordinal} from "../../helpers";
import {Link} from "react-router-dom";
import {parseUser} from "../../data/client/factories";

const dateRenderer = new Intl.DateTimeFormat('en-US', {
  hour12: true,
  timeZone: 'America/New_York',
  timeZoneName: 'short',
  weekday: "short",
  month: 'short', day: 'numeric',
  hour: 'numeric',
});

const TileDate: React.FC<{ date: string, timeClassName?: string, dateClassName?: string }> = ({ date, timeClassName, dateClassName }) => {
  let parts = dateRenderer.formatToParts(new Date(date));
  return (
    <>
      <span className={timeClassName || ""}>{`${parts[6].value}${parts[8].value} ${parts[10].value}`}</span>
      <span className={dateClassName}>{`${parts[0].value} ${parts[2].value} ${ordinal(parts[4].value)}`}</span>
    </>
  )
}



interface Props {
  cols?: 1 | 2
  course: Course
  company: Organization
  hasPast?: boolean
  isActive?: boolean
  isInternalTile?: boolean
}

const FeaturedEventTile: React.FC<Props> = ({ course, isInternalTile, company, hasPast= false, isActive}) => {
  const {
    id,
    attended,
    cover,
    description,
    title,
    course_modules,
    attendees
  } = course

  const instructor = parseUser(course_modules[0].sessions[0].featured_instructor?.user || {});

    return (
      <li key={id}
          className={classNames('w-full',
            "transition ease-in-out duration-500",
            isActive ? (isInternalTile ? "shadow-2xl" : "shadow-2xl -translate-y-3") : "shadow-lg",
            `course-tile bg-white relative rounded-lg flex`)}

      >
        <div
          className={classNames(`absolute w-full right-0 overflow-hidden rounded-t-lg bg-center bg-cover`, "h-full rounded-b-lg")}
          style={{backgroundImage: `url(${cover})`}}
        />
        <div
          className={classNames(`absolute w-full right-0 overflow-hidden rounded-t-lg`, "h-full rounded-b-lg")}
          style={{
            opacity: company.theme.img_bg_color_opacity,
            backgroundImage: `linear-gradient(to right, ${company.theme.img_bg_color_from}, ${company.theme.img_bg_color_to})`
          }}
        />
        <div
          className={classNames("absolute w-full right-0 overflow-hidden rounded-t-lg bg-black", "h-full rounded-b-lg")}
          style={{
            opacity: company.theme.img_bg_color_darkness
          }}
        />
        <article aria-labelledby={'feed-course-title-' + id} className={classNames(
          "grid gap-x-3 relative",
          "grid-cols-event-tile-3 text-white p-3 md:p-5 w-full"
        )}>

          {/* Header */}
          <div className={classNames("text-white", "flex justify-between  col-span-2 row-span-1")}>
            <div className=" text-lg font-semibold">
              <TileDate
                date={course.course_modules[0].sessions[0].starts_at}
                dateClassName={classNames(hasPast ? "block -mt-2 tracking-tighter" : "-mt-2 md:mt-0 md:ml-1 md:pl-1 md:border-l-2 md:border-white/60 block md:inline text-sm md:text-base")}
                timeClassName={classNames(hasPast ? "text-2xl": "")}
              />
            </div>
            <div className="inline-flex flex-col items-center">
              <AvatarGroup avatars={attendees.map((attendee) => attendee.image )} total_count={attended} size={(isInternalTile ? "xs": "sm")} color={company.theme.hired_count_bg_color} />
              <div className="uppercase text-xs font-semibold tracking-wider mt-1">{hasPast ? "Attended" : "Attending"}</div>
            </div>
          </div>

          {/*TITLE*/}
          <div className={
            classNames(
              "text-2 text-center py-4 col-span-2 text-lg md:text-2xl",
              "font-semibold leading-none"
            )}>
            {title}
          </div>

          {/* Instructor */}
          <div className={classNames("flex flex-col items-center justify-center row-span-2 col-span-2 md:col-span-1")}>
            <div className="h-24 w-24 relative">
              <img alt={`${instructor.first_name} ${instructor.last_name}`} src={instructor.image} className="rounded-full shadow-md"/>
              <div className={classNames(
                "bg-white rounded-full flex items-center justify-center",
                "w-14 h-14 p-2 absolute top-0 -right-6"
              )}>
                <img src={company.logo_alt} className="w-14" alt={`${company.name} Image`} />
                {/*<div style={{backgroundImage: `url(${company.logo_alt})`}} className="rounded-full bg-contain bg-no-repeat bg-center inset-0 h-full w-full"/>*/}
              </div>
            </div>
            <div className="opacity-90 mt-2 leading-none text-center">
              <div className="font-semibold">{`${instructor.first_name} ${instructor.last_name}`}</div>
              <div className="text-xs leading-none h-6">{instructor.tagline}</div>
            </div>
          </div>

          {/*BODY*/}
          <div className={classNames("flex flex-col justify-center col-span-2 md:col-span-1")}>
            <div className="flex flex-col">
              <div
                className={classNames(
                  "text-xs text-center md:text-left pb-3 md:pb-1",
                  "mt-2 space-y-4 text-ellipsis overflow-hidden leading-tight"
                )}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
          </div>

          {/*BUTTON*/}
          <div className="ml-auto mr-0 w-full mt-auto col-span-2 md:col-span-1">
            <Link className="tile-btn w-full"
               style={{
                 backgroundColor: company.theme.cta_bg_color,
                 color: company.theme.cta_text_color,
                 transition: "all .15s ease" }}
               type="button"
               to="/register"
            >
              Register
            </Link>
          </div>

        </article>
      </li>
    )
}

export default FeaturedEventTile
