import React, { useEffect, useState } from "react";
import {createRoutesFromChildren, matchRoutes, RouteObject, Routes, RoutesProps, useLocation} from "react-router-dom";
import {Transition} from "@headlessui/react";
import { getPageClassName } from "./index";

const AnimatedPage: React.FC<{animate: boolean, children?: React.ReactNode}> = ({children}) => {
  return <>{children}</>
}

export const withAnimation = (element:React.ReactComponentElement<any>) => {
  return <AnimatedPage animate>{element}</AnimatedPage>
}

function shouldAnimatePage(route:RouteObject | undefined){
  let element = route?.element as React.ReactElement;
  try{
    return element.props.animate
  } catch (e){
    return false
  }
}

const getMatchedRoute = (nextLocation: any, children: any) => {
  return (matchRoutes(createRoutesFromChildren(children), nextLocation) || [])[0]?.route;
}
export const AnimatedRoutes: React.FC<RoutesProps> = ({ children}) => {
  const location = useLocation();
  const [renderedLocation, setRenderedLocation] = useState(location);
  const [show, setShow] = useState(true)
  const [matchedRoute, setMatchedRoute] = useState<RouteObject | undefined>(getMatchedRoute(location, children));
  const prevPageClassName = getPageClassName(location.pathname)
  const nextPageClassName = getPageClassName(renderedLocation.pathname)
  useEffect(() => {
    if (location.pathname !== renderedLocation.pathname) {
      let nextMatchedRoute = getMatchedRoute(location, children)
      if(nextMatchedRoute?.path !== matchedRoute?.path && shouldAnimatePage(nextMatchedRoute) ){
        setShow(false)
        setTimeout(() => {
          window.scrollTo(0, 0);
          setShow(true)
          setMatchedRoute(nextMatchedRoute)
        }, 600)
        return;
      } else {
        setRenderedLocation(location)
      }
    }

  }, [matchedRoute?.path, renderedLocation, setShow, setMatchedRoute, setRenderedLocation, children, location])

  return (
    <Transition
      as="div"
      show={show}
      appear={shouldAnimatePage(matchedRoute)} //THIS will make the transition run everytime the component is rendered
      enter={` ${nextPageClassName} page-animate-enter`}
      enterFrom={`page-animate-enter-from`}
      enterTo="page-animate-enter-to"
      leave={`${prevPageClassName} page-animate-leave`}
      leaveFrom={`page-animate-leave-from`}
      leaveTo={`page-animate-leave-to`}
      afterLeave={() => {
        setRenderedLocation(location)
      }}
      className={`bg-transparent page-animate`}
    >
      <Routes children={children} location={renderedLocation} />
    </Transition>
  )
}
