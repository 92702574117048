/* Created  on 8/19/2023. */

import {parseMembership, parseModule, parseProfile, parseSession, parseUser} from "./factories";
import { parseCourseModule, parseInstructor} from "./factories";
import {defaultTheme, soarceConsultingTheme, clearflameTheme} from "../../helpers/defaults";
const _skipParse = (data: any) => (data?._parse === false) ? data : false;
//type OrganizationType = "COMPANY" | "UNIVERSITY" | "BOOTCAMP" | "GROUP"

type Maybe<T> = T | null;
type MaybePartial<T> = { [P in keyof T]?: Maybe<T[P]> }

export const parseCourseCustomAttributes = (data: MaybePartial<Course>) => {
  const { course_modules, attendees } = data
  return {
    course_modules: (course_modules || []).map((course_module) => parseCourseModule(course_module)),
    attendees: attendees || []
  }
}


export const parseInstructorCustomAttributes = (data: MaybePartial<Instructor>) => {
  const { memberships, membership, user } = data
  let membershipToParse = memberships ? memberships : membership;
  let profile = user && user.profiles && user.profiles[0] || user?.profile || {}
  return {
    membership: _skipParse(membershipToParse) || parseMembership(membershipToParse || {}),
    user: parseUser( {
      ...user,
      profile: parseProfile( profile )
    } )
  }
}


export const parseCourseModuleCustomAttributes = (data: MaybePartial<CourseModule>) => {
  const { subscription_level, sessions, instructors, modules, module } = data
  return {
    subscription_level: subscription_level || 0,
    module: parseModule(modules || module || {}),
    sessions: (sessions || []).map((session) => parseSession(session)),
    instructors: (instructors || []).map((instructor) => parseInstructor(instructor))
  }
}


export const parseJobApplicationCustomAttributes = (data: MaybePartial<JobApplication>) => {
  const { } = data
  return { }
}


export const parseMembershipCustomAttributes = (data: MaybePartial<Membership>) => {
  const { } = data
  return { }
}

const isOrganizationType = (type: string) => {
  return (new Set(["COMPANY", "UNIVERSITY", "BOOTCAMP", "GROUP", "INDIVIDUAL"])).has(type)
};
export const parseOrganizationCustomAttributes = (data: MaybePartial<Organization>) => {
  const { approval_process, theme, type, slug } = data
  let localTheme;

  switch (slug){
    case "clearflame":
      localTheme = clearflameTheme;
      break;
    case "soarce_consulting":
      localTheme = soarceConsultingTheme;
      break;
    default:
      localTheme = defaultTheme;
  }

  return {
    approval_process: approval_process || null,
    theme: parseTheme(theme || localTheme || {}),
    type: isOrganizationType(type || "") ? type : "GROUP"
  }
}


export const parseModuleCustomAttributes = (data: MaybePartial<Module>) => {
  const { } = data
  return {

  }
}


export const parsePlanCustomAttributes = (data: MaybePartial<Plan>) => {
  const { } = data
  return {

  }
}


export const parseJobCustomAttributes = (data: MaybePartial<Job>) => {
  const { } = data
  return { }
}


export const parseSessionCustomAttributes = (data: MaybePartial<Session>) => {
  const { additional_instructors, instructors } = data
  let parsedInstructors: any[] = [];
  let featured_instructor: any = undefined;
  (instructors || []).forEach((instructor: any) => {
    let parsedInstructor = _skipParse(instructor) || parseInstructor(instructor || { session: { id: '', _parse: false }})
    if(instructor.featured && featured_instructor === undefined) featured_instructor = parsedInstructor
    parsedInstructors.push(parsedInstructor)
  })


  return {
    additional_instructors: additional_instructors || [],
    featured_instructor: featured_instructor || parsedInstructors[0],
    instructors: parsedInstructors
  }
}


export const parseRegistrationCustomAttributes = (data: MaybePartial<Registration>) => {
  const { } = data
  return { }
}


export const parseProfileCustomAttributes = (data: MaybePartial<Profile>) => {
  const { } = data
  return {

  }
}


export const parseSubscriptionCustomAttributes = (data: MaybePartial<Subscription>) => {
  const { } = data
  return {

  }
}

export const parseUserCustomAttributes = (data: MaybePartial<User>) => {
  const { bio, phone, first_name, image, confirmed, last_name, location, slug, discord_invite, discord_invite_used, social_linkedin, social_discord, tagline, accepted_terms, memberships, has_password, profile, profiles, intercom_hash } = data
  const profileToParse = profile || (profiles ? profiles[0] : {});
  return {
    bio: bio || "",
    phone: phone || "",
    first_name: first_name || "",
    image: image || "",
    confirmed: confirmed === undefined || confirmed === null ? true : confirmed,
    last_name: last_name || "",
    location: location || "",
    slug: slug || "",
    discord_invite: discord_invite || "",
    discord_invite_used: !!discord_invite_used,
    social_linkedin: social_linkedin || "",
    social_discord: social_discord || "",
    tagline: tagline || "",
    memberships: memberships || [],
    has_password: has_password || false,
    profile: _skipParse(profileToParse) || parseProfile({
      ...profileToParse, user: { id: '', _parse: false }
    }),
    intercom_hash: intercom_hash || undefined,
    accepted_terms: accepted_terms ? new Date().toUTCString() : undefined,
  }
}

export const parseNewUser = (data: Partial<Omit<User, "accepted_terms"> & { accepted_terms: boolean }>): User => {
  const { created, confirmed, id, updated, bio, email, phone, first_name, image, last_name, has_password, location, privacy_available_to_hire, slug, social_linkedin, social_discord, stripe_customer_id, subscription_level, tagline, username, memberships, accepted_terms} = data
  return {
    created: created || "",
    id: id || "",
    updated: updated || "",
    bio: bio || "",
    email: email || "",
    phone: phone || "",
    first_name: first_name || "",
    image: image || "",
    confirmed: confirmed === undefined ? true : confirmed,
    last_name: last_name || "",
    location: location || "",
    privacy_available_to_hire: privacy_available_to_hire || false,
    slug: slug || "",
    social_linkedin: social_linkedin || "",
    social_discord: social_discord || "",
    stripe_customer_id: stripe_customer_id || "",
    subscription_level: subscription_level || 0,
    tagline: tagline || "",
    username: username || "",
    accepted_terms: accepted_terms ? new Date().toUTCString() : undefined,
    memberships: memberships || [],
    has_password: has_password || false,
  }
}

export const parseTheme = (data: Partial<Theme>): Theme => {
  return {
    title_font: "",
    body_font: "",
    primary_color: "",
    secondary_color: "",
    img_bg_color_to: "",
    img_bg_color_from: "",
    img_bg_color_opacity: "",
    img_bg_color_split: [1, 5, 6],
    top_bg_image: "",
    img_bg_color_darkness: "",
    logo: "",
    logo_classes: '',
    hired_count_bg_color: "",
    hired_count_text_color: "",
    cta_bg_color: "",
    cta_text_color: "",
    bottom_bg_color: "",
    menu_text_color: "",
    menu_tracker_color: "",
    dropdown_label_color: "",
    dropdown_bg_color: "",
    dropdown_outline_color: "",
    course_card_label_color: "",
    course_card_button_bg_color: "",
    course_card_button_font_color: "",
    course_card_button_outline_color: "",
    course_card_outline_color: "",
    course_card_shadow: "",
    course_sessions: "",
    ...data
  }
}