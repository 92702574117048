import React, {Fragment, useMemo, useState} from 'react'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon, MapPinIcon } from '@heroicons/react/20/solid'
import {CheckIcon, UsersIcon, XMarkIcon} from '@heroicons/react/24/outline'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import {classNames, getMembershipType, membershipIsApproved} from "../../helpers";
import {useFetchOrganizations} from "../../data/client/organization.api";
import OrganizationBadge from "../Elements/OrganizationLabel";
import {useAddMembership} from "../../data/client/membership.api";

interface Props {
  user: User,
  open: boolean,
  alert: AlertDialog
  userMemberships: Membership[]
  setOpen:  React.Dispatch<React.SetStateAction<boolean>>
  selectedOrganization: Organization | undefined,
  setSelectedOrganization:  React.Dispatch<React.SetStateAction<Organization | undefined>>
}

function getJoinButtonText(status:string | undefined, title: string){
  if(status === 'accepted') return <span>Joined <CheckIcon className="w-5"/></span>;
  if(status === 'rejected') return <span>Unable to Join <XMarkIcon className="w-5"/></span>;
  if(status === 'pending') return <span>Membership Pending</span>;
  return <span>{`Join ${title}`}</span>
}

const AddOrganization: React.FC<Props> = ({ alert, userMemberships, user, open, setOpen, selectedOrganization, setSelectedOrganization}) => {
  const [query, setQuery] = useState('')
  const { data: organizations = [] } = useFetchOrganizations();
  const { mutate: mutateAddMembership } = useAddMembership();
  const membershipStatusForOrganization = useMemo(() => {
    const map = new Map();
    userMemberships.map((membership) =>{
      if(membershipIsApproved(membership)){
        map.set(membership.organization.id, 'accepted');
      } else if(membership.rejected){
        map.set(membership.organization.id, 'rejected');
      } else {
        map.set(membership.organization.id, 'pending');
      }
    })
    return map;
  }, [userMemberships])

  const filteredPeople =
    query === ''
      ? organizations
      : organizations.filter((organization) => {
        return organization.name.toLowerCase().includes(query.toLowerCase())
      })

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => {setQuery(''); setSelectedOrganization(undefined);}} appear>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox value={selectedOrganization} onChange={(organization) => setSelectedOrganization(organization)}>
                {({ activeOption }) => (
                  <>
                    <div className="p-6 text-3xl text-blue font-bold ">Find an Organization</div>
                    <div className="relative">
                      <MagnifyingGlassIcon
                        className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <Combobox.Input
                        className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                        placeholder="Search for an organization by name..."
                        onChange={(event) => setQuery(event.target.value)}
                      />
                    </div>

                    {(query === '' || filteredPeople.length > 0) && (
                      <Combobox.Options as="div" static hold className="flex divide-x divide-gray-100">
                        <div
                          className={classNames(
                            'max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4',
                            activeOption ? 'sm:h-96' : ''
                          )}
                        >
                          <div className="-mx-2 text-sm text-gray-700">
                            {(filteredPeople).map((organization) => (
                              <Combobox.Option
                                as="div"
                                key={organization.id}
                                value={organization}
                                className={({ active }) =>
                                  classNames(
                                    'flex cursor-default select-none items-center rounded-md p-2',
                                    active ? 'bg-gray-100 text-gray-900' : ''
                                  )
                                }
                              >
                                {({ active }) => (
                                  <>
                                    <div className="h-6 w-6 flex-none rounded-full bg-center bg-contain bg-no-repeat" style={{backgroundImage: `url(${organization.logo})`}}/>
                                    <span className="ml-3 flex-auto truncate">{organization.name}</span>
                                    {active && (
                                      <ChevronRightIcon
                                        className="ml-3 h-5 w-5 flex-none text-gray-400"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </>
                                )}
                              </Combobox.Option>
                            ))}
                          </div>
                        </div>

                        {activeOption && (
                          <div className="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
                            <div className="flex-auto p-6 text-center">
                              <div className="h-16 w-16 mx-auto flex-none rounded-full bg-center bg-contain bg-no-repeat" style={{backgroundImage: `url(${activeOption.logo})`}}/>
                              <h2 className="mt-3 text-lg font-semibold text-gray-900">{activeOption.name}</h2>
                              <div className="text-xs leading-6 text-gray-500 flex justify-center items-center">
                                <MapPinIcon className="h-4 w-4 mr-1" />{activeOption.location}
                              </div>
                              <p className="my-3 text-sm text-gray-600">{activeOption.description}</p>
                              <OrganizationBadge type={activeOption.type} />
                            </div>
                            <div className="flex flex-none flex-col justify-between p-6">
                              <button
                                type="button"
                                onClick={async () =>{
                                  if(!membershipStatusForOrganization.has(activeOption.id)){
                                    await mutateAddMembership({ organization: activeOption, membership_type: getMembershipType("MEMBER"), user: user })
                                    setOpen(false);
                                    await alert({ buttonText: 'Ok', title: 'Membership Request Sent', description: `Your membership for ${activeOption.name} has been sent. We will let know when its been approved.`})
                                  }
                                }}
                                className={classNames(
                                  "w-full rounded-md bg-blue-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600",
                                  membershipStatusForOrganization.has(activeOption.id) ? 'pointer-events-none bg-gray-400' : ''
                                )}
                              >
                                {getJoinButtonText(membershipStatusForOrganization.get(activeOption.id), activeOption.name)}
                              </button>

                            </div>
                          </div>
                        )}
                      </Combobox.Options>
                    )}

                    {query !== '' && filteredPeople.length === 0 && (
                      <div className="py-14 px-6 text-center text-sm sm:px-14">
                        <UsersIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                        <p className="mt-4 font-semibold text-gray-900">No organizations found</p>
                        <p className="mt-2 text-gray-500">
                          We couldn’t find anything with that term. Please try again.
                        </p>
                      </div>
                    )}
                  </>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default AddOrganization