import React, {Fragment, useEffect, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {Link} from "react-router-dom";
import {classNames} from "../../helpers";
import {useForm, ValidationError} from "@formspree/react";
import {XMarkIcon} from "@heroicons/react/24/outline";

interface Props {
  formKey: string
  recordingToRequest: string
  setRecordingToRequest:  React.Dispatch<React.SetStateAction<string>>
  onRequestSuccess: ( courseTitle: string) => void
}


export default function RequestRecordingModal({ recordingToRequest, setRecordingToRequest, formKey, onRequestSuccess}: Props) {
  const [state, handleSubmit, resetForm] = useForm(formKey);
  const [input, setInput] = useState<string>("");
  const [title, setTitle] = useState<string>(recordingToRequest);

  useEffect(() => {
    if(recordingToRequest !== "") setTitle(recordingToRequest)
  }, [recordingToRequest])

  useEffect(() => {
    if(state.succeeded && input !== ""){
      //setInput("");
      onRequestSuccess(recordingToRequest)
      resetForm();
      setRecordingToRequest("")
    }
  }, [state.succeeded, input, resetForm, setRecordingToRequest, recordingToRequest])


  return (
    <Transition.Root show={recordingToRequest !== ""} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => setRecordingToRequest("")}>
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500/75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-3xl md:px-4 md:my-8 md:align-middle lg:max-w-2xl px-5">
              <div className="w-full relative flex items-center bg-white rounded-xl overflow-hidden shadow-2xl">
                <button
                  type="button"
                  className="absolute top-4 right-4 text-gray-400 lg:text-gray-400 hover:text-gray-300 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8 z-100"
                  onClick={() => setRecordingToRequest("")}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                <h2 id="contact-heading" className="sr-only">
                  Contact us
                </h2>

                <div className="grid grid-cols-12 w-full h-full">
                  <div className="col-span-12 flex flex-col inline-auth">
                    <div className="pt-10 px-6 sm:px-10 xl:px-12 pb-0">
                      <h6 className={classNames("text-sm font-bold uppercase", "text-blue-300")}>Request Recording for</h6>
                      <h2 className={classNames("text-xl md:text-3xl tracking-tight font-extrabold mb-4", "text-blue")}>
                        {title}
                      </h2>
                    </div>
                    <div className="px-6 sm:px-10 xl:px-12 flex items-center">
                      <div className="w-full">
                        <>
                          <form onSubmit={handleSubmit} className="gap-0 gap-x-2 grid grid-cols-12" action="#" method="POST">
                            <div className="inline-placeholder relative col-span-12">
                              <input name="subscriber-email" value={input} onChange={(e) => setInput(e.target.value)}
                                id="request-subscriber-email" className="input input-secondary w-full" placeholder="Enter Your Email"  type="email" />
                              <label htmlFor="subscriber-email" title="Enter Your Email"/>
                              <span className="text-sm h-6 w-full block flex justify-center items-center text-red-500 italic input-error" >
                                <ValidationError prefix="Email" field="request-recording-email" errors={state.errors} />
                              </span>
                            </div>
                            <input name="subscriber-requested-title" id="request-recording-title" value={recordingToRequest} type="hidden" />
                            <div className={classNames( "col-span-12")}>
                              <div className="flex">
                                <input name="subscriber-terms" id={"request-subscriber-terms"} type="checkbox" required={true}
                                       className={classNames(
                                         "h-3.5 w-3.5 text-blue-500 rounded",
                                         //className, meta.touched && meta.error ? 'input-has-error focus:ring-red-300 border-red-300' : 'focus:ring-blue-300 border-gray-300'
                                       )}/>
                                <label htmlFor="subscriber-terms" className={classNames(
                                  'flex-shrink inline-flex',
                                  // meta.touched && meta.error ? 'text-red-500 ' : ' text-gray-400 '
                                )}>
                                  <div className="-mt-1 text-sm pl-2 text-gray-400">
                                    I accept the <Link to="/terms" target="_blank" rel="noopener noreferrer" className="font-bold underline hover:text-orange-500">Terms and Conditions</Link> and have read the <Link to="/privacy" target="_blank" rel="noopener noreferrer" className="font-bold underline hover:text-orange-500">Privacy Policy</Link> and <Link to="/cookie" target="_blank" rel="noopener noreferrer" className="font-bold underline hover:text-orange-500">Cookie Policy</Link>.
                                  </div>
                                </label>
                              </div>
                              <input name="subscriber-type" value="initiative-subscriber" id="request-subscriber-type" type="hidden" />
                              <span className="text-sm h-6 w-full block flex justify-center items-center text-red-500 italic input-error" >
                                <ValidationError prefix="Email" field="request-recording-disclaimer" errors={state.errors} />
                              </span>
                            </div>
                            <div className="col-span-12 mb-6 mt-4">
                              <button
                                type="submit"
                                className={
                                  classNames(
                                    "animate__animated animate__fast",
                                    state.submitting ? "pointer-events-none opacity-50" :"",
                                    (state.errors || []).length > 0 ? 'animate__shakeX': '',
                                    "text-center font-bold font-button text-sm text-white uppercase px-6 py-3 w-full flex justify-center border border-transparent rounded-full shadow-sm bg-blue-500 hover:bg-blue-600 outline-none focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300"
                                  )}
                              >
                                Request Recording
                              </button>
                            </div>
                          </form>
                        </>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
