import './Dashboard.css'
import React, {useEffect, useState} from 'react'
//import SponsoredCourseTile from "../components/Dashboard/SponsoredCourseTile";
//import CourseTile from "../components/Dashboard/CourseTile";
import {useFetchAirTableCourses} from "../data/client/course.api";
//import { useQuery } from "@apollo/client";
import FeedFilter from "../components/Dashboard/FeedFilter";
//import { AcademicCapIcon, UsersIcon, /*BriefcaseIcon*/ } from "@heroicons/react/24/outline";
import SessionModal from "../components/Dashboard/SessionModal";
import Avatar from "../components/Elements/Avatar";
//import {parseCourse} from "../data/client/factories";
//import UpcomingSessions from "../components/Dashboard/UpcomingSessions";
import {UserIsUnconfirmed} from "../helpers";
//import MyJobApplications from "../components/Dashboard/MyJobApplications";
import SponsoredEventTile from "../components/Landing/SponsoredEventTile";
import FeaturedJobs from "../components/Dashboard/FeaturedJobs";

/*const Stat: React.FC<{
  label: string,
  Icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
  value: number | string
}> = ({ label, value, Icon }) =>
  <div
    key={label}
    className="flex flex-col items-center relative bg-white py-4 px-4 sm:pt-6 sm:px-6 rounded-lg overflow-hidden"
  >
    <div className="flex items-center">
      <Icon className="h-5 w-5 text-orange-500 mr-1 mb-1" aria-hidden="true" />
      <p className="text-2xl font-semibold text-gray-900">{value}</p>
    </div>
    <p className="text-sm font-medium text-gray-500 capitalize">{label}</p>
  </div>
*/

const Dashboard: React.FC<{ user?: User, registrations: Registration[], onShowVideo: (arg0: any) => any}> = ({ user, onShowVideo }) => {
  const [viewingSession, setViewingSession] = useState<boolean>(false)
  const [feedFilter, setFeedFilter] = useState<"upcoming" | "past">("upcoming")
  const [courses, setCourses] = useState<Course[]>([])
  const { data: events } = useFetchAirTableCourses(
    {
      key: "upcomingEvents",
      filterByFormula: 'AND(DATETIME_DIFF(NOW(), date, "hours") <= 1,{shown} = TRUE())',
      sort: [{ field: "date", direction:"asc" }]});

  const { data: pastEvents } = useFetchAirTableCourses(
    {
      key: "pastEvents",
      filterByFormula: 'AND(DATETIME_DIFF(NOW(), date, "hours") > 1,{shown} = TRUE())',
      sort: [{ field: "date", direction:"desc" }]});

  useEffect(() => {
    if(feedFilter === 'past'){
      setCourses(pastEvents)
    } else {
      console.log("EVENTS", events)
      setCourses(events)
    }
  }, [events, pastEvents, feedFilter])

  return (
    <main className="relative pb-8 pt-24 dashboard_page">
      <SessionModal viewingSession={viewingSession} setViewingSession={setViewingSession}/>
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <h1 className="sr-only">Profile</h1>
        {/* Main 3 column grid */}
        <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
          {/* Left column */}
          <div className="grid grid-cols-1 gap-4 lg:col-span-2">
            {/* Welcome panel */}
            <section aria-labelledby="profile-overview-title">
              <div className="rounded-lg bg-white overflow-hidden shadow">
                <h2 className="sr-only" id="profile-overview-title">
                  Profile Overview
                </h2>
                <div className="bg-white p-5 sm:p-16">
                  <div className="sm:flex sm:items-center sm:justify-between">
                    <div className="sm:flex sm:space-x-5">
                      <div className="flex-shrink-0">
                        <Avatar className="mx-auto h-20 w-20 text-3xl" image={user?.profile?.image} first_name={user?.profile?.first_name} last_name={user?.profile?.last_name} />
                      </div>
                      <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                        <p className="text-sm font-medium text-gray-600">Welcome back,</p>
                        <p className="text-xl font-bold text-gray-900 sm:text-2xl">{user?.profile?.first_name} {user?.profile?.last_name}</p>
                        <p className="text-sm font-medium text-gray-600">{user?.profile?.tagline}</p>
                      </div>
                    </div>
                    <div className="mt-5 flex justify-center sm:mt-0">
                      {/* <Link
                        to="/settings"
                        className="btn btn-primary-white-border"
                      >
                        Edit profile
                      </Link>*/}
                    </div>
                  </div>
                </div>
                {/*<div className="border-t border-gray-200 bg-white grid grid-cols-1  sm:grid-cols-2 ">
                  <Stat label="Sessions attended" Icon={UsersIcon} value={registrations.length} />
                  <Stat
                    label="Courses completed"
                    Icon={AcademicCapIcon}
                    value={(registrations.reduce((set, registration) => (set.add(registration.session.course.id)), new Set())).size}
                  />
                </div>*/}
              </div>
            </section>

            {/* Feed panel */}
            <section aria-labelledby="quick-links-title">
              <FeedFilter filter={feedFilter} setFilter={setFeedFilter} />

              <div className="rounded-lg divide-y sm:divide-y-0 grid lg:grid-cols-2 grid-cols-1 gap-4">
                <h2 className="sr-only" id="quick-links-title">
                  Course Feed
                </h2>
                {courses.map((course, index) =>
                  <SponsoredEventTile
                    hasPast={feedFilter === "past"}
                    isActive={feedFilter === "past"}
                    isInternalTile={true}
                    key={`event-tile-${index}`}
                    course={course} company={course.organization}
                    onRequest={(vimeo_code) => onShowVideo(vimeo_code)}
                  />)}
                {courses.length === 0 ? <div /> : "" }
                {/*(courses || []).map((course, index) => {
                  //let cols:1|2 = index%5 === 0 ? 2 : 1
                  let cols:1|2 = 2
                  if(course.organization.id){
                    return <SponsoredCourseTile key={`course-tile-${index}`} cols={cols} course={course} company={course.organization} />
                  } else {
                    return <CourseTile key={`course-tile-${index}`} cols={cols} course={course} />
                  }
                })*/}

              </div>
            </section>
          </div>

          {/* Right column */}
          <div className={`grid grid-cols-1 gap-4 sm:sticky ${user && UserIsUnconfirmed(user) ? "top-14" : "top-2"}`}>
            <FeaturedJobs />
          </div>
        </div>
      </div>
    </main>
  )
}

export default Dashboard;
