import { ApolloClient, InMemoryCache } from "@apollo/client";
const headers: Record<string, string> | undefined = {}
if(process.env.REACT_APP_SUPABASE_KEY){
  headers.apikey = process.env.REACT_APP_SUPABASE_KEY
}
export const apolloApi = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  headers,
  //credentials: 'include',
  cache: new InMemoryCache(),
});
