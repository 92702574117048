import {createClient} from "@supabase/supabase-js";
import { GraphQLClient } from "graphql-request";
import { RequestInit } from "graphql-request/src/types.dom";
import {generateId} from "./helpers";
const headers: Record<string, string> | undefined = {}

interface SupabaseFile {
  file_path: string
  bucket:string
  file?: File
  contentType?: string
}

if(process.env.REACT_APP_SUPABASE_KEY){
  headers.apikey = process.env.REACT_APP_SUPABASE_KEY
}
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabaseGQL = process.env.REACT_APP_SUPABASE_URL ? `${process.env.REACT_APP_SUPABASE_URL}/graphql/v1` : "";

export const supabase = createClient(supabaseUrl || '', supabaseKey || '')

async function headersMiddleware(request: RequestInit & {url: string, operationName?: string}) {
  const extraHeaders: Record<string, string> = {};
  const { data: { session } } = await supabase.auth.getSession();
  if(session && session.access_token){
    extraHeaders.authorization = `Bearer ${session.access_token}`;
  }

  return {
    ...request,
    headers: { ...request.headers, ...extraHeaders },
  }
}

export const api = new GraphQLClient(supabaseGQL, {
  headers,
  requestMiddleware: headersMiddleware
});


export const supahelpers = {
  auth: {
    requestVerificationCode: async function (){
      const { data: { session }} = await supabase.auth.getSession();
      if(!session) throw new Error("Please log in");
      await fetch(`${process.env.REACT_APP_SUPABASE_URL}/auth/v1/otp`, {
        method: 'POST', // or 'PUT'
        headers: {
          'apikey': process.env.REACT_APP_SUPABASE_KEY || '' ,
          'authorization': `Bearer ${session?.access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: session?.user.email,
          data: {},
          create_user: false,
          gotrue_meta_security: { captcha_token: undefined },
        })
      })
    },
    verifyVerificationCode: async function({ token, type = "magiclink" }: { token: string, type?: string }){
      const { data: { session }} = await supabase.auth.getSession();
      if(!session) throw new Error("Please log in");

      const { error } = await supabase.functions.invoke('verify-verification-code', {
        body: { token, type }
      });

      if(error) throw error

    },
    getUserHash: async function(){
      const { data: { session }} = await supabase.auth.getSession();
      if(!session) throw new Error("Please log in");

      const { data, error } = await supabase.functions.invoke('generate-user-hash');

      if(error) throw error

      return data;
    },
  },
  functions: {
    getDiscordInvite: async function(){
      const { data: { session }} = await supabase.auth.getSession();
      if(!session) throw new Error("Please log in");

      const { error } = await supabase.functions.invoke('manage-discord-invite');

      if(error) throw error
    },
    updateUserAvatar: async function({ image }: { image: string }){
      const { data: { session }} = await supabase.auth.getSession();
      if(!session) throw new Error("Please log in");

      const { data,  error } = await supabase.functions.invoke('update-avatar', {
        body: { image }
      });

      if(error) throw error

      return data;
    },
    updateImage: async function(
      { table_name, column_name, bucket, id, image }:
        { table_name: string, column_name: string, bucket: string, id: string, image: string },
      ){
      const { data: { session }} = await supabase.auth.getSession();
      if(!session) throw new Error("Please log in");
      if(image.indexOf("tmp/") !== 0) throw new Error("Invalid Image")

      const { data,  error } = await supabase.functions.invoke('update-image', {
        body: { table_name, column_name, bucket, id, image }
      });

      if(error) throw error

      return data;
    },
  },
  uploadFile: async ({ file, file_path, bucket, contentType }: Optional<Required<SupabaseFile>, 'contentType'>) => {
    const { data  } = await supabase
      .storage
      .from(bucket)
      .upload(`${file_path}`, file, {
        cacheControl: '3600',
        upsert: true,
        contentType: contentType || undefined
      })

      return supabase
        .storage
        .from(bucket)
        .getPublicUrl(data?.path || "")
  },
  moveFile: async ({ from_file, to_file_path, replace } : { from_file: SupabaseFile, to_file_path: string, replace?: boolean}) => {
    if(replace){
      let extension = to_file_path.split('.').pop();
      try{
        await supabase
          .storage
          .from(from_file.bucket)
          .move(to_file_path, `tmp/replaced_${generateId()}.${extension}`);
      } catch (e){
        console.log(e)
        //Keep going if file doesn't exists
      }
    }

    await supabase
      .storage
      .from(from_file.bucket)
      .move(from_file.file_path, to_file_path);

    return supabase
      .storage
      .from(from_file.bucket)
      .getPublicUrl(to_file_path)
  },
  getFileUrl: ({ file_path, bucket }: SupabaseFile) => {
    const { data: { publicUrl} } = supabase.storage.from(bucket).getPublicUrl(file_path)
    return publicUrl
  },
  getFile: async ({ file_path, bucket }: SupabaseFile ) => {
    return supabase
      .storage
      .from(bucket)
      .getPublicUrl(`${file_path}`)
  }
}
