/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid'
import {classNames} from "../../helpers";
import {parseUser} from "../../data/client/factories";


interface Option {
  name: string,
  value: RequireOnly<User, "id" | "first_name" | "last_name" | "slug" | "image">
}

interface Props {
  options: Option[]
  label: string,
  labelColor?: string
  borderColor?: string
  backgroundColor?: string
}

const PeopleDropdown: React.FC<Props> = ({options, label, backgroundColor, borderColor, labelColor}) => {
  const emptyOption: Option = { value: parseUser({}), name: "Select One" }
  const renderedOptions: Option[] = [ emptyOption ].concat(options);

  const [selected, setSelected] = useState<Option>(renderedOptions[0])

  return (
    <div>
      <Listbox value={selected} onChange={setSelected}>
        {({ open }) => (
          <>
            <Listbox.Label style={{color: labelColor || '#000000'}} className="block uppercase font-semibold tracking-widest">{label}</Listbox.Label>
            <div className="mt-1 relative">
              <Listbox.Button style={{backgroundColor: backgroundColor || '#FFFFFF', borderColor: borderColor || '#D7D3D1'}}
                className={classNames(
                  borderColor ? 'border-2' : 'border',
                  "relative w-full bg-white shadow-sm rounded-md pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-black sm:text-sm"
                )}>
              <span className="flex items-center">
                <span className="ml-3 block truncate h-6 flex items-center">{selected.name}</span>
              </span>
                <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black/5 overflow-auto focus:outline-none sm:text-sm">
                  {renderedOptions.map((option) => (
                    <Listbox.Option
                      key={option.value.id}
                      className={({ active }) =>
                        classNames(
                          active ? 'text-white bg-gray-400' : 'text-gray-900',
                          'cursor-default select-none relative py-2 pl-3 pr-9'
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex items-center">
                            {option.value.profile?.image ? <img src={option.value.profile?.image} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" /> : <div className="h-6"/>}
                            <span
                              className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                            >
                            { option.name === emptyOption.name ? emptyOption.name : `${option.value.profile?.first_name} ${option.value.profile?.last_name}` }

                          </span>
                          </div>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  )
}

export default PeopleDropdown
