import React, {Fragment, useCallback} from "react";
import {ApolloError, useMutation} from "@apollo/client";
import {REQUEST_UPDATE_SUBSCRIPTION_MUTATION} from "../../data/client/user.api";
import {classNames} from "../../helpers";
import {Formik, Form, FormikHelpers} from "formik";
import AuthInputField from "./../Authentication/AuthInputField";
import * as Yup from 'yup';
import {Dialog, Transition} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {CheckCircleIcon} from "@heroicons/react/24/solid";
import AuthCheckBoxField from "../Authentication/AuthCheckBoxField";
import {useLocation, useNavigate} from "react-router-dom";

const includedFeatures = [
  'Private forum access',
  'Member resources',
  'Entry to annual conference',
  'Official member t-shirt',
]

interface Props {
  user: User
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
}

const UpgradeModal: React.FC<Props> = ({ user, show, setShow }) => {
  const [requestUpdateSubscription, { loading: loadingRequestUpdateSubscription, data: dataRequestUpdateSubscription  }] = useMutation(REQUEST_UPDATE_SUBSCRIPTION_MUTATION);
  const navigate = useNavigate()
  const onSubmit = useCallback(async () => {
    let response = await requestUpdateSubscription({ variables: { user: user.id, subscription_level: 10, referrer: window.location.href  }});
    window.location.href = response.data.requestUpdateSubscription.message

  }, [requestUpdateSubscription])

  const onClose = () => {
    setShow(false)
  };
  return (

    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
        <div className="flex min-h-screen text-center md:block md:px-2 lg:px-4" style={{ fontSize: 0 }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="hidden fixed inset-0 bg-gray-500/75 transition-opacity md:block" />
          </Transition.Child>

          <span className="hidden md:inline-block md:align-middle md:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enterTo="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 md:scale-100"
            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-3xl md:px-4 md:my-8 md:align-middle lg:max-w-5xl">
              <div className="w-full max-w-4xl mx-auto relative items-center bg-white md:rounded-xl overflow-hidden shadow-2xl">
                <button
                  type="button"
                  className="absolute top-4 right-4 text-white lg:text-gray-400 hover:text-gray-300 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8 z-100"
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                <div className="relative max-w-7xl">
                    <div className="max-w-lg mx-auto rounded-lg overflow-hidden lg:max-w-none lg:flex">
                      <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                        <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">Equipt Pro Membership</h3>
                        <p className="mt-6 text-base text-gray-500">
                          Lorem ipsum dolor sit amet consect etur adipisicing elit. Itaque amet indis perferendis blanditiis
                          repellendus etur quidem assumenda.
                        </p>
                        <div className="mt-8">
                          <div className="flex items-center">
                            <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-indigo-600">
                              What's included
                            </h4>
                            <div className="flex-1 border-t-2 border-gray-200" />
                          </div>
                          <ul role="list" className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                            {includedFeatures.map((feature) => (
                              <li key={feature} className="flex items-start lg:col-span-1">
                                <div className="flex-shrink-0">
                                  <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                                </div>
                                <p className="ml-3 text-sm text-gray-700">{feature}</p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                        <p className="text-lg leading-6 font-medium text-gray-900">Monthly Subscription</p>
                        <div className="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
                          <span>$19</span>
                          <span className="ml-2 text-xl font-medium text-gray-500">/month</span>
                        </div>
                        <p className="mt-4 text-sm">
                          <a href="#" className="font-medium text-gray-500 underline">
                            Learn about our membership policy
                          </a>
                        </p>
                        <div className="mt-6">
                          <div className="rounded-md shadow">
                            <button onClick={onSubmit}
                              className="flex items-center w-full justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900"
                            >
                              Start your trial
                            </button>
                          </div>
                        </div>
                        <div className="mt-4 text-sm">
                          <a href="#" className="font-medium text-gray-900">
                            14 Day Trial
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default UpgradeModal
