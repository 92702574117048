import React, {useCallback, useEffect} from "react";
import {ReactComponent as EquiptLogo} from './../../assets/img/logo-white.svg'
import {Link, useLocation, useNavigate} from "react-router-dom";
import {getPageClassName, UserIsAuthenticated, UserIsUnconfirmed} from "../../helpers";

import { Fragment } from 'react'
import {Disclosure, Menu, Popover, Transition} from '@headlessui/react'
import {MagnifyingGlassIcon} from '@heroicons/react/24/solid'
import { FaDiscord, FaPatreon } from "react-icons/fa"
import { /*BellIcon,*/ Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Avatar from "../Elements/Avatar";
import Banner from "../Banner";
import {useMutation} from "@apollo/client";
import {
  REQUEST_USER_CONFIRM_MUTATION,
  REQUEST_DATA_VERIFY_MUTATION,
} from "../../data/client/authentication.api";
import { supahelpers } from "../../api_supabase";

interface Props {
  user: User
  refreshUser: (args?: any) => Promise<any>
  confirmations: Confirmation[]
  refreshConfirmations: () => void
  alert: AlertDialog
  showModal: React.Dispatch<React.SetStateAction<string>>
}

/*const navigation = [
  { name: 'Calendar', href: '#', current: false }
]*/

const userNavigation = [
  { name: 'Dashboard', href: '/dashboard' },
  { name: 'Settings', href: '/settings' },
  { name: 'Sign out', href: '/logout' },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}
const Navbar: React.FC<Props> = ({ user, refreshUser, alert, showModal, confirmations, refreshConfirmations }) => {
  let location = useLocation();
  const navigate = useNavigate()
  let navClassName = getPageClassName(location.pathname)
  const [reqeustUserConfirm] = useMutation(REQUEST_USER_CONFIRM_MUTATION);
  const [requestDataVerify] = useMutation(REQUEST_DATA_VERIFY_MUTATION);
  //const [reqeustPasswordReset] = useMutation(REQUEST_PASSWORD_RESET_MUTATION);
  useEffect(() => {
    onGetDiscordStatus(user)
  }, [user]);

  const onGetDiscordStatus = useCallback( async (user: User) => {
    if(UserIsAuthenticated(user)){
      if(!user.discord_invite){
        await supahelpers.functions.getDiscordInvite();
        await refreshUser();
      }
    }
  }, [refreshUser])

  const onRequestUserConfirm = useCallback(async () => {
    await supahelpers.auth.requestVerificationCode()
    setTimeout(() => alert({ buttonText: 'Ok', title: 'User Confirmation Request Send', description: "Check your email for directions to confirm your account"}), 3000)
    navigate('/confirm');

  }, [reqeustUserConfirm, alert, navigate])

  /*
  TODO: enable creating accounts with no passwords
  const onResetPassword = useCallback(async () => {
    await reqeustPasswordReset({ variables: { email: user.email }})
    alert({ buttonText: 'Ok', title: 'Create Password Request Sent', description: "You should get an email to create a password"})
  }, [reqeustPasswordReset, alert, user.email])*/

  const onRequestPhoneNumberVerify = useCallback(async () => {
    let unconfirmedConfirmation = confirmations.find((confirmation) => confirmation.usage === 'phone' && !confirmation.confirmed)
    if(unconfirmedConfirmation && (new Date()).getTime() < parseInt(unconfirmedConfirmation.expiry)){
      //confirmation confirmation exists and is not expired
      showModal('verify')
    } else {
      await requestDataVerify({ variables: { confirmation_usage: 'phone' }})
      await refreshConfirmations()
      showModal('verify')
    }
  }, [confirmations, refreshConfirmations, requestDataVerify, showModal])

  const showConfirmedUserNotification = location.pathname !== '/confirm' && UserIsUnconfirmed(user);
  const showConfirmPhoneNotification = location.pathname !== '/confirm' && !UserIsUnconfirmed(user) && user.phone
    && !confirmations.find((confirmation) => confirmation.usage === 'phone' && !!confirmation.confirmed);
  //const showSetPasswordNotification = UserIsAuthenticated(user) && !showConfirmedUserNotification && !showConfirmPhoneNotification && !user.has_password;
  if(user.is_placeholder_data){
    return <div className={classNames(
      navClassName || "",
      "nav-container top-0")}/>
  }

  return (
    <>
      <div className={`${navClassName} nav_notifications`}>
        {showConfirmedUserNotification &&
        <Banner status="warning" dismissible={false} >
          <span className="md:hidden block">Please confirm your email address!</span>
          <span className="hidden md:inline">Please follow the link in your email to confirm your email address!</span>
          <span className="block sm:ml-2 sm:inline-block">
            <button onClick={onRequestUserConfirm} className="font-bold underline bg-transparent">
              {' '}
              If you are having trouble, request another verification code here <span aria-hidden="true">&rarr;</span>
            </button>
          </span>
        </Banner>}
        {showConfirmPhoneNotification &&
        <Banner status="warning" dismissible={false} >
          <span>Complete your Account Set Up</span>
          <span className="block sm:ml-2 sm:inline-block">
            <button onClick={onRequestPhoneNumberVerify} className="font-bold underline">
              {' '}
              Verify your phone number here <span aria-hidden="true">&rarr;</span>
            </button>
          </span>
        </Banner>}
        {/*showSetPasswordNotification &&
        <Banner status="warning" dismissible={false} >
          <span>Secure Your Account!</span>
          <span className="block sm:ml-2 sm:inline-block">
            <button onClick={onResetPassword} className="font-bold underline">
              {' '}
              Set a password for your account here <span aria-hidden="true">&rarr;</span>
            </button>
          </span>
        </Banner>*/}
      </div>
      <div className={classNames(
        navClassName || "",
        "nav-container",
        showConfirmPhoneNotification || showConfirmedUserNotification /*|| showSetPasswordNotification*/ ? "" : "top-0",
      )}>

        {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
        <Popover
          as="header"
          className={({ open }) =>
            classNames(
              open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
              'bg-black/0 relative lg:overflow-y-visible z-40 w-full pb-5'
            )
          }
        >
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className={classNames(
                  "h-20 relative flex justify-between items-center xl:grid xl:grid-cols-12 lg:gap-8",
                  UserIsAuthenticated(user) ? "border-b border-white/20" : ""
                )}>
                  {/*Right Side*/}
                  <div className="flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-2">
                    <div className="flex-shrink-0 flex items-center">
                      <Link to={UserIsAuthenticated(user) ? "/dashboard" : "/"}>
                        <EquiptLogo
                          className={classNames("nav-logo block h-8 w-auto", navClassName)}
                        />
                      </Link>
                    </div>
                  </div>

                  {/*Center*/}
                  <div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-5 justify-center items-center flex">
                    {UserIsAuthenticated(user) ?
                    <div className="flex items-center px-6 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
                      <div className="w-full opacity-0">
                        <label htmlFor="search" className="sr-only">
                          Search
                        </label>
                        <div className="relative text-white focus-within:text-gray-600">
                          <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                            <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                          </div>
                          <input
                            id="search"
                            name="search"
                            className={classNames(
                              "block w-full rounded-md py-2 pl-10 pr-3 bg-white border-0 text-sm ",
                              "bg-white/20 placeholder-gray-100 text-gray-100",
                              "focus:bg-white/90 focus:placeholder-gray-400 focus:text-gray-600 focus:border-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-100"
                            )}
                            placeholder="Search"
                            type="search"
                          />
                        </div>
                      </div>
                    </div> :
                      <>{/* TODO: show menu when we have more items to show*/}
                      <Link to={"/equipt-for-success"} className="hidden auth_buttons font-bold font-button text-white uppercase p-2 hover:bg-white/30 rounded-lg">
                        Equipt for Success
                      </Link>
                    </>
                    }
                  </div>

                  {/*Left Side Mobile Menu Button*/}
                  <div className="flex items-center md:absolute md:right-0 md:inset-y-0 lg:hidden auth_buttons">
                    <Popover.Button className="-mx-2 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                      <span className="sr-only">Open menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Popover.Button>
                  </div>

                  {/*Left Side*/}
                  <div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-5">
                    { UserIsAuthenticated(user) && <Link
                      to="/"
                      className="ml-5 flex-shrink-0 rounded-full p-1 text-white bg-white/0 opacity-60 hover:bg-white/20 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                    >
                      <span className="sr-only">View notifications</span>
                      {/*<BellIcon className="h-6 w-6" aria-hidden="true" />*/}
                    </Link>}

                    { UserIsAuthenticated(user) && <a
                      type="button"
                      href="https://www.patreon.com/joinequipt"
                      target="_blank"
                      className="inline-flex bg-orange-500 items-center rounded-md border uppercase border-transparent px-4 py-2 text-sm font-bold text-white shadow-sm hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                    >
                      <FaPatreon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                      Memberships
                    </a>}

                    { UserIsAuthenticated(user) && !!user?.discord_invite &&  (user.discord_invite_used ? <a
                        type="button"
                        style={{backgroundColor: '#5865F2'}}
                        href={`https://discord.com/channels/1067824032210227301`}
                        target="_blank"
                        className="inline-flex items-center rounded-md border ml-5 uppercase border-transparent px-4 py-2 text-sm font-bold text-white shadow-sm hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                      >
                        <FaDiscord className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                        Open Discord
                      </a> : <a
                      type="button"
                      style={{backgroundColor: '#5865F2'}}
                      href={`https://discord.gg/${user.discord_invite}`}
                      target="_blank"
                      className="inline-flex items-center rounded-md border ml-5 uppercase border-transparent px-4 py-2 text-sm font-bold text-white shadow-sm hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                    >
                      <FaDiscord className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                      Join Us on Discord
                    </a>)}


                    {/* Profile dropdown */}
                    {UserIsAuthenticated(user) &&
                    <Menu as="div" className="flex-shrink-0 relative ml-5">
                      <div>
                        <Menu.Button className="bg-white rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          <span className="sr-only">Open user menu</span>
                          <Avatar className="h-9 w-9 border" image={user.profile?.image} first_name={user.profile?.first_name} last_name={user.profile?.last_name} />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black/5 py-1 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block py-2 px-4 text-sm text-gray-700'
                                  )}
                                >
                                  {item.name}
                                </Link>
                              )}

                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>}
                    {!UserIsAuthenticated(user) &&
                    <>
                      <Link
                        to="/login"
                        className={
                          classNames(
                            "mr-4 btn btn-secondary-white w-28 auth_buttons auth_buttons-login"
                          )}
                      >
                        Login
                      </Link>
                      <Link
                        to="/register"
                        className={
                          classNames(
                            "btn btn-color bg-orange w-40 auth_buttons auth_buttons-register"
                          )}
                      >
                      Join Now
                      </Link>
                    </>
                    }
                  </div>
                </div>
              </div>
              {/* Dropdown Menu*/}
              <Popover.Panel as="nav" className="lg:hidden" aria-label="Global">
                <>
                  {!UserIsAuthenticated(user) && <div className="border-t border-gray-200 pt-4 pb-3 bg-white rounded-lg drop-shadow-lg auth_buttons">
                    <div className="mt-3 max-w-3xl mx-auto px-2 space-y-1 sm:px-4">
                    <Popover.Button
                      as={Link}
                      to="/login"
                      className={
                        classNames(
                          "block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                        )}
                    >
                      Login
                    </Popover.Button>
                    <Popover.Button
                      as={Link}
                      to="/register"
                      className={
                        classNames(
                          "block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                        )}
                    >
                      Sign Up
                    </Popover.Button>
                      <div className="border-t border-gray-200 pt-4 pb-3">
                        <Popover.Button
                          as={Link}
                          to="/equipt-for-success"
                          className={
                            classNames(
                              "block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                            )}
                        >
                          Equipt for Success
                        </Popover.Button>
                      </div>
                  </div>
                  </div>}
                  {/* Authenticated Dropdown Menu*/}
                  {UserIsAuthenticated(user) && <div className="border-t border-gray-200 pt-4 pb-3 bg-white rounded-lg drop-shadow-lg">
                    <div className="max-w-3xl mx-auto px-4 flex items-center sm:px-6">
                      <div className="flex-shrink-0">
                        <Avatar className="h-10 w-10" image={user.profile?.image} first_name={user.profile?.first_name} last_name={user.profile?.last_name} />
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium text-gray-800">{user.profile?.first_name}</div>
                        <div className="text-sm font-medium text-gray-500">{user.email}</div>
                      </div>
                      <button
                        type="button"
                        className="ml-auto flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <span className="sr-only">View notifications</span>
                        {/*<BellIcon className="h-6 w-6" aria-hidden="true" />*/}
                      </button>
                    </div>
                    <div className="mt-3 max-w-3xl mx-auto px-2 space-y-1 sm:px-4">
                      {userNavigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                        >
                          {item.name}
                        </a>
                      ))}
                        <a type="button"
                           style={{backgroundColor: '#5865F2'}}
                           href={`https://discord.gg/${user.discord_invite}`}
                           target="_blank"
                           className="inline-flex w-full items-center justify-center rounded-md border uppercase border-transparent px-4 py-2 text-sm font-bold text-white shadow-sm hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                        >
                          <FaDiscord className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                          <span>
                            Join Us on Discord
                          </span>
                        </a>
                      { UserIsAuthenticated(user) && <a
                        type="button"
                        href="https://www.patreon.com/joinequipt"
                        target="_blank"
                        className="inline-flex w-full bg-orange-500 items-center justify-center rounded-md border uppercase border-transparent px-4 py-2 text-sm font-bold text-white shadow-sm hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                      >
                        <FaPatreon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                        Memberships
                      </a>}
                    </div>
                  </div>}
                </>
              </Popover.Panel>
            </>
          )}
        </Popover>
        <Disclosure as="div" className={classNames(
            navClassName,
          "nav-bg absolute overflow-hidden w-full top-0"
        )}>
          {({ open }) => (
            <>
              <div
                aria-hidden="true"
                className={classNames(
                  open ? 'bottom-0' : 'inset-y-0',
                  'absolute inset-x-0 left-1/2 transform -translate-x-1/2 w-full overflow-hidden lg:inset-y-0',
                  'nav-bg-pattern'
                )}
              >
              </div>
            </>
          )}
        </Disclosure>
      </div>
    </>
  )
}
export default Navbar
