import microsoft_bg from "../assets/img/microsoft_bg.png";
import dell_bg from "../assets/img/dell_img.jpg"
import accentureLogo from "../assets/img/company-accenture.png";

const defaultTheme: Theme = {
    title_font: '',
    body_font: '',
    primary_color: '#FA7953',
    secondary_color: '#4668DC',
    img_bg_color_split: [1, 5, 6],
    img_bg_color_to: "rgba(68, 64, 60, .9)",
    img_bg_color_from: "rgba(68, 64, 60, 1)",
    img_bg_color_opacity: '.9',
    top_bg_image: microsoft_bg,
    img_bg_color_darkness: '.1',
    logo: '',
    logo_classes: 'p-10',
    hired_count_bg_color: '#000000',
    hired_count_text_color: '#FFFFFF',
    cta_bg_color: '',
    cta_text_color: '#FFFFFF',
    cta_outline_color: "#FFFFFF",
    bottom_bg_color: '#F8F8FA',
    menu_text_color: '#4668DC',
    menu_tracker_color: '#798DBA',
    dropdown_label_color: '#535353',
    dropdown_bg_color: '#FFFFFF',
    dropdown_outline_color: '',
    course_card_label_color: '#FA7953',
    course_card_button_bg_color: '#2141AF',
    course_card_button_font_color: '#FFFFFF',
    course_card_button_outline_color: '',
    course_card_outline_color: '',
    course_card_shadow: '',
    course_sessions: '#2141AF'
}

const cubsTheme: Theme = {
    title_font: '',
    body_font: '',
    primary_color: '#CC3433',
    secondary_color: '#0E3386',
    img_bg_color_to: "rgba(14, 51, 134, .42)",
    img_bg_color_from: "rgba(14, 51, 134, .96)",
    img_bg_color_split: [1, 5, 6],
    img_bg_color_opacity: '1',
    top_bg_image: microsoft_bg,
    img_bg_color_darkness: '.1',
    logo: '',
    logo_classes: 'p-10',
    hired_count_bg_color: '#CC3433',
    hired_count_text_color: '#FFFFFF',
    cta_bg_color: '#CC3433',
    cta_text_color: '#FFFFFF',
    bottom_bg_color: '#F8F8FA',
    menu_text_color: '#0E3386',
    menu_tracker_color: '#798DBA',
    dropdown_label_color: '#0E3386',
    dropdown_bg_color: '#FFFFFF',
    dropdown_outline_color: '#0E3386',
    course_card_label_color: '#CC3433',
    course_card_button_bg_color: '#0E3386',
    course_card_button_font_color: '#FFFFFF',
    course_card_button_outline_color: '',
    course_card_outline_color: '',
    course_card_shadow: '',
    course_sessions: '#0E3386'
}

const dellTheme: Theme = {
    title_font: '',
    body_font: '',
    primary_color: '#0076CE',
    secondary_color: '#808080',
    img_bg_color_from: "rgba(0, 118, 206, .96)",
    img_bg_color_to: "rgba(183, 41, 90, .52)",
    //img_bg_color_from: "rgba(0, 68, 124, .96)",
    img_bg_color_split: [3, 8, 1],
    img_bg_color_opacity: '1',
    top_bg_image: dell_bg,
    img_bg_color_darkness: '.1',
    logo: '',
    logo_classes: 'p-10',
    hired_count_bg_color: '#808080',
    hired_count_text_color: '#FFFFFF',
    cta_bg_color: '#0076CE',
    cta_text_color: '#FFFFFF',
    bottom_bg_color: '#F8F8FA',
    menu_text_color: '#00447C',
    menu_tracker_color: '#798DBA',
    dropdown_label_color: '#00447C',
    dropdown_bg_color: '#FFFFFF',
    dropdown_outline_color: '#0E3386',
    course_card_label_color: '#B7295A',
    course_card_button_bg_color: '#0076CE',
    course_card_button_font_color: '#FFFFFF',
    course_card_button_outline_color: '',
    course_card_outline_color: '',
    course_card_shadow: '',
    course_sessions: '#0076CE'
}

const lehighTheme: Theme = {
    title_font: '',
    body_font: '',
    primary_color: '#502D0E',
    secondary_color: '#d0343e',
    img_bg_color_from: "rgba(80,45,14,0.9)",
    img_bg_color_to: "rgba(0, 0, 0, .52)",
    //img_bg_color_from: "rgba(0, 68, 124, .96)",
    img_bg_color_split: [3, 8, 1],
    img_bg_color_opacity: '1',
    top_bg_image: dell_bg,
    img_bg_color_darkness: '.1',
    logo: '',
    logo_classes: 'p-10',
    hired_count_bg_color: '#d0343e',
    hired_count_text_color: '#FFFFFF',
    cta_bg_color: '#ffd94a',
    cta_text_color: '#555555',
    bottom_bg_color: '#F8F8FA',
    menu_text_color: '#d0343e',
    menu_tracker_color: '#798DBA',
    dropdown_label_color: '#00447C',
    dropdown_bg_color: '#FFFFFF',
    dropdown_outline_color: '#0E3386',
    course_card_label_color: '#d0343e',
    course_card_button_bg_color: '#502D0E',
    course_card_button_font_color: '#FFFFFF',
    course_card_button_outline_color: '',
    course_card_outline_color: '',
    course_card_shadow: '',
    course_sessions: '#502D0E'
}

const modyfiTheme: Theme = {
    title_font: '',
    body_font: '',
    // 234,60,186
    primary_color: '#ea3cba',
    secondary_color: '#a8117e',
    img_bg_color_from: "rgba(0, 0, 0, .76)",
    //img_bg_color_to: "rgba(234,60,186,0.82)",
    //img_bg_color_to: "rgba(168, 17, 126, .56)",
    img_bg_color_to: "rgba(66,66,66,0.76)",
    img_bg_color_split: [4, 7, 1],
    img_bg_color_opacity: '1',
    top_bg_image: dell_bg,
    img_bg_color_darkness: '.1',
    logo: '',
    logo_classes: 'px-28 pb-4',
    hired_count_bg_color: '#ea3cba',
    hired_count_text_color: '#FFFFFF',
    cta_bg_color: '#ea3cba',
    cta_text_color: '#FFFFFF',
    bottom_bg_color: '#F8F8FA',
    menu_text_color: '#626262',
    menu_tracker_color: '#798DBA',
    dropdown_label_color: '#00447C',
    dropdown_bg_color: '#FFFFFF',
    dropdown_outline_color: '#0E3386',
    course_card_label_color: '#ea3cba',
    course_card_button_bg_color: '#ea3cba',
    course_card_button_font_color: '#FFFFFF',
    course_card_button_outline_color: '',
    course_card_outline_color: '',
    course_card_shadow: '',
    course_sessions: '#000000'
}

//    name: 'Accenture',
//       slug: 'accenture',
//       logo: accentureLogo,

const accentureTheme: Theme ={
    title_font: '',
    body_font: '',

    primary_color: '', //new
    secondary_color: '', //new

    img_bg_color_to: "rgba(0, 47, 37, .54)",
    img_bg_color_from: "rgba(0, 77, 255, 1)",

    img_bg_color_split: [4, 7, 1],//new
    top_bg_image: dell_bg, //new

    img_bg_color_opacity: '1',
    img_bg_color_darkness: '.4',
    logo: accentureLogo,
    logo_classes: 'px-28 pb-4', //new

    hired_count_bg_color: '#A100FF',
    hired_count_text_color: '',
    cta_bg_color: '#A100FF',
    cta_text_color: '#FFFFFF',
    bottom_bg_color: '',
    menu_text_color: '',
    menu_tracker_color: '',
    dropdown_label_color: '',
    dropdown_bg_color: '',
    dropdown_outline_color: '',
    course_card_label_color: '',
    course_card_button_bg_color: '#A100FF',
    course_card_button_font_color: '#FFFFFF',
    course_card_button_outline_color: '',
    course_card_outline_color: '',
    course_card_shadow: '',
    course_sessions: '#000000'
}

const clearflameTheme: Theme = {
    title_font: '',
    body_font: '',
    img_bg_color_to: "rgba(0, 0, 0, .54)",
    img_bg_color_from: "rgba(0, 0, 0, .86)",
    img_bg_color_opacity: '1',
    top_bg_image: "https://equipt-dev.nyc3.cdn.digitaloceanspaces.com/clearflame_cover.jpg",
    img_bg_color_darkness: '.1',
    logo: '',
    hired_count_bg_color: '#00A6FF',
    hired_count_text_color: '#FFFFFF',
    cta_bg_color: 'transparent',
    cta_outline_color: '#FFFFFF',
    cta_text_color: '#FFFFFF',
    bottom_bg_color: '#FFFFFF',
    menu_text_color: '#0A1236',
    menu_tracker_color: '#00A6FF',
    menu_border_color: '#0A1236',
    dropdown_label_color: '#0A1236',
    dropdown_bg_color: '#FFFFFF',
    dropdown_outline_color: '#0A1236',
    course_card_label_color: '#005CD4',
    course_card_button_bg_color: '#00A6FF',
    course_card_button_font_color: '#FFFFFF',
    course_card_button_outline_color: '',
    course_card_outline_color: '#0A1236',
    course_card_shadow: '',
    table_divider_color: '#0A1236',
    table_font_color: '#0A1236',
    primary_color: '',
    secondary_color: '',
    img_bg_color_split: [0,0,0],
    logo_classes: '',
    course_sessions: ''
}

const soarceConsultingTheme: Theme = {
    ...defaultTheme,
    cta_bg_color: '#FFD6CA',
    cta_outline_color: '#211F1D',
    cta_text_color: '#211F1D',
    //course_card_button_bg_color: '#FFD6CA',
    //course_card_button_font_color: '#211F1D'
}

export { defaultTheme, cubsTheme, clearflameTheme, dellTheme, soarceConsultingTheme, lehighTheme, modyfiTheme, accentureTheme }
