import React, { useEffect, useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import {classNames} from "../../helpers";
import useCallbackWithTimeout from "../../hooks/useCallbackWithTimeout";
import {Link} from "react-router-dom";

interface Props {
  formKey: string
}

const SubscriberForm: React.FC<Props> = ({formKey}) => {
  const [state, handleSubmit, resetForm] = useForm(formKey);
  const [input, setInput] = useState<string>("");
  const [callFormReset] = useCallbackWithTimeout(resetForm, [resetForm, state], 5000)

  useEffect(() => {
    if(state.succeeded && input !== ""){
      setInput("");
      callFormReset();
    }
  }, [state.succeeded, input, setInput, resetForm])

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex items-center justify-center pt-5">
        <div className={classNames(
          "inline-placeholder relative max-w-2xl w-full flex flex-col md:flex-row items-center md:pl-5 transition-opacity ease-in-out delay-500 duration-500",
          state.submitting ? "opacity-50" : ""
        )}>
          <input name="subscriber-email" id="subscriber-email" type="email" placeholder="Enter Your Email"
                 value={input} onChange={(e) => setInput(e.target.value)}
            className="placeholder-italic appearance-none block w-full md:w-1/2 px-1.5 md:px-3 py-2 md:pr-10 bg-gray-100 border border-orange-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm peer"/>
          <label htmlFor="subscriber-email" title="Enter Your Email"/>
          <button type="submit" className={
            classNames("right-0 btn btn-primary btn-group z-1 ring-1 ring-white peer-focus:ring-orange-500",
              "md:absolute transition-growing-button ease-in-out duration-1000 mt-2 md:mt-0",
              state.succeeded ? "w-full pointer-events-none btn-secondary": "w-full md:w-96 btn-primary"
              )} disabled={state.submitting}>
            {state.succeeded ? "Thanks for Signing Up!" : "Sign Up for Notifications"}
          </button>
          <input name="subscriber-type" value="initiative-subscriber" id="subscriber-type" type="hidden" />
        </div>
      </div>
      <div className="mt-1 text-xs pl-2 text-gray-400">
        <input name="subscriber-terms" id="subscriber-terms" value="agree" type="hidden" />
        By submitting you agree to our <Link to="/terms" target="_blank" rel="noopener noreferrer" className="font-bold underline hover:text-orange-500">Terms and Conditions</Link> and have read the <Link to="/privacy" target="_blank" rel="noopener noreferrer" className="font-bold underline hover:text-orange-500">Privacy Policy</Link> and <Link to="/cookie" target="_blank" rel="noopener noreferrer" className="font-bold underline hover:text-orange-500">Cookie Policy</Link>.
      </div>
      <span className="text-sm h-6 w-full block flex justify-center items-center text-red-500 italic input-error" >
          <ValidationError prefix="Email" field="subscriber-email" errors={state.errors} />
      </span>
    </form>
  );
}

export default SubscriberForm


