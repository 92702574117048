import React, {useCallback, useEffect, useRef} from "react";
import {classNames, UserIsAuthenticated} from "../../helpers";
import {Formik, Form, FormikHelpers, FormikProps, Field} from "formik";
import AuthInputField from "./AuthInputField";
import * as Yup from 'yup';
import {useNavigate, useLocation} from "react-router-dom";
import {supahelpers} from "../../api_supabase";
import {AuthError} from "@supabase/supabase-js";

const ConfirmSchema = Yup.object().shape({
  confirmation_code: Yup.string()
    .length(6, 'Please Enter a Valid Code')
    .required('Please Enter a Valid Code'),
  type: Yup.string().optional(),
});

interface Values {
  confirmation_code: string
  type?: string
}

interface Props {
  user: User
  refreshUser: (args?: any) => Promise<any>
  alert: AlertDialog
  buttonText?: string
  onConfirm?: () => any
}

const Confirm: React.FC<Props> = ({ user, refreshUser, alert, onConfirm, buttonText }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const formikRef = useRef<FormikProps<Values>>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      let searchParams = new URLSearchParams(location.search);
      let token = searchParams.get('token');
      let type = searchParams.get('type')
      if(token && formikRef.current){
        formikRef.current.setValues({ confirmation_code: token, type: type ?? undefined })
        formikRef.current.submitForm()
      }
    }, 0)
    return () => clearTimeout(timer);
  }, [location.search, formikRef])

  const handleSubmit =  useCallback(async (values: Values, { setFieldError, setStatus } : FormikHelpers<Values>) => {
    setStatus('')
    try {
      await supahelpers.auth.verifyVerificationCode({ token: values.confirmation_code, type: values.type })
      if(onConfirm) {
        await onConfirm()
      }
      else{
        if(UserIsAuthenticated(user)){
          refreshUser()
          alert({ buttonText: 'Ok', title: 'Account Confirmed', description: "Your Account is ready for use"})
          setTimeout(() => navigate('/dashboard'), 100)
        } else {
          alert({ buttonText: 'Ok', title: 'Account Confirmed', description: "Login to start using your account"})
          setTimeout(() => navigate('/login'), 100)
        }
      }
    } catch (e){
      if(e instanceof AuthError ){
        setStatus('submit_error')
        setFieldError("confirmation_code", e.message)
      } else if( e instanceof Error ) {
        setFieldError("confirmation_code", e?.message )
        setStatus({value: 'submit_error',  message: "Server Error" })
      } else {
        setFieldError("confirmation_code", "Server Error" )
        setStatus({value: 'submit_error',  message: "Server Error" })
      }
    }
  }, [navigate, alert, onConfirm, refreshUser, user])

  return (
    <Formik innerRef={formikRef} initialValues={{
      confirmation_code: '',
    }} onSubmit={handleSubmit} validationSchema={ConfirmSchema}>
      {({ isSubmitting, status }) => (
        <>
          <div className="sm:mx-auto mb-6 auth_header">
            <h2 className="text-center text-gray-400 text-4xl">Confirm Your Account</h2>
            <h6 className="text-center text-sm uppercase mt-4 py-2 font-semibold text-blue-500">Check your email</h6>
            <div className="text-center text-xs w-full text-gray-500">Use the code to verify your email address</div>
          </div>
          <Form className="gap-0 gap-x-2 grid grid-cols-12" action="#" method="POST">
            <AuthInputField name="confirmation_code" id="confirmation_code" placeholder="Code" containerClassName="inline-placeholder-center col-span-12"
                            type="text" className="input input-secondary" autoComplete="off"/>
            <Field id="type" name="type" type="hidden" />
            <div className="col-span-12 mb-6 mt-4">
              <button
                type="submit"
                className={
                  classNames(
                    "animate__animated animate__fast",
                    isSubmitting ? "pointer-events-none opacity-50" :"",
                    status === "submit_error" ? 'animate__shakeX': '',
                    "text-center font-bold font-button text-sm text-white uppercase px-6 py-3 w-full flex justify-center border border-transparent rounded-full shadow-sm bg-blue-500 hover:bg-blue-600 outline-none focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300"
                  )}
              >
                {buttonText || 'Submit'}
              </button>
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default Confirm
