const user = {
  name: 'Debbie Lewis',
  handle: 'deblewis',
  email: 'debbielewis@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=320&h=320&q=80',
}

export default function EditAccount() {
  return (
    <form action="#" method="POST" className="bg-gradient-to-r from-gray-50 to-white">
      <div className="py-6 px-4 sm:p-12 lg:pb-8">
        <div>
          <h2 className="text-3xl leading-10 font-semibold text-blue-500">Account</h2>
          <p className="mt-1 text-sm text-gray-500">
            Manage your account information.
          </p>
        </div>

        <div className="mt-6 grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-12">
            <label htmlFor="username" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <div className="mt-1 rounded-md shadow-sm flex">
              <input
                type="text"
                name="email"
                id="email"
                autoComplete="email"
                className="focus:ring-sky-500 focus:border-sky-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                defaultValue={user.email}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Privacy section */}
      <div className="pt-6 divide-y divide-gray-200">
        <div/>
        <div className="mt-4 py-4 px-4 flex justify-end sm:px-6">
          <button
            type="button"
            className="btn btn-primary-white-border"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-5 btn btn-primary"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  )
}
