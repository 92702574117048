import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import React from "react";
import TileDate from "./Elements/TileDate";
import {useFetchRecentSessions} from "../data/client/session.api";
import {Link} from "react-router-dom";
import {classNames} from "../helpers";
import { defaultTheme } from "../helpers/defaults";

export default function RecentEvents() {
  const { data: recentSessions } = useFetchRecentSessions({ limit: 5 })
  return(
    <section title="past events" className="bg-gray-100 flex py-40 min-h-screen overflow-x-hidden">
      <div className="max-w-5xl w-full mx-auto">
        <div className="text-2xl text-gray-500 pb-12 mx-auto max-w-3xl text-center">Check out some other events from great companies</div>
        <div className="mt-10 max-w-sm">
          <Swiper
            navigation={true}
            modules={[Navigation]}
            speed={500}
            className="past-event-swiper"
          >
            {recentSessions.map((session) =>
              <SwiperSlide className="py-10 px-16">
                {({ isActive }) => (
                  <Link to={`/course/${session.course.slug || ""}`} className={classNames(
                    "transition ease-in-out duration-500 block rounded-lg hover:scale-105",
                    isActive ?  "shadow-2xl -translate-y-6" : "shadow-lg")} >
                    <div className="bg-cover bg-center rounded-lg h-64 w-64 relative"  style={{backgroundImage: `url(${session.course.cover})`}}>
                      <div
                        className={classNames(`absolute w-full right-0 overflow-hidden rounded-t-lg`,  "h-full rounded-b-lg")}
                        style={{
                          opacity:defaultTheme.img_bg_color_opacity,
                          backgroundImage: `linear-gradient(to right, ${defaultTheme.img_bg_color_from}, ${defaultTheme.img_bg_color_to})`
                        }}
                      />
                      <div
                        className={classNames("absolute w-full right-0 overflow-hidden rounded-t-lg bg-black", "h-full rounded-b-lg")}
                        style={{
                          opacity: defaultTheme.img_bg_color_darkness
                        }}
                      />
                      <div className="absolute w-full h-full inset-0 py-6 flex flex-col">
                        <div className="flex text-xs font-bold text-white/80 px-6 mb-1">
                          <TileDate timeClassName="order-2 ml-1" dateClassName="order-1" date={session.starts_at}/>
                        </div>
                        <div className="font-semibold text-lg text-white mb-auto px-6 leading-tight">{session.course.title}</div>
                        <div className="grid grid-cols-2 w-40 h-28 items-end mx-auto">
                          <div className="h-28 w-28 bg-cover bg-center rounded-full" style={{backgroundImage: `url(${session.featured_instructor?.user?.profile?.image})`}}/>
                          <div className="h-20 w-20 bg-cover bg-center rounded-full mb-3" style={{backgroundImage: `url(${session.featured_instructor?.membership?.organization.logo})`}}/>
                        </div>
                        <div className="text-white/70 whitespace-nowrap text-center">
                          <span className="font-semibold">{`${session.featured_instructor?.user?.profile?.first_name} ${session.featured_instructor?.user?.profile?.last_name}, `}</span>
                          <span>{session.featured_instructor?.membership?.organization.name}</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                )}
              </SwiperSlide>
            )}
          </Swiper>
          {recentSessions.length === 0 ? <div /> : ""}
        </div>
      </div>
    </section>
  )
}