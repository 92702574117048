/* Created by JideOsan on 1/11/2023. */
import { isSomeEnum } from "is-some-enum";
import {
  Course_Modules,
  Courses,
  Organizations,
  Maybe,
  Users,
  Sessions,
  Registrations,
  Modules, Memberships, Job_Applications, Jobs, Profiles, Instructors
} from "../../gql/graphql";
import {
  parseCourse,
  parseCourseModule, parseInstructor,
  parseJob,
  parseJobApplication,
  parseMembership, parseModule,
  parseOrganization, parseProfile, parseRegistration, parseSession,
  parseUser
} from "./factories";
import { parseTheme } from "./factories.custom";
type ServerCollection<ServerModelType> = { edges: { node: ServerModelType }[] }

enum ApprovalProcess {
  DOMAIN = "DOMAIN",
  INVITE = "INVITE",
  MANUEL = "MANUAL"
}

enum OrganizationType {
  "COMPANY" = "COMPANY",
  "UNIVERSITY" = "UNIVERSITY",
  "BOOTCAMP" = "BOOTCAMP",
  "GROUP" = "GROUP"
}

function parseStringArray(arr: Maybe<Maybe<string>[]> | undefined){
  return (arr || []).reduce((values, value) =>
    ((values).concat(value || [])), [] as string[] );
}

function parseCollection<ServerModelType, ModelType>(
  data:ServerCollection<ServerModelType> | undefined | null,
  parseFunction: (data: ServerModelType) => ModelType
): ModelType[] {
  if(!data) return []
  return data.edges.map(({ node }) => parseFunction(node))
}

export const parseServerCourse = (data: Partial<Courses> | undefined): Course => {
  if(!data) return parseCourse({});
  const { attended, highlights, organizations, course_modulesCollection, ...course } = data
  return {
    ...parseCourse(course),
    organization: parseServerOrganization(organizations || {}),
    highlights: parseStringArray(highlights),
    course_modules: parseCollection<Course_Modules, CourseModule>(course_modulesCollection, parseServerCourseModule),
  }
}


export const parseServerCourseModule = (data: Partial<Course_Modules> | undefined): CourseModule => {
  if(!data) return parseCourseModule({});
  const { course, courses, instructorsCollection, module, modules, sessionsCollection, ...course_module } = data
  return {
    ...parseCourseModule(course_module),
    course: parseServerCourse(courses || {}),
    instructors: parseCollection<Instructors, Instructor>(instructorsCollection, parseServerInstructor),
    module: parseServerModule(modules || {}),
    sessions: parseCollection<Sessions, Session>(sessionsCollection, parseServerSession),
  }
}

export const parseServerInstructor = (data: Partial<Instructors> | undefined): Instructor => {
  if(!data) return parseInstructor({});
  const { memberships, membership, ...instructor } = data
  return {
    ...parseInstructor({ ...instructor }),
    membership: parseServerMembership(memberships || {})
  }
}


export const parseServerJob = (data: Partial<Jobs> | undefined): Job => {
  if(!data) return parseJob({});
  const { organizations, ...jobs } = data
  return {
    ...parseJob(jobs),
    organization: parseServerOrganization(organizations || {}),
  }
}


export const parseServerJobApplication = (data: Partial<Job_Applications> | undefined): JobApplication => {
  if(!data) return parseJobApplication({});
  const { jobs, users, ...jobApplication } = data
  return {
    ...parseJobApplication(jobApplication || {}),
    job: parseServerJob(jobs || {}),
    user: parseServerUser(users || {})
  }
}


export const parseServerMembership = (data: Partial<Memberships> | undefined): Membership => {
  if(!data) return parseMembership({});
  const { organizations, users, ...membership } = data
  return {
    ...parseMembership(membership || {}),
    organization: parseServerOrganization(organizations || {}),
    user: parseServerUser(users || {})
  }
}


export const parseServerModule = (data: Partial<Modules> | undefined): Module => {
  if(!data) return parseModule({});
  const { skills, ...module } = data
  return {
    ...parseModule(module),
    skills: parseStringArray(skills)
  }
}


export const parseServerOrganization = (data: Partial<Organizations> | undefined): Organization => {
  if(!data) return parseOrganization({});
  const { users, theme, benefits, approval_process, type, ...organization } = data
  const isApprovalProcess = isSomeEnum(ApprovalProcess);
  const isOrganizationType = isSomeEnum(OrganizationType);
  return {
    ...parseOrganization({
      approval_process: isApprovalProcess(approval_process) ? approval_process : undefined,
      type: isOrganizationType(type) ? type : undefined,
      ...organization
    }),
    admin: parseServerUser(users || {}),
    theme: parseServerTheme(JSON.parse(theme || "{}")),
  }
}


export const parseServerRegistration = (data: Partial<Registrations> | undefined): Registration => {
  if(!data) return parseRegistration({});
  const { session, users, ...registration } = data
  return {
    ...parseRegistration(registration),
    session: parseServerSession(session || {}),
    student: parseServerUser(users || {})
  }
}


export const parseServerSession = (data: Partial<Sessions> | undefined): Session => {
  if(!data) return parseSession({});
  const { courses, course_modules, files, modules, ...session } = data
  return {
    ...parseSession(session),
    course: parseServerCourse(courses || {}),
    course_module: parseServerCourseModule(course_modules || {}),
    files: parseStringArray(files),
    //instructors: parseServerInstructor(instructors || {}),
    module: parseServerModule(modules || {}),
  }
}


export const parseServerUser = (data: Partial<Users> | undefined): User => {
  if(!data) return parseUser({});
  const { membershipsCollection, profiles, ...users } = data
  return {
    ...parseUser(users),
    memberships: parseCollection<Memberships, Membership>(membershipsCollection, parseServerMembership),
    profile: parseServerProfile(profiles || {})
  }
}

export const parseServerProfile = (data: Partial<Profiles> | undefined): Profile => {
  if(!data) return parseProfile({});
  const { ...profiles} = data
  return {
    ...parseProfile(profiles)
  }
}

export const parseServerTheme = (data: Partial<Theme> | undefined = {}): Theme => {
  return {
    ...parseTheme(data),
  }
}
