import React from 'react';

import ModalVideo from 'react-modal-video'
import "react-modal-video/css/modal-video.min.css"

export default function VideoModal({ showVideo, onClose }) {
  return (
    <>
      <ModalVideo channel='vimeo' autoplay isOpen={!!showVideo} videoId={showVideo} onClose={onClose} />
    </>
  );
}
