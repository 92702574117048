import React from 'react'
import {useFetchJobs} from "../../data/client/job.api";
import {ArrowTopRightOnSquareIcon, MapPinIcon} from "@heroicons/react/24/outline";
import {CalendarIcon} from "@heroicons/react/24/solid";

const FeaturedJobs: React.FC = () => {
  const { data: jobs = [] } = useFetchJobs()
  return (
    <section aria-labelledby="recent-hires-title">
      <div className="rounded-lg bg-white overflow-hidden shadow">
        <div className="p-6">
          <h2 className="text-base font-semibold text-gray-900 uppercase" id="recent-hires-title">
            Featured Jobs
          </h2>
          <div className="flow-root mt-4">
            <ul className="">
              {jobs.map((job) => (
                <li key={job.id} className="">
                  <div className="block my-2 bg-orange-100/50 p-3 rounded-lg">
                    <div className="px-0 py-2 flex items-center">
                      <div className="shrink bg-white rounded-full flex items-center justify-center shadow-md w-12 h-12 mr-4">
                        <img src={job.organization.logo} className="w-8" alt={`${job.organization.name} Image`} />
                      </div>
                      <div className="grow">
                        <div className={"text-sm font-semibold text-gray-500 truncate "}>{job.title}</div>
                          <span className="mt-2 flex items-center text-xs text-gray-500 sm:mt-0">
                            <MapPinIcon className="flex-shrink-0 mr-.5 h-4 w-4 text-gray-400" aria-hidden="true" />
                            {job.location}
                          </span>
                      </div>
                    </div>
                    <div className="flex justify-between items-end">
                      <div className="flex items-center text-xs text-gray-500">
                        <CalendarIcon className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" aria-hidden="true" />
                        <p>
                          Closes on <span>{(new Date(job.closed_at)).toLocaleDateString()}</span>
                        </p>
                      </div>
                      <a href={job.url} target="_blank" rel="noreferrer"
                         className="job-apply-btn-slim flex items-center relative bg-orange text-white">Apply <ArrowTopRightOnSquareIcon className="h-5 right-1 absolute"/></a>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturedJobs
