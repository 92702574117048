import React from 'react'
import {ReactComponent as EquiptLogo} from "../assets/img/logo-white.svg";
import {FaFacebook, FaLinkedinIn} from "react-icons/fa";
import {SiTrustpilot} from "react-icons/si";

export default function EquiptCTA() {
  const social = [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/joinequipt',
      icon: (props: React.SVGProps<SVGSVGElement>) => <FaFacebook {...props} />,
    },
      {
        name: 'LinkedIn',
          href: 'https://www.linkedin.com/company/joinequipt',
        icon: (props: React.SVGProps<SVGSVGElement>) => <FaLinkedinIn {...props} />,
      },
      {
        name: 'TrustPilot',
          href: 'https://www.trustpilot.com/review/joinequipt.com',
        icon: (props: React.SVGProps<SVGSVGElement>) => <SiTrustpilot {...props} />,
      },
    ]

  return (
    <>
      <section title="final cta" className="bg-transparent z-1 p-5">
        <div className="max-w-7xl mx-auto bg-blue-500 drop-shadow-lg rounded-2xl p-10 lg:p-20 text-center relative -mb-56 z-1 text-center">
          <div className="leading-none text-white font-bold font-heading text-3xl lg:text-5xl uppercase mb-6">Interested in working with us?</div>
          <div className="text-white/80 mx-auto content_body mb-6 lg:px-32">
            We’re building a live & interactive professional networking platform that transforms how companies and talent connect. If you’d like to learn more, we'd love to hear from you.
          </div>
          <a href="https://calendly.com/hubert-dagbo/30min?back=1&month=2021-07" target="_blank" className="rounded-none btn btn-secondary-white mx-auto w-full md:w-96">Contact Us</a>

        </div>
      </section>
      <footer className="bg-blue-800 relative pt-56" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="grid grid-cols-3 gap-8">
            <div className="col-span-3 md:col-span-2 lg:col-span-1">
              <EquiptLogo className="logo-white-orange h-12 w-auto" />
              <div className="text-white/60 text-lg font-light mt-2">Transforming how companies <br /> and professionals connect</div>
              {/*<div className="flex justify-between text-white uppercase font-heading font-bold mt-8 text-sm">
                <span>Companies</span>
                <span>Members</span>
                <span>Partners</span>
              </div>*/}
            </div>
            <div className="col-start-3 col-span-1">
              {/*<h3 className="text-base font-medium text-white uppercase font-heading font-bold">Subscribe to our newsletter</h3>
              <p className="mt-4 text-blue-200 text-lg font-semibold">
                The latest news, articles, and resources, sent to your inbox weekly.
              </p>
              <form className="mt-4 sm:flex sm:max-w-md">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  type="email"
                  name="email-address"
                  id="email-address"
                  autoComplete="email"
                  required
                  className="w-full min-w-0 appearance-none rounded-l-full border border-transparent bg-white py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:border-white focus:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  placeholder="Enter your email"
                />
                <div className="mt-3 rounded-md sm:mt-0 -ml-5 sm:flex-shrink-0">

                  <button className="rounded-none btn btn-color bg-orange">Subscribe</button>
                </div>
              </form>*/}
            </div>
          </div>
          <div className="mt-8 border-t border-white/60 pt-8 md:flex md:items-center md:justify-between">
            <div className="flex space-x-6 md:order-2">
              {social.map((item) => (
                <a key={item.name} href={item.href} className="text-white/60 hover:text-white/20">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
            <p className="mt-8 text-base text-white/60 md:order-1 md:mt-0">
              &copy; 2022 Equipt, Inc. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  )
}
