/* This example requires Tailwind CSS v2.0+ */
import React, {forwardRef, useState} from 'react'
import {ErrorMessage, FieldArray, Field, FieldAttributes, useFormikContext} from "formik";
import {classNames} from "../../helpers";
import {Combobox} from "@headlessui/react";
import {ChevronUpDownIcon} from "@heroicons/react/24/solid";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {useFindNewInstructors} from "../../data/client/instructor.api";
import { get as selectValue } from 'object-path'

interface Props extends FieldAttributes<any> {
  id: string
  name: string
  selector: string
}

const parseProfileFullName = (profile?: Profile) => {
  return `${profile?.first_name} ${profile?.last_name}`;
}

export type FormHelpers = {
  reset: () => void;
};

interface InstructorValue {
  id: string,
  featured: boolean,
  membership: string,
  user: string,
  instructor: Instructor
}

const InstructorSelectorInput: React.FC<Props> = forwardRef<FormHelpers, Props>(({ id, name, selector, ...fieldProps }, ref) => {
  const [instructorSearch, setInstructorSearch] = useState("");
  const { data: instructorOptions = [] } = useFindNewInstructors({ search: instructorSearch});
  const { values, errors, touched } = useFormikContext<{ instructors: { id: string, featured: boolean, membership: string, user: string, instructor: Instructor }[]}>();

  return(
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <>
          <Combobox as="div" onChange={(instructor: Instructor) => {
            if(!selectValue(values, selector).some(({ id, membership} : InstructorValue) =>
              (instructor.id && instructor.id === id) || membership === instructor.membership.id
            )){
              arrayHelpers.push({ id: instructor.id, featured: false,  membership: instructor.membership.id, instructor })
            }
          }}>
            <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">Hosts
              <span className="ml-2 text-sm text-gray-400">
                Who will be hosting and speaking at the event?
              </span></Combobox.Label>
            <div className="relative mt-2">
              <Combobox.Input
                className={classNames(
                  "w-full rounded-md border border-gray-300 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm focus:outline-none focus:ring-blue-300 focus:border-blue-300 sm:text-sm sm:leading-6",
                  errors.instructors && touched.instructors  ? 'border-red' : ''
                )}
                onChange={(event) => setInstructorSearch(event.target.value)}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </Combobox.Button>

              {instructorOptions.length > 0 && (
                <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {instructorOptions.map((instructor) => (
                    <Combobox.Option
                      key={instructor.membership.id}
                      value={instructor}
                      className={({ active }) =>
                        classNames(
                          'relative cursor-default select-none py-2 pl-3 pr-9',
                          active ? 'bg-blue-100' : 'text-gray-900'
                        )
                      }
                    >
                      {({ active, selected }) => (
                        <>
                          <div className="flex items-center">
                            <img src={instructor?.user?.profile?.image} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" />
                            <span className={classNames('ml-3 truncate')}>{parseProfileFullName(instructor.user.profile)}</span>
                          </div>
                        </>
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              )}
            </div>
          </Combobox>
          <span className="text-xs h-6 w-full block text-left text-red-500 italic input-error" >
            <ErrorMessage name={name}/>
          </span>
          <div>
            <ul role="list" className="divide-y divide-gray-100">
              {selectValue(values, selector).map(({instructor, ...instructorFormValues} : InstructorValue, index: number) => (
                <li key={(index)} className="flex items-center justify-between gap-x-6 py-5">
                  <div className="flex flex-none gap-x-4">
                    <div className="isolate flex -space-x-2">
                      <img
                        className="relative z-20 inline-block h-10 w-10 rounded-full bg-gray-50 ring-2 ring-white"
                        src={instructor.user.profile?.image}
                        alt=""
                      />
                      <div
                        className="relative z-30 inline-block h-8 w-8 my-auto shadow-lg rounded-full ring-2 bg-contain ring-white bg-white bg-no-repeat bg-center"
                        style={{backgroundImage: `url(${instructor.organization?.logo})`}}
                      />
                    </div>
                    <div className="min-w-0 flex-auto">
                      <p className="text-sm font-semibold text-gray-900">{parseProfileFullName(instructor.user.profile)}</p>
                      <p className="truncate text-xs text-gray-500">{instructor.organization?.name}</p>
                    </div>
                  </div>
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <Field
                        type="checkbox" name={`instructors[${index}].featured`}
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                      />
                      <Field type="hidden" name={`instructors[${index}].id`} />
                      <Field type="hidden" name={`instructors[${index}].membership`} />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="comments" className="font-medium text-gray-900">
                        Featured Guest
                      </label>
                      <p id="comments-description" className="text-gray-500 text-xs">
                        Check this box if this person is a featured guest.
                      </p>
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={() => arrayHelpers.remove(index)}
                    className="inline-flex items-center gap-x-1.5 rounded-full bg-red-400 px-2.5 py-1 text-xs font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                  >
                    <XMarkIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    Remove
                  </button>

                </li>
              ))}
            </ul>
          </div>
        </>
      )}/>
  )
})

export default InstructorSelectorInput
