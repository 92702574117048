 /* Created  on 2/28/2022. */
import gql from "graphql-tag";
 import {useQuery, UseQueryResult} from "@tanstack/react-query";
 import {supabase} from "../../api_supabase";
 import {parseJob} from "./factories";

export const JobFragment = `
created
id
updated
available
description
posted_at
title
url
`;

export const GET_JOB_QUERY_BY_ID = (id: string) => gql`
  {
    job(job:{ id: "${id}" }) {
      ${JobFragment}
    }
  }
`;

 export const FIND_JOB_BY_ORGANIZATION_QUERY = (organization: string, available:boolean = true) => gql`
  {
    jobs(job:{ available: ${available}, organization: "${organization}" }) {
      ${JobFragment}
    }
  }
`;

export const FIND_JOB_QUERY = (available: boolean, organization: { id: string }, title: string) => gql`
  {
    jobs(job:{available: "${available}", organization: "${organization}", title: "${title}"}) {
      ${JobFragment}
    }
  }
`;

export const CREATE_JOB_MUTATION = gql`
  mutation JobCreate($available: Boolean, $description: String, $organization: IdInput!, $posted_at: String, $title: String!, $url: String){
    jobCreate(job:{ available: $available, description: $description, organization: $organization, posted_at: $posted_at, title: $title, url: $url }) {
      ${JobFragment}
    }
  }
`;

export const DELETE_JOB_MUTATION = gql`
  mutation JobDelete($id: ID!){
     jobDelete(job:{ id: $id }) {
      ${JobFragment}
    }
  }
`;

export const UPDATE_JOB_MUTATION = gql`
  mutation JobUpdate($application: String!, $available: Boolean, $description: String, $organization: IdInput!, $posted_at: String, $title: String!, $url: String){
    jobUpdate(job:{ application: $string, available: $boolean, description: $string, organization: $string, posted_at: $string, title: $string, url: $string }) {
      ${JobFragment}
    }
  }
`;

 export function useFetchJobs(): UseQueryResult<Job[]> {

   async function fetchJobs(): Promise<Job[]> {
     const { data } = await supabase
       .from('jobs')
       .select('id, created, updated, available, closed_at, description, location, posted_at, title, url , organization ( name, logo )')
       .eq('available', true);

     return (data || []).map((job) => parseJob(job));
   }

   return useQuery<Job[]>({
     queryKey: ["featuredJobs"],
     queryFn: fetchJobs,
   })
 }

