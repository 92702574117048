import React, {Fragment, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  PaperClipIcon,
} from '@heroicons/react/24/solid'
import {getUpcomingSessions} from "../../fake_api";
import { organizations } from "../../data/fakeData";
import AvatarGroup from "../Elements/AvatarGroup";
import {getAttendees} from "../../data/fakeData";
import {Link} from "react-router-dom";

interface Props {
  viewingSession: boolean
  setViewingSession:  React.Dispatch<React.SetStateAction<boolean>>
}

const sessions = getUpcomingSessions()
const session =  sessions[1]
const organization = organizations[3]


export default function SessionModal({ viewingSession, setViewingSession}: Props) {
  const [attendees] = useState(getAttendees().map(({ image}) => image))

  return (
    <Transition.Root show={viewingSession} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setViewingSession}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500/75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
              <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h3 className="text-2xl leading-6 font-bold text-gray-900 mb-5 pt-5">The Agile Mindset</h3>
                  <div className="flex items-center">
                    <AvatarGroup size="sm" avatars={attendees} total_count={23} color="#A100FF" />
                    <span className="text-xs text-black uppercase tracking-wide font-semibold ml-5">
                          23 Students have attended this session with you
                        </span>
                  </div>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Instructor</dt>
                      <dd className="mt-1 text-sm text-gray-900">{session.teacher.name}</dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Date</dt>
                      <dd className="mt-1 text-sm text-gray-900">January 7 2020 6PM</dd>
                    </div>
                    <div className="sm:col-span-2">
                      <dt className="text-sm font-medium text-gray-500">Description</dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        Agile can sound overwhelming, or abstract at first, but at the heart of it is an intense focus on delivering innovation and value to your customer quickly. In this course, you will gain a better and more methodological approach to delivering innovative and disruptive technology products and services through the use of Agile.
                      </dd>
                    </div>
                    <div className="sm:col-span-2">
                      <dt className="text-sm font-medium text-gray-500">Downloads</dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                          <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                            <div className="w-0 flex-1 flex items-center">
                              <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                              <span className="ml-2 flex-1 w-0 truncate">cheatsheet.pdf</span>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                              <button className="font-medium text-indigo-600 hover:text-indigo-500">
                                Download
                              </button>
                            </div>
                          </li>
                          <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                            <div className="w-0 flex-1 flex items-center">
                              <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                              <span className="ml-2 flex-1 w-0 truncate">email_template.pdf</span>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                              <button className="font-medium text-indigo-600 hover:text-indigo-500">
                                Download
                              </button>
                            </div>
                          </li>
                        </ul>
                      </dd>
                    </div>
                    <div className="sm:col-span-2">
                      <dd className="mt-1 text-sm text-gray-900">
                        <ul className="grid grid-cols-2 gap-4 gap-y-2">
                          <button
                            className="tile-btn w-full col-span-2"
                            style={{
                              backgroundColor: organization.theme.cta_bg_color,
                              color: organization.theme.course_card_button_font_color,
                              transition: "all .15s ease" }}
                            type="button"
                          >
                            Book a 1-on-1 or group information session with HR
                          </button>
                          <Link
                            className="tile-btn w-full"
                            style={{
                              backgroundColor: organization.theme.course_card_button_bg_color,
                              color: organization.theme.course_card_button_font_color,
                              transition: "all .15s ease" }}
                            type="button"
                            to={`/organization/the-chicago-cubs/jobs`}
                          >
                            View relevant jobs
                          </Link>
                          <a
                            className="tile-btn w-full"
                            style={{
                              backgroundColor: organization.theme.course_card_button_bg_color,
                              color: organization.theme.course_card_button_font_color,
                              transition: "all .15s ease" }}
                            type="button"
                            href={`#}`}
                          >
                            Follow the cubs
                          </a>
                        </ul>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
