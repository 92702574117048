import {ErrorMessage, FieldAttributes, useField} from "formik";
import React from "react";
import {classNames} from "../../helpers";

interface RadioFieldProps extends FieldAttributes<any> {
  id: string
  name: string
  as?: "textarea"
  placeholder?: string
  containerClassName?: string
  labelClassName?: string
  labelText: JSX.Element | string
  description?: string
  options: string[]
  //errorClassName?: string
}

const RadioField: React.FC<RadioFieldProps> = (
  { name,
    id,
    as,
    placeholder,
    containerClassName = "col-span-12",
    className = "h-4 w-4 border-gray-300 text-orange-500 focus:ring-orange-500",
    labelClassName = "block text-sm font-medium text-gray-800",
    labelText,
    description,
    options,
    children, ...fieldProps }) => {
  const [field, meta] = useField({ name, id, placeholder, ...fieldProps});
  return(
    <div className={containerClassName}>
      <label htmlFor={id} className={labelClassName}>
        {labelText}
        {description && <span className="ml-2 text-sm text-gray-400">{description}</span>}
      </label>
      <fieldset className="mt-4">
        <legend className="sr-only">Event Type</legend>
        <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
          {options.map((option) => (
            <div key={`${name}_${option}`} className="flex items-center">
              <input id={`${name}_${option}`} placeholder={placeholder} {...field} {...fieldProps}
                     value={option} type="radio" checked={meta.value === option}
                     className={classNames(className, meta.touched && meta.error ? 'border-red' : '')}/>
              <label htmlFor={`${name}_${option}`} className="ml-3 block text-sm font-medium capitalize leading-6 text-gray-900">
                {option.toLowerCase()}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
      <span className="text-xs h-6 w-full block text-left text-red-500 italic input-error" >
        <ErrorMessage name={name}/>
      </span>
    </div>
  )
}

export default RadioField
