import {nanoid} from "nanoid";

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export function romanize(num: number) {
  const lookup:{ [index:string] : number } = {M:1000,CM:900,D:500,CD:400,C:100,XC:90,L:50,XL:40,X:10,IX:9,V:5,IV:4,I:1}
  let roman = '', i;
  for ( i in lookup ) {
    while ( num >= lookup[i] ) {
      roman += i;
      num -= lookup[i];
    }
  }
  return roman;
}

export function humanize (value: number): string {
  value = Math.floor(value)
  var ones = [ '', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten',
    'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen']
  var tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety']

  var numString = value.toString()

  if (value < 0) throw new Error('Negative numbers are not supported.')

  if (value === 0) return 'zero'

  //the case of 1 - 20
  if (value < 20) {
    return ones[value]
  }

  if (numString.length === 2) {
    return tens[Number(numString[0])] + ' ' + ones[Number(numString[1])]
  }

  //100 and more
  if (numString.length === 3) {
    if (numString[1] === '0' && numString[2] === '0')
      return ones[Number(numString[0])] + ' hundred'
    else
      return (
        ones[Number(numString[0])] +
        ' hundred and ' +
        humanize(+(numString[1] + numString[2]))
      )
  }

  if (numString.length === 4) {
    var end = +(numString[1] + numString[2] + numString[3])
    if (end === 0) return ones[Number(numString[0])] + ' thousand'
    if (end < 100)
      return (
        ones[Number(numString[0])] +
        ' thousand and ' +
        humanize(end)
      )
    return (
      ones[Number(numString[0])] + ' thousand ' + humanize(end)
    )
  }
  return ''
}


function isWithinDays(current: Date, days:number, from_date = new Date()) {
  let calculatedDateString = new Date(from_date.getTime() + (days * 24 * 60 * 60 * 1000)).toLocaleDateString();
  return (new Date(calculatedDateString).getTime()) >= (new Date(current.toLocaleDateString()).getTime())
}

export function getRelativeDate(relative: Date, duration = 60){
  const now = new Date();
  const nowTime = now.getTime()
  const relativeTime = relative.getTime()

  if(nowTime - relativeTime > 0){
    if(nowTime - relativeTime > (duration*60*1000)){
      return { label: "Archived", status: 'archived', color: 'gray', colorClassName: 'bg-gray-900', description: 'This Event Ended'  }
    }
    return { label: "Live", status: 'live', color: 'red', colorClassName: 'bg-red-500', description: 'This Event is in Progress'  }
  } else {
    if(now.getDate() === relative.getDate()){
      let formatted = new Intl.DateTimeFormat('en-US', { hour: 'numeric', hour12: true, timeZoneName: 'short'}).format(relativeTime)
      return { label: "Today", status: 'today', color: 'green', colorClassName: 'bg-green-500', description: `Live Today at ${formatted}` }
    } else if(isWithinDays(relative, 1, now)){
      let formatted = new Intl.DateTimeFormat('en-US', { hour: 'numeric', hour12: true, timeZoneName: 'short'}).format(relativeTime)
      return { label: "Tomorrow", status: 'upcoming', color: 'yellow', colorClassName: 'bg-yellow-500', description: `Live Tomorrow at ${formatted}` }
    } else if(isWithinDays(relative, 6 - now.getDay(), now)){
      let formatted = new Intl.DateTimeFormat('en-US', { weekday:"long", hour: 'numeric', hour12: true, timeZoneName: 'short'}).format(relativeTime)
      return { label: "This Week", status: 'upcoming', color: 'yellow', colorClassName: 'bg-yellow-500', description: `Live This ${formatted}`  }
    } else if(isWithinDays(relative, 13 - now.getDay(), now)){
      let formatted = new Intl.DateTimeFormat('en-US', { weekday:"long", hour: 'numeric', hour12: true, timeZoneName: 'short'}).format(relativeTime)
      return { label: "Next Week", status: 'upcoming', color: 'yellow', colorClassName: 'bg-yellow-500', description: `Live Next ${formatted}`  }
    } else {
      let label = "In "+
        Math.ceil((new Date(relative.toLocaleDateString()).getTime() - new Date(now.toLocaleDateString()).getTime()) / (1000 * 3600 * 24))
        +" Days";
      let formatted = new Intl.DateTimeFormat('en-US', { hour: 'numeric', hour12: true, timeZoneName: 'short'}).format(relativeTime)
      return { label, status: 'upcoming', color: 'yellow', colorClassName: 'bg-yellow-500', description: `Live ${label} at ${formatted}` };
    }
  }
}

export function getMembershipTypeName(membership_type: number) {
  if( membership_type === 9) return "ADMIN"
  else if( membership_type === 3) return "INSTRUCTOR"
  else if( membership_type === 2) return "MEMBER"
  else if( membership_type === 1) return "FOLLOWER"
  else return ""
}

export function getMembershipType(membership_type_name: "ADMIN" | "INSTRUCTOR" | "MEMBER" | "FOLLOWER" | string) {
  if( membership_type_name === "ADMIN") return 9
  else if( membership_type_name === "INSTRUCTOR") return 3
  else if( membership_type_name === "MEMBER") return 2
  else if( membership_type_name === "FOLLOWER") return 1
  else return 0
}

export const getParseImageFromServer = (bucket:string, path: string) =>{
  if(!path) return "";
  try{
    const url = new URL(path);
    return url.pathname.replace(`/storage/v1/object/public/${bucket}/`, "")
  } catch (e){
    return path
  }
}

export const delay = (time: number) => {
  return new Promise(res => {
    setTimeout(res,time)
  })
}

interface StringByString {
  [key: string]: string | undefined;
}

const pageClassMap: StringByString = {
  'login': `auth_page login_page`,
  'forgot': `auth_page forgot_page`,
  'register': 'auth_page register_page',
  'confirm': 'auth_page confirm_page',
  'reset': 'auth_page reset_page',
  '': 'home_page'
}

export const companySizes = [
  { name: "0-1 employees", display: "1 person", value: 1 },
  { name: "2-10 employees", display: "2+ people", value: 10 },
  { name: "11-50 employees", display: "10+ people", value: 50 },
  { name: "51-200 employees", display: "50+ people", value: 200 },
  { name: "201-500 employees", display: "200+ people", value: 500 },
  { name: "501-1,000 employees", display: "500+ people", value: 1000 },
  { name: "1,001-5,000 employees", display: "1,000+ people", value: 5000 },
  { name: "5,001-10,000 employees", display: "5,000+ people", value: 10000 },
  { name: "10,000+ employees", display: "10,000+ people", value: 10001 },
]

export function ordinal(n: string) {
  let s = ["th", "st", "nd", "rd"];
  let v = parseInt(n)%100;
  return n + (s[(v-20)%10] || s[v] || s[0]);
}

export function formatDateForTile(utc_timestamp: number | string){
  utc_timestamp = typeof utc_timestamp === "string" ? parseInt(utc_timestamp) : utc_timestamp
  const fullFormat = new Intl.DateTimeFormat("en", { dateStyle: "full", timeStyle: "full" });
  try{
    const partsMap = fullFormat.formatToParts(utc_timestamp).reduce((map, { type, value }) => {
      map.set(type, value)
      return map;
    }, new Map());

    let formattedDate = `${partsMap.get('weekday')}, ${partsMap.get('month')} ${ordinal(partsMap.get('day'))}`
    let formattedTime = `${partsMap.get('hour')}${(parseInt(partsMap.get('minute')) === 0 ? '' : `:${partsMap.get('minute')}` )}${partsMap.get('dayPeriod')}`

    return  formattedDate+', '+formattedTime;
  } catch (e) {
    return "Invalid Date"
  }
}

export const getPageClassName = (path: string, suffix: string = "_page") => {
  let className = path.split("/")[1];
  return pageClassMap[className] || (className ? `${className}${suffix}` : '');
}

export const UserIsAuthenticated = (user: User) => !user.is_placeholder_data && user.id

export const UserIsUnconfirmed = (user: User) => UserIsAuthenticated(user) && user.confirmed === false

export const UserHasSubscription = (user: User, subscription_level = 10) => user.subscription_level >= subscription_level;

export function hexToRGB(h:string) {
  let r:string = '0', g:string = '0', b:string = '0';

  // 3 digits
  if (h.length === 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];

    // 6 digits
  } else if (h.length === 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }

  return "rgb("+ +r + "," + +g + "," + +b + ")";
}

export function stringToColour(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}

export async function sleep(seconds: number) {
  return new Promise((resolve) =>setTimeout(resolve, seconds * 1000));
}

export const membershipIsApproved = (membership:Membership) =>{
  return !membership.organization.approval_process || (membership.organization.approval_process && membership.accepted)
}

export const generateId = nanoid