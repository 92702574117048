import React, { useState, useCallback } from "react";

interface UseAlert {
  alert: (props: AlertProps) => any
  confirm: (props: AlertProps) => any
  modal: AlertModal
  setModal: React.Dispatch<React.SetStateAction<AlertModal>>
  onConfirm: (args: any) => any
  onClose: (args: any) => any
}

export default function useAlert(): UseAlert {
  const [modal, setModal] = useState<AlertModal>({ open: false });
  const [confirmCallback, setConfirmCallback] = useState<{resolve?: () => any, reject?: () => any}>({})
  const onConfirmed = useCallback(() => {
    return new Promise<void>(( savedResolve, savedReject) => {
      setConfirmCallback({
        resolve: () => {
          setModal((prevModal) => ({...prevModal, open: false}))
          savedResolve()
        },
        reject: () => {
          setModal((prevModal) => ({...prevModal, open: false}))
          savedReject()
        }
      })
    });
  }, [setConfirmCallback])

  const onClose = useCallback(() => {
      if(modal.isConfirm){
        confirmCallback.reject && confirmCallback.reject()
      } else {
        confirmCallback.resolve && confirmCallback.resolve()
      }
    },[modal, confirmCallback])

  const onConfirm = useCallback(() => {
    confirmCallback.resolve && confirmCallback.resolve()
    },[confirmCallback])

  return {
    modal, setModal, onClose, onConfirm,
    alert: (props: AlertProps) => {
      let args = {...props, open: true, isConfirm: false}
      setModal(args)
      return onConfirmed()
    }, //send an alert
    confirm: (props: AlertProps): Promise<any> => {
      setModal({...props, open: true, isConfirm: true})
      return onConfirmed()
    } //send a confirm dialog
  }
}
