import React, { useCallback } from "react";
import { classNames } from "../../helpers";
import { Formik, Form, FormikHelpers } from "formik";
import AuthInputField from "./AuthInputField";
import * as Yup from 'yup';
import {supabase} from "../../api_supabase";

const ForgotSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

interface Values {
  email: string
}

interface Props {
  alert: (props: AlertProps) => any
}

const Forgot: React.FC<Props> = ({ alert }) => {

  const handleSubmit =  useCallback(async (values: Values, { setFieldError, setStatus, resetForm, setSubmitting } : FormikHelpers<Values>) => {
    setStatus('')
    try {
      const { error: requestResetError } = await supabase.auth.resetPasswordForEmail(values.email, {
        redirectTo: `${document.location.origin}/reset`,
      })
      if(requestResetError) throw requestResetError;
      alert({ buttonText: 'Ok', title: 'Reset Password Request Send', description: "If you have an account with us, you should get an email to reset your password"})
      resetForm()
    } catch (e){
      setStatus('submit_error')
      setFieldError("email", "Server Error")
    }
    setSubmitting(false)
  }, [ alert])

  return (
    <Formik initialValues={{
      email: '',
    }} onSubmit={handleSubmit} validationSchema={ForgotSchema}>
      {({ isSubmitting, status }) => (
        <>
          <div className="sm:mx-auto mb-6">
            <h2 className="text-center text-gray-400 text-4xl">Forgot Password</h2>
            <h6 className="text-center text-sm uppercase mt-4 py-2 font-semibold text-blue-500">No worries. We got you covered.</h6>
          </div>
          <Form className="gap-0 gap-x-2 grid grid-cols-12" action="#" method="POST">
            <AuthInputField name="email" id="register_email" placeholder="Email Address"
                            type="email" autoComplete="email" className="input input-secondary"/>
            <div className="col-span-12 mb-6 mt-4">
              <button
                type="submit"
                className={
                  classNames(
                    "animate__animated animate__fast",
                    isSubmitting ? "pointer-events-none opacity-50" :"",
                    status === "submit_error" ? 'animate__shakeX': '',
                    "text-center font-bold font-button text-sm text-white uppercase px-6 py-3 w-full flex justify-center border border-transparent rounded-full shadow-sm bg-blue-500 hover:bg-blue-600 outline-none focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300"
                  )}
              >
                Request Password Reset
              </button>
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default Forgot
