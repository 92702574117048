import React, {useState} from 'react'
import { Navigation, EffectCards } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/pagination"
import "swiper/css/navigation"
import 'swiper/css/effect-cards';


import { ChevronRightIcon } from '@heroicons/react/24/solid'
import {ReactComponent as CompanyCultivr} from './../assets/img/company-cultivr.svg'
import {ReactComponent as CompanySquareknot} from './../assets/img/company-Squareknot.svg'
import {ReactComponent as CompanyNike} from './../assets/img/company-Nike.svg'
import {ReactComponent as CompanySafeway} from './../assets/img/company-Safeway.svg'
import {ReactComponent as CompanyEY} from './../assets/img/company-EY.svg'
import {ReactComponent as CompanyMorganStanly} from './../assets/img/company-Morgan.svg'
import {ReactComponent as CompanyCreditSuisse} from './../assets/img/company-CreditSuisse.svg'
import {ReactComponent as CompanyDPI} from './../assets/img/company-dpi.svg'
import {ReactComponent as CompanyElsevier} from './../assets/img/company-elsevier.svg'
import {ReactComponent as CompanyQuire} from './../assets/img/company-quire.svg'
import {ReactComponent as CompanyLehigh} from './../assets/img/company-Lehigh.svg'
import {ReactComponent as CompanyEulerius} from './../assets/img/company-Eulerius.svg'
import { Link } from 'react-router-dom'
import {classNames} from "../helpers";

const GetCompanySVG = (name: string, key?: string) => {
  switch (name){
    case "Squareknot":
      return  <CompanySquareknot key={key} className="mx-auto w-56"  />;
    case "Cultivr":
      return <CompanyCultivr key={key} className="mx-auto h-32 w-32" />;
    case "Safeway":
      return <CompanySafeway key={key} className="mx-auto w-72"  />;
    case "Nike":
      return <CompanyNike key={key} className="mx-auto h-32 w-40"  />;
    case "MorganStanly":
      return <CompanyMorganStanly key={key} className="mx-auto h-32 w-64"  />;
    case "EY":
      return <CompanyEY key={key} className="mx-auto h-24 w-24 mb-5"  />;
    case "CreditSuisse":
      return <CompanyCreditSuisse key={key} className="mx-auto h-32 w-72"  />;
    case "DPI":
      return <CompanyDPI key={key} className="mx-auto h-32 w-48 mt-3"  />;
    case "Elsevier":
      return <CompanyElsevier key={key} className="mx-auto h-32 w-48 mt-1 mb-2"  />;
    case "Quire":
      return <CompanyQuire key={key} className="mx-auto h-32 w-72 mt-3" />;
    case "Lehigh":
      return <CompanyLehigh key={key} className="mx-auto h-32 w-72 mt-3"  />
    case "Eulerius":
      return <CompanyEulerius key={key} className="mx-auto h-32 w-72 mt-3"  />
    default:
      return <></>
  }
}

interface Props {
  instructors: Instructor[]
  primaryColor?: string
  secondaryColor?: string
  showCompanies?: boolean
}

const InstructorDetails: React.FC<Props> = ({ instructors, primaryColor, secondaryColor, showCompanies }) => {
  const [selectedInstructor, setSelectedInstructor] = useState(instructors[0])
  return (
    <>
      <div className="relative bg-gradient-to-b from-gray-100 to-gray-100 overflow-hidden">
        <div className="relative pt-20 pb-36">
          <div className="mt-8 sm:mt-8">
            <div className="mx-auto max-w-7xl">
              <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                <div className="relative mt-16 sm:mt-24 lg:mt-0 lg:col-span-6 flex items-center flex-col">
                  <Swiper slidesPerView={1} navigation={instructors.length > 1} pagination={true} effect={'cards'}
                          cardsEffect={{ slideShadows: false, perSlideOffset: Math.max(32/instructors.length, 2)}}
                          grabCursor={true} onSlideChangeTransitionStart={(slide) => { setSelectedInstructor(instructors[slide.activeIndex]) }}
                          modules={[EffectCards, Navigation]} autoplay={{delay: 5000, pauseOnMouseEnter: true}}>
                    {instructors.map((instructor, index) =>
                    <SwiperSlide key={`slide-${instructor.id}-${index}`}>
                      {({ isActive }) => (
                        <div className="h-120 flex items-center">
                          <div className="relative mx-auto h-100 w-100 rounded-full bg-cover bg-center shadow-lg" style={{backgroundImage: `url(${instructor.user.profile?.image})`}}>
                           <img className="absolute -right-5 -top-5 mx-auto h-40 w-40 rounded-full" src={instructor.membership.organization.logo} alt="" />

                            <div className={classNames("absolute inset-0 rounded-full transition ease-in-out duration-500", isActive ? "opacity-0" : "opacity-50")}>
                              <div className="relative">
                                <div className={classNames("absolute inset-0 h-100 w-100 bg-white rounded-full")}/>
                                <div className="absolute -right-5 -top-5 mx-auto h-40 w-40 rounded-full bg-white"/>
                              </div>
                            </div>

                          </div>
                        </div>
                      )}
                    </SwiperSlide>
                    )}
                  </Swiper>

                  {/*<div style={{ backgroundColor: primaryColor || ''}}
                    className="bg-orange sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden -mt-14">
                    <div className="px-4 py-6 sm:px-7">
                      <div>
                        <h3 style={{ color: "white", opacity: .8}}
                          className="pb-1 text-gray-800 uppercase font-button text-base font-bold text-center text-orange-200">{`Attend Sessions lead by ${instructor.user.profile?.first_name} to unlock`}</h3>
                        <button
                          type="submit"
                          disabled={true}
                          style={{ backgroundColor: 'white', color: primaryColor}}
                          className="w-full btn btn-color flex items-center pointer-events-none"
                        >
                          <LockClosedIcon
                            style={{color: primaryColor}}
                            className="h-4 w-4 text-orange mr-1"  />
                          Schedule a private 15-minute session
                        </button>
                      </div>
                    </div>
                  </div>*/}
                </div>
                <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-start pt-16">
                  <div>
                    <Link
                      to="/"
                      className="inline-flex items-center text-gray-500 bg-gray-200 rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
                    >
                <span
                  //style={{ backgroundColor: secondaryColor || ''}}
                  className="px-3 py-0.5 pt-1 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-orange-500 rounded-full">
                  Speaker
                </span>
                      <span className="ml-4 text-sm mr-2">A Seasoned Professional</span>
                      <ChevronRightIcon className="w-5 h-5 text-gray-500 hidden" aria-hidden="true" />
                    </Link>
                    <h6 className="mt-4 sm:mt-3 lg:mt-3 text-gray-400 text-sm font-bold uppercase">{`Meet the ${selectedInstructor.featured ? "Featured Guest":"Host"}`}</h6>
                    <h1 className="text-4xl tracking-tight font-extrabold text-gray-500  sm:leading-none lg:text-5xl xl:text-6xl capitalize">
                      {selectedInstructor.user.profile?.first_name} {selectedInstructor.user.profile?.last_name}
                    </h1>
                    <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                      {selectedInstructor.user.profile?.bio}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      { showCompanies ?
        <div className="relative bg-gray-800 overflow-hidden">
          <div className="relative pt-6 pb-10">
            <div>
              <div className="mx-auto max-w-7xl">
                <div className="flex items-center justify-center mb-4">
                  <hr className="flex-1 border-gray-600"/>
                  <span className="mx-4 flex-0 text-gray-600 font-semibold uppercase">Experience from innovative companies</span>
                  <hr className="flex-1 border-gray-600"/>
                </div>
                <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                  <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-12 flex items-center text-gray-600">
                    {selectedInstructor.user.memberships?.map(({organization}) => organization.name ? GetCompanySVG(organization.name, organization.name) : '')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        : ''
      }
    </>
  )
}

export default InstructorDetails
