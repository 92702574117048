import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";

interface Props {
  children: JSX.Element
  user: User
}

const RequireAuth: React.FC<Props> = ({ children, user ,}) => {
  const location = useLocation()
  const navigate = useNavigate();

  useEffect(() => {
    if (user.is_placeholder_data !== true && !user.id) {
      navigate('/login', { state: {from: location.pathname}})
    }
  }, [location.pathname, user.id, user.is_placeholder_data])

  if (!user.id) {
    return <div/>;
  }

  return children;
}

export default RequireAuth
