import React, {useEffect, useState} from 'react'
import './Organization.css';
import {
  useParams,
  Link, Outlet, useLocation
} from "react-router-dom";
import {classNames, getMembershipTypeName, companySizes} from "../helpers";
import {getAttendees, organizations } from "../data/fakeData";
import { Element as ScrollElement } from 'react-scroll'
import AvatarGroup from "../components/Elements/AvatarGroup";
import balance from 'balance-text';
import EquiptCTA from "../components/EquiptCTA";
import {useGetOrganization} from "../data/client/organization.api";
import {FaArrowUpRightFromSquare, FaLocationDot, FaPencil, FaPeopleGroup} from "react-icons/fa6"
import ManageOrganization from "../components/Organization/ManageOrganization";
import FollowButton from "../components/FollowButton";
import RecentEvents from "../components/RecentEvents";
import {ArrowTopRightOnSquareIcon} from "@heroicons/react/20/solid";

interface Tab {
  name: string, href: string
}

const tabs: Tab[] = [
  { name: 'Events', href: '' },
  {  name: 'Jobs', href: 'jobs' }
  /*{ name: 'Members', href: 'members'},
  { name: 'Instructors', href: '#'},
  { name: 'Values', href: '#' },
  { name: 'Benefits', href: '#' },*/
]

function getCurrentTab(pathname:string, organization_id:string){
  return tabs.find((tab) => `/organization/${organization_id}/${tab.href}` === pathname )?.name || 'Events'
}

export default function Organization({ user }: { user?: User}) {
  const { organization_id } = useParams<{organization_id: string}>();
  const location = useLocation();
  const [openManageOrganization, setOpenManageOrganization] = useState(false)
  const [attendees] = useState(getAttendees(0).map(({ image}) => image))
  const { data: organization, status } = organization_id == 'clearflame' ?
    { data: organizations.find(({slug}) => organization_id === slug), status: "success"}
    : useGetOrganization({ slug: organization_id });

  const isAdmin = !!user?.memberships.find((membership) => ( membership.organization == organization?.id && getMembershipTypeName(membership.membership_type) == "ADMIN"))

  const [currentTab, setCurrentTab] = useState(getCurrentTab(location.pathname, organization_id || ''))

  useEffect(() => {
    if(organization_id) setCurrentTab(getCurrentTab(location.pathname, organization_id));
  }, [location, organization_id])

  if(!organization){
    return <div className="relative bg-gradient-to-r from-blue-500 to-blue-900 overflow-hidden h-screen"/>
  }

  balance('#organization_title')

  return (
    <>
      {user && isAdmin && <ManageOrganization organization={organization} user={user} open={openManageOrganization} setOpen={setOpenManageOrganization} />}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-cover bg-center h-full" style={{backgroundImage: `url(${organization.cover})`}}/>
        <div className="absolute h-full w-full inset-0 flex">
          <div
            className={`h-full w-1/12 overflow-hidden`}
            style={{
              opacity: organization.theme.img_bg_color_opacity,
              backgroundImage: `linear-gradient(to right, ${organization.theme.img_bg_color_from}, ${organization.theme.img_bg_color_from})`
            }}
          />
          <div
            className={`h-full w-5/12 overflow-hidden`}
            style={{
              opacity: organization.theme.img_bg_color_opacity,
              backgroundImage: `linear-gradient(to right, ${organization.theme.img_bg_color_from}, ${organization.theme.img_bg_color_to})`
            }}
          />
          <div
            className={`h-full w-6/12 right-0 overflow-hidden`}
            style={{
              opacity: organization.theme.img_bg_color_opacity,
              backgroundImage: `linear-gradient(to right, ${organization.theme.img_bg_color_to}, ${organization.theme.img_bg_color_to})`
            }}
          />
        </div>
        <div
          className="absolute h-full w-full right-0 overflow-hidden bg-black"
          style={{
            opacity: organization.theme.img_bg_color_darkness
          }}
        />
        <div className="relative pt-16 h-full">
          <main className="mx-auto mt-20 mb-4 max-w-7xl">
            <div className="flex flex-col lg:grid lg:grid-cols-12 lg:gap-x-8">
              <div className="col-span-9">
                <div className="sm:text-center lg:text-left lg:flex lg:items-start justify-start">
                  <div className="shrink-0 rounded-full bg-white inline-block h-32 w-32">
                    <div className="bg-center rounded-full bg-contain bg-no-repeat w-full h-full" style={{
                      backgroundImage: `url(${organization.logo})`
                    }}/>
                  </div>
                    <div className="pl-4 flex flex-col h-32 justify-between py-2">
                      <h1
                        id="organization_title"
                        className="text-5xl tracking-tight font-bold text-white leading-snug">
                        {organization.name}
                      </h1>
                      <h1
                        id="organization_tagline"
                        className="balance-text text-lg tracking-tight text-white  sm:leading-none mb-2">
                        {organization.tagline}
                      </h1>
                      <div className="flex gap-x-8 opacity-70">
                        {organization.location && <div className="flex items-center text-sm text-white">
                          <FaLocationDot className="inline mr-1 h-3 w-3 mb-1"/> {organization.location}
                        </div>}
                        {organization.website && <div className="flex items-center text-sm text-white">
                          <FaArrowUpRightFromSquare className="inline mr-1 mb-1 h-4"/> <a href={`//${organization.website}`} target="_blank">{organization.website}</a>
                        </div>}
                        {organization.size && <div className="flex items-center text-sm text-white">
                          <FaPeopleGroup className="inline mr-1 h-4 w-4 mb-.5"/> {companySizes.find(({ value }) => value === organization.size)?.display}
                        </div>}
                      </div>
                    </div>
                </div>
              </div>
              <div className="col-span-3">
                {user && isAdmin && <div className="ml-auto lg:col-span-3">
                  <button
                    onClick={() => setOpenManageOrganization(true)}
                    className="tile-btn w-64 text-white opacity-20 bg-white/20 border border-white hover:bg-white/40 hover:opacity-100 items-center"
                    style={{
                      transition: "all .15s ease" }}
                  >
                    <FaPencil className="mr-1 h-3" />
                    Edit Organization
                  </button>
                </div>}
              </div>
              <div className="col-span-7 h-48 pt-14 text-white leading-6">
                {organization.description}
              </div>
              <div className="col-span-12 mt-auto w-full flex justify-between py-24 items-center">
                <div className="flex items-center ">
                  <AvatarGroup size="lg" avatars={attendees} total_count={organization.hired > 0 ? organization.hired : 10 } color="#211f1d" />
                  <span
                    style={{color: organization.theme.hired_count_text_color}}
                    className="text-base uppercase tracking-wide font-semibold ml-4">
                          event attendees
                        </span>
                </div>
                <div>

                  <a href={`//${organization.cta_button_link}`} target="_blank" className="w-96 btn btn-color mb-4" style={{
                    backgroundColor: organization.theme.cta_bg_color,
                    color: organization.theme.cta_text_color,
                    borderColor: organization.theme.cta_outline_color || 'transparent',
                    borderWidth: organization.theme.cta_outline_color ? 2 : 0,
                    borderStyle: organization.theme.cta_outline_color ? "solid" : "none"
                  }}>
                    {organization.cta_button}
                    <ArrowTopRightOnSquareIcon className="ml-1 mt-0.5 h-4"/>
                  </a>
                  <FollowButton organization={organization} user={user} />
                </div>

              </div>
            </div>
          </main>
        </div>
      </div>
      {/* NAV */}
      <div>
        <ScrollElement name="organizationBody"/>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full pl-3 pr-10 py-2 text-lg border-gray-300 focus:outline-none focus:ring-grey-500  rounded-md"
            defaultValue={currentTab}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block" style={{backgroundColor: organization.theme.bottom_bg_color}} >
          <div className="border-b-2" style={{
            borderColor: organization.theme.menu_border_color
          }}>
            <nav className="flex space-x-8 w-11/12 mx-auto px-16" aria-label="Tabs">
              {tabs.map((tab) => (
                <Link
                  key={tab.name}
                  to={`/organization/${organization_id}/${tab.href}`}
                  style={{
                    color: organization.theme.menu_text_color,
                    borderColor: tab.name === currentTab ? organization.theme.menu_tracker_color : ''
                  }}
                  className={classNames(
                     'border-transparent hover:border-gray-300',
                    'whitespace-nowrap py-4 px-4 border-b-4 font-semibold text-lg'
                  )}
                  aria-current={tab.name === currentTab ? 'page' : undefined}
                >
                  {tab.name}
                </Link>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className="relative" style={{minHeight: '600px', backgroundColor: organization.theme.bottom_bg_color}}>
        <Outlet />
      </div>
      <RecentEvents />
      <EquiptCTA />
  </>
  )

}
