import {ErrorMessage, FieldAttributes, useField} from "formik";
import React from "react";
import {classNames} from "../../helpers";

interface InputFieldProps extends FieldAttributes<any> {
  id: string
  name: string
  as?: "textarea"
  placeholder?: string
  containerClassName?: string
  labelClassName?: string
  labelText?: JSX.Element | string
  description?: string
  inputClassName?: string
  errorClassName?: string
}

const InputField: React.FC<InputFieldProps> = (
  { name,
    id,
    as,
    placeholder,
    containerClassName = "col-span-12",
    className = "flex mt-1 bg-white block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus-within:outline-none focus-within:ring-blue-300 focus-within:border-blue-300 sm:text-sm",
    labelClassName = "block text-sm font-medium text-gray-800",
    labelText,
    description,
    inputClassName,
    errorClassName = "text-xs h-6 w-full block text-left text-red-500 italic input-error",
    children, ...fieldProps }) => {
  const [field, meta] = useField({ name, id, placeholder, ...fieldProps});
  return(
    <div className={containerClassName}>
      {labelText && <label htmlFor={id} className={labelClassName}>
        {labelText}
        {description && <span className="ml-2 text-sm text-gray-400">{description}</span>}
      </label>}
      <div className={classNames(className, meta.touched && meta.error ? 'border-red' : '')}>
        {children}
        { as === "textarea" ?
          <textarea id={id} placeholder={placeholder} {...field} {...fieldProps}
                    className={classNames(inputClassName || "p-0 text-sm block flex-1 border-0 bg-transparent placeholder:text-gray-400 focus:ring-0 outline-transparent")}/>
          :
          <input id={id} placeholder={placeholder} {...field} {...fieldProps}
                 className={classNames(inputClassName || "block flex-1 border-0 bg-transparent placeholder:text-gray-400 focus:ring-0 outline-transparent")}/>
        }
      </div>
      <span className={errorClassName} >
        <ErrorMessage name={name}/>
      </span>
    </div>
  )
}

export default InputField
