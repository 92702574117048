import {ErrorMessage, FieldAttributes, useField} from "formik";
import React from "react";
import {classNames} from "../../helpers";

interface AuthFieldProps extends FieldAttributes<any> {
  id: string
  name: string
  containerClassName?: string
  children?: JSX.Element
}

const AuthCheckBoxField: React.FC<AuthFieldProps> = ({ name, id, placeholder, containerClassName, className, children, ...fieldProps }) => {
  const [field, meta] = useField({ name, id, placeholder, ...fieldProps});
  return(
    <div className={classNames(
      containerClassName ? containerClassName : "col-span-12"
    )}>
      <div className="flex">
        <input id={id} {...field} {...fieldProps}
               type="checkbox"
               className={classNames(
                 "h-3.5 w-3.5 text-blue-500 rounded",
                 className, meta.touched && meta.error ? 'input-has-error focus:ring-red-300 border-red-300' : 'focus:ring-blue-300 border-gray-300')}/>
        <label htmlFor={id} className={classNames(
          'flex-shrink inline-flex',
            meta.touched && meta.error ? 'text-red-500 ' : ' text-gray-400 '
          )}>
          {children}
        </label>
      </div>
      <span className="text-sm h-6 w-full block flex justify-center items-center text-red-500 italic input-error" >
        <ErrorMessage name={name}/>
      </span>
    </div>
  )
}

export default AuthCheckBoxField
