/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid'
import {classNames} from "../../helpers";

export interface Option {
  name: string
  value: any
}

interface Props {
  id?: string
  options: Option[]
  labelText?: JSX.Element | string
  wrapperClassName?: string
  className?: string
  labelClassName?: string
  labelColor?: string
  description?: string
  borderColor?: string
  backgroundColor?: string
  selected: Option
  setSelected: React.Dispatch<React.SetStateAction<any>>
}

export const Dropdown: React.FC<Props> = ({id, className, wrapperClassName, selected, setSelected, options, labelText, description, labelClassName, backgroundColor, borderColor, labelColor}) => {
  const emptyOption: Option = { value: undefined, name: "Select One" }
  const renderedOptions: Option[] = [emptyOption].concat(options);

  return (
    <div className={wrapperClassName}>
      <Listbox value={selected} onChange={setSelected}>
        {({open}) => (
          <>
          {labelText && <Listbox.Label htmlFor={id} style={{color: labelColor || '#000000'}}
                           className={classNames(
                             labelClassName ? labelClassName : "block uppercase font-semibold tracking-widest"
                           )}>
              {labelText}
              <span className="ml-2 text-sm text-gray-400">{description}</span>
            </Listbox.Label>}
            <div className="mt-1 relative">
              <Listbox.Button
                style={{backgroundColor: backgroundColor || '#FFFFFF', borderColor: borderColor || '#D7D3D1'}}
                className={classNames(
                  borderColor ? 'border-2' : 'border',
                  "relative cursor-default ",
                  className ? className : "w-full rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none focus:ring-1 focus:ring-1 focus:ring-blue-300 sm:text-sm"
                )}>
                <span className="block truncate h-5 flex items-center">{selected.name}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
              </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black/5 overflow-auto focus:outline-none sm:text-sm">
                  {renderedOptions.map((option) => (
                    <Listbox.Option
                      key={option.name}
                      className={({active}) =>
                        classNames(
                          active ? 'text-white bg-gray-400' : 'text-gray-900',
                          'cursor-default select-none relative py-2 pl-3 pr-9'
                        )
                      }
                      value={option}
                    >
                      {({selected, active}) => (
                        <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {option.name}
                        </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                          </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  )
}