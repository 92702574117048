import React, {useCallback, useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";
import {getMembershipType, delay, classNames} from "../helpers";
import { useFetchMemberships, useManageFollower} from "../data/client/membership.api";

function isFollowing(organization: Organization, memberships: Membership[], user?: User){
  return memberships.some((membership) => {
    return membership.user === user?.id
      && membership.organization.id === organization?.id
      && membership.membership_type === getMembershipType("FOLLOWER")
  })
}

export default function FollowButton({ user, organization }: { user?: User, organization: Organization}) {
  const navigate = useNavigate();
  const [ followingStatus, setFollowingStatus] = useState("");
  const { mutateAsync: manageFollower } = useManageFollower();
  const { data: userMemberships = [] } = useFetchMemberships({ user_id: user?.id || ''});
  useEffect(() => {
    if(followingStatus !== "to_following" && followingStatus !== "to_not_following"){
      setFollowingStatus(isFollowing(organization, userMemberships, user) ? "following" : "not_following" )
    }
  }, [user, organization, userMemberships]);

  const onToggleFollowing = useCallback(async () => {
    if(followingStatus !== "to_following" && followingStatus !== "to_not_following"){
      const follow = !(followingStatus === "following")
      if(user && user.id !== ""){
        setFollowingStatus(follow ? "to_following" : "to_not_following")
        await manageFollower({ membership: { organization, user }, follow })
        await delay(3000)
        setFollowingStatus(follow ? "following" : "not_following");
      } else {
        navigate(`/register`);
      }
    }
  }, [user, organization, userMemberships, followingStatus])

  if(!organization){
    return (<></>)
  }

  return (
    <button onClick={onToggleFollowing}
            className={classNames(
              "w-96 bg-transparent",
              followingStatus === "following" ? "group" : "",
              followingStatus === "not_following" ? "group" : "",
              followingStatus === "to_following" ? "pointer-events-none": "",
              followingStatus === "to_not_following" ? "pointer-events-none" : ""
            )} /*style={{
      backgroundColor: organization.theme.cta_bg_color,
      borderColor: organization.theme.cta_outline_color || 'transparent',
      borderWidth: organization.theme.cta_outline_color ? 2 : 0,
      borderStyle: organization.theme.cta_outline_color ? "solid" : "none"
    }}*/>
      <div className={classNames(
        "text-center font-bold font-button text-sm uppercase px-6 py-3 flex justify-center border-2 rounded-full shadow-sm",
        "outline-none focus:outline-none focus:ring-2 focus:ring-offset-2 transition transition-transform transform group-hover:scale-95",
        followingStatus === "following" ? "bg-white border-transparent text-black group-hover:bg-white/10 group-hover:border-white group-hover:text-white" : "",
        followingStatus === "not_following" ? "bg-transparent border-white text-white" : "",
        followingStatus === "to_following" ? "bg-white border-transparent text-black": "",
        followingStatus === "to_not_following" ? "hover:bg-white/10 border-white text-white" : ""
      )}>
        <span className="group-hover:hidden">
        {followingStatus === "following" ? "Following" : "Follow"}
      </span>
        <span className="group-hover:inline hidden">Unfollow</span>
      </div>
    </button>
  )

}
