import React, {useCallback, useEffect, useState} from 'react'
import { RadioGroup } from '@headlessui/react'
import { romanize } from "../../helpers";
import { Actions } from "../../hooks/useMap";
import { LockClosedIcon } from '@heroicons/react/24/solid'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

interface Props {
  sessions: Session[]
  courseModule: CourseModule
  moduleIndex: number
  theme: Theme
  isAdmin: boolean
  registrationForm: UseMapState<string, CourseRegistrationForm>
  setRegistrationForm: Actions<string,CourseRegistrationForm>
  defaultRegistrationForm: UseMapState<string, CourseRegistrationForm>
}

const SessionSelector: React.FC<Props> = ({ moduleIndex,courseModule, isAdmin, sessions, registrationForm,setRegistrationForm, defaultRegistrationForm, theme}) => {
  const [selectedSession, setSelectedSession] = useState<Session | null>(null)

  const onSelectSession = useCallback((newSelectedSession: Session) => {
    let exists = registrationForm.get(courseModule.id)
    let { registration_id } = defaultRegistrationForm.get(courseModule.id) || {}
    if(exists){
      //replace a session that exists
      setRegistrationForm.set(courseModule.id, { ...exists, session_id: newSelectedSession.id, registration_id })
    } else {
      setRegistrationForm.set(courseModule.id, { session_id: newSelectedSession.id, course_module_id: courseModule.id, registration_id})
    }
  }, [setRegistrationForm, registrationForm, courseModule, defaultRegistrationForm])

  useEffect(() => {
    const exists = registrationForm.get(courseModule.id)
    if(exists) {
      const session = sessions.find(({ id }) => id === exists.session_id );
      console.log("SELECTED", session)
      setSelectedSession(session || null)
    } else {
      console.log("SELECTED NONE")
      setSelectedSession(null)
    }
    console.log("RESETR?")
  }, [registrationForm, setSelectedSession, courseModule, sessions])
  return (
    <>
      <RadioGroup value={selectedSession} onChange={onSelectSession}>
        <RadioGroup.Label className="sr-only">Sessions</RadioGroup.Label>
        <div className="relative bg-white rounded-lg shadow-lg h-full flex flex-col">
          <h2 className="px-5 pt-5 pb-1 uppercase font-button text-xs font-bold" style={{color: theme.course_card_label_color}}>
            <span>{courseModule.subscription_level > 0 ? <LockClosedIcon className="h-4 mb-1 mx-0 inline"/> : ""}</span>
            {`Module ${romanize(moduleIndex+1)}`}
          </h2>
          <div className="px-5 text-base font-bold text-gray-600">{courseModule.module.title}</div>
          <div className="px-5 py-2 text-gray-600 mb-auto">{courseModule.module.description}</div>
          {sessions.map((session, index) => (
              <RadioGroup.Option
                key={session.id}
                value={session}
                disabled={session.status === 'closed'}
              >
                {({ active, checked }) => (
                  <div
                    style={( checked ? {
                      backgroundColor: theme.course_card_button_bg_color+"20",
                      borderColor: theme.course_card_button_bg_color+"80",
                    } : {})}
                    className={
                    classNames(
                      index === 0 ? 'mt-4' : 'mt-0',
                      checked ? 'z-10 border-l border-r' : 'border-gray-300',
                      'border-b',
                      'relative border-t p-4 flex flex-row justify-between cursor-pointer md:pl-4 md:pr-6 focus:outline-none'
                    )
                  }>
                    <div className="flex items-center text-sm">
                      <span
                        style={({
                          backgroundColor: checked ? theme.course_card_button_bg_color : "white",
                          boxShadow: active ? `0px 0px 0px 2px white, 0px 0px 0px 4px ${theme.course_card_button_bg_color}`: "none"

                        })}
                        className={classNames(
                          checked ? 'border-transparent' : 'border-gray-400',
                          session.status === 'closed' ? 'border-gray-300' : '',
                          'h-4 w-4 rounded-full border flex items-center justify-center'
                        )}
                        aria-hidden="true"
                      >
                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                      </span>
                      <RadioGroup.Label
                        as="span"
                        style={{ color: checked ? theme.course_card_button_bg_color : undefined }}
                        className={classNames(
                          checked ? '' : (session.status === 'closed' ? 'text-gray-300' : 'text-gray-900') ,
                          'ml-3 font-medium text-xs capitalize',
                        )}
                      >
                        {session.featured_instructor.user?.profile?.first_name+' '+session.featured_instructor.user?.profile?.last_name}
                      </RadioGroup.Label>
                    </div>
                    {/*<RadioGroup.Description className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                  <span
                    style={{ color: checked ? theme.course_card_button_bg_color : undefined }}
                    className={classNames(
                      checked ? '' : (session.status === 'closed' ? 'text-gray-300' : 'text-gray-900'),
                      'font-medium text-xs'
                    )}
                  >
                    {session.seats}
                  </span>{' '}
                      <span
                        style={{ color: checked ? theme.course_card_button_bg_color : undefined }}
                        className={checked ? '' : (session.status === 'closed' ? 'text-gray-300' : 'text-gray-900')}>seats left</span>
                    </RadioGroup.Description>*/}
                    <RadioGroup.Description
                      style={{ color: checked ? theme.course_card_button_bg_color : undefined }}
                      className={classNames(
                        checked ? '' : (session.status === 'closed' ? 'text-gray-300' : 'text-gray-900'),
                        'ml-6 pl-1 text-xs md:ml-0 md:pl-0 md:text-right font-bold'
                      )}
                    >
                      {new Date(session.starts_at).toLocaleString('en-US', {year: "2-digit", month: 'numeric', day: 'numeric' , hour: "numeric", timeZoneName: "short"})}
                    </RadioGroup.Description>
                  </div>
                )}
              </RadioGroup.Option>
            ))
          }
          <div className="px-4 h-16 flex items-center justify-center rounded-b-lg">
            {sessions.length === 0 || (!courseModule.available && !isAdmin) ?
              <div className="uppercase h-12 text-center mt-auto font-semibold" style={{color: theme.course_card_label_color}}>No sessions available</div> :
              <button type="button"
                      className={classNames(
                        "w-full btn btn-sm btn-gray-white-border",
                        !selectedSession ? 'pointer-events-none opacity-50' : ''
                      )}
                      onClick={() => {
                        setRegistrationForm.remove(courseModule.id)
                        //setRegistrationForm((prevRegistrationForm) => prevRegistrationForm.filter(({ session_id}) => session_id !== selectedSession?.id))
                      }}
                      disabled={!selectedSession}>
                Clear Selection
              </button>
            }
          </div>
        </div>
      </RadioGroup>
    </>

  )
}

export default SessionSelector


