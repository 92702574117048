/* This example requires Tailwind CSS v2.0+ */
import React, {Fragment, useState, useRef, useMemo, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { CalendarIcon, ClockIcon } from '@heroicons/react/24/solid'
import { IoHourglass } from 'react-icons/io5'
import { GiCheckMark } from 'react-icons/gi';
import Register from "../Authentication/Register";
import Login from "../Authentication/Login";
import {parseSession} from "../../data/client/factories";

interface Props {
  course: Course
  sessions: Session[]
  alert: AlertDialog
  showModal: boolean
  setShowModal: ( showModal: boolean ) => any
  registrationForm: UseMapState<string, CourseRegistrationForm>
  refetchRegistration: () => Promise<any>
  onRegister: () => any
  user: User
  setUser: React.Dispatch<React.SetStateAction<User>>
  refreshUser: (args?: any) => Promise<any>
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const NewUserCourseRegistration: React.FC<Props> = ({ user, refreshUser, course, sessions, registrationForm, onRegister, refetchRegistration, showModal, setShowModal, alert }) => {
  const [authPage, setAuthPage] = useState<'login' | 'register' | 'confirm' | 'new_account'>('register');
  const onRegisterRef = useRef(onRegister);

  useEffect(() => {
    onRegisterRef.current = onRegister;
  }, [onRegister]);

  const onClose = () => {
    setShowModal(false)
  };

  const selectedSessions: { session: Session, title: string }[] = useMemo(() => {
    return Array.from(registrationForm.entries()).map(([, { course_module_id, session_id }]) => {
      let session = sessions.find(({id}) => id === session_id);
      let courseModule = course.course_modules.find(({ id }) => id === course_module_id );
      return { session: parseSession(session || {}), title: courseModule?.module.title || "" }
    })
  }, [course, registrationForm, sessions])

  const onLogin = async () => {
    await refetchRegistration()
    setShowModal(false);
    setTimeout(() => {
      onRegisterRef.current()
    }, 1000)
  };

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
        <div className="flex min-h-screen text-center md:block md:px-2 lg:px-4" style={{ fontSize: 0 }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="hidden fixed inset-0 bg-gray-500/75 transition-opacity md:block" />
          </Transition.Child>

          <span className="hidden md:inline-block md:align-middle md:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enterTo="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 md:scale-100"
            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-3xl md:px-4 md:my-8 md:align-middle lg:max-w-5xl">
              <div className="w-full relative flex items-center bg-white md:rounded-xl overflow-hidden shadow-2xl">
                <button
                  type="button"
                  className="absolute top-4 right-4 text-white lg:text-gray-400 hover:text-gray-300 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8 z-100"
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                <h2 id="contact-heading" className="sr-only">
                  Contact us
                </h2>

                <div className="grid grid-cols-12 w-full h-full">
                  <div className="relative overflow-hidden bg-cover col-span-12 lg:col-span-5" style={{backgroundImage: `url(${course.cover})`}} >
                    <div className="absolute inset-0 bg-gradient-to-l from-gray-600 to-gray-900 opacity-95" />
                    <div className="relative py-10 px-3 sm:px-6 xl:px-8 xl:py-12 h-full">
                      <h6 className="text-gray-300 text-sm font-bold uppercase">You've Selected</h6>
                      <h2 className="text-3xl tracking-tight font-extrabold text-gray-100">
                        {course.title}
                      </h2>
                      <span className="text-white text-sm leading-3">{course.tagline}</span>
                      <div className="text-sm font-bold uppercase text-gray-300 mt-4">
                        {`Including the following modules:`}
                      </div>
                      {selectedSessions.map(({ session, title }) => {
                        return (
                          <div className="mt-1 mb-4" key={`course-register-${session.id}`}>
                            <div className="text-sm text-white font-semibold flex items-center">
                              <GiCheckMark className="flex-shrink-0 w-3 h-3 text-gray-200 mr-1 hidden sm:flex" aria-hidden="true" />
                              {title}
                            </div>
                            <div className="flex text-xs text-gray-50 sm:ml-4 mt-1 w-full flex-wrap">
                              <div className="flex mr-3" style={{flexBasis: '100%'}}>
                                <CalendarIcon className="flex-shrink-0 w-3 h-3 text-gray-200" aria-hidden="true" />
                                <span className="ml-1 font-semibold hidden sm:flex">{(new Date(parseInt(session.starts_at)).toLocaleDateString(
                                  'en-US',
                                  { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
                                ))}</span>

                                <span className="ml-1 font-semibold sm:hidden">{(new Date(parseInt(session.starts_at)).toLocaleDateString(
                                  'en-US',
                                  { year: 'numeric', month: 'numeric', day: 'numeric' }
                                ))}</span>
                              </div>
                              <div className="flex mr-3">
                                <ClockIcon className="flex-shrink-0 w-3 h-3 text-gray-200" aria-hidden="true" />
                                <span className="ml-1 font-semibold">{(new Date(parseInt(session.starts_at)).toLocaleTimeString(
                                  'en-US',
                                  { hour: 'numeric', minute: 'numeric', timeZoneName: "short" }
                                ))}</span>
                              </div>
                              <div className="flex">
                                <IoHourglass className="flex-shrink-0 w-3 h-3 text-gray-200" aria-hidden="true" />
                                <span className="ml-1 font-semibold">60 minutes</span>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-7 flex flex-col inline-auth">
                    <div className="pt-10 px-6 sm:px-10 xl:px-12 pb-0">
                      <h6 className={classNames("text-sm font-bold uppercase", authPage === 'login' || authPage === 'new_account' ? "text-orange-300": "text-blue-300")}>Save your Seat</h6>
                      <h2 className={classNames("text-3xl tracking-tight font-extrabold mb-4", authPage === 'login' || authPage === 'new_account' ? "text-orange": "text-blue")}>
                        {authPage === 'login' && "Log in to Register"}
                        {(authPage !== 'login') && "Contact Information"}
                      </h2>
                    </div>
                    <div className="px-6 sm:px-10 xl:px-12 flex items-center">
                      <div className="w-full">
                        { (authPage === 'login' || authPage === 'new_account') &&
                          <>
                            { authPage === 'new_account' && <p className="text-center text-gray-600 mb-8 w-full max-w-xs mx-auto">You're account has been created. Log in below register for this course.</p>}
                            <Login user={user} refreshUser={refreshUser} buttonText="Login & Register" onLogin={onLogin} />
                          </>
                        }
                        {authPage === 'register' &&
                          <>
                            <h6 className="text-center text-sm uppercase my-2 py-2 font-semibold text-gray-500">Add your contact information to register</h6>
                            <Register user={user} refreshUser={refreshUser} simpleRegistration={true} buttonText="Register" onAfterRegister={onLogin} alert={alert} />
                          </>
                        }
                      </div>
                    </div>
                    {authPage === 'login' || authPage === 'new_account' ?
                      <p className="mt-2 text-center text-blue-400 py-6 border-t border-gray-200">
                        Don’t have an account?
                        <button onClick={() => setAuthPage('register')} className="block mx-auto text-lg font-bold text-blue-500 hover:underline">
                          REGISTER
                        </button>
                      </p>:
                      <p className="mt-2 text-center text-orange-400 py-6 border-t border-gray-200">
                        Already have an account?
                        <button onClick={() => setAuthPage('login')}  className="block mx-auto text-center text-lg font-bold text-orange-500 hover:underline">
                          LOG IN
                        </button>
                      </p>
                    }
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default NewUserCourseRegistration;
