/* This example requires Tailwind CSS v2.0+ */
import React, {Fragment, useState, useRef, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import Register from "./Authentication/Register";

interface Props {
  alert: AlertDialog
  showModal: boolean
  setShowModal: ( showModal: boolean ) => any
  onRegister: () => any
  user: User
  refreshUser: (args?: any) => Promise<any>
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const NewUserLaunchRegistration: React.FC<Props> = ({ user, refreshUser, onRegister, showModal, setShowModal, alert }) => {
  const [authPage,] = useState<'login' | 'register' | 'confirm' | 'new_account'>('register');
  const onRegisterRef = useRef(onRegister);

  useEffect(() => {
    onRegisterRef.current = onRegister;
  }, [onRegister]);

  const onClose = () => {
    setShowModal(false)
  };


  const onCompleteSignUp = async () => {

  };

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
        <div className="flex min-h-screen text-center md:block md:px-2 lg:px-4" style={{ fontSize: 0 }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="hidden fixed inset-0 bg-gray-500/75 transition-opacity md:block" />
          </Transition.Child>

          <span className="hidden md:inline-block md:align-middle md:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enterTo="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 md:scale-100"
            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-3xl md:px-4 md:my-8 md:align-middle lg:max-w-5xl">
              <div className="w-full relative flex items-center bg-white md:rounded-xl overflow-hidden shadow-2xl">
                <button
                  type="button"
                  className="absolute top-4 right-4 text-white lg:text-gray-400 hover:text-gray-300 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8 z-100"
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                <h2 id="contact-heading" className="sr-only">
                  Contact us
                </h2>

                <div className="grid grid-cols-1 w-full h-full">
                  <div className="col-span-12 lg:col-span-1 flex flex-col inline-auth">
                    <div className="pt-10 px-6 sm:px-10 xl:px-12 pb-0">
                      <h6 className={classNames("text-sm font-bold uppercase", "text-blue-300")}>Launching Soon!</h6>
                      <h2 className={classNames("text-3xl tracking-tight font-extrabold mb-4", authPage === 'login' || authPage === 'new_account' ? "text-orange": "text-blue")}>
                        Join the Waitlist
                      </h2>
                    </div>
                    <div className="px-6 sm:px-10 xl:px-12 flex items-center">
                      <div className="w-full">
                        <>
                          <Register user={user} refreshUser={refreshUser} simpleRegistration={true} buttonText="Register" onAfterRegister={onCompleteSignUp} alert={alert} />
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default NewUserLaunchRegistration;
