import { classNames } from "../../helpers";
import React from "react";

interface Props {
  filter: "upcoming" | "past"
  setFilter: React.Dispatch<React.SetStateAction<"upcoming" | "past">>
}

const FeedFilter: React.FC<Props> = ({ filter, setFilter}) => {

  const tabs:{ name: string, value: "upcoming" | "past" }[] = [
    { name: 'Upcoming Events', value: "upcoming" },
    { name: 'Past Events', value: "past" },
  ]

  return (
    <div className="px-4 sm:px-0 mb-4">
      <div>
        <div className="block">
          <div className="flex items-center border-b border-gray-200">
            <nav className="flex-1 -mb-px flex space-x-6 xl:space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <button
                  key={tab.name}
                  onClick={() => setFilter(tab.value)}
                  aria-current={"page"}
                  className={classNames(
                    filter === tab.value
                      ? 'border-orange-500 text-orange-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap py-2 px-1 border-b-2 font-semibold text-sm uppercase tracking-wide'
                  )}
                >
                  {tab.name}
                </button>
              ))}
            </nav>
            {/*<div className="hidden ml-6 bg-gray-100 p-0.5 rounded-lg items-center sm:flex">
              <div className="flex items-center">
                <Menu as="div" className="relative">
                  <Menu.Button className="w-full my-2 inline-flex justify-center text-sm font-medium text-gray-400 hover:text-gray-700 focus:outline-none rounded-sm focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                    <SortAscendingIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                  <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black/5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            Name
                          </div>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            Next session
                          </div>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            Date created
                          </div>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Menu>
              </div>
            </div>*/}

          </div>
        </div>

      </div>
    </div>
  )
}

export default FeedFilter
