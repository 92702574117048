import React, {Fragment, useCallback, useRef, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {XMarkIcon} from '@heroicons/react/24/outline'
import * as Yup from "yup";
import {Form, Formik, FormikHelpers} from "formik";
import {useCreateOrganization, useUpdateOrganization} from "../../data/client/organization.api";
import ImageUploaderInput, {ImageUploaderHelpers} from "../Elements/ImageUploaderInput";
import InputField from "../Elements/InputField";
import RadioField from "../Elements/RadioField";
import SelectField from "../Elements/SelectField";
import {companySizes, classNames, getParseImageFromServer} from "../../helpers";

interface Props {
  user: User,
  open: boolean,
  organization?: Organization
  setOpen:  React.Dispatch<React.SetStateAction<boolean>>
}

const ManageOrganizationSchema = Yup.object().shape({
  //approval_process: Yup.string().nullable().oneOf(["DOMAIN", "INVITE", "MANUAL"]),
  //benefit: Yup.string().min(2, 'Too Short!').max(400, 'Too Long!'),
  //bio: Yup.string().min(2, 'Too Short!').max(400, 'Too Long!'),
  cover: Yup.mixed().nullable(),
  cta_button: Yup.string(),
  cta_button_link: Yup.string(),
  description: Yup.string()
    .min(2, 'Too Short!')
    .max(800, 'Too Long!')
    .required('Required'),
  //hired: Yup.number().required("Required"),
  logo: Yup.mixed().nullable(),
  logo_alt: Yup.mixed().nullable(),
  location_city: Yup.string().required('Required'),
  location_state: Yup.string().required('Required'),
  location_country: Yup.string().required('Required'),
  size: Yup.number().required("Required"),
  name: Yup.string().max(150, 'Too Long!').required('Required'),
  slug: Yup.string().required('Required').required('Required'),
  social_linkedin: Yup.string(),
  tagline: Yup.string().max(150, 'Too Long!'),
  type: Yup.mixed<OrganizationType>().oneOf(["COMPANY", "UNIVERSITY", "BOOTCAMP", "GROUP", "INDIVIDUAL"]).required('Required'),
  //values: Yup.string().min(2, 'Too Short!').max(400, 'Too Long!'),
  website: Yup.string().max(150, 'Too Long!')
});

interface Values {
  //approval_process: null | "DOMAIN" | "INVITE" | "MANUAL"
  //benefits: string,
  //bio: string,
  cover: string,
  cta_button: string
  cta_button_link: string
  description: string,
  //hired: number
  logo: string
  logo_alt: string
  location_city: string
  location_state: string
  location_country: string
  size?: number
  name: string
  slug: string
  social_linkedin: string,
  tagline: string,
  type?: OrganizationType,
  //values: string,
  website: string,
}

const parseInitialValues = (organization: Partial<Organization> = {}): Values => {
  const { /*approval_process, benefits, bio,*/ cover, cta_button, cta_button_link, description, /*hired, id,*/ logo, logo_alt, location, size, name, slug, social_linkedin, tagline, type, /*values,*/ website } = organization
  const [city, state, country] = (location || '').split(",");
  return {
    //approval_process: approval_process || null,
    //benefits: benefits || "",
    //bio: bio || "",
    cover: getParseImageFromServer('course_covers',cover || ""),
    cta_button: cta_button || "",
    cta_button_link: cta_button_link || "",
    description: description || "",
    //hired: hired || 0,
    logo: getParseImageFromServer('course_covers',logo || ""),
    logo_alt: getParseImageFromServer('course_covers',logo_alt || ""),
    location_city: (city || "").trim(),
    location_state: (state || "").trim(),
    location_country: (country || "").trim(),
    size: size || undefined,
    name: name || "",
    slug: slug || "",
    social_linkedin: social_linkedin || "",
    tagline: tagline || "",
    type: type || undefined,
    //values: values || "",
    website: website || ""
  }
}

const ManageOrganization: React.FC<Props> = ({ user, open, setOpen, organization}) => {
  const [, setProgress] = useState(0);
  const { mutateAsync: createOrganization } = useCreateOrganization();
  const { mutateAsync: updateOrganization } = useUpdateOrganization()

  //const { mu} = useCreateOr
  const handleSubmit = useCallback(async (values: Values, { setStatus, setFieldError } : FormikHelpers<Values>) => {
    setStatus({value: ''})
    let { location_state, location_city, location_country, type, ...filteredValues } = values

    if(location_country || location_city || location_state){
      // @ts-ignore
      filteredValues.location = `${location_city}, ${location_state}, ${location_country}`;
    }

    try{
      setProgress(5);
      if(organization?.id){
        await updateOrganization({ organization: { id: organization.id, ...filteredValues }})
      } else {
        //TODO: Fix this
        if(type !== undefined){
          await createOrganization( { organization: { ...filteredValues, admin: user, type} })
        } else {
          setFieldError("type", "Please select an organization type");
        }
      }

      setProgress(100);
      setOpen(false);
    } catch(e: any){
      setStatus({value: 'submit_error',  message: e.message })
    }

  }, [user, setProgress, organization])

  const coverUploaderRef = useRef<ImageUploaderHelpers>();
  const logoUploaderRef = useRef<ImageUploaderHelpers>();
  const logoAltUploaderRef = useRef<ImageUploaderHelpers>();

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => {}} appear>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all z-50">
              <div className="p-6 text-3xl text-blue font-bold ">{organization && organization?.id ? `Edit Organization` : 'Create an Organization' }</div>
              <button
                type="button"
                className="absolute top-4 right-4 text-white lg:text-gray-400 hover:text-gray-300 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8 z-100"
                onClick={() => setOpen(false)}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              <Formik
                initialValues={parseInitialValues(organization || {})}
                onSubmit={handleSubmit}
                onReset={() => {
                  coverUploaderRef.current?.reset()
                  logoUploaderRef.current?.reset()
                  logoAltUploaderRef.current?.reset()
                }}
                validationSchema={ManageOrganizationSchema}
              >
                {({ status, isValid, isSubmitting }) => (
                  <>
                    <Form className="bg-gradient-to-r from-gray-50 to-white" action="#" method="POST">
                      <div className="px-6 py-2">
                        <div className="grid grid-cols-12 gap-x-4 gap-y-1">
                          <div className="col-span-6">
                            <InputField id="name" name="name" labelText="Name" containerClassName="col-span-6" />
                            <InputField id="slug" name="slug" labelText="Handle" description="What is your organization handle" />
                            <InputField id="website" name="website" labelText="Website" description="What is your website url?" containerClassName="col-span-6"  >
                              <span className="flex select-none items-center pr-1 text-gray-400 sm:text-sm">https://</span>
                            </InputField>
                          </div>
                          <div className="col-span-6">
                            <div className="mt-6 flex-grow lg:mt-0 lg:ml-6 lg:flex-grow-0 lg:flex-shrink-0">
                              <p className="text-sm font-medium text-gray-700 text-center" aria-hidden="true">
                                Cover
                                <span className="ml-2 text-sm text-gray-400">
                                 A cover image for your organization page.
                              </span>
                              </p>
                              <div className="create-event_cover relative overflow-hidden mx-auto w-full h-52 bg-gray-200 rounded-lg">
                                <ImageUploaderInput imagePreviewHeight={208} imageCropAspectRatio={"16:10"}
                                                    layout="integrated" ref={coverUploaderRef} initialValue={getParseImageFromServer("images", organization?.cover || "" )} bucket="images" id="cover" name="cover" />
                              </div>
                            </div>
                          </div>
                          <InputField id="tagline" name="tagline" labelText="Tagline" />
                          <InputField id="description" as="textarea" rows={3} name="description" labelText="Description" description="A description for your Event." />
                          <div className="col-span-6 pt-5 pb-10">
                            <div className="flex-grow lg:flex-grow-0 lg:flex-shrink-0">
                              <p className="text-sm font-medium text-gray-700 text-center" aria-hidden="true">
                                Logo
                                <span className="ml-2 text-sm text-gray-400">
                                Upload a logo for your organization.
                              </span>
                              </p>
                              <div className="bg-gray-100 p-3 rounded-lg">
                                <div className="create-organization_logo relative overflow-hidden mx-auto w-40 h-40 bg-white rounded-full">
                                  <ImageUploaderInput imagePreviewHeight={160} imageCropAspectRatio={"1:1"}
                                                      layout="integrated" ref={logoUploaderRef} initialValue={getParseImageFromServer("images", organization?.logo || "" )} bucket="images" id="logo" name="logo" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-6 pt-5 pb-10">
                            <div className="flex-grow lg:flex-grow-0 lg:flex-shrink-0">
                              <p className="text-sm font-medium text-gray-700 text-center" aria-hidden="true">
                                Alternative Logo
                                <span className="ml-2 text-sm text-gray-400">
                                Upload a logo for dark background.
                              </span>
                              </p>
                              <div className="bg-gray-700 p-3 rounded-lg">
                                <div className="create-organization_logo relative overflow-hidden mx-auto w-40 h-40 bg-gray-800 rounded-full text-white">
                                  <ImageUploaderInput imagePreviewHeight={160} imageCropAspectRatio={"1:1"}
                                                      layout="integrated" ref={logoAltUploaderRef} initialValue={getParseImageFromServer("images", organization?.logo_alt || "" )} bucket="images" id="logo_alt" name="logo_alt" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-12">
                            <RadioField containerClassName="w-full" options={["COMPANY", "UNIVERSITY", "BOOTCAMP", "GROUP", "INDIVIDUAL"]}
                                        id="type" name="type" labelText="Organization Type" description="What kind of organization is this?" />
                          </div>
                          <InputField id="location_city" name="location_city" labelText="Location" description="City" containerClassName="col-span-4" />
                          <InputField id="location_state" name="location_state" labelText="Location" description="State" containerClassName="col-span-4" />
                          <InputField id="location_country" name="location_country" labelText="Location" description="Country" containerClassName="col-span-4" />
                          <InputField id="social_linkedin" name="social_linkedin" labelText="Linkedin" description="What is your LinkedIn url?" containerClassName="col-span-6" >
                            <span className="flex select-none items-center pr-1 text-gray-400 sm:text-sm">https://</span>
                          </InputField>
                          <SelectField id="size" name="size" containerClassName="col-span-6" labelClassName="block text-sm font-medium text-gray-800" options={companySizes} labelText="Company size" description="how large is your company?" />
                          <InputField id="cta_button" name="cta_button" labelText="Call to Action Button" description="What action do you want users to take? ie: 'Book a coaching session'" containerClassName="col-span-6" />
                          <InputField id="cta_button_link" name="cta_button_link" labelText="Call to Action Link" description="To take the action where do you want to redirect users to?" containerClassName="col-span-6"  >
                            <span className="flex select-none items-center pr-1 text-gray-400 sm:text-sm">https://</span>
                          </InputField>
                        </div>
                      </div>

                      <span className="text-sm w-full block flex justify-center items-center text-red-500 italic input-error" >
                        {status?.value === 'submit_error' && status?.message ? status.message : ''}
                      </span>
                      <div className="mt-4 py-4 px-4 flex justify-end sm:px-6 border-t border-gray-200">
                        <button
                          type="button"
                          onClick={() => setOpen(false)}
                          className="btn btn-primary-white-border"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className={classNames("animate__animated animate__fast",
                            isSubmitting ? "pointer-events-none opacity-50" :"",
                            !isValid ? 'animate__shakeX': '',
                            "ml-5 btn btn-primary")}
                        >
                          Save
                        </button>
                      </div>
                    </Form>
                  </>
                )}
              </Formik>

            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ManageOrganization