 /* Created  on 2/22/2022. */
import gql from "graphql-tag";
import {UserFragment} from "./user.api";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {supabase, supahelpers} from "../../api_supabase";
import {parseProfile, parseUser} from "./factories";

export const GET_AUTHENTICATED_USER = gql`
  {
    authenticatedUser {
      ${UserFragment}
      confirmed
      phone
      has_password
      subscription_level
    }
  }
`;

export const GET_USER_CONFIRMATIONS = gql`
  query Confirmations($user: String!){
    confirmations(confirmation:{ user: $user }) {
      token
      user
      confirmed
      expiry
      usage
      created
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation Login($credentials: LoginInput) {
    login(credentials: $credentials) {
      ${UserFragment}
      confirmed
      phone
      has_password
      subscription_level
    }
  }
`;

export const LOGIN_WITH_TOKEN_MUTATION = gql`
  mutation Login($credentials: LoginWithTokenInput) {
    loginWithToken(credentials: $credentials) {
      ${UserFragment}
      confirmed
      phone
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout {
      id
    }
  }
`;

export const CONFIRM_ACCOUNT_MUTATION = gql`
  mutation UserConfirm($token: String!) {
    userConfirm(token: $token){
      status
    }
  }
`;

export const REQUEST_PASSWORD_RESET_MUTATION = gql`
  mutation RequestPasswordReset($email: String!) {
    requestPasswordReset(email: $email){
      status
    }
  }
`;

export const REQUEST_USER_CONFIRM_MUTATION = gql`
  mutation RequestUserConfirm($email: String!) {
    requestUserConfirm(email: $email){
      status
    }
  }
`;

export const REQUEST_DATA_VERIFY_MUTATION = gql`
  mutation RequestDataVerify($confirmation_usage: String!) {
    requestDataVerify(confirmation_usage: $confirmation_usage){
      status
    }
  }
`;

export const VERIFY_DATA_MUTATION = gql`
  mutation UserVerifyData($token: String!, $usage: String!) {
    userVerifyData(token: $token, usage: $usage){
      status
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation UserResetPassword($token: String!, $password: String!) {
    userResetPassword(token: $token, password: $password){
      status
    }
  }
`;



export function useLogin(){

}

export function useLogout(){

}

 export function useFetchAppUser(): UseQueryResult<User> & { refresh: () => any} {
    const { data: sessionUser, refetch, status: sessionStatus } = useQuery(["appSession"], async function(){
      const { data: { session } } = await supabase.auth.getSession();
      const { data: { user: sessionUser}} = await supabase.auth.getUser();
      return { ...(sessionUser || {}), id: session?.user.id }
    })

  async function fetchAppUser(): Promise<User> {
     if(!sessionUser?.id){
       supabase.auth.signOut();
       return parseUser({})
     }

    const { data } = await supabase
      .from('users')
      .select('id, ...user_accounts!users_account_fkey(email, accepted_terms, stripe_customer_id), subscription_level, created, profiles( id, bio, first_name, last_name, image, is_public, location, slug, tagline), memberships ( id, created, updated, membership_type, accepted, rejected, organization, title ) ')
      .eq('id', sessionUser?.id);

       // @ts-ignore https://github.com/supabase/postgrest-js/issues/459
       let { profiles, memberships, ...user } = (data||[])[0];
       let { data: { hash } } = sessionUser?.id ? await supahelpers.auth.getUserHash() : { data: { hash: undefined} }
       return parseUser({
         ...sessionUser,
         first_name: sessionUser.user_metadata?.first_name,
         last_name: sessionUser.user_metadata?.last_name,
         discord_invite: sessionUser.user_metadata?.discord_invite,
         discord_invite_used: sessionUser.user_metadata?.discord_invite_used,
         ...user,
         memberships: memberships && (Array.isArray(memberships) ? memberships : [memberships]) || [],
         intercom_hash: hash,
         confirmed: new Date(sessionUser.email_confirmed_at || 0).getTime() > 0,
         profile: parseProfile({...(Array.isArray(profiles) ? profiles[0] : {})})
       })

   }

   return {...useQuery<User>({
     queryKey: ["appUser", sessionUser],
     queryFn: fetchAppUser,
     placeholderData: { ...parseUser({}), is_placeholder_data: true },
     enabled: sessionStatus === "success"
  }), refresh: refetch }
 }
