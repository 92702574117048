import React from "react";
import {ordinal} from "../../helpers";

const dateRenderer = new Intl.DateTimeFormat('en-US', {
  hour12: true,
  timeZone: 'America/New_York',
  timeZoneName: 'short',
  weekday: "short",
  month: 'short', day: 'numeric',
  hour: 'numeric',
});


export default function TileDate({ date, timeClassName, dateClassName }: { date: string, timeClassName?: string, dateClassName?: string }){
  let parts = dateRenderer.formatToParts(new Date(date));
  return (
    <>
      <span className={timeClassName || ""}>{`${parts[6].value}${parts[8].value} ${parts[10].value}`}</span>
      <span className={dateClassName}>{`${parts[0].value} ${parts[2].value} ${ordinal(parts[4].value)}`}</span>
    </>
  )
}