import React from 'react'


function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

interface Props {
  image?: string
  first_name?: string
  last_name?: string
  className?: string
}

const Avatar:React.FC<Props> = ({ image, className, first_name, last_name }) => {
  if(image) return (<img className={classNames("rounded-full", className || '')} src={image} alt="" />)
  return (<div className={classNames(
    "rounded-full uppercase flex items-center justify-center border-2 border-white text-white",
    className || '',
    "bg-gradient-to-b from-gray-600 to-gray-400"
  )}>
    {`${first_name?.trim().charAt(0)}${last_name?.trim().charAt(0)}`}
  </div>)
}

export default Avatar
