 /* Created  on 2/28/2022. */
import gql from "graphql-tag";

export const RegistrationFragment = `
created
id
updated
session {
	id
}
student {
	id
}
`;

export const GET_MY_REGISTRATIONS_QUERY = gql`
  {
    authenticatedUserRegistrations {
      created
      id
      updated
      session {
        id
	      starts_at
	      course_module {
	        id
	      }
	      module {
	        id
	        title
	      }
	      instructor {
          first_name
          last_name
        }
	      course {
          id
          slug
          title
          company {
            name
          }
        }
      }
    }
  }
`;

export const GET_REGISTRATION_QUERY_BY_ID = (id: string) => gql`
  {
    registration(registration:{ id: "${id}" }) {
      ${RegistrationFragment}
    }
  }
`;

export const FIND_REGISTRATION_QUERY = (id: string, session: Session, student: User) => gql`
  {
    registrations(registration:{ id: "${id}", session: "${session}", student: "${student}" }) {
      ${RegistrationFragment}
    }
  }
`;

export const CREATE_REGISTRATION_MUTATION = gql`
  mutation RegistrationCreate($session: IdInput!, $student: IdInput!){
    registrationCreate(registration:{ session: $session, student: $student }) {
      ${RegistrationFragment}
    }
  }
`;

export const DELETE_REGISTRATION_MUTATION = gql`
  mutation RegistrationDelete($id: ID!){
     registrationDelete(registration:{ id: $id }) {
      id
    }
  }
`;

export const UPDATE_REGISTRATION_MUTATION = gql`
  mutation RegistrationUpdate($id: ID!, $session: IdInput!, $student: IdInput!){
    registrationUpdate(registration:{ id: $id, session: $session, student: $student }) {
      ${RegistrationFragment}
    }
  }
`;
