/* Created  on 2/28/2022. */
import gql from "graphql-tag";
import {useMutation} from "@tanstack/react-query";
import {api} from "../../api_supabase";
import {parseServerModule} from "./factories.gql";
import {Modules, Mutation} from "../../gql/graphql";

export const ModuleFragment = `
created
id
updated
cover
description
duration
skills
tagline
title
`;

export const GET_MODULE_QUERY_BY_ID = (id: string) => gql`
  {
    module(module:{ id: "${id}" }) {
      ${ModuleFragment}
    }
  }
`;

export const FIND_MODULE_QUERY = (id: string, cover: string, description: string, duration: number, skills: string[], tagline: string, title: string) => gql`
  {
    modules(module:{ id: "${id}", cover: "${cover}", description: "${description}", duration: "${duration}", skills: "${skills}", tagline: "${tagline}", title: "${title}" }) {
      ${ModuleFragment}
    }
  }
`;

export const CREATE_MODULES_MUTATION = gql`
mutation InsertIntomodulesCollection($objects: [modulesInsertInput!]!) {
  insertIntomodulesCollection(objects: $objects) {
    affectedCount
    records {
      ${ModuleFragment}
    }
  }
}
`;

export const DELETE_MODULE_MUTATION = gql`
  mutation ModuleDelete($id: ID!){
     moduleDelete(module:{ id: $id }) {
      ${ModuleFragment}
    }
  }
`;

export const UPDATE_MODULE_MUTATION = gql`
  mutation ModuleUpdate($application: String!, $cover: String, $description: String, $duration: Float, $skills: [String], $tagline: String, $title: String!){
    moduleUpdate(module:{ application: $string, cover: $string, description: $string, duration: $number, skills: $array, tagline: $string, title: $string }) {
      ${ModuleFragment}
    }
  }
`;

interface UseCreateModulesProps {
  modules: RequireOnly<Module, "title" | "duration">[]
}

export function useCreateModules(){
  function createModule({ modules }: UseCreateModulesProps): Promise<Module[]>{
    return api.request<Mutation>(CREATE_MODULES_MUTATION, { objects: modules })
      .then(({ insertIntomodulesCollection }) => {
        const { records } = insertIntomodulesCollection || { records: [] }
        return records.map((record: Modules) => parseServerModule(record))

      });
  }

  return useMutation({
    mutationFn: createModule
  })
}
