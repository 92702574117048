import PreRegisterForm from "./PreRegisterForm";
import SessionSelector from "./SessionSelector";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {classNames, formatDateForTile, UserIsAuthenticated, UserHasSubscription} from "../../helpers";
import { Waypoint } from 'react-waypoint';
import {useMutation} from "@apollo/client";
import useMap from '../../hooks/useMap';
import {CREATE_REGISTRATION_MUTATION, DELETE_REGISTRATION_MUTATION, UPDATE_REGISTRATION_MUTATION} from "../../data/client/registration.api";
import NewUserCourseRegistration from "./NewUserCourseRegistration";
import UpgradeModal from "../Modal/UpgradeModal";
import {useGetSessions} from "../../data/client/session.api";
import {ArrowTopRightOnSquareIcon} from "@heroicons/react/20/solid";

interface Props {
  course: Course
  theme: Theme
  user: User
  setUser: React.Dispatch<React.SetStateAction<User>>
  isAdmin: boolean
  alert: AlertDialog
  confirm: AlertDialog
  registrations: Registration[]
  refreshUser: (args?: any) => Promise<any>
  refetchRegistration: () => any
}

const CourseRegistration: React.FC<Props> = ({ course, theme, registrations, user, setUser, isAdmin, refreshUser, alert, confirm, refetchRegistration }) => {
  const { data: sessionData, isLoading: loading } = useGetSessions({ key: 'course_sessions', course: course.id });
  const [showNewUserRegistration, setShowNewUserRegistration] = useState(false)
  const [registerForSession] = useMutation(CREATE_REGISTRATION_MUTATION);
  const [unregisterForSession] = useMutation(DELETE_REGISTRATION_MUTATION);
  const [updateRegistrationsForSession] = useMutation(UPDATE_REGISTRATION_MUTATION);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)

  const [sessions, setSessions] = useState<Session[]>([])
  const [showFooter, setShowFooter] = useState(false)
  const [registrationForm, setRegistrationForm] = useMap<string, CourseRegistrationForm>();
  const [defaultRegistrationForm, { setAll: setAllForDefaultRegistrationForm }] = useMap<string, CourseRegistrationForm>();

  const { setAll: setAllForRegistrationForm } = setRegistrationForm

  useEffect(()=> {
    let dataToUpdate = new Map()
    sessions.forEach((session) => {
      let found = registrations.find(( { session: { id } }) => id === session.id );
      if(found && found.session.id && found.session.course_module.id){
        dataToUpdate.set(found.session.course_module.id, { session_id: found.session.id, course_module_id: found.session.course_module.id, registration_id: found.id})
      }
    })
    setAllForDefaultRegistrationForm(dataToUpdate);
  }, [registrations, sessions, setAllForDefaultRegistrationForm])

  const [formHasChanges, formExternalSession] = useMemo(() => {
    let externalSession: Session | undefined;

    Array.from(registrationForm.entries()).forEach(([, { session_id }]) => {
      externalSession = !externalSession && sessions.find(({ id, external_registration_link}) => {
        return id === session_id && external_registration_link
      }) || undefined
    })

    let formHasChanges = registrationForm.size !== defaultRegistrationForm.size
      || !Array.from(registrationForm.entries()).every(([, { registration_id, course_module_id, session_id }]) => {
        if(!registration_id) {
          return false;
        }
        let exists = defaultRegistrationForm.get(course_module_id)
        if(!exists) {
          return false;
        }
        return exists.registration_id === registration_id && exists.session_id === session_id;
      })

    return [formHasChanges, externalSession]

  }, [registrationForm, defaultRegistrationForm])

  const onRegister = useCallback(async () => {
    if(UserIsAuthenticated(user) && UserHasSubscription(user, Math.max(...course.course_modules.map(({ subscription_level}) => subscription_level )))){
      if(formHasChanges){
        let operations: { create: CourseRegistrationForm[], remove: CourseRegistrationForm[], update: CourseRegistrationForm[]} = { create: [], remove: [], update: [] }
        Array.from(defaultRegistrationForm.entries()).forEach(([, { registration_id, course_module_id, session_id }]) => {
          let exists = registrationForm.get(course_module_id)
          if(exists){
            if(exists.session_id !== session_id || exists.registration_id !== registration_id){
              operations.update.push(exists)
            }
          } else {
            operations.remove.push({ registration_id, course_module_id, session_id })
          }
        })
        Array.from(registrationForm.entries()).forEach(([, { registration_id, course_module_id, session_id }]) => {
          if(!registration_id){
            operations.create.push({ registration_id, course_module_id, session_id })
          }
        })

        if(operations.remove.length > 0 || operations.update.length > 0){
          await confirm({
            buttonText: operations.update.length > 0 ? "Update" :"Unregister",
            type: "danger",
            title:`Are you sure you want to ${operations.update.length > 0 ? "update your registration" : "unregister" }?`,
            description: <div className="grid grid-cols-1 gap-4">
              { operations.update.length > 0 ?
                (<div>
                  <div className="text-base">You will be moved to:</div>
                  { operations.update.map(({ course_module_id, session_id}) => {
                    let courseModule = course.course_modules.find(({ id}) => course_module_id === id);
                    let newSession = sessions.find(({ id }) => session_id === id );
                    let oldRegistration = defaultRegistrationForm.get(course_module_id)
                    let oldSession = sessions.find(({ id }) => oldRegistration?.session_id === id );
                    return (
                      <>
                      <span className="block italic text-red-500">
                        {`${courseModule?.module.title}`}
                      </span>
                        <span className="block font-semibold italic text-red-500">
                        {`${formatDateForTile(oldSession?.starts_at || "")} ➝ ${formatDateForTile(newSession?.starts_at || "")}`}
                      </span>
                      </>
                    )
                  })}
                </div>)
                : ""
              }
              { operations.remove.length > 0 ?
                (<div>
                  <div className="text-base">You will be removed from:</div>
                  { operations.remove.map(({ course_module_id}) => {
                    let courseModule = course.course_modules.find(({ id}) => course_module_id === id);
                    return <span className="block font-semibold italic text-red-500">{courseModule?.module.title}</span>
                  })}
                  <div className="mt-2">You won't be able to reregister if the session is full.</div>
                </div>)
                : ""
              }
            </div>
          })
        }

        await Promise.all([
          ...operations.update.map(({ registration_id, session_id}) => {
            return updateRegistrationsForSession({ variables: { id: registration_id, session: { id: session_id }, student: { id: user.id } }}).then(() => true)
          }),
          ...operations.remove.map(({ registration_id}) => {
            return unregisterForSession({variables: { id: registration_id } }).then(() => true)
          }),
          ...operations.create.map(({ session_id}) => {
            return registerForSession({variables: { session: { id: session_id }, student: { id: user.id }}}).then(() => true)
          }),
        ])
      }
      alert({
        buttonText: "Ok",
        type: "success",
        title:"You're all set!",
        description:"We will send a zoom link when the class is live."
      });
      await refetchRegistration()
    } else if(UserIsAuthenticated(user)){
      setShowUpgradeModal(true)
    } else  {
      setShowNewUserRegistration(true)
    }
  }, [user, registrationForm, defaultRegistrationForm, confirm, alert, formHasChanges,
    course.course_modules, refetchRegistration, registerForSession, sessions, unregisterForSession, updateRegistrationsForSession
  ]);

  useEffect(() => {
    if(!loading && sessionData){
      setSessions(sessionData)
    }
  },[sessionData, loading])

  useEffect(() => {
    setAllForRegistrationForm((prev) => {
      const base = new Map(defaultRegistrationForm.entries())
      Array.from(prev).forEach(([ key, value]) => {
        let exists = base.get(key)
        if(exists){
          base.set(key, { ...exists, session_id: value.session_id })
        } else {
          base.set(key, value)
        }
      })
      return base
    })
  }, [defaultRegistrationForm, setAllForRegistrationForm])

  if(loading) return <div/>

  let module_count = course.course_modules.length;
  let gridClasses = `items-stretch ${module_count%2 === 1 ? 'md:grid-cols-1' : 'md:grid-cols-2'}`;
  if (module_count >= 3) gridClasses+=` ${module_count%3 === 0 ? 'lg:grid-cols-3' : 'lg:grid-cols-2'}`
  if (module_count >= 4 )gridClasses+=` ${module_count%3 === 0 ? 'xl:grid-cols-3' : 'xl:grid-cols-4'}`;
  if(module_count === 1) gridClasses = 'items-stretch grid-cols-1 justify-center w-1/2 mx-auto'

  return (
    <>
      <NewUserCourseRegistration
        user={user} setUser={setUser} refreshUser={refreshUser} sessions={sessions}
        onRegister={onRegister} refetchRegistration={refetchRegistration}
        course={course} registrationForm={registrationForm} alert={alert}
        showModal={showNewUserRegistration} setShowModal={setShowNewUserRegistration}/>
      <UpgradeModal user={user} show={showUpgradeModal} setShow={setShowUpgradeModal} />
      <div id="attend" className={classNames("relative px-4 -mt-48", UserIsAuthenticated(user) && !user.confirmed ? "pt-1":"")}>
        <div className="max-w-4xl mx-auto px-0 pb-4 sm:px-0 sm:pb-4 lg:max-w-8xl lg:px-0">
          {course.course_modules.every((course_module) => !course_module.available && !isAdmin) ?
            (<div className="relative bg-white rounded-lg -space-y-px shadow-lg text-center max-w-3xl mx-auto">
              <h2 className="px-5 pt-10 pb-1 uppercase font-button text-5xl font-bold"
                  style={{color: theme.course_card_label_color}}
              >Reserve your seat</h2>
              <div className="px-5 pb-4 pt-2 text-gray-600 text-lg uppercase font-semibold">We will notify you when registration opens.</div>
              <div className="py-4 px-16 flex justify-center">
                <div className="w-full max-w-lg"><PreRegisterForm actionText={"Register"} course={course.slug}/></div>
              </div>
            </div>) :(
              <>
                <Waypoint onEnter={() => setShowFooter(true)} onLeave={() => setShowFooter(false)} bottomOffset={400}>
                  <div className="lg:mt-16">
                    <span className="text-center block text-white uppercase font-semibold text-sm pb-6">Select the sessions you would like to attend, and then hit the Registration button below to register</span>
                    <div className={`grid grid-cols-1 gap-x-6 gap-y-12 lg:gap-x-4 lg:gap-y-8 ${gridClasses}`}>
                      {course.course_modules.map((courseModule, moduleIndex) =>{
                        return <SessionSelector
                          key={courseModule.id}
                          sessions={sessions.filter((session) => session.course_module?.id === courseModule.id)}
                          {...{ isAdmin, moduleIndex, courseModule, registrationForm, setRegistrationForm, defaultRegistrationForm, theme }}
                        />
                      })}
                    </div>
                  </div>
                </Waypoint>
                <div className={classNames(
                  showFooter && formHasChanges ? 'sticky bottom-0 left-0' : 'static',
                  "md:w-1/2 mx-auto flex justify-center z-10 py-5 px-5 md:px-0"
                )}>
                  <div className="mt-4 grid grid-cols-2 gap-x-4 max-w-4xl lg:max-w-8xl w-full">
                    {formExternalSession ? <a href={formExternalSession.external_registration_link} target="_blank" style={{backgroundColor: theme.cta_bg_color, color: theme.cta_text_color}} className={classNames(
                        'w-full shadow-2xl btn btn-secondary btn-floating mb-2 col-span-2 transform transition-transform hover:scale-95',
                        (!formHasChanges) ? 'pointer-events-none opacity-50 ' : '',
                      )}>
                        Register Externally for this session <ArrowTopRightOnSquareIcon className="ml-1 mt-0.5 h-4"/>
                      </a> :
                      <button
                        type="button"
                        disabled={!formHasChanges}
                        onClick={onRegister}
                        style={{backgroundColor: theme.cta_bg_color, color: theme.cta_text_color}}
                        className={classNames(
                          'w-full shadow-2xl btn btn-secondary btn-floating mb-2 col-span-2 transform transition-transform hover:scale-95',
                          (!formHasChanges) ? 'pointer-events-none opacity-50 ' : '',
                        )}
                      >
                        {!formHasChanges ? 'Select A Session to Register' :
                          (defaultRegistrationForm.size === 0 ? `Register for ${registrationForm.size} of ${course.course_modules.length} modules` :
                            (registrationForm.size === 0 ? 'Unregister' : 'Update Registration'))}
                      </button>
                    }
                  </div>
                </div>
              </>
            )
          }
        </div>
      </div>
    </>
  )
}

export default CourseRegistration
