/* Created by JideOsan on 10/11/2022. */
import gql from "graphql-tag";
import {
  useMutation,
  UseMutationResult,
  MutationFunction,
  useQuery,
  UseQueryResult,
  QueryFunction, useQueryClient
} from "@tanstack/react-query";
import {supabase} from "../../api_supabase";
import {parseMembership} from "./factories";
import {getMembershipType} from "../../helpers";

export const MembershipFragment = `
created
id
updated
membership_type
organization {
	created
	id
	updated
	admin {
		created
		id
		updated
		bio
		email
		first_name
		image
		last_name
		location
		privacy_available_to_hire
		slug
		social_linkedin
		social_discord
		subscription_level
		tagline
		username 
	}
	benefits
	bio
	hired
	logo
	logo_alt
	location
	name
	slug
	social_linkedin
	tagline
	theme
	values 
}
user {
	created
	id
	updated
	bio
	email
	first_name
	image
	last_name
	location
	privacy_available_to_hire
	slug
	social_linkedin
	social_discord
	subscription_level
	tagline
	username 
}
`;

export const GET_MEMBERSHIP_QUERY_BY_ID = (id: string) => gql`
  {
    membership(membership:{ id: "${id}" }) {
      ${MembershipFragment}
    }
  }
`;

export const FIND_MEMBERSHIP_QUERY = (id: string, membership_type: string, organization: Organization, user: User) => gql`
  {
    memberships(membership:{ id: "${id}", membership_type: "${membership_type}", organization: "${organization}", user: "${user}" }) {
      ${MembershipFragment}
    }
  }
`;

export const CREATE_MEMBERSHIP_MUTATION = gql`
  mutation MembershipCreate($membership_type: String!, $organization: IdInput!, $user: IdInput!){
    membershipCreate(membership:{ membership_type: $membership_type, organization: $organization, user: $user }) {
      ${MembershipFragment}
    }
  }
`;

export const DELETE_MEMBERSHIP_MUTATION = gql`
  mutation MembershipDelete($id: ID!){
     membershipDelete(membership:{ id: $id }) {
      ${MembershipFragment}
    }
  }
`;

export const UPDATE_MEMBERSHIP_MUTATION = gql`
  mutation MembershipUpdate($application: String!, $membership_type: String!, $organization: IdInput!, $user: IdInput!){
    membershipUpdate(membership:{ application: $string, membership_type: $string, organization: $string, user: $string }) {
      ${MembershipFragment}
    }
  }
`;

interface UseManageFollowerProps {
  membership: ManageFollowerServerModel,
  follow: boolean
}


export function useFindInstructorMemberships({ organization_id, search = "" }: { organization_id?: string, search: string }): UseQueryResult<Membership[]> {
  const fetchMemberships: QueryFunction<Membership[]> = async () => {
    let query = supabase
      .from('profiles')
      .select('first_name, last_name, image, user(id, memberships(id, membership_type, organization( id, logo, name)))')
      .gte("user.memberships.membership_type", 3)

    if(organization_id) query = query.eq('user.memberships.organization.id', organization_id);
    if(search !== "") query = query.ilike('full_name', `%${search}%`)

    const { data } = await query;

    console.log(data)
    return (data || []).map((membership) => parseMembership(membership));
  }

  return useQuery<Membership[]>({
    queryKey: ["instructor_memberships" ],
    queryFn: fetchMemberships,
  })
}

export function useFetchMemberships({ user_id }: { user_id: string }): UseQueryResult<Membership[]> {

  const fetchMemberships: QueryFunction<Membership[]> = async () => {
    const { data } = await supabase
      .from('memberships')
      .select('*, organization( id, logo, location, name, type, description, slug)')
      .eq("user", user_id);

    return (data || []).map((membership) => parseMembership(membership));
  }

  return useQuery<Membership[]>({
    queryKey: ["user_memberships", user_id ],
    queryFn: fetchMemberships,
  })
}

export function useAddMembership(): UseMutationResult<Membership, Error, CreateMembershipServerModel> {
  const addMembership: MutationFunction<Membership, CreateMembershipServerModel> = async(newMembership) => {
    const {organization, user, accepted, rejected, ...membership} = newMembership
    const { data } = await supabase
      .from('memberships')
      .insert({ ...membership, organization: organization.id, user: user.id }).select();
    return ((data || []).map((membership) => parseMembership(membership)))[0];
  }

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: addMembership,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user_memberships"]})
    }
  })
}

export function useManageFollower(): UseMutationResult<Membership, Error, UseManageFollowerProps> {
  const manageFollower: MutationFunction<Membership, UseManageFollowerProps> = async({ membership, follow }) => {
    const {organization, user} = membership
    if(follow){
      const { data } = await supabase
        .from('memberships')
        .insert({ membership_type: getMembershipType("FOLLOWER"), accepted: new Date().toISOString(), organization: organization.id, user: user.id }).select();
      return ((data || []).map((membership) => parseMembership(membership)))[0];
    } else {
      const { data } = await supabase
        .from('memberships')
        .delete()
        .eq("organization", organization.id)
        .eq("membership_type", getMembershipType("FOLLOWER"))
        .eq("user", user.id)
        .select();
      return ((data || []).map((membership) => parseMembership(membership)))[0];
    }
  }

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: manageFollower,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user_memberships"]})
    }
  })
}

export function useDeleteMemberships(): UseMutationResult<Membership, Error, { id: string }> {
    const addMembership: MutationFunction<Membership,  { id: string }> = async({ id }) => {
      const { data } = await supabase
        .from('memberships')
        .delete()
        .match({'id': id}).select();
      return ((data || []).map((membership) => parseMembership(membership)))[0];
    }

    const queryClient = useQueryClient()

    return useMutation({
      mutationFn: addMembership,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["user_memberships"]})
      }
    })
  }
