import React, {useState} from 'react'
import {Link} from "react-router-dom";


function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

interface Props {
  avatars: { image: string, title: string, description: string, slug: string }[]
  color?: string
  dark?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl',

}

const AvatarLabel:React.FC<Props> = ({ color, avatars, size, dark }) => {
  const [selectedIndex, setSelectedIndex] = useState(avatars.length-1)

  let dimensions = `h-10 w-10`
  let text_size = 'text-sm';
  let space = '-space-x-4'
  if(size === 'xs'){
    dimensions = `h-8 w-8`
    text_size = 'text-sm';
  } else if(size === 'sm'){
    dimensions = `h-9 w-9`
    text_size = 'text-sm';
  } else if (size === 'lg'){
    dimensions = `h-12 w-12`
    text_size = 'text-base';
  } else if (size === 'xl'){
    dimensions = `h-16 w-16`
    text_size = 'text-xl';
    space = '-space-x-7'
  }

  let style: React.CSSProperties = {};

  if(color){
    style.backgroundColor = color
  }

  return (
    <>
      <div className={`flex ${space} flex-shrink-0`}>
        {avatars.map(({ image, title, description}, index) =>
          <div
            key={`avatarGroup-${index}`}
            className={classNames(
              "inline-block relative",
              dimensions,
              "rounded-full ring-white bg-center bg-no-repeat bg-cover",
              "transition-transform transform hover:scale-110",
              dark ? "ring-2 ring-white/50" : "ring-1",
              `z-${Math.abs(selectedIndex === index ? avatars.length : (selectedIndex > index ? avatars.length-selectedIndex+index : (avatars.length*-1)-selectedIndex+index ))}`,
            )}
            style={{backgroundImage: `url(${image})`}}
            onMouseEnter={() => setSelectedIndex(index)}
            onMouseLeave={() => setSelectedIndex(avatars.length - 1)}
          />
        )}
      </div>
      <div className=" min-w-0 flex-1 flex items-center">
        <div className={classNames(text_size, "inline-flex flex-col leading-none")}>
          <Link to={avatars[selectedIndex]?.slug} className={`hover:underline font-bold ${dark ? "text-white" : "text-black"} capitalize`}>
            {avatars[selectedIndex]?.title}
          </Link>
          <span className={dark ? "text-white" : "text-gray-600"}>{avatars[selectedIndex]?.description}</span>
        </div>
      </div>
    </>
  )
}

export default AvatarLabel
