import React from 'react'
import { dellTheme } from "../helpers/defaults";
import modyfiScreenshot from "../assets/img/modyfi-screenshot.png"
import modyfiflyer from "../assets/img/modyfy-flyer.png"
import modyfiLogo from "../assets/img/modyfi-new-logo.svg"
import {ReactComponent as EquiptLogo} from '../assets/img/logo-white.svg'

import {ReactComponent as ModyfiWhiteLogo} from '../assets/img/company-modyfi-new-logo-white.svg'
import {ReactComponent as CubsWhiteLogo} from '../assets/img/company-cubs-white.svg'
import {ReactComponent as QuireWhiteLogo} from '../assets/img/company-quire.svg'
import AquaosoWhiteLogo from '../assets/img/company-aquaoso-Logo.png'


const profilePictures = {
  female: [
    'https://images.unsplash.com/photo-1532417344469-368f9ae6d187?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    'https://images.unsplash.com/photo-1611432579699-484f7990b127?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920',
    "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    'https://images.unsplash.com/photo-1590955256762-e60f6e08122a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80',
    "https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80"
  ],
  male: [
    'https://images.unsplash.com/photo-1566492031773-4f4e44671857?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    'https://images.unsplash.com/photo-1513910367299-bce8d8a0ebf6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    'https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    'https://images.unsplash.com/photo-1601576084861-5de423553c0f?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920',
    'https://images.unsplash.com/photo-1642736468880-89d8a6de3fbe?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920',
    "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    "https://images.unsplash.com/photo-1522529599102-193c0d76b5b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80",
    "https://images.unsplash.com/photo-1629425733761-caae3b5f2e50?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80",
    "https://images.unsplash.com/photo-1544725391-7aefff7d6d4c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80",
    "https://images.unsplash.com/photo-1564564244660-5d73c057f2d2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80"
  ]
}

interface Props {
  user: User
  alert: AlertDialog
}

const images = {
  cover: "https://spaces.joinequipt.com/community_cover.jpg",
  cover_girl: "https://images.unsplash.com/photo-1599687351724-dfa3c4ff81b1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
  customer_acquisition: "https://images.unsplash.com/photo-1556742502-ec7c0e9f34b1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
  product_feedback: "https://images.unsplash.com/photo-1512758017271-d7b84c2113f1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1024&q=80"
}

import {FaFacebook, FaLinkedinIn } from 'react-icons/fa'
import {SiTrustpilot} from 'react-icons/si'

const navigation = {
  solutions: [
    { name: 'Marketing', href: '#' },
    { name: 'Analytics', href: '#' },
    { name: 'Commerce', href: '#' },
    { name: 'Insights', href: '#' },
  ],
  support: [
    { name: 'Pricing', href: '#' },
    { name: 'Documentation', href: '#' },
    { name: 'Guides', href: '#' },
    { name: 'API Status', href: '#' },
  ],
  company: [
    { name: 'About', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'Jobs', href: '#' },
    { name: 'Press', href: '#' },
    { name: 'Partners', href: '#' },
  ],
  legal: [
    { name: 'Claim', href: '#' },
    { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' },
  ],


  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/joinequipt',
      icon: (props: React.SVGProps<SVGSVGElement>) => <FaFacebook {...props} />,
    },
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/joinequipt',
      icon: (props: React.SVGProps<SVGSVGElement>) => <FaLinkedinIn {...props} />,
    },
    {
      name: 'TrustPilot',
      href: 'https://www.trustpilot.com/review/joinequipt.com',
      icon: (props: React.SVGProps<SVGSVGElement>) => <SiTrustpilot {...props} />,
    },
  ],
}

const LandingCommunity: React.FC<Props> = () => {
  return (
    <>
      <section className="relative">
        <div className="absolute inset-0 bg-cover h-full bg-center" style={{backgroundImage: `url('${images.cover}')`}}/>
        <div className="absolute h-full w-full inset-0 flex bg-black/40" />
        <div className="relative inset-0 pt-16 flex flex-col min-h-screen">
          <div className="flex flex-col grow justify-center my-28">
            <div className="w-full max-w-3xl mx-auto text-center">
              <h6 className="text-blue-300 uppercase font-heading block font-bold text-xl p-1">Community</h6>
              <h1 className="text-white uppercase font-heading font-bold block text-6xl"><span className="text-highlighted from-orange-500/80 to-orange-500/70 ">Supercharge</span> your developer community</h1>
            </div>
            <div className="w-full max-w-4xl mx-auto text-center">
              <div className="text-white text-2xl pt-6">
                We give you the tools to nurture your developer communities with online learning. With Equipt you’ll convert developers into champions to drive revenue, product feedback, and more
              </div>
              <a href="https://calendly.com/hubert-dagbo/30min" target="_blank" className="btn btn-color w-96 bg-blue mx-auto mt-20">Connect with us to learn more</a>
            </div>
          </div>
          <div className="h-48 text-white border-t border-white ">
            <h6 className="text-white/80 font-bold uppercase block p-5 text-center font-heading">You are in Good Company</h6>
            <div className="flex items-center justify-around opacity-80 max-w-screen-2xl mx-auto pb-5">
              <ModyfiWhiteLogo className="h-14 w-40" />
              <CubsWhiteLogo  className="h-20 w-20"/>
              <QuireWhiteLogo className="h-14 w-48" />
              <img className="h-6" alt="Dell Logo"  src="https://equipt-prod-cdn.nyc3.digitaloceanspaces.com/logo-dell-technologies.svg"/>
              <img className="h-12" alt="Aquaoso Logo"  src={AquaosoWhiteLogo}/>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white pt-40">
        <div className="max-w-screen-2xl mx-auto flex">
          <div className="text-left w-1/2">
            <div className="mx-auto">
              <div className="flex justify-around items-start">
                <div className="text-center ml-10">
                  <div className="h-32 w-32 bg-cover bg-center rounded-full drop-shadow-md" style={{backgroundImage: `url('${profilePictures.male[2]}')`}} />
                  <div className="font-semibold leading-none mt-1">Sam</div>
                  <div className="text-sm leading-none">Designer</div>
                </div>
                <div className="flex items-center">
                  <div className="text-center mt-2">
                    <div className="h-20 w-20 bg-cover bg-center rounded-full mx-auto drop-shadow-md" style={{backgroundImage: `url('${profilePictures.female[6]}')`}} />
                    <div className="font-semibold leading-none mt-1">Lauren</div>
                    <div className="text-sm leading-none">Frontend</div>
                  </div>
                  <span className="shrink w-40 ml-3 pb-8 text-lg leading-none italic">“It’s great to see how to deliver Agile projects with Dell’s tools”</span>
                </div>
              </div>

              <div className="flex items-center justify-center">
                <div className="text-center mr-4">
                  <div className="h-20 w-20 bg-cover rounded-full drop-shadow-md" style={{backgroundImage: `url('${profilePictures.female[4]}')`}} />
                  <div className="font-semibold leading-none mt-1">Sandra</div>
                  <div className="text-sm leading-none">UI/UX</div>
                </div>
                {/* Class Tile*/}
                <div className="p-5 rounded-md relative w-96 m-5 shrink-0" >
                  <div className="absolute h-full z-1 w-full inset-0 rounded-md rotate-6" style={{backgroundColor: "#70A5CD"}}/>
                  <div className="absolute h-full z-1 w-full inset-0 rounded-md drop-shadow-md" style={{backgroundImage: `url("https://images.unsplash.com/photo-1573165231923-13fb19113505?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1024&q=80")`}}/>
                  <div className="absolute h-full z-1 w-full inset-0 rounded-md"
                       style={{
                         opacity: dellTheme.img_bg_color_opacity,
                         backgroundImage: `linear-gradient(to bottom, rgba(0, 118, 206, .62), rgba(183, 41, 90, .47))`
                       }}/>
                  <div className="absolute h-full z-1 w-full inset-0 bg-gray-700/30 rounded-md"/>
                  <div className="relative z-10 flex flex-col items-center justify-between">
                  <span className="inline-flex items-center rounded-full bg-white pl-1 pr-2 text-xs font-medium text-red-800 font-semibold mr-auto">
                  <span className="animate-ping absolute inline-flex h-2.5 w-2.5 rounded-full bg-red-400 opacity-75"/>
                    <svg className="mr-1 h-2.5 w-2.5 text-red-500-400" fill="currentColor" viewBox="0 0 8 8">
                    <circle cx={4} cy={4} r={3} />
                  </svg>
                  LIVE
                </span>
                    <img className="h-6" alt="Dell Logo"  src="https://equipt-prod-cdn.nyc3.digitaloceanspaces.com/logo-dell-technologies.svg"/>
                    <h1 className="text-white uppercase font-heading block font-bold text-lg">Presents</h1>
                    <div className="text-white block font-bold text-xl text-center leading-none my-5">Project Management with <br /> the Agile Mindset</div>
                    <div className="text-white leading-tight text-center">In this course, you will gain a better and more methodological approach to delivering innovative and disruptive technology products and services through the use of Agile.</div>
                    <div className="h-28 w-28 bg-cover rounded-full mt-4" style={{backgroundImage: "url('https://equipt-prod-cdn.nyc3.digitaloceanspaces.com/sharon.jpeg')"}} />
                    <div className="text-white font-bold text-lg">Sharon Stephens</div>
                    <div className="text-white text-lg mb-4 leading-none">Cyber Security, Dell Technologies</div>
                    <div
                      className="tile-btn w-full"
                      style={{
                        backgroundColor: dellTheme.cta_bg_color,
                        color: dellTheme.cta_text_color,
                        transition: "all .15s ease" }}
                    >
                      Join Session Now
                    </div>
                  </div>
                </div>
                {/* End Class Tile*/}
                <div className="flex flex-col self-stretch justify-between">
                  <div className="text-center mt-16 ml-14">
                    <div className="h-24 w-24 bg-cover rounded-full bg-center drop-shadow-md" style={{backgroundImage: `url('${profilePictures.male[11]}')`}} />
                    <div className="font-semibold leading-none mt-1">Sam</div>
                    <div className="text-sm leading-none">Data Engineer</div>
                  </div>
                  <div className="text-center w-24 mb-5">
                    <div className="h-24 w-24 bg-cover rounded-full bg-center drop-shadow-md" style={{backgroundImage: `url('${profilePictures.female[2]}')`}} />
                    <div className="font-semibold leading-none mt-1">Candice</div>
                    <div className="text-sm leading-none">Devops</div>
                  </div>
                </div>
              </div>

              <div className="flex items-start justify-around -mt-4">
                <div className="text-center mr-10">
                  <div className="h-28 w-28 bg-cover rounded-full drop-shadow-md" style={{backgroundImage: `url('${profilePictures.male[0]}')`}} />
                  <div className="font-semibold leading-none mt-1">Dan</div>
                  <div className="text-sm leading-none">Backend</div>
                </div>
                <div className="flex items-center mt-20 ">
                  <div className="text-center">
                    <div className="h-20 w-20 bg-cover rounded-full drop-shadow-md" style={{backgroundImage: `url('${profilePictures.male[10]}')`}} />
                    <div className="font-semibold leading-none mt-1">Charles</div>
                    <div className="text-sm leading-none">Full Stack</div>
                  </div>
                  <span className="shrink w-56 ml-3 pb-8 text-lg leading-none italic">An honest look at best practices. Thanks!</span>
                </div>
              </div>

            </div>
          </div>
          <div className="text-left w-1/2 flex items-center">
            <div className="max-w-lg mx-auto">
              <h6 className="text-blue uppercase font-heading block font-bold text-xl p-1">Our Process</h6>
              <h1 className="text-gray-600 uppercase font-heading font-bold block text-6xl pr-9 leading-none">Showcase your tech and team. <span className="text-highlighted from-orange-500/30 to-orange-500/20 ">We’ll take care of the rest.</span></h1>
              <div className="text-black text-2xl pt-6 font-semibold leading-7 pr-16">
                You can count on us to create bespoke learning content for an inclusive network of developers. We provide you with the tools to build your community, understand their needs and win over more evangelists.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white pt-40">
        <div className="max-w-screen-2xl mx-auto flex">
          <div className="text-left w-1/2 flex items-center">
            <div className="max-w-lg mx-auto">
              <h6 className="text-orange uppercase font-heading block font-bold text-xl p-1">Your Impact</h6>
              <h1 className="text-gray-600 uppercase font-heading font-bold block text-6xl leading-none">The impact of a community is <span className="text-highlighted from-blue-500/30 to-blue-500/20 ">far reaching</span></h1>
              <div className="text-black text-2xl pt-6 font-semibold leading-7 pr-16">With a strong community of champions, you can drive revenue, increase brand affinity, and strengthen alignment between your product and your customers.</div>
            </div>
          </div>
          <div className="text-left w-1/2">

            <div className="mx-auto">

              <div className="flex items-start justify-center flex-col p-10">
                <div className="p-5 rounded-md relative w-88 h-48 my-4 shrink-0" >
                  <div className="absolute h-full z-1 w-full inset-0 rounded-md drop-shadow-md bg-cover"
                       style={{
                         backgroundPosition: "100% -20px",
                         backgroundImage: `url("https://images.unsplash.com/photo-1598257006408-538c27529235?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80")`
                       }}/>
                  <div className="absolute h-full z-1 w-full inset-0 rounded-md bg-gradient-to-t via-transparent from-black/90" />
                  <div className="absolute h-full z-1 w-full inset-0 bg-black/30 rounded-md"/>
                  <div className="relative z-2 flex flex-col items-center justify-between h-full">
                    <h1 className="text-white uppercase font-heading block font-bold text-2xl">Recruitment</h1>
                    <div className="text-white leading-tight text-center font-semibold text-2xl opacity-80">
                      Turn your audiences into employees
                    </div>
                  </div>
                </div>

                <div className="p-5 rounded-md relative w-88 h-48 my-4 ml-32 shrink-0" >
                  <div className="absolute h-full z-1 w-full inset-0 rounded-md drop-shadow-md bg-cover"
                       style={{
                         backgroundPosition: "100% 40%",
                         backgroundImage: `url("${images.customer_acquisition}")`
                       }}/>
                  <div className="absolute h-full z-1 w-full inset-0 rounded-md bg-gradient-to-t via-transparent from-black/90" />
                  <div className="absolute h-full z-1 w-full inset-0 bg-black/30 rounded-md"/>
                  <div className="relative z-2 flex flex-col items-center justify-between h-full">
                    <h1 className="text-white uppercase font-heading block font-bold text-2xl">Customer Acquisition</h1>
                    <div className="text-white leading-tight text-center font-semibold text-2xl opacity-80">
                      Showcase your product in a whole new light
                    </div>
                  </div>
                </div>

                <div className="p-5 rounded-md relative w-88 h-48 my-4 ml-16 shrink-0" >
                  <div className="absolute h-full z-1 w-full inset-0 rounded-md drop-shadow-md bg-cover"
                       style={{
                         backgroundPosition: "100% -20px",
                         backgroundImage: `url("${images.product_feedback}")`
                       }}/>
                  <div className="absolute h-full z-1 w-full inset-0 rounded-md bg-gradient-to-t via-transparent from-black/90" />
                  <div className="absolute h-full z-1 w-full inset-0 bg-black/30 rounded-md"/>
                  <div className="relative z-2 flex flex-col items-center justify-between h-full">
                    <h1 className="text-white uppercase font-heading block font-bold text-2xl">Product Feedback</h1>
                    <div className="text-white leading-tight text-center font-semibold text-2xl opacity-80">
                      Keep a pulse on how to enhance your product
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </section>
      <section className="bg-white flex pt-40">
        <div className="max-w-7xl mx-auto text-center">
          <h6 className="text-blue uppercase font-heading block font-bold text-xl p-1">Case Study</h6>
          <h1 className="text-gray-600 uppercase font-heading font-bold block text-6xl leading-none">From Stealth to <span className="text-highlighted from-blue-500/30 to-blue-500/20 ">Stardom</span></h1>
          <div className="text-black text-2xl pt-6 font-semibold leading-7 px-32">
            Modyfi is a startup in stealth, building an AI-powered design tool. With Equipt, they’ve developed a community of senior software engineers and product designers from which they've recruited new employees and gathered product feedback.
          </div>
          <img src={modyfiScreenshot} alt="Modyfi Screenshot"/>
        </div>
      </section>
      <section className="overflow-hidden py-12 md:py-20 lg:py-32" style={{backgroundColor: "#212121"}}>
        <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="relative">
            <img
              className="mx-auto h-8"
              src={modyfiLogo}
              alt="Modyfi Logo"
            />
            <blockquote className="mt-1">
              <div className="mx-auto max-w-4xl text-center text-3xl font-medium leading-9 text-white relative">
                <div className="text-white opacity-10 absolute mt-24 -ml-20 font-heading font-normal h-32" style={{fontSize: "400px", lineHeight:0}}>“</div>
                <p>
                  &ldquo;Equipt connected us to a diverse group of engineers. They also saved us weeks on our recruitment process and helped me find and recruit senior engineers that I would've previously overlooked.&rdquo;
                </p>
              </div>
              <footer className="mt-8">
                <div className="md:flex md:items-center md:justify-center">
                  <div className="md:flex-shrink-0">
                    <img
                      className="mx-auto h-10 w-10 rounded-full"
                      src={profilePictures.male[6]}
                      alt=""
                    />
                  </div>
                  <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                    <div className="text-base font-semibold text-white uppercase font-heading">Joeseph</div>

                    <svg className="mx-1 hidden h-5 w-5 md:block" style={{color: "#EA3BBA"}} fill="currentColor" viewBox="0 0 20 20">
                      <path d="M11 0h3L9 20H6l5-20z" />
                    </svg>

                    <div className="text-base font-bold text-gray-50 font-heading uppercase">CEO, Modyfi</div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </section>
      <section className="bg-white pt-40">
        <div className="max-w-screen-2xl mx-auto flex  items-center justify-center">
          <div className="text-left w-1/2 flex items-center">
            <div className="max-w-lg mx-auto">
              <h6 className="text-blue uppercase font-heading block font-bold text-xl p-1">Success Metrics</h6>
              <h1 className="text-gray-800 uppercase font-heading font-bold block text-6xl pr-24 leading-none">Community Engagement <span className="text-highlighted from-blue-500/30 to-blue-500/20"> in a box</span></h1>
              <div className="text-gray-600 text-2xl pt-6 font-semibold leading-7 pr-10">
                We partnered with Modyfi to build engaging technical workshops on web graphics engineering languages and demos showcasing their AI-powered design tools. As a result, they grew their graphic software engineering and product designer community, gathered product feedback, and recruited new employees for their team.
              </div>
              <div className="grid grid-cols-3 gap-10 mt-8 text-gray-600">
                <div>
                  <div className="text-5xl font-bold font-heading" style={{color: "#EA3BBA"}}>400+</div>
                  <div className="leading-none">New <span className="font-bold">Community members</span> over 3 weeks</div>
                </div>
                <div>
                  <div className="text-5xl font-bold font-heading" style={{color: "#EA3BBA"}}>4.7/5</div>
                  <div className="leading-none">Average Workshop <span className="font-bold">Satisfaction Rating</span></div>
                </div>
                <div>
                  <div className="text-5xl font-bold font-heading" style={{color: "#EA3BBA"}}>3X</div>
                  <div className="leading-none">Increase in <span className="font-bold">Recruitment Candidates </span></div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-1/2 flex items-center">
            <div className="mx-auto">
              <div className="relative" style={{width: 500, height: 500}}>
                <div className="absolute inset-0 rounded-3xl w-full h-full drop-shadow-md -rotate-12" style={{backgroundColor: "#EA3BBA"}} />
                <div className="absolute inset-0 rounded-3xl w-full h-full drop-shadow-md rotate-6" style={{backgroundColor: `#484848`}} />
                <div className="absolute inset-0 bg-cover rounded-3xl w-full h-full drop-shadow-md -rotate-3" style={{backgroundImage: `url(${modyfiflyer})`}} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gradient-to-b from-white to-gray-100">
        <div className="overflow-hidden py-12 md:py-20 lg:py-32">
          <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="relative">
              <blockquote className="mt-1">
                <div className="mx-auto max-w-4xl text-center text-3xl font-medium leading-9 text-gray-600 relative">
                  <div className="opacity-10 absolute mt-24 -ml-20 font-heading font-normal h-32" style={{color: "#EA3BBA", fontSize: "400px", lineHeight:0}}>“</div>
                  <p>
                    &ldquo;I loved the Modyfi web graphics meetup and the community you’re building! It was great to see your new design tools in action.&rdquo;
                  </p>
                </div>
                <footer className="mt-8">
                  <div className="md:flex md:items-center md:justify-center">
                    <div className="md:flex-shrink-0">
                      <img
                        className="mx-auto h-10 w-10 rounded-full"
                        src={profilePictures.male[5]}
                        alt=""
                      />
                    </div>
                    <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                      <div className="text-base font-semibold text-black uppercase font-heading">Kyle J.</div>

                      <svg className="mx-1 hidden h-5 w-5 md:block" style={{color: "#EA3BBA"}} fill="currentColor" viewBox="0 0 20 20">
                        <path d="M11 0h3L9 20H6l5-20z" />
                      </svg>

                      <div className="text-base font-bold text-gray-600 font-heading uppercase">Graphics Engineer</div>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-transparent z-1">
        <div className="max-w-6xl mx-auto bg-blue drop-shadow-lg rounded-2xl p-20 text-center relative -mb-56 z-1">
          <div className="text-white font-bold font-heading text-5xl uppercase">READY TO UNLOCK the potential of YOUR DEVELOPER COMMUNITY?</div>
          <div className="text-blue-200 text-2xl font-semibold max-w-lg mx-auto my-7">Then lets talk about ways Equipt can help you build, grow, and retain your biggest asset.</div>
          <a href="https://calendly.com/hubert-dagbo/30min" target="_blank" className="rounded-none btn btn-secondary-white mx-auto w-96">Connect with us to learn more</a>
        </div>
      </section>

      <footer className="bg-blue-700 relative pt-56" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="grid grid-cols-3 gap-8">
            <div className="col-span-1">
              <EquiptLogo className="logo-white-orange h-12 w-auto" />
              <div className="text-blue-200 text-lg font-semibold mt-2">Open education for your open <br/> source communities</div>
              {/*<div className="flex justify-between text-white uppercase font-heading font-bold mt-8 text-sm">
                <span>Companies</span>
                <span>Members</span>
                <span>Partners</span>
              </div>*/}
            </div>
            <div className="col-start-3 col-span-1">
              {/*<h3 className="text-base font-medium text-white uppercase font-heading font-bold">Subscribe to our newsletter</h3>
              <p className="mt-4 text-blue-200 text-lg font-semibold">
                The latest news, articles, and resources, sent to your inbox weekly.
              </p>
              <form className="mt-4 sm:flex sm:max-w-md">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  type="email"
                  name="email-address"
                  id="email-address"
                  autoComplete="email"
                  required
                  className="w-full min-w-0 appearance-none rounded-l-full border border-transparent bg-white py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:border-white focus:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  placeholder="Enter your email"
                />
                <div className="mt-3 rounded-md sm:mt-0 -ml-5 sm:flex-shrink-0">

                  <button className="rounded-none btn btn-color bg-orange">Subscribe</button>
                </div>
              </form>*/}
            </div>
          </div>
          <div className="mt-8 border-t border-blue-300 pt-8 md:flex md:items-center md:justify-between">
            <div className="flex space-x-6 md:order-2">
              {navigation.social.map((item) => (
                <a key={item.name} href={item.href} className="text-blue-200 hover:text-blue-400">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
            <p className="mt-8 text-base text-blue-200 md:order-1 md:mt-0">
              &copy; 2022 Equipt, Inc. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
  </>
  )

}

export default LandingCommunity
