import React, {useCallback, useRef, useState} from 'react'
//import { Switch } from '@headlessui/react'
import SuccessModal from "../Elements/SuccessModal";
import { Formik, Form, Field, FormikHelpers } from "formik";
import * as Yup from "yup";
import {useUpdateProfile} from "../../data/client/user.api";
import ImageUploaderInput, { ImageUploaderHelpers } from "../Elements/ImageUploaderInput";


const EditProfileSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  last_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  tagline: Yup.string().max(150, 'Too Long!'),
  bio: Yup.string().max(1500, 'Too Long!'),
  image: Yup.mixed().nullable(),
  location: Yup.string(),
  social_linkedin: Yup.string(),
  social_discord: Yup.string()
});

interface Values {
  first_name: string,
  last_name: string,
  tagline: string
  bio: string
  image: string
  location: string
  social_linkedin: string
  social_discord: string
}
const parseInitialValues = (profile: Partial<Profile> = {}) => {
  const { first_name, last_name, tagline, bio, location, image, social_linkedin, social_discord} = profile
  return {
    first_name: first_name || "",
    last_name: last_name || "",
    tagline: tagline || "",
    bio: bio || "",
    image: getParseImageFromServer(image || ""),
    location: location || "",
    social_linkedin: social_linkedin || "",
    social_discord: social_discord || ""
  }
}

interface Props {
  user: User | undefined
  alert: AlertDialog
  refreshUser: (args?: any) => Promise<any>
}

const getParseImageFromServer = (path: string) =>{
  if(!path) return "";
  try{
    const url = new URL(path);
    return url.pathname.replace("/storage/v1/object/public/avatars/", "")
  } catch (e){
    return path
  }
}

const EditProfile: React.FC<Props> = ({ user, alert}) => {
  //const [availableToHire, setAvailableToHire] = useState(true)
  const [open, setOpen] = useState(false)
  const { mutate: mutateUpdateProfile } = useUpdateProfile()
  const handleSubmit = useCallback(async (values: Values, { setStatus } : FormikHelpers<Values>) => {
    setStatus({value: ''})
    try{
      if(user?.profile?.id){
        await mutateUpdateProfile({ profile: { id: user?.profile?.id, ...values }, user})
        await alert({ buttonText: 'Ok', title: 'Profile Updated', description: "The changes to you profile are saved"})
      }
    } catch(e: any){
      setStatus({value: 'submit_error',  message: e.message })
    }
  }, [user, mutateUpdateProfile])

  const uploaderRef = useRef<ImageUploaderHelpers>();

  return (
    <Formik
      initialValues={parseInitialValues(user?.profile)}
      onSubmit={handleSubmit}
      onReset={() => {
        uploaderRef.current?.reset()
      }}
      validationSchema={EditProfileSchema}
    >
      {({ status, resetForm }) => (
        <>
          <SuccessModal open={open} setOpen={setOpen} message="Profile Updated!" />
          <Form className="bg-gradient-to-r from-gray-50 to-white" action="#" method="POST">
            {/* Profile section */}
            <div className="py-6 px-4 sm:p-12 lg:pb-8">
              <div>
                <h2 className="text-3xl leading-10 font-semibold text-blue-500">Profile</h2>
                <p className="mt-1 text-sm text-gray-500">
                  This information will be displayed publicly so be careful what you share.
                </p>
              </div>

              <div className="mt-6 flex flex-col lg:flex-row">
                <div className="flex-grow space-y-6">

                  <div className="col-span-12 sm:col-span-6">
                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                      First name
                    </label>
                    <Field
                      type="text"
                      name="first_name"
                      id="first_name"
                      autoComplete="given-name"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-orange-300 focus:border-orange-300 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                      Last name
                    </label>
                    <Field
                      type="text"
                      name="last_name"
                      id="last_name"
                      autoComplete="family-name"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-orange-300 focus:border-orange-300 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="mt-6 flex-grow lg:mt-0 lg:ml-6 lg:flex-grow-0 lg:flex-shrink-0">
                  <p className="text-sm font-medium text-gray-700 text-center" aria-hidden="true">
                    Profile Picture
                  </p>
                  <div className=" relative rounded-full overflow-hidden mx-auto w-40 h-40">
                    <ImageUploaderInput imagePreviewHeight={170} imageCropAspectRatio={'1:1'}
                      ref={uploaderRef} layout="compact circle" initialValue={getParseImageFromServer(user?.profile?.image || "")} bucket="avatars" id="image" name="image" />
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-12 gap-6">

                <div className="col-span-12 sm:col-span-12">
                  <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                    Tagline
                  </label>
                  <Field
                    type="text"
                    name="tagline"
                    id="tagline"
                    autoComplete="tagline"
                    className="mt-1 focus:ring-orange-300 focus:border-orange-300 flex-grow block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                  />
                </div>

                <div className="col-span-12 sm:col-span-12">
                  <label htmlFor="bio" className="block text-sm font-medium text-gray-700">
                    About
                  </label>
                  <div className="mt-1">
                      <Field as="textarea"
                        id="bio"
                        name="bio"
                        rows={3}
                        className="shadow-sm focus:ring-orange-300 focus:border-orange-300 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                      />
                  </div>
                  <p className="mt-2 text-sm text-gray-500">
                    Brief description for your profile.
                  </p>
                </div>

                {/*<div className="col-span-12 sm:col-span-12">
                  <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                    Current City
                  </label>
                  <Field
                    type="text"
                    name="location"
                    id="location"
                    autoComplete="location"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-orange-300 focus:border-orange-300 sm:text-sm"
                  />
                </div>*/}

                {/*<div className="col-span-12 sm:col-span-6">
                  <label htmlFor="url" className="block text-sm font-medium text-gray-700">
                    LinkedIn
                  </label>
                  <div className="relative mt-0 rounded-md">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gray-400 sm:text-sm">https://www.linkedin.com/in/</span>
                    </div>
                    <Field
                      type="text"
                      name="social_linkedin"
                      id="social_linkedin"
                      //style={{paddingLeft: "11.5rem"}}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 placeholder:text-gray-400 focus:outline-none focus:ring-orange-300 focus:border-orange-300 sm:text-sm"
                    />
                  </div>
                </div>*/}

                {/*<div className="col-span-12 sm:col-span-6">
                  <label htmlFor="social_discord" className="block text-sm font-medium text-gray-700">
                    Discord Username
                  </label>
                  <div className="relative mt-0 rounded-md">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gray-400 sm:text-sm">

                        https://discord.com/users/</span>
                    </div>
                    <Field
                      type="text"
                      name="social_discord"
                      id="social_discord"
                      //style={{paddingLeft: "11.5rem"}}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 placeholder:text-gray-400 focus:outline-none focus:ring-orange-300 focus:border-orange-300 sm:text-sm"
                    />
                  </div>
                </div>*/}


              </div>
            </div>

            {/* Privacy section */}
            {/*<div className="pt-6 divide-y divide-gray-200">
              <div className="px-4 sm:px-12">
                <div>
                  <h2 className="text-xl leading-6 text-gray-900">Privacy</h2>
                  <p className="mt-1 text-sm text-gray-500">
                    Manage your privacy and visibility on Equipt.
                  </p>
                </div>
                <ul className="mt-2 divide-y divide-gray-200">
                  <Switch.Group as="li" className="py-4 flex items-center justify-between">
                    <div className="flex flex-col">
                      <Switch.Label as="p" className="text-sm font-medium text-gray-900" passive>
                        Available to hire
                      </Switch.Label>
                      <Switch.Description className="text-sm text-gray-500">
                        Let potential employers know that you are ready for the next step.
                      </Switch.Description>
                    </div>
                    <Switch
                      checked={availableToHire}
                      onChange={setAvailableToHire}
                      className={classNames(
                        availableToHire ? 'bg-blue-500' : 'bg-gray-200',
                        'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-300'
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          availableToHire ? 'translate-x-5' : 'translate-x-0',
                          'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                </ul>
              </div>
            </div>*/}
            <span className="text-sm w-full block flex justify-center items-center text-red-500 italic input-error" >
              {status?.value === 'submit_error' && status?.message ? status.message : ''}
            </span>
            <div className="mt-4 py-4 px-4 flex justify-end sm:px-6 border-t border-gray-200">
              <button
                type="button"
                onClick={() => {resetForm() }}
                className="btn btn-primary-white-border"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="ml-5 btn btn-primary"
              >
                Save
              </button>
            </div>
          </Form>
        </>
      )}
    </Formik>
)
}
export default EditProfile
