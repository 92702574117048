import spokenLayerLogo from "./assets/img/company-spokenLayer.png";
import shopifyLogo from "./assets/img/company-shopify.png";
import accentureLogo from "./assets/img/company-accenture.png";

export function getUser(){
  return {
    first_name: 'Chelsea',
    last_name: 'Hagon',
    name: 'Chelsea Hagon',
    email: 'chelseahagon@example.com',
    headline: 'Student at Northwestern',
    imageUrl:
      'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  }
}
export function getUserApplications(){
  return [
    {
      id: 1,
      title: 'Account Manager',
      status: 'pending',
      company: 'Microsoft',
      submitDate: '2020-01-07',
      submitDateFull: 'January 7, 2022',
    },
    {
      id: 2,
      title: 'Sales Manager',
      status: 'offer received',
      company: 'Paypal',
      submitDate: '2020-01-07',
      submitDateFull: 'January 7, 2022',
    },
    {
      id: 3,
      title: 'Sales Operations Lead',
      status: 'rejected',
      company: 'Facebook',
      submitDate: '2020-01-14',
      submitDateFull: 'January 14, 2022',
    },
  ]
}
export function getAllCourses(){
  const courses = [
    {
      id: '1',
      title: 'The Power Of Sound',
      tagline: 'Sound has power',
      description: 'You will gain a better and more methodological approach to delivering innovative and disruptive technology products and services. This class is perfect for those interested in learning more about delivering innovation.',
      course_img: 'https://images.unsplash.com/photo-1567596275753-92607c3ce1ae?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=4740&q=80',
      attendees: [
        {
          img : "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
        },
        {
          img: "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
        },
        {
          img: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
        },
        {
          img: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
        }
      ],
      attended: 100,
      teacher: {
        name: 'Leslie Abbott',
        title: 'Voice Talent',
        img:
          'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        company: 'SpokenLayer',
        slug: ''
      },
      course_slug: 'the-power-of-sound',
      company: {
        name: 'SpokenLayer',
        slug: 'spoken-layer',
        logo: spokenLayerLogo,
        theme: {
          title_font: '',
          body_font: '',
          img_bg_color_to: "rgba(77, 77, 77, .43)",
          img_bg_color_from: "rgba(77, 77, 77, .83)",
          img_bg_color_opacity: '1',
          top_bg_color_image: '',
          img_bg_color_darkness: '.4',
          logo: '',
          hired_count_bg_color: '#FF00AE',
          hired_count_text_color: '',
          cta_bg_color: '',
          cta_text_color: '',
          bottom_bg_color: '',
          menu_text_color: '',
          menu_tracker_color: '',
          dropdown_label_color: '',
          dropdown_bg_color: '',
          dropdown_outline_color: '',
          course_card_label_color: '',
          course_card_button_bg_color: '#00C1F3',
          course_card_button_font_color: '#000000',
          course_card_button_outline_color: '',
          course_card_outline_color: '',
          course_card_shadow: '',
        }
      }
    }
  ]

  courses.push(
    {
      ...courses[0],
      id: '2',
      title: 'International Leadership for Women',
      course_img: 'https://images.unsplash.com/photo-1573165706511-3ffde6ef1fe3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2938&q=80',
      teacher: {
        ...courses[0].teacher,
        title: 'Leadership Coach',
        company: 'Equipt',
        slug: ''
      },
      attended: 53,
      company: {
        name: 'Shopify',
        slug: 'shopify',
        logo: shopifyLogo,
        theme: {
          title_font: '',
          body_font: '',
          img_bg_color_to: "rgba(0, 47, 37, .54)",
          img_bg_color_from: "rgba(0, 47, 37, .86)",
          img_bg_color_opacity: '1',
          top_bg_color_image: '',
          img_bg_color_darkness: '.4',
          logo: '',
          hired_count_bg_color: '#008060',
          hired_count_text_color: '',
          cta_bg_color: '',
          cta_text_color: '',
          bottom_bg_color: '',
          menu_text_color: '',
          menu_tracker_color: '',
          dropdown_label_color: '',
          dropdown_bg_color: '',
          dropdown_outline_color: '',
          course_card_label_color: '',
          course_card_button_bg_color: '#008060',
          course_card_button_font_color: '#FFFFFF',
          course_card_button_outline_color: '',
          course_card_outline_color: '',
          course_card_shadow: '',
        }
      }

    }
  )

  courses.push(
    {
      ...courses[0],
      id: '3',
      title: 'Version Control: Git Best Practices',
      course_img: 'https://images.unsplash.com/photo-1594904351111-a072f80b1a71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1635&q=80',
      teacher: {
        ...courses[0].teacher,
        title: 'Software Developer',
        company: 'Accenture',
        slug: ''
      },
      attended: 53,
      company: {
        name: 'Accenture',
        slug: 'accenture',
        logo: accentureLogo,
        theme: {
          title_font: '',
          body_font: '',
          img_bg_color_to: "rgba(0, 47, 37, .54)",
          img_bg_color_from: "rgba(0, 77, 255, 1)",
          img_bg_color_opacity: '1',
          top_bg_color_image: '',
          img_bg_color_darkness: '.4',
          logo: '',
          hired_count_bg_color: '#A100FF',
          hired_count_text_color: '',
          cta_bg_color: '',
          cta_text_color: '',
          bottom_bg_color: '',
          menu_text_color: '',
          menu_tracker_color: '',
          dropdown_label_color: '',
          dropdown_bg_color: '',
          dropdown_outline_color: '',
          course_card_label_color: '',
          course_card_button_bg_color: '#A100FF',
          course_card_button_font_color: '#FFFFFF',
          course_card_button_outline_color: '',
          course_card_outline_color: '',
          course_card_shadow: '',
        }
      }

    }
  )

  return courses
}
export function getUpcomingSessions(){
  return [
    {
      id: '2',
      title: 'Project Management with the Agile Mindset',
      date: new Intl.DateTimeFormat(undefined, { dateStyle: 'long', timeStyle: 'medium', hour12: false }).format(new Date('January 7 2020 18:00:00')),
      slug: 'master-the-code',
      company: {
        title: "Equipt"
      },
      teacher: {
        name: "Jide Osanyingbemi",
        company: "Equipt"
      }
    },
    {
      id: '1',
      title: 'Career Hacking: Successfully Navigating Non-Diverse Industries',
      date: new Intl.DateTimeFormat(undefined, { dateStyle: 'long', timeStyle: 'medium', hour12: false }).format(new Date()) ,
      slug: 'career-hacking',
      company: {
        title: "Equipt"
      },
      teacher: {
        name: "Mark Omari",
        company: "Equipt"
      }
    },
    {
      id: 3,
      title: 'Lean UX Design: From Deciphering the Problem Space to Ideation',
      date: new Intl.DateTimeFormat(undefined, { dateStyle: 'long', timeStyle: 'medium', hour12: false }).format(new Date(new Date().getTime() + (1 * 24 * 60 * 60 * 1000))),

      slug: 'lean-ux-design',
      company: {
        title: "Equipt"
      },
      teacher: {
        name: "Stephanie Lin",
        company: "Elsiver"
      }
    },
  ]
}
