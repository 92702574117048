import React, {useState, useEffect, useMemo} from 'react'
import {
  useLocation,
  useParams,
} from "react-router-dom";
import {
  ChatBubbleLeftEllipsisIcon,
  DocumentChartBarIcon,
  PencilSquareIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'
import {IoStarHalfSharp, IoStarSharp} from 'react-icons/io5'
import InstructorDetails from "../components/InstructorDetails";
import Footer from "../components/Footer";
import odiPhoto from './../assets/img/odi.png'
import {parseCourse, parseInstructor, parseSession, parseUser} from "../data/client/factories";
import { parseTheme } from "../data/client/factories.custom";
import CourseRegistration from "../components/Course/CourseRegistration";
import EquiptCTA from "../components/EquiptCTA";
import CourseHeader from "../components/Course/CourseHeader";
import { defaultTheme, dellTheme, lehighTheme, modyfiTheme, cubsTheme } from "../helpers/defaults";
import { useGetCourse} from "../data/client/course.api";
//import CompanyAbout from "../components/Course/CompanyAbout";
import { Helmet } from 'react-helmet-async'
import {getMembershipTypeName} from "../helpers";
import RecentEvents from "../components/RecentEvents";

const features = [
  {
    name: 'Personal Story',
    description: 'The instructor will share a personal story giving you close up insights about what actually goes on in the workplace.',
    icon: ChatBubbleLeftEllipsisIcon,
  },
  {
    name: 'Collaborative Exercise',
    description: 'Engage with your peers to gain a deeper understanding of the material.',
    icon: UsersIcon,
  },
  {
    name: 'Practical Skill',
    description: 'At the end of this class you will walk away with a skill that you can start using right away',
    icon: PencilSquareIcon,
  },
  {
    name: 'Takeaway Resources',
    description: 'Be prepared for anything with the documents, templates, or diagrams you receive at the end of the course',
    icon: DocumentChartBarIcon,
  },
]

interface Props {
  user: User
  setUser: () => any //React.Dispatch<React.SetStateAction<User>>
  refreshUser: (args?: any) => Promise<any>
  alert: AlertDialog
  confirm: AlertDialog
  registrations: Registration[]
  refetchRegistration: () => any
}

const Course: React.FC<Props> = ({ user, setUser, refreshUser, alert, confirm, registrations, refetchRegistration}) => {
  const { course_slug } = useParams<{course_slug: string}>();
  const { data, isLoading: loading } = useGetCourse({ slug: course_slug})
  const [course, setCourse ] = useState<Course>(parseCourse( {}))
  const [sessions, setSessions ] = useState<Session[]>([])
  const [selectedSession, setSelectedSession ] = useState<Session>(parseSession( {}))
  const [theme, setTheme] = useState<Theme>(parseTheme( {}))
  const [instructors, setInstructors] = useState<Instructor[]>([])
  const location = useLocation()

  const isAdmin = useMemo(() => {
    return !!user?.memberships.find((membership) => ( membership.organization == course.organization?.id && getMembershipTypeName(membership.membership_type) == "ADMIN"))
  }, [user, course])

  useEffect(() => {
    if(!loading && data) {
      let courseToSet = data || {}
      let defaultSession: Session | undefined = undefined;
      let sessionsToSet: Session[] = []
      let instructorsToSet: Instructor[] = []

      courseToSet.course_modules.forEach((course_module) => {
        course_module.sessions.forEach((session) => {
          session.instructors.forEach((instructor) => {
            instructorsToSet.push(parseInstructor({ ...instructor, user: parseUser({ ...instructor.user }) }))
          })
          sessionsToSet.push(session);
          if(!defaultSession || new Date(session.starts_at) > new Date() && new Date(session.starts_at) < new Date(defaultSession.starts_at)){
            defaultSession = session
          }
        })
      })

      setCourse(courseToSet)
      setSessions(sessionsToSet)
      setSelectedSession(defaultSession || sessionsToSet[0] || parseSession({}))
      setInstructors(instructorsToSet)

      if(courseToSet.organization?.id){
        if(courseToSet.organization.name === 'Dell') setTheme(parseTheme(dellTheme))
        if(courseToSet.organization.name === 'Lehigh University') setTheme(parseTheme(lehighTheme))
        if(courseToSet.organization.name === 'Modyfi') setTheme(parseTheme(modyfiTheme))
        if(courseToSet.organization.name === 'The Chicago Cubs') setTheme(parseTheme(cubsTheme))
        setTheme(parseTheme(defaultTheme))
      } else {
        setTheme(parseTheme(defaultTheme))
      }
    }
  }, [loading, data])

  if(!course.id) return <div className="relative bg-gradient-to-r from-blue-500 to-blue-900 overflow-hidden h-screen"/>

  return (
    <>
      <Helmet>
        <title>{course.title}</title>
        <meta property="og:image" content={course.seo_image || course.cover} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={course.title}/>
        <meta property="og:description" content={course.description} />
        <meta property="og:url" content={location.pathname} />
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <CourseHeader user={user} isAdmin={isAdmin} course={course} theme={theme} session={selectedSession} sessions={sessions} />
      <InstructorDetails instructors={instructors} secondaryColor={theme.secondary_color} primaryColor={theme.primary_color} />
      {/*<CompanyAbout organization={course.organization} theme={theme} />*/}

      <div className="bg-gray-100">
        <div
          //style={{backgroundColor: theme.course_sessions}}
          className="pb-48 bg-blue-500">
          <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:pb-8 lg:max-w-7xl lg:pt-24 lg:px-8">
            <h2 className="text-3xl font-extrabold text-white tracking-tight opacity-80">Attend A Session</h2>
            <p
              className="mt-4 max-w-3xl text-lg text-white opacity-50">
              A {course.course_modules[0].module.duration || 60}-minute session where you get a chance to interact directly with experts, thought leaders, and individuals with unique experiences. It's a dynamic and engaging forum where you can ask any question that's been on your mind and gain insights that you won't find anywhere else.
            </p>
            <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name}>
                  <div>
                <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white/10">
                  <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
                  </div>
                  <div className="mt-6">
                    <h3 className="text-lg font-medium text-white">{feature.name}</h3>
                    <p className="mt-2 text-base text-white opacity-50">{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div title="company cta" className="bg-blue-500 px-5 mt-28">
              <div className="max-w-7xl mx-auto bg-blue-700 drop-shadow-lg rounded-2xl p-10 md:p-20 text-center relative z-1 grid grid-cols-4 gap-x-16 flex">
                <div className="leading-none text-white font-bold font-heading text-4xl uppercase text-center lg:text-left col-span-2 flex justify-center items-center">Do you have questions for our speakers?</div>
                <div className="col-span-2">
                  <div className="text-blue-100 mx-auto content_body lg:mb-2 my-5">Fill out this survey before the workshop so that you can submit your questions and get the most out of the event</div>
                  <a href={selectedSession.pre_survey_link} target="_blank" className="rounded-none btn btn-secondary-white mx-auto max-w-md w-full">Submit A Question</a>
                </div>
              </div>
            </div>

          </div>
        </div>
        <CourseRegistration {...{ course, theme, alert, confirm, registrations, user, setUser, isAdmin, refreshUser, refetchRegistration}} />
      </div>

      <div className="py-12 bg-gradient-to-b from-gray-100 to-gray-200 overflow-hidden md:py-24 lg:py-36">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <svg
            className="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
            width={404}
            height={404}
            fill="none"
            viewBox="0 0 404 404"
            role="img"
            aria-labelledby="svg-workcation"
          >
            <defs>
              <pattern
                id="ad119f34-7694-4c31-947f-5c9d249b21f3"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={404} fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)" />
          </svg>

          <div className="relative">
            <blockquote>
              <div className="max-w-3xl mx-auto text-center">
                <span className="text-gray-400 mb-2 font-semibold uppercase tracking-wider"> Top Rated Workshops from Top Rated Experts</span>
                <h2 className="text-gray-400 font-extrabold tracking-tight sm:text-4xl font-button uppercase">Excellent Rating on Trustpilot</h2>
                <div className="flex justify-center text-gray-300 mt-5 mb-10">
                  <IoStarSharp className="h-20 w-20 flex-shrink-0 mx-2" />
                  <IoStarSharp className="h-20 w-20 flex-shrink-0 mx-2" />
                  <IoStarSharp className="h-20 w-20 flex-shrink-0 mx-2" />
                  <IoStarSharp className="h-20 w-20 flex-shrink-0 mx-2" />
                  <IoStarHalfSharp className="h-20 w-20 flex-shrink-0 mx-2" />
                </div>
                <p className="text-2xl leading-9 font-semibold text-gray-500">
                  &ldquo;The main takeaway is the value of telling your own story.&rdquo;
                </p>
              </div>
              <footer className="mt-2">
                <div className="md:flex md:items-center md:justify-center">
                  <div className="md:flex-shrink-0">
                    <img
                      className="mx-auto h-10 w-10 rounded-full"
                      src={odiPhoto}
                      alt=""
                    />
                  </div>
                  <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                    <div className="text-base font-medium text-gray-700">Odilon N.</div>

                    <svg className="hidden md:block mx-1 h-5 w-5 text-orange" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M11 0h3L9 20H6l5-20z" />
                    </svg>

                    <div className="text-base font-medium text-gray-500 font-semibold">Student, Project Engineer</div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
      <RecentEvents />
      <EquiptCTA />
      <Footer className="bg-white text-black w-full"/>
  </>
  )

}

export default Course
