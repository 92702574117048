import {DependencyList, useCallback, useEffect, useRef} from 'react'

// See: https://usehooks-ts.com/react-hook/use-isomorphic-layout-effect

function useCallbackWithTimeout(callback: (...args: any) => any, deps: DependencyList, delay: number | null) {
  // use ref to store the timer id
  const refTimer = useRef<number | null>(null);

// trigger the timer
  const delayedCallback = useCallback((...args: any) => {
    if (refTimer.current !== null) return;
    refTimer.current = window.setTimeout(() => {
      console.log("LETS GO")
      callback(...args);
    }, delay || 0);
  }, [refTimer, callback]);


// stop the timer
  const stopDelayedCallback = () => {
    if (refTimer.current === null) return;
    window.clearTimeout(refTimer.current);
    refTimer.current = null;
  };

// Cleanup function to clear the timer when the component unmounts
  useEffect(() => {
    // cleanup function
    return () => {
      if (refTimer.current !== null) {
        console.log('HELLO')
        window.clearTimeout(refTimer.current);
      }
    };
  }, []);

  return [delayedCallback, stopDelayedCallback]
}

export default useCallbackWithTimeout
