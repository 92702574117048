import {FieldAttributes} from "formik";
import React, {useState} from "react";
import {EyeIcon, EyeSlashIcon} from "@heroicons/react/24/solid";
import AuthInputField from "./AuthInputField";

interface AuthFieldProps extends FieldAttributes<any> {
  id: string
  name: string
  placeholder: string
  containerClassName?: string
  children?: JSX.Element
}

const AuthPasswordField: React.FC<AuthFieldProps> = ({ ...fieldProps}) => {
  const [showPassword, setShowPassword] = useState(false);
  return(
    <AuthInputField {...fieldProps} type={showPassword ? "text" : "password"}>
      <div className="absolute top-0 right-0 px-3 py-2 z-10 text-gray-400 hover:text-gray-500" onClick={() => setShowPassword((value) => !value)}>
        {showPassword ?
          <EyeSlashIcon className="h-5 w-5" aria-hidden="true" /> :
          <EyeIcon className="h-5 w-5" aria-hidden="true" />
        }
      </div>
    </AuthInputField>
  )
}

export default AuthPasswordField
