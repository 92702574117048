/* This example requires Tailwind CSS v2.0+ */
import React, {Fragment} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import {classNames} from "../../helpers";

interface Props {
  modal: AlertModal
  setModal: React.Dispatch<React.SetStateAction<AlertModal>>
  onConfirm: (args: any) => any
  onClose: (args: any) => any
}
// title, description, buttonText

const DialogModal: React.FC<Props> = ({ modal, onClose, onConfirm }) => {
  return (
    <Transition.Root show={modal.open} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={() => {}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500/75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
              <div>
                <div className={
                  classNames(
                    "mx-auto flex items-center justify-center h-12 w-12 rounded-full",
                    modal.type === 'danger' ? "bg-red-100" : "bg-green-100"
                  )
                }>
                  {modal.type === 'danger' ?
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    :<CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  }
                </div>

                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    {modal.title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className="text-sm text-gray-500">
                      {modal.description}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 flex md:flex-row flex-col gap-y-2">
                <button
                  type="button"
                  style={{flexBasis: '50%'}}
                  className={
                    classNames(
                      "text-center flex-grow mx-1 font-bold font-button text-sm text-white uppercase px-6 py-3 flex justify-center border border-transparent rounded-full shadow-sm  outline-none focus:outline-none focus:ring-2 focus:ring-offset-2 ",
                    modal.type === 'danger' ? "bg-red-500 hover:bg-red-600 focus:ring-red-300"
                    : "bg-green-500 hover:bg-green-600 focus:ring-green-300"
                    )
                  }
                  onClick={onConfirm}
                >
                  {modal.buttonText}
                </button>
                {modal.isConfirm ?
                  <button
                    type="button"
                    style={{flexBasis: '50%'}}
                    className={
                      classNames(
                        "text-center flex-grow mx-1 font-bold font-button text-sm text-white uppercase px-6 py-3 flex justify-center border border-transparent rounded-full shadow-sm  outline-none focus:outline-none focus:ring-2 focus:ring-offset-2 ",
                          "bg-gray-500 hover:bg-gray-600 focus:ring-gray-300"
                      )
                    }
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                :''}

              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default DialogModal
