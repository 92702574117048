/* This example requires Tailwind CSS v2.0+ */
import {useParams} from "react-router-dom";
import {organizations} from "../../data/fakeData";
import React from "react";
import {classNames} from "../../helpers";


const content: { heading: string, image: string, body: string }[] = [
  {
    heading: 'Meet the founders',
    image: "https://spaces.joinequipt.com/clearflame_founders.jpg",
    body: 'Diverse experiences, ideas, and thought leadership are the guiding principles of ClearFlame’s founders, Drs. Julie Blumreiter and BJ Johnson. Born through their years of research at Stanford University and Argonne National Laboratory, this black and woman-founded company is on a mission to create a scalable, equitable solution to addressing climate change that is available to everyone.'
  },
  {
    heading: 'People and Culture',
    image: "https://spaces.joinequipt.com/clearflame_people.jpg",
    body: 'At ClearFlame it’s all about the team. We succeed and fail together. We have each other’s back and focus on the power of the ensemble over the power of the individual. We champion diversity. With a 25% female workforce, we encourage and look for diverse professionals to bring their experiences and skills to ClearFlame. Solving the world’s problems will require a diverse range of ideas, and we must acknowledge that those ideas will come from a diverse range of thought-leaders.'
  },
  {
    heading: 'Values',
    image: "https://spaces.joinequipt.com/clearflame_values.jpg",
    body: 'We embrace courage over comfort, and welcome the unknown. We are committed fully to the challenges we are solving, while taking personal ownership to drive solutions. We share openly and understand that candor and constructive dissent are critical to execution. We hold each other accountable, while giving feedback respectfully. We have big ambitions that require big solutions, but we make time to focus on the little things because we know they make a big difference.'
  },
  {
    heading: 'Product Impact',
    image: "https://spaces.joinequipt.com/clearflame_impact.jpg",
    body: 'We fundamentally believe that everyone on this planet deserves access to energy, so we want to deploy a solution for others who don’t have access or capital for electrical solutions. Our goal is to help solve the world\'s sustainability issues in an equitable  way by decarbonizing engines. From fleets and long-haul trucking to agriculture, power generation, construction, and other off road diesel sectors we’re driving down fossil fuels usage while simultaneously increasing energy access.'
  },
  {
    heading: 'Investment In You',
    image: "https://spaces.joinequipt.com/clearflame_investment.jpg",
    body: 'You can expect to learn from experienced and innovative professionals with decades of experience at leading automotive companies or large scale engine manufacturers. We believe that you can drive solutions from any seat and we empower each other to bring new ideas to the table regardless of role. We want to learn from you while allowing you to find mentorship here as you build an impactful career at ClearFlame.'
  },
]


export default function OrganizationWhy() {
  const { organization_id } = useParams<{organization_id: string}>();
  const organization = organizations.find(({slug}) => organization_id === slug);

  if(!organization){
    return <div className="relative overflow-hidden h-screen"/>
  }

  return (
    <div className="mx-12 pb-28">
      <div className={classNames(
        "bg-white overflow-hidden sm:rounded-md w-11/12 mx-auto",
        organization.theme.course_card_shadow ? "shadow-md": ""
      )}>
        {content.map(({ heading, body, image }, index) => (
          <div className={classNames("w-full flex mt-36", index % 2 === 0 ? 'flex-row-reverse' : 'flex-row')}>
            <div className="bg-gray-500 rounded-xl h-56 w-96 flex-shrink-0 mx-5 bg-cover bg-center" style={{ backgroundImage: `url(${image})`}} />
            <div className="flex flex-col justify-center px-5">
              <div className="uppercase font-bold text-4xl border-b-2" style={{borderColor: organization.theme.menu_tracker_color}}>{heading}</div>
              <div className="mt-8">{body}</div>
            </div>
          </div>
        ))}

      </div>
    </div>
  )
}
