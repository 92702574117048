import React, { useState, ChangeEvent, FormEvent, useCallback } from 'react'
import {AiOutlineLoading3Quarters} from "react-icons/ai";
const encode = (data: {[key: string]: any}) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

interface Props {
  actionText: string
  course?: string
}


const PreRegisterFrom: React.FC<Props> = ({ actionText, course}) => {
  const [formValues, setFormValues] = useState(
    {
      email: '',
    })

  const [status, setStatus] = useState('')

  const handleSubmit =  useCallback((e: FormEvent<HTMLFormElement>) => {
    if(status === ''){
      setStatus('loading')
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": 'pre-register', ...formValues, course: course || '' })
      })
        .then(() => {
          setFormValues({ email: ''})
          setTimeout(() => setStatus('submitted'), 2000)
          setTimeout(() => setStatus(''), 5000)
        })
        .catch(error => alert(error));
    }
    e.preventDefault();
  }, [ status, setStatus, course, formValues, setFormValues])

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) =>
    setFormValues((prev) => ({...prev, [e.target.name]: e.target.value }))
    , [setFormValues]);

  return (
    <>
      <form className={`flex flex-wrap md:flex-nowrap md:shadow-center md:rounded-3xl md:bg-white max-w-xl mx-auto md:mx-0`} onSubmit={handleSubmit}>
        <input type="hidden" name="form-name" value="pre-register"/>
        <input
          type="email" value={formValues.email}
          name="email" disabled={!!status} onChange={handleChange}
          className="appearance-none border-0 p-3 md:p-0 text-center md:text-left md:px-0 shadow-center md:shadow-none rounded-3xl md:rounded-none w-full md:w-auto flex-grow md:ml-6 mb-6 md:mb-0 font-semibold placeholder-gray-400 text-gray-700 focus:outline-none focus:shadow-outline"
          placeholder="Email"
          style={{ transition: "all .15s ease" }}
        />
        <button
          style={{ transition: "all .15s ease" }}
          className={`${status === 'loading' ? 'pointer-events-none bg-blue-300 py-4': 'bg-blue'} h-12 shadow-center md:shadow-none w-full md:w-auto font-bold font-button text-white rounded-3xl px-6 py-3 outline-none focus:outline-none active:bg-bg-blue-300 uppercase`}
        >{status === 'loading' ? <AiOutlineLoading3Quarters className="animate-spin" /> : actionText}</button>
      </form>
      <div className={`flex flex-col my-2.5 items-center h-7`}>
        <span style={{ transition: "all .15s ease" }} className={`text-xl uppercase text-orange font-bold pt-1 ${status !== 'submitted' ? 'opacity-0' : 'opacity-100'}`}>Great! We will be in touch.</span>
      </div>
    </>
  )
}


export default PreRegisterFrom
