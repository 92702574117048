import React, { useCallback } from "react";
import {classNames, UserIsAuthenticated} from "../../helpers";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from 'yup';
import AuthPasswordField from "./AuthPasswordField";
import { useNavigate, useParams } from "react-router-dom";
import {supabase} from "../../api_supabase";

const ResetSchema = Yup.object().shape({
  password:Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
});

interface Values {
  password: string
}

interface Props {
  alert: AlertDialog
  user: User
  refreshUser: (args?: any) => Promise<any>
}

const ResetPassword: React.FC<Props> = ({ alert, user, refreshUser }) => {
  const navigate = useNavigate()
  const params = useParams()

  const handleSubmit =  useCallback(async (values: Values, { setStatus, setSubmitting } : FormikHelpers<Values>) => {
    setStatus('')
    try {
      const { error: updatePasswordError } = await supabase.auth.updateUser({ password: values.password })
      if(updatePasswordError) throw updatePasswordError;
      if(UserIsAuthenticated(user)){
        await refreshUser()
        alert({ buttonText: 'Ok', title: 'You are all set!', description: "We've updated your password"})
        setTimeout(() => navigate('/dashboard'), 100)
      } else {
        alert({ buttonText: 'Ok', title: 'Password Reset', description: "Login to start using your new password"})
        setTimeout(() => navigate('/login'), 100)
      }

    } catch (e){
      setStatus('submit_error')
      setSubmitting(false)
      alert({ buttonText: 'Ok', title: 'Invalid Link', type: 'danger',  description: "This link is not valid. Please request a new link to reset your password"})
      setTimeout(() => navigate('/forgot'), 100)
    }
  }, [alert, navigate, params.code, refreshUser, user])

  return (
    <Formik initialValues={{
      password: '',
    }} onSubmit={handleSubmit} validationSchema={ResetSchema}>
      {({ isSubmitting, status }) => (
        <>
          <div className="sm:mx-auto mb-6">
            <h2 className="text-center text-gray-400 text-4xl">{ user.has_password ? "Reset Your Password" : "Create Your Password"}</h2>
            <h6 className="text-center text-sm uppercase mt-4 py-2 font-semibold text-orange-500">Enter in your new password.</h6>
          </div>
          <Form className="gap-0 gap-x-2 grid grid-cols-12" action="#" method="POST">
            <AuthPasswordField name="password" id="register_password" placeholder="Password"
                               autoComplete="new-password" className="input input-secondary"/>
            <div className="col-span-12 mb-6 mt-4">
              <button
                type="submit"
                className={
                  classNames(
                    "animate__animated animate__fast",
                    isSubmitting ? "pointer-events-none opacity-50" :"",
                    status === "submit_error" ? 'animate__shakeX': '',
                    "text-center font-bold font-button text-sm text-white uppercase px-6 py-3 w-full flex justify-center border border-transparent rounded-full shadow-sm bg-orange-500 hover:bg-orange-600 outline-none focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-300"
                  )}
              >
                Set Password
              </button>
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default ResetPassword
