import "balance-text"
import 'animate.css';
import React, {useEffect, useState} from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom"
import { useLazyQuery } from "@apollo/client";
import {GET_USER_CONFIRMATIONS, useFetchAppUser} from "./data/client/authentication.api";
import { AnimatedRoutes, withAnimation } from "./helpers/animated.pages";

import Navbar from "./components/Navigation/Navbar"

/* Authentication*/
import Authentication from "./pages/Authentication";
import Login from "./components/Authentication/Login";
import Logout from "./components/Authentication/Logout";
import Forgot from "./components/Authentication/Forgot";
import RequireAuth from "./components/Authentication/RequireAuth";
import ResetPassword from "./components/Authentication/ResetPassword";
//import RequestInvite from "./components/Authentication/RequestInvite";

import VideoModal from "./components/VideoModal";


import Dashboard from "./pages/Dashboard";
import Organization from "./pages/Organization"

import Settings from "./pages/Settings";
import EditProfile from "./components/Settings/EditProfile"
import EditOrganizations from "./components/Settings/EditOrganizations";
import EditAccount from "./components/Settings/EditAccount";
import EditPassword from "./components/Settings/EditPassword";
import EditAdmin from "./components/Settings/EditAdmin";

import JobsBoard from "./components/Organization/JobsBoard";
import OrganizationWhy from "./components/Organization/OrganizationWhy";
import OrganizationCourses from "./components/Organization/OrganizationCourses";

//import Admin from "./pages/Admin"
import Course from './pages/Course'
//import LandingPage from "./pages/LandingPage";
import LandingHome from "./pages/Landing/Home";
//import LandingNavbar from "./components/Navigation/LandingNavbar";
import Legal from "./pages/Legal";
import Launch from "./pages/Launch";
import LandingCommunity from "./pages/LandingCommunity";
import LandingInitiative from "./pages/Landing/Initiative";

import './App.css';

import { parseUser } from "./data/client/factories";
//import Register from "./components/Authentication/Register";
import Confirm from "./components/Authentication/Confirm";
//import {GET_MY_REGISTRATIONS_QUERY} from "./data/client/registration.api";
import useAlert from "./hooks/useDialog";
import DialogModal from "./hooks/useDialog/DialogModal";
//import VerifyModal from "./components/Modal/VerifyModal";
import Register from "./components/Authentication/Register";

const App: React.FC = () => {
  //const { data: userData, loading: userLoading, error: userError, called: userRequested, refetch: refetchAppUser } = useQuery(GET_AUTHENTICATED_USER)
  //const [ fetchRegistrations, { refetch: refetchRegistration = () => {}, data: { authenticatedUserRegistrations: registrationData } = { authenticatedUserRegistrations: [] } }] = useLazyQuery<{ authenticatedUserRegistrations: Registration[]}>(GET_MY_REGISTRATIONS_QUERY)
  const [,{ refetch: refreshConfirmations = () => {}, data: { confirmations } = { confirmations: [] } }] = useLazyQuery<{ confirmations: Confirmation[]}>(GET_USER_CONFIRMATIONS)
  const [showVideo, setShowVideo] = useState('')
  const [, setShowModal] = useState('');
  //const [currentPath, setCurrentPath] = useState('')
  const { data: appUser = parseUser({}), isSuccess, refresh: refetchAppUser } = useFetchAppUser()
  const { confirm, alert, ...alertProps  } = useAlert();

  useEffect(() => {
    if(isSuccess){
      if(appUser.id){
        Intercom('update', {
          email: appUser.email,
          name: `${appUser.first_name} ${appUser.last_name}`,
          user_id: appUser.id,
          user_hash: appUser.intercom_hash
        });
      } else {
        Intercom("shutdown");
        Intercom("boot", {
          api_base: process.env.REACT_APP_INTERCOM_API,
          app_id: process.env.REACT_APP_INTERCOM_ID
        });

      }
    }
  }, [appUser.id, isSuccess])

  /*const refetchAppUser = useCallback(async () => {
    const { data: { session }, error: sessionError } = await supabase.auth.getSession();
    const { data: { user: sessionUser}} = await supabase.auth.getUser();
    if(session && sessionUser){
      const { data, error } =
        await supabase
          .from('profiles')
          .select('id, bio, first_name, last_name, image, is_public, location, slug, tagline, user ( id, email, accepted_terms, stripe_customer_id, subscription_level, created ) ')
          .eq('user', session.user.id);
      if(error) return supabase.auth.signOut();
      const { user, ...profile } = (data||[])[0];
      setAppUser(parseUser({
        ...sessionUser,
        ...user,
        confirmed: new Date(sessionUser.email_confirmed_at || 0).getTime() > 0,
        profile: parseProfile({...profile})
      }))
    } else {
      setAppUser(parseUser({}))
    }
  }, [])

  useEffect(() => {
    refetchAppUser();
  },[])*/

  /*useEffect(() => {
    if(!userLoading && (userData || userError)) {
      setAppUser(parseUser(userData?.authenticatedUser || { id: 'unauthenticated'}))
    }
  }, [ userData, setAppUser, userLoading, userError])

  useEffect(() => {
    if(appUser.id !== 'unauthenticated'){
      //fetchRegistrations({ variables: { student: appUser.id }})
      //fetchConfirmations({ variables: { user: appUser.id }})
    }
  }, [appUser, fetchRegistrations, fetchConfirmations])

  if(!userRequested || userLoading || appUser.id === '') return <div className="min-h-full"/>
*/
  return (
    <div className="min-h-full relative">
      <Router>
        <Routes>
          <Route path="*" element={<Navbar user={appUser} refreshUser={refetchAppUser}  alert={alert} showModal={setShowModal} refreshConfirmations={refreshConfirmations} confirmations={confirmations} />} />
        </Routes>
        <AnimatedRoutes location={location} >
          <Route path="dashboard" element={withAnimation(<RequireAuth user={appUser}><Dashboard user={appUser} onShowVideo={setShowVideo} registrations={[]}  /></RequireAuth>)} />

          <Route path="/course/:course_slug" element={withAnimation(<Course user={appUser} setUser={() => {}} refreshUser={refetchAppUser} alert={alert} confirm={confirm} registrations={[]} refetchRegistration={() => {}} />)} />
          <Route path="/settings/*" element={withAnimation(<RequireAuth user={appUser}><Settings /></RequireAuth>)}>
            <Route index element={<EditProfile user={appUser} refreshUser={refetchAppUser} alert={alert} />} />
            <Route path="organizations" element={<EditOrganizations user={appUser} confirm={confirm} alert={alert}/>} />
            <Route path="account" element={<EditAccount/>} />
            <Route path="password" element={<EditPassword/>} />
            <Route path="admin" element={<EditAdmin/>} />
          </Route>

          <Route path="/organization/:organization_id/*" element={withAnimation(<Organization user={appUser} />)}>
            <Route path="jobs" element={<JobsBoard />} />
            <Route index element={<OrganizationCourses user={appUser}/>} />
            <Route path="why" element={<OrganizationWhy/>} />
          </Route>
          <Route path="instructor/:instructor_id" />
          <Route element={withAnimation(<Authentication user={appUser}/>)} key="auth" >
            <Route path="login" element={<Login user={appUser} refreshUser={refetchAppUser}/>}/>
            <Route path="register" element={<Register user={appUser} refreshUser={refetchAppUser} alert={alert}/>}/>
            <Route path="register/create-profile" element={<Register user={appUser} refreshUser={refetchAppUser} alert={alert}/>}/>
            <Route path="forgot" element={<Forgot alert={alert} />}/>
            <Route path="reset" element={<ResetPassword alert={alert} user={appUser} refreshUser={refetchAppUser} />}/>
            <Route path="confirm" element={<RequireAuth user={appUser}><Confirm user={appUser} refreshUser={refetchAppUser} alert={alert} /></RequireAuth>}/>
          </Route>
          <Route path="privacy" element={<Legal/>} />
          <Route path="cookie" element={<Legal/>} />
          <Route path="terms" element={<Legal/>} />
          <Route path="launch" element={<Launch user={appUser} alert={alert} />} />
          <Route path="/community" element={<LandingCommunity user={appUser} alert={alert} />} />
          <Route path="/equipt-for-success" element={<LandingInitiative user={appUser} alert={alert} />} />
          <Route path="/" element={<LandingHome />} />
          <Route path="/logout" element={<RequireAuth user={appUser}><Logout refreshUser={refetchAppUser} user={appUser} /></RequireAuth>} />
        </AnimatedRoutes>
        <VideoModal showVideo={showVideo} onClose={() => setShowVideo('')}/>
        <DialogModal {...alertProps} />
        {/*<VerifyModal user={appUser} usage={"phone"} show={showModal} setShow={setShowModal} alert={alert}  refreshConfirmations={refreshConfirmations} />*/}
      </Router>
    </div>
    );
}

export default App;
