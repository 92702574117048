import {ArrowRightOnRectangleIcon, PlusIcon} from '@heroicons/react/24/outline'
import React, {useCallback, useState} from "react";
import {classNames, membershipIsApproved} from "../../helpers";
import AddOrganization from "./AddOrganization";
import {useDeleteMemberships, useFetchMemberships} from "../../data/client/membership.api";
import OrganizationBadge from "../Elements/OrganizationLabel";
import { Link } from 'react-router-dom'

interface Props {
  confirm: AlertDialog
  alert: AlertDialog
  user: User | undefined
}

const EditOrganizations: React.FC<Props> = ({ user, confirm, alert }) => {
  const [ organizationToAdd, setOrganizationToAdd ] = useState<Organization | undefined>()
  const { data: userMemberships = [] } = useFetchMemberships({ user_id: user?.id || ''});
  const { mutate: mutateDeleteMembership } = useDeleteMemberships();
  const onDeleteMembership = useCallback(async (membership: Membership) => {
    try{
      await confirm({
        buttonText: "Leave Organization",
        type: "danger",
        title:`Are you sure you want to ${membershipIsApproved(membership) ? "leave" : "cancel your membership request for" } ${membership.organization.name}?`,
        description: membership.accepted ? "You will have to be approved by the Admin of this organization again" : "You can request to join again if you change your mind"
      })

      await mutateDeleteMembership({ id: membership.id })
   } catch(e){

    }
  }, [mutateDeleteMembership])

  const [open, setOpen] = useState(false)
  if(!user) return (<div/>)
  return (
    <div className="bg-gradient-to-r from-gray-50 to-white">
      <AddOrganization  alert={alert} userMemberships={userMemberships} user={user} open={open} setOpen={setOpen} selectedOrganization={ organizationToAdd} setSelectedOrganization={setOrganizationToAdd} />
      {/* Profile section */}
      <div className="py-6 px-4 sm:p-12 lg:pb-8">
        <div>
          <h2 className="text-3xl leading-10 font-semibold text-blue-500">Organizations</h2>
          <p className="mt-1 text-sm text-gray-500">
            These are all of the organizations you are apart of.
          </p>
        </div>

        <div className="mt-7 flex flex-col">
          <ul className="grid xl:grid-cols-3 md:grid-cols-2 gap-6">
            <button
              type="button"
              onClick={() => setOpen(true)}
              className={classNames(
                "h-72 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
                userMemberships.length === 0 ? "col-span-3" : "col-span-1"
              )}
            >
              <div className="relative w-20 h-20 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={.5} stroke="currentColor" className="w-20 h-20  text-gray-400">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                </svg>
                <div className="rounded-full h-6 w-6 absolute -right-4 bottom-0"><PlusIcon className="p-.5 text-gray-400"/></div>
              </div>

              <span className="mt-1 block font-semibold text-gray-700">Join your a Organization</span>
              { userMemberships.length === 0 && <div className="text-xs">If you are part of a organization like a bootcamp, university, or professional organization, you can join them here.</div> }

            </button>
            {userMemberships.map((membership) => (
              <li key={membership.organization.id} className={
                classNames(
                  "h-72 col-span-1 flex flex-col bg-white rounded-lg shadow divide-y divide-gray-200",
              )}>

                <div className="w-full p-6 flex-grow relative">
                  {!membershipIsApproved(membership) && <div className="absolute bg-yellow-300 text-yellow-800 rounded-t-lg inset-x-0 top-0 font-semibold h-10 w-full flex items-center justify-center text-base text-center">Membership Pending Approval</div>}
                  <div className="mx-auto w-24 h-24 bg-contain bg-center bg-no-repeat flex-shrink-0" style={{backgroundImage: `url(${membership.organization.logo})`}} />
                  <div className="w-full text-center">
                    <h3 className="text-gray-900 text-lg font-semibold">{membership.organization.name}</h3>
                    <p className="mb-3 text-gray-500 text-xs">{membership.organization.location}</p>
                    <OrganizationBadge type={membership.organization.type} />
                  </div>
                </div>
                <div>
                  <div className="-mt-px flex divide-x divide-gray-200">
                    <div className="-ml-px w-0 flex-1 flex">
                      <button
                        onClick={() => {
                          onDeleteMembership(membership)
                        }}
                        className="transition ease-in-out duration-300 relative w-0 flex-1 inline-flex items-center justify-center py-2 text-red-600 font-semibold border border-transparent rounded-bl-lg hover:text-white hover:bg-red-500 "
                      >
                        <ArrowRightOnRectangleIcon className="w-5 h-5 text-red-300" aria-hidden="true" />
                        <span className="ml-1 text-sm">Leave</span>
                      </button>

                    </div>
                    <div className="-ml-px w-0 flex-1 flex">
                      <Link to={`/organization/${membership.organization.slug}`}
                        className="transition ease-in-out duration-300 relative w-0 flex-1 inline-flex items-center justify-center py-2 text-blue-600 font-semibold border border-transparent rounded-br-lg hover:text-white hover:bg-blue-500 "
                      >
                        <ArrowRightOnRectangleIcon className="w-5 h-5 text-blue-300" aria-hidden="true" />
                        <span className="ml-1 text-sm">Visit Page</span>
                      </Link>

                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default EditOrganizations

