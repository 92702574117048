/* This example requires Tailwind CSS v2.0+ */
import { CalendarIcon, MapPinIcon, UsersIcon } from '@heroicons/react/24/solid'
import { ArrowTopRightOnSquareIcon} from '@heroicons/react/24/outline'
import {useParams} from "react-router-dom";
import {organizations} from "../../data/fakeData";
import React, {useState} from "react";
import { Dropdown, Option} from "../Elements/Dropdown";
import {classNames} from "../../helpers";
import {useGetOrganization} from "../../data/client/organization.api";

interface Position {
    id: number
    title: string
    type: string,
    location: string,
    department: string,
    closeDate: string,
    closeDateFull: string,
    skills: string[],
    link?: string
}


interface Options {
  value: string
  name: string
}

const newPosition= (data: { title?: string, department?: string, location?: string, skills?: string[], link?: string }): Position  => ({
    id: 1,
    title: 'Back End Developer',
    type: 'Full-time',
    location: 'Remote',
    department: 'Engineering',
    closeDate: '2023-03-01',
    closeDateFull: 'March 1, 2023',
    skills: [],
    ...data
  })

const positions: { slug: string, positions: Position[] }[] = [
  {
    slug: 'cubs',
    positions: [
      newPosition({title: 'Manager, Evaluation and Impact', department: 'Cub Charities'}),
      newPosition({title: 'Senior Director', department: 'Communications'}),
      newPosition({title: 'Executive Assistant', department: ' Sales & Marketing'}),
      newPosition({title: 'Activation Specialist', department: 'Corporate Partnerships'}),
      newPosition({title: 'Account Manager', department: 'Corporate Partnerships'}),
      newPosition({title: 'Assistant Director', department: 'Guest Services'}),
      newPosition({title: 'Analyst', department: 'Database Marketing'}),
      newPosition({title: 'Front End Developer, Senior', department: 'Engineering'}),
      newPosition({title: 'User Interface Designer', department: 'Design'}),
      newPosition({title: 'User Interface Designer', department: 'Design'}),
      newPosition({title: 'User Experience Designer', department: 'Design'}),
      newPosition({title: 'Scrum Master', department: 'Engineering'}),
      newPosition({title: 'Project Manager', department: 'Product'}),
    ],
  },
  {
    slug: 'soarce_consulting',
    positions: [
      newPosition({title: 'Shopify Software Engineer, Tech Lead', skills: ['React', 'Laravel', 'AWS', 'Shopify'], department: 'Engineering', link: "https://forms.gle/JPkgASom7vkJifzG7"}),
      newPosition({title: 'Technical Support, B2B SaaS', skills: ['B2B', 'SaaS', 'Support'], location: 'Dublin or London', department: 'Support', link: "https://forms.gle/JPkgASom7vkJifzG7"}),
      newPosition({title: 'Quality Assurance Engineer, Junior', skills: ['B2B', 'SaaS'], location: 'Remote, Canada', department: 'Engineering', link: "https://forms.gle/JPkgASom7vkJifzG7"}),
      newPosition({title: 'Customer Success Manager, Senior', skills: ['Cyber Security'], location: 'Remote, US or Canada', department: 'Sales', link: "https://forms.gle/JPkgASom7vkJifzG7"}),
      newPosition({title: 'Technical Product Manager, Cyber Security', skills: ['Cyber Security'], department: 'Product', link: "https://forms.gle/JPkgASom7vkJifzG7"}),
    ],
  },
  {
    slug: 'clearflame',
    positions: [
      newPosition({title: 'Diesel Mechanic – Class 8', department: 'Engineering', location: 'Geneva, IL', skills: ["Mechanical Engineering"], link: "https://641n0rjz0gd.typeform.com/to/pcIjvpgm"}),
      newPosition({title: 'Diesel Mechanic – Class 8', department: 'Engineering', location: 'Columbus, IN', skills: ["Mechanical Engineering"], link: "https://641n0rjz0gd.typeform.com/to/pcIjvpgm"}),
      newPosition({title: 'Controls Engineer', department: 'Engineering', location: 'Columbus, IN', skills: ["Mechanical Engineering"], link: "https://641n0rjz0gd.typeform.com/to/pcIjvpgm"}),
      newPosition({title: 'Engine Performance Calibration Engineer', department: 'Engineering', location: 'Columbus, IN', skills: ["Mechanical Engineering"], link: "https://641n0rjz0gd.typeform.com/to/pcIjvpgm"}),
      newPosition({title: 'CDL Driver ', department: 'Engineering', location: 'Geneva, IL', skills: ["Mechanical Engineering"], link: "https://641n0rjz0gd.typeform.com/to/pcIjvpgm"}),
      newPosition({title: 'Controls Engineer – Entry Level', department: 'Engineering', location: 'Geneva, IL', skills: ["Mechanical Engineering"], link: "https://641n0rjz0gd.typeform.com/to/pcIjvpgm"}),
      newPosition({title: 'Controls Engineer – Entry Level', department: 'Engineering', location: 'Columbus, IN', skills: ["Mechanical Engineering"], link: "https://641n0rjz0gd.typeform.com/to/pcIjvpgm"}),
      newPosition({title: 'Mechanical Engineer', department: 'Engineering', location: 'Geneva, IL', skills: ["Mechanical Engineering"], link: "https://641n0rjz0gd.typeform.com/to/pcIjvpgm"}),
      newPosition({title: 'Design Engineer ', department: 'Engineering', location: 'Geneva, IL', skills: ["Mechanical Engineering"], link: "https://641n0rjz0gd.typeform.com/to/pcIjvpgm"}),
    ]
  }
];

function getJobsOptions(attr: string, positions: Position[]): Options[] {
  const options = new Set<string>();
  switch (attr){
    case "location":
      positions.forEach(({ location }) => { options.add((location))});
      break;
    case "department":
      positions.forEach(({ department }) => { options.add((department))});
      break;
    case "skills":
      positions.forEach(({ skills }) => { skills.forEach(( skill ) => {
        options.add((skill))
      })});
      break;
    default: break;
  }
  return Array.from(options).map((option) => ({ name: option, value: option }))
}

export default function JobsBoard() {
  const { organization_id } = useParams<{organization_id: string}>();
  const { data: organization } = organization_id == 'clearflame' ?
    { data: organizations.find(({slug}) => organization_id === slug)}
    : useGetOrganization({ slug: organization_id })
  const organizationPositions = positions.find(({slug}) => organization_id === slug)?.positions || []
  const [selectedSkill, setSelectedSkill] = useState<Option>({name: "", value: undefined})
  const [selectedDepartment, setSelectedDepartment] = useState<Option>({name: "", value: undefined})
  const [selectedLocation, setSelectedLocation] = useState<Option>({name: "", value: undefined})


  if(!organization){
    return <div className="relative overflow-hidden h-screen"/>
  }

  const dropdownStyles = {
    backgroundColor: organization.theme.dropdown_bg_color,
    labelColor: organization.theme.dropdown_label_color,
    borderColor: organization.theme.dropdown_outline_color
  }
  return (
    <div className="mx-12 pb-28">
      <div className="grid gap-8 grid-cols-3 w-11/12 mx-auto items-center py-10">
        <Dropdown selected={selectedSkill} setSelected={setSelectedSkill} id="job-skills" options={getJobsOptions("skills", organizationPositions)} labelText="Skill" {...dropdownStyles}  />
        <Dropdown selected={selectedDepartment} setSelected={setSelectedDepartment} options={getJobsOptions("department", organizationPositions)} labelText="Department" {...dropdownStyles}/>
        <Dropdown selected={selectedLocation} setSelected={setSelectedLocation} options={getJobsOptions("location", organizationPositions)} labelText="Location" {...dropdownStyles}/>
      </div>
      <div className={classNames(
        "bg-white overflow-hidden sm:rounded-md w-11/12 mx-auto",
        organization.theme.course_card_shadow ? "shadow-md": ""
      )}>

        <ul className={classNames(
          organization.theme.table_divider_color ? "divide-inherit divide-y-2": "divide-gray-200 divide-y"
          )} style={{borderColor: organization.theme.table_divider_color}}>
          {organizationPositions.map((position) => (
            <li key={position.id}>
              <div className="block">
                <div className="px-4 py-4 sm:px-6 text-gray-500"
                     style={{color: organization.theme.table_font_color || "inherit"}}>
                  <div className="flex items-center justify-between">
                    <p className="text-sm font-medium truncate">{position.title}</p>
                    <div className="ml-2 flex-shrink-0 flex">
                      <a href={position.link} target="_blank" rel="noreferrer" style={{
                        color: organization.theme.course_card_button_font_color,
                        backgroundColor: organization.theme.course_card_button_bg_color
                      }}
                        className="job-apply-btn flex items-center px-0 relative">Apply on site <ArrowTopRightOnSquareIcon className="h-5 right-1 absolute"/></a>
                    </div>
                  </div>
                  <div className="mt-2 sm:flex sm:justify-between">
                    <div className="sm:flex">
                      <p className="flex items-center text-sm">
                        <UsersIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        {position.department}
                      </p>
                      <p className="mt-2 flex items-center text-sm sm:mt-0 sm:ml-6">
                        <MapPinIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        {position.location}
                      </p>
                    </div>
                    <div className="mt-2 flex items-center text-sm sm:mt-0 text-gray-400">
                      <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <p>
                        Closing on <time dateTime={position.closeDate}>{position.closeDateFull}</time>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
