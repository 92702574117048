import {parseCourse, parseCourseModule, parseOrganization, parseSession, parseUser} from "./client/factories"
import spokenLayerLogo from "../assets/img/company-spokenLayer.png";
import shopifyLogo from "../assets/img/company-shopify.png";
import accentureLogo from "../assets/img/company-accenture.png";
import damian from "../assets/img/daimen.png";
import microsoft_bg from "../assets/img/microsoft_bg.png";
import shopify_bg from '../assets/img/shopify_bg.png';
import cubsLogo from '../assets/img/company-cubs.svg'

const profilePictures = {
  female: [
    'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    'https://images.unsplash.com/photo-1506980595904-70325b7fdd90?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    'https://images.unsplash.com/photo-1505840717430-882ce147ef2d?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',

    'https://images.unsplash.com/photo-1532417344469-368f9ae6d187?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    'https://images.unsplash.com/photo-1611432579699-484f7990b127?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920',
    'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    'https://images.unsplash.com/photo-1602795361100-78bd8eb6c2a2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=3&w=256&q=80',
    'https://images.unsplash.com/photo-1548142813-c348350df52b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=4&w=256&q=80'


  ],
  male: [
    'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    'https://images.unsplash.com/photo-1517070208541-6ddc4d3efbcb?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    'https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',

    'https://images.unsplash.com/photo-1566492031773-4f4e44671857?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    'https://images.unsplash.com/photo-1513910367299-bce8d8a0ebf6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    'https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    'https://images.unsplash.com/photo-1601576084861-5de423553c0f?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920',
    'https://images.unsplash.com/photo-1642736468880-89d8a6de3fbe?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920'
  ]
}

const teacherProfilePictures = {
  female: [
    "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  ],
  male: [
    "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
  ]
}

function newModel(data: object){
  return ({
    id: (Math.random() + 1).toString(36).substring(7),
    created: new Date("1//1/2022").toLocaleDateString(),
    updated: new Date("1//1/2022").toLocaleDateString(),
    ...data
  })
}

const user = parseUser(newModel({
    id: '100',
    bio: 'Damian is currently working as an account manager at Ebay.',
    first_name: 'Damian',
    image: damian,
    last_name: 'Jones',
    tagline: 'Account Manager at Ebay',
    memberships: [
      {
        membership_type: 'STUDENT',
        organization: {}
      }
    ]
  }));
/*
const students = [
  newModel({
    bio: 'Tyler is current working at a Google.',
    first_name: 'Tyler',
    image: profilePictures.male[0],
    last_name: 'Richardson',
    tagline: 'Student at the University of Chicago',
    memberships: [
      {
        membership_type: 'STUDENT',
        organization: {}
      }
    ]
  }),
  newModel({
    bio: 'Tola currently works a Amazon and is seeking new job opportunities',
    first_name: 'Tola',
    image: profilePictures.female[0],
    last_name: 'Akinyemi',
    tagline: 'User Experience Designer',
    memberships: [
      {
        membership_type: 'STUDENT',
        organization: {}
      }
    ]
  }),
  newModel({
    bio: 'Tyler is current working at a Google.',
    first_name: 'Jeremy',
    image: profilePictures.male[1],
    last_name: 'Woods',
    tagline: 'Senior Software Engineer',
    memberships: [
      {
        membership_type: 'STUDENT',
        organization: {}
      }
    ]
  }),
  newModel({
    bio: 'Janice is current working as a freelance Developer.',
    first_name: 'Janice',
    image: profilePictures.female[1],
    last_name: 'Davis',
    tagline: 'Senior Software Engineer',
    memberships: [
      {
        membership_type: 'STUDENT',
        organization: {}
      }
    ]
  }),
  newModel({
    bio: 'Tyler is current working for a fintech start up.',
    first_name: 'Issac',
    image: profilePictures.male[2],
    last_name: 'Reeves',
    tagline: 'Tech Sales Representative',
    memberships: [
      {
        membership_type: 'STUDENT',
        organization: {}
      }
    ]
  }),
  newModel({
    bio: 'Kibwe is current working for at Microsoft.',
    first_name: 'Kibwe',
    image: profilePictures.male[3],
    last_name: 'Zuberi',
    tagline: 'Junior Frontend Developer',
    memberships: [
      {
        membership_type: 'STUDENT',
        organization: {}
      }
    ]
  }),
  newModel({
    bio: 'Jalen is studying Computer Science',
    first_name: 'Jalen',
    image: profilePictures.male[4],
    last_name: 'Wolf',
    tagline: 'Student at Northwestern',
    memberships: [
      {
        membership_type: 'STUDENT',
        organization: {}
      }
    ]
  })
]
*/
const teachers: User[] = [
  parseUser(newModel({
    bio: 'Adele is current working at a Google.',
    first_name: 'Adele',
    image: profilePictures.female[2],
    last_name: 'Roy',
    title: 'User Interface Designer',
    slug: 'teacher-adele-roy',
    memberships: [
      {
        id: '100',
        created: new Date("1//1/2022").toLocaleDateString(),
        updated: new Date("1//1/2022").toLocaleDateString(),
        membership_type: 'TEACHER',
        organization: {
          name: "Accenture"
        }
      }
    ]
  })),
  parseUser(newModel({
    bio: 'Allison is leadership coach at Equipt.',
    first_name: 'Allison',
    image: teacherProfilePictures.female[0],
    last_name: 'Halley',
    title: 'Leadership Coach',
    slug: 'teacher-allison-halley',
    memberships: [
      {
        id: '100',
        created: new Date("1//1/2022").toLocaleDateString(),
        updated: new Date("1//1/2022").toLocaleDateString(),
        membership_type: 'TEACHER',
        organization: {
          name: "Equipt"
        }
      }
    ]
  })),
  parseUser(newModel({
    first_name: 'Leslie',
    last_name: 'Abbott',
    title: 'Voice Talent',
    image: teacherProfilePictures.female[1],
    memberships: [
      {
        id: '100',
        created: new Date("1//1/2022").toLocaleDateString(),
        updated: new Date("1//1/2022").toLocaleDateString(),
        membership_type: 'TEACHER',
        organization: {
          name: "SpokenLayer"
        }
      }
    ],
    slug: ''
  })),
  parseUser(newModel({
    first_name: 'Chris',
    last_name: 'Campbell',
    title: 'Software Engineer',
    image: profilePictures.male[1],
    memberships: [
      {
        id: '100',
        created: new Date("1//1/2022").toLocaleDateString(),
        updated: new Date("1//1/2022").toLocaleDateString(),
        membership_type: 'TEACHER',
        organization: {
          name: "Accenture"
        }
      }
    ],
    slug: ''
  })),
  parseUser(newModel({
    first_name: 'Mark',
    last_name: 'Omari',
    title: 'Leadership Coach',
    image: profilePictures.male[4],
    memberships: [
      {
        id: '100',
        created: new Date("1//1/2022").toLocaleDateString(),
        updated: new Date("1//1/2022").toLocaleDateString(),
        membership_type: 'TEACHER',
        organization: {
          name: "Equipt"
        }
      }
    ],
    slug: ''
  })),
  parseUser(newModel({
    first_name: 'Timothy',
    last_name: 'Gray',
    title: 'Project Manager',
    image: teacherProfilePictures.male[2],
    memberships: [
      {
        id: '100',
        created: new Date("1//1/2022").toLocaleDateString(),
        updated: new Date("1//1/2022").toLocaleDateString(),
        membership_type: 'TEACHER',
        organization: {
          name: "Equipt"
        }
      }
    ],
    slug: ''
  })),

]
// 0 - accenture, 1 -shopify, 2:spokenlayer, 3:cubs
const organizations = [
  parseOrganization(newModel({
    admin: parseUser(newModel(teachers[0])),
    benefits: '',
    bio: '',
    hired: 74,
    logo_alt: '',
    tagline: '',
    values: '',
    name: 'Accenture',
    slug: 'accenture',
    logo: accentureLogo,
    theme: {
      title_font: '',
      body_font: '',
      img_bg_color_to: "rgba(0, 47, 37, .54)",
      img_bg_color_from: "rgba(0, 77, 255, 1)",
      img_bg_color_opacity: '1',
      top_bg_image: '',
      img_bg_color_darkness: '.4',
      logo: '',
      hired_count_bg_color: '#A100FF',
      hired_count_text_color: '',
      cta_bg_color: '',
      cta_text_color: '',
      bottom_bg_color: '',
      menu_text_color: '',
      menu_tracker_color: '',
      dropdown_label_color: '',
      dropdown_bg_color: '',
      dropdown_outline_color: '',
      course_card_label_color: '',
      course_card_button_bg_color: '#A100FF',
      course_card_button_font_color: '#FFFFFF',
      course_card_button_outline_color: '',
      course_card_outline_color: '',
      course_card_shadow: '',
    }
  })),
  parseOrganization(newModel({
    admin: parseUser(newModel(teachers[0])),
    benefits: '',
    bio: '',
    hired: 49,
    logo_alt: '',
    name: 'Shopify',
    tagline: '',
    values: '',
    slug: 'shopify',
    logo: shopifyLogo,
    theme: {
      title_font: '',
      body_font: '',
      img_bg_color_to: "rgba(0, 47, 37, .54)",
      img_bg_color_from: "rgba(0, 47, 37, .86)",
      img_bg_color_opacity: '1',
      top_bg_image: shopify_bg,
      img_bg_color_darkness: '.4',
      logo: '',
      hired_count_bg_color: '#008060',
      hired_count_text_color: '#FFFFFF',
      cta_bg_color: '#00825E',
      cta_text_color: '',
      bottom_bg_color: '#FBF7ED',
      menu_text_color: '',
      menu_tracker_color: '',
      dropdown_label_color: '#004E3F',
      dropdown_bg_color: '',
      dropdown_outline_color: '#004E3F',
      course_card_label_color: '#6B7177',
      course_card_button_bg_color: '#008060',
      course_card_button_font_color: '#FFFFFF',
      course_card_button_outline_color: '',
      course_card_outline_color: '',
      course_card_shadow: '',
    }
  })),
  parseOrganization(newModel({
    name: 'SpokenLayer',
    slug: 'spoken-layer',
    logo: spokenLayerLogo,
    theme: {
      title_font: '',
      body_font: '',
      img_bg_color_to: "rgba(77, 77, 77, .43)",
      img_bg_color_from: "rgba(77, 77, 77, .83)",
      img_bg_color_opacity: '1',
      top_bg_image: '',
      img_bg_color_darkness: '.4',
      logo: '',
      hired_count_bg_color: '#FF00AE',
      hired_count_text_color: '',
      cta_bg_color: '',
      cta_text_color: '',
      bottom_bg_color: '',
      menu_text_color: '',
      menu_tracker_color: '',
      dropdown_label_color: '',
      dropdown_bg_color: '',
      dropdown_outline_color: '',
      course_card_label_color: '',
      course_card_button_bg_color: '#00C1F3',
      course_card_button_font_color: '#000000',
      course_card_button_outline_color: '',
      course_card_outline_color: '',
      course_card_shadow: '',
    },
    admin: parseUser(newModel(teachers[0])),
    benefits: '',
    bio: '',
    hired: 18,
    logo_alt: '',
    tagline: '',
    values: '',
  })),
  parseOrganization(newModel({
    admin: parseUser(newModel(teachers[0])),
    benefits: '',
    bio: "The Chicago Cubs franchise, a charter member of Major League Baseball’s National League since 1876, has won the National League pennant 17 times and was the first team to win back-to-back World Series titles in the 1907 and 1908 seasons. In 2016, the Chicago Cubs made history again when the team won its first World Series in 108 years, ending the longest championship drought in North American sports.\n\n Known for its ivy covered outfield walls, hand-operated scoreboard and famous Marquee, iconic Wrigley Field has been the home of the Chicago Cubs since 1914, making it the second oldest ballpark in Major League Baseball.",
    hired: 25,
    logo_alt: '',
    name: 'The Chicago Cubs',
    tagline: 'Committed to diversity, equality, and inclusion',
    values: '',
    slug: 'the-chicago-cubs',
    logo: cubsLogo,
    theme: {
      title_font: '',
      body_font: '',
      img_bg_color_to: "rgba(14, 51, 134, .42)",
      img_bg_color_from: "rgba(14, 51, 134, .96)",
      img_bg_color_opacity: '1',
      top_bg_image: microsoft_bg,
      img_bg_color_darkness: '.1',
      logo: '',
      hired_count_bg_color: '#CC3433',
      hired_count_text_color: '#FFFFFF',
      cta_bg_color: '#CC3433',
      cta_text_color: '#FFFFFF',
      bottom_bg_color: '#F8F8FA',
      menu_text_color: '#0E3386',
      menu_tracker_color: '#798DBA',
      dropdown_label_color: '#0E3386',
      dropdown_bg_color: '#FFFFFF',
      dropdown_outline_color: '#0E3386',
      course_card_label_color: '#CC3433',
      course_card_button_bg_color: '#0E3386',
      course_card_button_font_color: '#FFFFFF',
      course_card_button_outline_color: '',
      course_card_outline_color: '',
      course_card_shadow: '',
    }
  })),
  parseOrganization(newModel({
    admin: parseUser(newModel(teachers[0])),
    benefits: '',
    bio: "Diesel engines are critical to world economies and quality of life. However, they are currently coupled to dirty diesel emissions. At ClearFlame Engine Technologies, we are breaking the bond between the diesel engine and diesel fuel, clearing the path to gigaton scale emissions reduction for hard-to-electrify applications, including heavy-duty trucking, power generation, and other off-highway markets. \n" +
      "\n" +
      "We are on a mission to free global economies from a need for petroleum-based fuels in heavy-duty applications. We need talented, passionate individuals to help make our vision a reality. In return, we provide comprehensive benefits and are committed to providing a positive team culture—supporting our team relentlessly and focusing on creating personal successes as well as organizational ones.  ",
    hired: 39,
    logo_alt: '',
    name: 'Clear Flame Engine Technologies',
    tagline: 'WE HAVE BIG AMBITIONS, BUT WE CAN’T DO IT ALONE.',
    values: '',
    slug: 'clearflame',
    logo: "https://equipt-dev.nyc3.cdn.digitaloceanspaces.com/ClearFlame-Logo.png",
    theme: {
      title_font: '',
      body_font: '',
      img_bg_color_to: "rgba(0, 0, 0, .54)",
      img_bg_color_from: "rgba(0, 0, 0, .86)",
      img_bg_color_opacity: '1',
      top_bg_image: "https://equipt-dev.nyc3.cdn.digitaloceanspaces.com/clearflame_cover.jpg",
      img_bg_color_darkness: '.1',
      logo: '',
      hired_count_bg_color: '#00A6FF',
      hired_count_text_color: '#FFFFFF',
      cta_bg_color: 'transparent',
      cta_outline_color: '#FFFFFF',
      cta_text_color: '#FFFFFF',
      bottom_bg_color: '#FFFFFF',
      menu_text_color: '#0A1236',
      menu_tracker_color: '#00A6FF',
      menu_border_color: '#0A1236',
      dropdown_label_color: '#0A1236',
      dropdown_bg_color: '#FFFFFF',
      dropdown_outline_color: '#0A1236',
      course_card_label_color: '#005CD4',
      course_card_button_bg_color: '#00A6FF',
      course_card_button_font_color: '#FFFFFF',
      course_card_button_outline_color: '',
      course_card_outline_color: '#0A1236',
      course_card_shadow: '',
      table_divider_color: '#0A1236',
      table_font_color: '#0A1236'
    }
  }))
]

function randomIntFromInterval(min: number, max: number) { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const getAttendees = (demo: number = 100):{image:string}[] => {
  let attendees = new Set<string>();

  function getImage(gender: 'male' | 'female', demo: number){
    if(gender === 'male'){
      return profilePictures.male[randomIntFromInterval(Math.round(demo/100*5)+1, Math.round(demo/100*5)+5)-1]
    }
    return profilePictures.female[randomIntFromInterval(Math.round(demo/100*5)+1, Math.round(demo/100*5)+5)-1]
  }

  while( attendees.size < 4)  {
    attendees.add(getImage(randomIntFromInterval(1,50) % 2 == 0 ? 'male' : 'female', demo))
  }

  return Array.from(attendees).map(( image ) => ({ image }))
}

const equiptCourses: Course[] = [
  parseCourse(newModel({
    title: 'Making Meetings Matter',
    tagline: 'Storying tell at its finest',
    description: 'You will gain a better and more methodological approach to delivering innovative and disruptive technology products and services. This class is perfect for those interested in learning more about delivering innovation.',
    cover: 'https://images.unsplash.com/photo-1515378960530-7c0da6231fb1?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920',
    attendees: getAttendees(),
    attended: 100,
    slug: 'the-magic-of-storytelling',
    course_modules: [newModel({
      available: true,
      course: { id: '' },
      instructor: {
        ...teachers[4],
        image: profilePictures.male[0],
        first_name: 'Kevin',
        last_name: 'Richardson'
      },
      module: { title: "The Power Of Sound" },
      order: 1,
      sessions: []
    })]
  })),
  parseCourse(newModel({
    title: 'The Agile Mindset',
    cover: "https://source.unsplash.com/zoCDWPuiRuA/1920x1280",
    tagline: "A proven methodology for developing innovative products and services.",
    description: 'You will gain a better and more methodological approach to delivering innovative and disruptive technology products and services. This class is perfect for those interested in learning more about delivering innovation.',
    attendees: getAttendees(),
    attended: 100,
    course_slug: 'chicago-cubs-presents-the-agile-mindset',
    company: organizations[4],
    course_modules: [newModel({
      available: true,
      course: { id: '' },
      instructor: teachers[0],
      module: { title: "The Agile Mindset" },
      order: 1,
      sessions: []
    })]
  })),
  parseCourse(newModel({
    title: 'International Leadership for Women',
    cover: 'https://images.unsplash.com/photo-1573165706511-3ffde6ef1fe3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2938&q=80',
    tagline: 'Sound has power',
    description: 'You will gain a better and more methodological approach to delivering innovative and disruptive technology products and services. This class is perfect for those interested in learning more about delivering innovation.',
    attendees: getAttendees(),
    attended: 100,
    slug: 'international-leadership-for-women',
    course_modules: [newModel({
      available: true,
      course: { id: '' },
      instructor: teachers[1],
      module: { title: "International Leadership for Women" },
      order: 1,
      sessions: []
    })]
  })),
  parseCourse(newModel({
    title: 'Master The Code',
    cover: 'https://images.unsplash.com/photo-1594904351111-a072f80b1a71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1635&q=80',
    tagline: 'Sound has power',
    description: 'You will gain a better and more methodological approach to delivering innovative and disruptive technology products and services. This class is perfect for those interested in learning more about delivering innovation.',
    attendees: getAttendees(),
    attended: 100,
    slug: 'version-control-git-best-practices',
    course_modules: [newModel({
      available: true,
      course: { id: '' },
      instructor: {
        ...teachers[3],
        memberships: [
          {
            id: '100',
            created: new Date("1//1/2022").toLocaleDateString(),
            updated: new Date("1//1/2022").toLocaleDateString(),
            membership_type: 'TEACHER',
            organization: {
              name: 'Equipt'
            }
          }
        ]

      },
      module: { title: "Version Control: Git Best Practices" },
      order: 1,
      sessions: []
    })]
  })),
  parseCourse(newModel({
    title: 'Recognizing & Responding to Bias',
    tagline: 'The art and science of closing the opportunity',
    description: 'Learn the art and science of closing the opportunity. That exchange builds momentum that will eventually steer you towards an opportunity to close. You have first cultivated the client opportunity and have made a connection. True sales dialogues are not linear, they are a choreographed, give and take of information.',
    cover: 'https://images.unsplash.com/photo-1521791136064-7986c2920216?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920',
    attendees: getAttendees(),
    attended: 100,
    slug: 'chicago-cubs-presents-negotiate-with-influence',
    highlights: [
      "How can I recognize my own strengths and challenges when consulting with clients?",
      "How should I use listening and questioning techniques to uncover needs",
      "How do I follow a consultative process and structure when interacting with client"
    ],
    course_modules: [newModel({
      available: true,
      course: { id: '' },
      instructor: parseUser(newModel({
        bio: 'Mark is current working at a ',
        first_name: 'Steven',
        image: profilePictures.male[3],
        last_name: 'Thomas',
        title: 'Inclusion Specialist',
        slug: 'teacher-ben-bowden',
        memberships: [
          newModel({
            membership_type: 'TEACHER',
            organization: {
              name: 'Equipt'
            }
          })
        ]
      })),
      module: { title: "Negotiate with Influence" },
      order: 1,
      sessions:[]
    })]
  })),
  parseCourse(newModel({
    title: 'Lean UX: Deciphering the Problem Space to Ideation',
    tagline: 'The art and science of closing the opportunity',
    description: 'Learn the art and science of closing the opportunity. That exchange builds momentum that will eventually steer you towards an opportunity to close. You have first cultivated the client opportunity and have made a connection. True sales dialogues are not linear, they are a choreographed, give and take of information.',
    cover: 'https://images.unsplash.com/photo-1586717791821-3f44a563fa4c?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920',
    attendees: getAttendees(),
    attended: 100,
    slug: 'chicago-cubs-presents-negotiate-with-influence',
    highlights: [
      "How can I recognize my own strengths and challenges when consulting with clients?",
      "How should I use listening and questioning techniques to uncover needs",
      "How do I follow a consultative process and structure when interacting with client"
    ],
    course_modules: [newModel({
      available: true,
      course: { id: '' },
      instructor: parseUser(newModel({
        bio: 'Mark is current working at a ',
        first_name: 'Tola',
        image: profilePictures.female[0],
        last_name: 'Akinyemi',
        title: 'User Experience Designer',
        slug: 'teacher-ben-bowden',
        memberships: [
          newModel({
            membership_type: 'TEACHER',
            organization: {
              name: 'IDEO'
            }
          })
        ]
      })),
      module: { title: "Negotiate with Influence" },
      order: 1,
      sessions:[]
    })]
  })),
]



const oldCourses: Course[] = [
  parseCourse(newModel({
    title: 'The Power Of Sound',
    tagline: 'Sound has power',
    description: 'You will gain a better and more methodological approach to delivering innovative and disruptive technology products and services. This class is perfect for those interested in learning more about delivering innovation.',
    cover: 'https://images.unsplash.com/photo-1567596275753-92607c3ce1ae?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=4740&q=80',
    attendees: getAttendees(),
    attended: 100,
    slug: 'spoken-layer-presents-the-power-of-sound-by',
    company: organizations[2],
    course_modules: [newModel({
      available: true,
      course: { id: '' },
      instructor: teachers[2],
      module: { title: "The Power Of Sound" },
      order: 1,
      sessions: []
    })]
  })),
  parseCourse(newModel({
    title: 'Ruby on Rails: Best Practices',
    cover: 'https://images.unsplash.com/photo-1594904351111-a072f80b1a71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1635&q=80',
    tagline: 'Sound has power',
    description: 'You will gain a better and more methodological approach to delivering innovative and disruptive technology products and services. This class is perfect for those interested in learning more about delivering innovation.',
    attendees: getAttendees(),
    attended: 100,
    slug: 'accenture-presents-version-control-git-best-practices',
    company: organizations[1],
    course_modules: [newModel({
      available: true,
      course: { id: '' },
      instructor: {
        ...teachers[5],
        title: 'Senior Software Developer',
        memberships: [
          {
            id: '100',
            created: new Date("1//1/2022").toLocaleDateString(),
            updated: new Date("1//1/2022").toLocaleDateString(),
            membership_type: 'TEACHER',
            organization: {
              name: 'Shopify'
            }
          }
        ]
      },
      module: { title: "Ruby on Rails: Best Practices" },
      order: 1,
      sessions: []
    })]
  })),

  parseCourse(newModel({
    title: 'Making Meetings Matter',
    tagline: 'Storying tell at its finest',
    description: 'You will gain a better and more methodological approach to delivering innovative and disruptive technology products and services. This class is perfect for those interested in learning more about delivering innovation.',
    cover: 'https://images.unsplash.com/photo-1515378960530-7c0da6231fb1?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920',
    attendees: getAttendees(),
    attended: 100,
    slug: 'the-magic-of-storytelling',
    course_modules: [newModel({
      available: true,
      course: { id: '' },
      instructor: teachers[4],
      module: { title: "The Power Of Sound" },
      order: 1,
      sessions: []
    })]
  })),

  parseCourse(newModel({
    title: 'Master The Code',
    cover: 'https://images.unsplash.com/photo-1594904351111-a072f80b1a71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1635&q=80',
    tagline: 'Sound has power',
    description: 'You will gain a better and more methodological approach to delivering innovative and disruptive technology products and services. This class is perfect for those interested in learning more about delivering innovation.',
    attendees: getAttendees(),
    attended: 100,
    slug: 'version-control-git-best-practices',
    course_modules: [newModel({
      available: true,
      course: { id: '' },
      instructor: teachers[0],
      module: { title: "Version Control: Git Best Practices" },
      order: 1,
      sessions: []
    })]
  })),
  parseCourse(newModel({
    title: 'The Agile Mindset',
    cover: "https://source.unsplash.com/zoCDWPuiRuA/1920x1280",
    tagline: "A proven methodology for developing innovative products and services.",
    description: 'You will gain a better and more methodological approach to delivering innovative and disruptive technology products and services. This class is perfect for those interested in learning more about delivering innovation.',
    attendees: getAttendees(),
    attended: 100,
    course_slug: 'the-agile-mindset',
    highlights: [
      "Why is Agile the best way to manage a technology project?",
      "How does the Agile process works?",
      "What does a successful Agile implementation look like?",
      "What can go wrong with Agile and how do I avoid the pitfalls?"
    ],
    course_modules: [
      parseCourseModule(newModel({
        available: true,
        course: { id: "01" },
        instructor: teachers[0],
        order: 1,
        module: {
          id: "module-100",
          cover: "",
          description: "Agile can sound overwhelming, or abstract, but at the heart of it is an intense focus on delivering innovation and value to your customer quickly. In this Equipt course, you will practice the process of turning requirements into products through the use of Agile.",
          duration: 60,
          skills: [],
          tagline: "",
          title: "Intro to The Agile Mindset"
        },
        sessions: [
          parseSession(newModel({
            additional_instructors: [],
            files: [],
            instructor: teachers[0],
            registrations: [],
            seats: 30,
            starts_at: new Date("9/4/2022").toLocaleDateString(),
            status: "open",
          })),
          parseSession(newModel({
            additional_instructors: [],
            files: [],
            instructor: teachers[0],
            registrations: [],
            seats: 30,
            starts_at: new Date("9/22/2022").toLocaleDateString(),
            status: "open"
          })),parseSession(newModel({
            additional_instructors: [],
            files: [],
            instructor: teachers[0],
            registrations: [],
            seats: 30,
            starts_at: new Date("9/27/2022").toLocaleDateString(),
            status: "open"
          }))
        ]
      }))
    ]
  })),
]

const generateSession = (teacher: User, count = 1) => {
  const base = randomIntFromInterval(1, 3);
  const multiplier = randomIntFromInterval(1, 5);
  const sessions = [];
  for (let i = 0; i < count; i++) {
    sessions.push(parseSession(newModel({
      additional_instructors: [],
      files: [],
      instructor: teacher,
      registrations: [],
      seats: 30,
      starts_at: `9/${base+(multiplier*i)}/2022 6PM`,
      status: "open",
    })))

  }
  return sessions
}

const getCompanyCourses = (organization: Organization): Course[] => {
  if(organization.slug === 'clearflame') {
    return [
      parseCourse(newModel({
        title: 'Disrupting a Traditional Engineering Industry',
        cover: 'https://spaces.joinequipt.com/clearflame_impact.jpg',
        tagline: 'Sound has power',
        description: 'Hear lessons from a diverse MechE startup founder disrupting the auto industry',
        attendees: getAttendees(),
        attended: 100,
        event: 'Workshop',
        slug: 'https://www.eventbrite.com/e/467484527807',
        company: organization,
        course_modules: [newModel({
          available: true,
          course: { id: '' },
          instructor: parseUser(newModel({
            first_name: 'BJ',
            last_name: 'Johnson',
            title: 'CEO',
            id: 3000,
            tagline: 'CEO',
            image: 'https://spaces.joinequipt.com/clearflame_bj.jpg',
            memberships: [
              {
                id: '100',
                created: new Date("1//1/2022").toLocaleDateString(),
                updated: new Date("1//1/2022").toLocaleDateString(),
                membership_type: 'TEACHER',
                organization: {
                  name: "ClearFlame"
                }
              }
            ],
            slug: ''
          })),
          module: { title: "Disrupting a Traditional Engineering Industry" },
          order: 1,
          sessions: []
        })]
      })),
    ]
  }
  return [
  parseCourse(newModel({
    title: 'Intro to The Agile Mindset',
    cover: "https://source.unsplash.com/zoCDWPuiRuA/1920x1280",
    tagline: "A proven methodology for developing innovative products and services.",
    description: 'You will gain a better and more methodological approach to delivering innovative and disruptive technology products and services. This class is perfect for those interested in learning more about delivering innovation.',
    attendees: getAttendees(),
    attended: 100,
    course_slug: 'chicago-cubs-presents-the-agile-mindset',
    company: organization,
    course_modules: [newModel({
      available: true,
      course: { id: '' },
      instructor: {
        ...teachers[5],
        memberships: [
          {
            id: '100',
            created: new Date("1//1/2022").toLocaleDateString(),
            updated: new Date("1//1/2022").toLocaleDateString(),
            membership_type: 'TEACHER',
            organization: {
              name: organization.name
            }
          }
        ]
      },
      module: { title: "The Agile Mindset" },
      order: 1,
      sessions: []
    })]
  })),
  parseCourse(newModel({
    title: 'International Leadership for Women',
    cover: 'https://images.unsplash.com/photo-1573165706511-3ffde6ef1fe3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2938&q=80',
    tagline: 'Sound has power',
    description: 'You will gain a better and more methodological approach to delivering innovative and disruptive technology products and services. This class is perfect for those interested in learning more about delivering innovation.',
    attendees: getAttendees(),
    attended: 100,
    slug: 'shopify-presents-international-leadership-for-women',
    company: organization,
    course_modules: [newModel({
      available: true,
      course: { id: '' },
      instructor: {
        ...teachers[1],
        memberships: [
          {
            id: '100',
            created: new Date("1//1/2022").toLocaleDateString(),
            updated: new Date("1//1/2022").toLocaleDateString(),
            membership_type: 'TEACHER',
            organization: {
              name: 'Equipt'
            }
          }
        ]
      },
      module: { title: "International Leadership for Women" },
      order: 1,
      sessions: []
    })]
  })),
  parseCourse(newModel({
    title: 'Version Control: Git Best Practices',
    cover: 'https://images.unsplash.com/photo-1594904351111-a072f80b1a71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1635&q=80',
    tagline: 'Sound has power',
    description: 'You will gain a better and more methodological approach to delivering innovative and disruptive technology products and services. This class is perfect for those interested in learning more about delivering innovation.',
    attendees: getAttendees(),
    attended: 100,
    slug: 'accenture-presents-version-control-git-best-practices',
    company: organization,
    course_modules: [newModel({
      available: true,
      course: { id: '' },
      instructor: {
        ...teachers[3],
        memberships: [
          newModel({
            membership_type: 'TEACHER',
            organization: {
              name: organization.name
            }
          })
        ]
      },
      module: { title: "Version Control: Git Best Practices" },
      order: 1,
      sessions: []
    })]
  })),
  parseCourse(newModel({
    title: 'Consulting Superpowers',
    tagline: 'Recognize your strengths & challenges when consulting',
    description: 'You have first cultivated the client opportunity and have made a connection. In this phase of the process, you need to begin a consulting conversation to understand more about the current situation and in turn provide value through insights. True sales dialogues are not linear, they are a choreographed, give and take of information. That exchange builds momentum that will eventually steer you towards an opportunity to close.',
    cover: 'https://images.unsplash.com/photo-1515378960530-7c0da6231fb1?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920',
    attendees: getAttendees(),
    attended: 100,
    company: organization,
    slug: 'chicago-cubs-presents-consulting-superpowers',
    highlights: [
      "How can I recognize my own strengths and challenges when consulting with clients?",
      "How should I use listening and questioning techniques to uncover needs",
      "How do I follow a consultative process and structure when interacting with client"
    ],
    course_modules: [newModel({
      available: true,
      course: { id: '' },
      instructor: parseUser(newModel({
        bio: 'I believe access to education empowers people to positively affect our world. I am a charismatic marketing and sales leader. My experience with multiple products launches have led me to develop innovative sales strategies to meet customer needs and exceed targets',
        first_name: 'Christine',
        image: profilePictures.female[1],
        last_name: 'Griffs',
        title: 'Leadership Coach',
        slug: 'teacher-christine-griffs',
        memberships: [
          newModel({
            membership_type: 'TEACHER',
            organization: {
              name: 'Equipt'
            }
          })
        ]
      })),
      module: { duration: 60, title: "Consulting Superpowers", description: 'You have first cultivated the client opportunity and have made a connection. In this phase of the process, you need to begin a consulting conversation to understand more about the current situation and in turn provide value through insights. True sales dialogues are not linear, they are a choreographed, give and take of information. That exchange builds momentum that will eventually steer you towards an opportunity to close.',},
      order: 1,
      sessions: generateSession(parseUser(newModel({
        bio: 'Christine is current working at a '+organization.name,
        first_name: 'Christine',
        image: teacherProfilePictures.female[2],
        last_name: 'Griffs',
        title: 'User Interface Designer',
        slug: 'teacher-christine-griffs',
        memberships: [
          newModel({
            membership_type: 'TEACHER',
            organization: {
              name: organization.name
            }
          })
        ]
      })), 3)
    })]
  })),
  parseCourse(newModel({
    title: 'Negotiate with Influence',
    tagline: 'The art and science of closing the opportunity',
    description: 'Learn the art and science of closing the opportunity. That exchange builds momentum that will eventually steer you towards an opportunity to close. You have first cultivated the client opportunity and have made a connection. True sales dialogues are not linear, they are a choreographed, give and take of information.',
    cover: 'https://images.unsplash.com/photo-1521791136064-7986c2920216?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920',
    attendees: getAttendees(),
    attended: 100,
    company: organization,
    slug: 'chicago-cubs-presents-negotiate-with-influence',
    highlights: [
      "How can I recognize my own strengths and challenges when consulting with clients?",
      "How should I use listening and questioning techniques to uncover needs",
      "How do I follow a consultative process and structure when interacting with client"
    ],
    course_modules: [newModel({
      available: true,
      course: { id: '' },
      instructor: parseUser(newModel({
        bio: 'Ben is current working at a '+organization.name,
        first_name: 'Ben',
        image: teacherProfilePictures.male[1],
        last_name: 'Bowden',
        title: 'Account Executive',
        slug: 'teacher-ben-bowden',
        memberships: [
          newModel({
            membership_type: 'TEACHER',
            organization: {
              name: organization.name
            }
          })
        ]
      })),
      module: { title: "Negotiate with Influence" },
      order: 1,
      sessions: generateSession(parseUser(newModel({
        bio: 'Ben is current working at a '+organization.name,
        first_name: 'Ben',
        image: teacherProfilePictures.male[1],
        last_name: 'Bowden',
        title: 'Account Executive',
        slug: 'teacher-ben-bowden',
        memberships: [
          newModel({
            membership_type: 'TEACHER',
            organization: {
              name: organization.name
            }
          })
        ]
      })), 3)
    })]
  })),
  parseCourse(newModel({
    title: 'Advance your Relationships',
    tagline: 'Moving from from solution provider to trusted partner',
    description: 'Moving from from solution provider to trusted partner. That exchange builds momentum that will eventually steer you towards an opportunity to close. You have first cultivated the client opportunity and have made a connection. True sales dialogues are not linear, they are a choreographed, give and take of information.',
    cover: 'https://images.unsplash.com/photo-1611095973015-2c65f77541e1?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920',
    attendees: getAttendees(),
    attended: 90,
    company: organization,
    slug: 'chicago-cubs-presents-advance-your-relationships',
    highlights: [
      "How can I recognize my own strengths and challenges when consulting with clients?",
      "How should I use listening and questioning techniques to uncover needs",
      "How do I follow a consultative process and structure when interacting with client"
    ],
    course_modules: [newModel({
      available: true,
      course: { id: '' },
      instructor: parseUser(newModel({
        bio: 'Mark is current working at a Equipt',
        first_name: 'Mark',
        image: profilePictures.male[4],
        last_name: 'Omari',
        title: 'Inclusion Specialist',
        slug: 'teacher-mark-omari',
        memberships: [
          newModel({
            membership_type: 'TEACHER',
            organization: {
              name: 'Equipt'
            }
          })
        ]
      })),
      module: { title: "Negotiate with Influence" },
      order: 1,
      sessions: generateSession(parseUser(newModel({
        bio: 'Mark is current working at a Equipt',
        first_name: 'Mark',
        image: profilePictures.male[2],
        last_name: 'Omari',
        title: 'Inclusion Specialist',
        slug: 'teacher-mark-omari',
        memberships: [
          newModel({
            membership_type: 'TEACHER',
            organization: {
              name: 'Equipt'
            }
          })
        ]
      })), 3)
    })]
  })),
]}

const cubsCourse = getCompanyCourses(organizations[3]);
const Accenture = getCompanyCourses(organizations[0]);

const courses: Course[] = []
courses.push(equiptCourses[0])
courses.push(cubsCourse[3])
courses.push(equiptCourses[1])
courses.push(equiptCourses[2])
courses.push(equiptCourses[4])
courses.push(cubsCourse[5])
courses.push(oldCourses[0])
courses.push(equiptCourses[3])
courses.push(equiptCourses[5])
courses.push(oldCourses[1])
Accenture.forEach((old_course) => courses.push(old_course))


const mySessions: Session[] = [
  parseSession(newModel({
    title: 'Career Hacking: Successfully Navigating Non-Diverse Industries',
    date: new Intl.DateTimeFormat(undefined, { dateStyle: 'long', timeStyle: 'medium', hour12: false }).format(new Date()) ,
    slug: 'career-hacking',
    company: {
      title: "Equipt"
    },
    teacher: {
      name: "Hubert Dagbo",
      company: "Equipt"
    }
  })),
  parseSession(newModel({
    title: 'Master the Code: Lessons from a Self-Taught Software Developer',
    date: new Intl.DateTimeFormat(undefined, { dateStyle: 'long', timeStyle: 'medium', hour12: false }).format(new Date(new Date().getTime() + (.5 * 24 * 60 * 60 * 1000))),
    slug: 'master-the-code',
    company: {
      title: "Equipt"
    },
    teacher: {
      name: "Jide Osanyingbemi",
      company: "Equipt"
    }
  })),
  parseSession(newModel({
    title: 'Lean UX Design: From Deciphering the Problem Space to Ideation',
    date: new Intl.DateTimeFormat(undefined, { dateStyle: 'long', timeStyle: 'medium', hour12: false }).format(new Date(new Date().getTime() + (24 * 60 * 60 * 1000))),
    slug: 'lean-ux-design',
    company: {
      title: "Equipt"
    },
    instructor: parseUser(newModel({
      bio: 'Mark is current working at a Equipt',
      first_name: 'Stephanie',
      image: profilePictures.male[4],
      last_name: 'Lin',
      title: 'Inclusion Specialist',
      slug: 'teacher-mark-omari',
      memberships: [
        newModel({
          membership_type: 'TEACHER',
          organization: {
            name: 'Elsiver'
          }
        })
      ]
      }))
  })),
]


export { courses, organizations, user, getAttendees, getCompanyCourses, teachers, mySessions }
