import React from 'react'
import AvatarGroup from "../Elements/AvatarGroup";
import {classNames, ordinal} from "../../helpers";
import {CheckIcon} from "@heroicons/react/24/outline";
import {parseUser} from "../../data/client/factories";

const dateRenderer = new Intl.DateTimeFormat('en-US', {
  hour12: true,
  timeZone: 'America/New_York',
  timeZoneName: 'short',
  weekday: "short",
  month: 'short', day: 'numeric',
  hour: 'numeric',
});

const getRecordingId = (url: string) => {
  if(url.indexOf('vimeo') !== -1){

    return url.replace('https://vimeo.com/', "")
  }
  return ""
}

const TileDate: React.FC<{ date: string, timeClassName?: string, dateClassName?: string }> = ({ date, timeClassName, dateClassName }) => {
  let parts = dateRenderer.formatToParts(new Date(date));
  return (
    <>
      <span className={timeClassName || ""}>{`${parts[6].value}${parts[8].value} ${parts[10].value}`}</span>
      <span className={dateClassName}>{`${parts[0].value} ${parts[2].value} ${ordinal(parts[4].value)}`}</span>
    </>
  )
}



interface Props {
  cols?: 1 | 2
  course: Course
  company: Organization
  hasPast?: boolean
  isActive?: boolean
  isInternalTile?: boolean
  onRequest?: (courseTitle: string) => void
  isRequested?: boolean
}

const SponsoredEventTile: React.FC<Props> = ({ course, isInternalTile, company, hasPast= false, isActive, onRequest, isRequested}) => {
  const {
    id,
    attended,
    cover,
    description,
    slug,
    title,
    course_modules,
    attendees
  } = course
  const instructor = parseUser(course_modules[0].sessions[0].featured_instructor?.user || {});
  console.log("<<<<<<<<", course_modules)
  console.log(">>>>>>>",instructor)

    return (
      <li key={id}
          className={classNames(hasPast ? 'w-full' : "col-span-2",
            "transition ease-in-out duration-500",
            isActive ? (isInternalTile ? "shadow-2xl" : "shadow-2xl -translate-y-3") : "shadow-lg",
            `course-tile bg-white relative rounded-lg flex`)}

      >
        <div
          className={classNames(`absolute w-full right-0 overflow-hidden rounded-t-lg bg-center bg-cover`, hasPast ? "h-40" : "h-full rounded-b-lg")}
          style={{backgroundImage: `url(${cover})`}}
        />
        <div
          className={classNames(`absolute w-full right-0 overflow-hidden rounded-t-lg`, hasPast ? "h-40" : "h-full rounded-b-lg")}
          style={{
            opacity: company.theme.img_bg_color_opacity,
            backgroundImage: `linear-gradient(to right, ${company.theme.img_bg_color_from}, ${company.theme.img_bg_color_to})`
          }}
        />
        <div
          className={classNames("absolute w-full right-0 overflow-hidden rounded-t-lg bg-black", hasPast ? "h-40" : "h-full rounded-b-lg")}
          style={{
            opacity: company.theme.img_bg_color_darkness
          }}
        />
        <article aria-labelledby={'feed-course-title-' + id} className={classNames(
          "grid gap-x-6 relative",
          hasPast ? "grid-cols-event-tile-2 text-gray-700 p-3 md:p-5": "grid-cols-1 md:grid-cols-event-tile-1 text-white p-5 "
        )}>

          {/* Header */}
          <div className={classNames("text-white", hasPast ? " items-start":"items-start lg:items-end", "flex justify-between")}>
            <div className=" text-lg font-semibold">
              <TileDate
                date={course.course_modules[0].sessions[0].starts_at}
                dateClassName={classNames(hasPast ? "block -mt-2 tracking-tighter" : "-mt-2 md:mt-0 md:ml-1 md:pl-1 md:border-l-2 md:border-white/60 block md:inline")}
                timeClassName={classNames(hasPast ? "text-2xl": "")}
              />
            </div>
            <div className="inline-flex flex-col items-center">
              <AvatarGroup avatars={attendees.map((attendee) => attendee.image )} total_count={attended} size={(isInternalTile ? "xs": "sm")} color={company.theme.hired_count_bg_color} />
              <div className="uppercase text-xs font-semibold tracking-wider mt-1">{hasPast ? "Attended" : "Attending"}</div>
            </div>
          </div>

          {/* Instructor */}
          <div className={classNames(hasPast ? "":"md:order-first", "flex flex-col items-center justify-center col-span-1 row-span-2")}>
            <div className="h-32 w-32 md:h-40 md:w-40 relative">
              <img alt={`${instructor.first_name} ${instructor.last_name}`} src={instructor.image} className="rounded-full shadow-md"/>
              <div className={classNames(
                "bg-white rounded-full flex items-center justify-center shadow-md",
                hasPast ? "w-16 h-16 md:w-20 md:h-20 p-2 absolute top-3 md:-top-3 -right-6" : "w-16 h-16 md:w-20 md:h-20 p-2 absolute bottom-0 md:-top-3 -right-6"
              )}>
                <img src={company.logo_alt} className="w-14" alt={`${company.name} Image`} />
                {/*<div style={{backgroundImage: `url(${company.logo_alt})`}} className="rounded-full bg-contain bg-no-repeat bg-center inset-0 h-full w-full"/>*/}
              </div>
            </div>
            <div className="opacity-90 mt-2 leading-none text-center"><span className="font-semibold">{`${instructor.first_name} ${instructor.last_name}, `}</span>{company.name}</div>
          </div>

          {/*BODY*/}
          <div className={classNames(hasPast ?  (isInternalTile ? "min-h-max": "h-52"):"", "flex flex-col justify-center")}>
            <div className="flex flex-col">
              <div className={
                classNames(
                  hasPast ? (isInternalTile ? "text-xl py-2 text-center " : "text-lg md:text-2xl text-center py-2") : "sponsored_event_tile-title",
                  "font-semibold leading-none"
                )}>
                {title}
              </div>
              <div
                className={classNames(
                  hasPast ? "text-center max-h-20" : "text-sm lg:text-md text-left lg:pr-10 max-h-24 md:max-h-16 pb-1",
                  isInternalTile ? "text-sm max-h-32 pb-2": "",
                  "mt-2 space-y-4 text-ellipsis overflow-hidden leading-tight"
                )}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
            <div className={classNames("ml-auto mr-0 w-full ",
            hasPast ? "mt-auto" : "md:w-1/2 lg:w-1/3 mt-3 md:mt-auto" )}>
              {hasPast ?
                (isInternalTile ?
                  <button className={classNames(
                    !getRecordingId(slug) ? "pointer-events-none text-gray-400 border-gray-300" :
                      "text-orange-500 border-orange-300 hover:bg-orange-100",
                    isActive ? "" : "opacity-50",
                    "tile-btn w-full  bg-white border ")}
                          style={{transition: "all .15s ease" }}
                          type="button"
                          onClick={() => {
                            return onRequest ? onRequest(getRecordingId(slug)) : null
                          }}>
                    { !getRecordingId(slug) ? 'Recording Coming Soon': "View Recording"}
                  </button> :
                <button className={classNames(
                  isRequested ? "pointer-events-none text-white bg-gray-500" : (
                    isActive ? "text-orange-500 border-orange-300 hover:bg-orange-100": "text-gray-500 border-gray-300 hover:bg-gray-100"
                  ),
                  isActive ? "" : "opacity-50",
                  "tile-btn w-full  bg-white border ")}
                  style={{
                    transition: "all .15s ease" }}
                  type="button"
                  onClick={() => {
                    return !isRequested && onRequest ? onRequest(course.title) : null
                  }}>
                  { isRequested ? <><CheckIcon className="h-5 mr-1"/> Recording Requested </> : 'Request Recording'}
                </button>)
                : <a className="tile-btn w-full"
                style={{
                  backgroundColor: company.theme.cta_bg_color,
                  color: company.theme.cta_text_color,
                  transition: "all .15s ease" }}
                type="button"
                href={slug}
                target="_blank" rel="noreferrer"
              >
                Register
              </a>
              }
            </div>
          </div>

        </article>
      </li>
    )
}

export default SponsoredEventTile
