import {ErrorMessage, FieldAttributes, useField} from "formik";
import React, {useEffect, useState} from "react";
import { Dropdown, Option} from "./Dropdown"

interface SelectFieldProps extends FieldAttributes<any> {
  id: string
  name: string
  placeholder?: string
  containerClassName?: string
  labelClassName?: string
  labelText: JSX.Element | string
  description?: string
  options: Option[]
  //errorClassName?: string
}

const SelectField: React.FC<SelectFieldProps> = (
  { name,
    id,
    placeholder,
    containerClassName = "col-span-12",
    className = "mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-300 focus:border-blue-300 sm:text-sm",
    labelClassName = "block text-sm font-medium text-gray-800",
    labelText,
    description,
    options,
    children, ...fieldProps }) => {
  const [, meta, helpers] = useField({ name, id, placeholder, ...fieldProps});
  const [selected, setSelected] = useState<Option>(options.find(({value}) => meta.initialValue === value) || {name: "Select One", value: meta.initialValue})
  useEffect(() => {
    helpers.setValue(selected.value);
  }, [selected])

  return(
    <div className={containerClassName}>
      <Dropdown
        id={id}
        className={className}
        description={description}
        options={options}
        labelText={labelText}
        labelClassName={labelClassName}
        selected={selected}
        setSelected={setSelected} />
      <span className="text-xs h-6 w-full block text-left text-red-500 italic input-error" >
        <ErrorMessage name={name}/>
      </span>
    </div>
  )
}

export default SelectField
