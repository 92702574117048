 /* Created by JideOsan on 10/11/2022. */
import gql from "graphql-tag";
 import {MutationFunction, useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult} from "@tanstack/react-query";
 import {api, supabase, supahelpers} from "../../api_supabase";
 import {parseOrganization} from "./factories";
 import {organizations} from "../fakeData";
 import {Mutation} from "../../gql/graphql";
 import {parseServerCourse, parseServerOrganization} from "./factories.gql";

export const OrganizationFragment = `
created
id
updated
admin {
	created
	id
	updated
	bio
	email
	first_name
	image
	last_name
	location
	privacy_available_to_hire
	slug
	social_linkedin
	social_discord
	subscription_level
	tagline
	username 
}
benefits
bio
hired
logo
logo_alt
location
name
slug
social_linkedin
tagline
theme
values
`;

export const GET_ORGANIZATION_QUERY_BY_ID = (id: string) => gql`
  {
    organization(organization:{ id: "${id}" }) {
      ${OrganizationFragment}
    }
  }
`;

export const FIND_ORGANIZATION_QUERY = (id: string, admin: User, benefits: string, bio: string, hired: number, logo: string, logo_alt: string, location: string, name: string, slug: string, social_linkedin: string, tagline: string, theme: string, values: string) => gql`
  {
    organizations(organization:{ id: "${id}", admin: "${admin}", benefits: "${benefits}", bio: "${bio}", hired: "${hired}", logo: "${logo}", logo_alt: "${logo_alt}", location: "${location}", name: "${name}", slug: "${slug}", social_linkedin: "${social_linkedin}", tagline: "${tagline}", theme: "${theme}", values: "${values}" }) {
      ${OrganizationFragment}
    }
  }
`;

export const CREATE_ORGANIZATION_MUTATION = gql`
  mutation InsertIntoorganizationsCollection($objects: [organizationsInsertInput!]!) {
    insertIntoorganizationsCollection(objects: $objects) {
      affectedCount
      records {
        ${OrganizationFragment}
      }
    }
  }
`;

export const DELETE_ORGANIZATION_MUTATION = gql`
  mutation OrganizationDelete($id: ID!){
     organizationDelete(organization:{ id: $id }) {
      ${OrganizationFragment}
    }
  }
`;

export const UPDATE_ORGANIZATION_MUTATION = gql`
  mutation UpdateorganizationsCollection($set: organizationsUpdateInput!, $filter: organizationsFilter) {
    updateorganizationsCollection(set:$set, filter:$filter) {
      affectedCount
      records {
        ${OrganizationFragment}
      }
    }
  }
`;

interface UseUpdateOrganizationProps {
  organization: RequireOnly<Organization, "id">
}

interface UseCreateOrganizationProps {
  organization: RequireOnly<Organization, "name" | "slug" | "admin" | "type">
}

export function useFetchOrganizations(): UseQueryResult<Organization[]> {

 async function fetchOrganizations(): Promise<Organization[]> {
   const { data } = await supabase
     .from('organizations')
     .select('*')
     .neq('type', 'COMPANY');

   return (data || []).map((organization) => parseOrganization(organization));
 }

 return useQuery<Organization[]>({
     queryKey: ["allOrganizations"],
     queryFn: fetchOrganizations,
   })
}

export function useGetOrganization({ id, slug } : { id?: string, slug?: string }): UseQueryResult<Organization> {

 async function findOrganization(): Promise<Organization> {

   if(!id && !slug) return parseOrganization({})

   const { data } = await supabase
     .from('organizations')
     .select('*')
     .eq(id ? 'id' : 'slug', id ? id : slug);

   let organizationData = (data || [])[0];
   //organizationData.theme = organizationData.theme || defaultTheme;

   return parseOrganization(organizationData);
 }

 return useQuery<Organization>({
   queryKey: ["organization", { id, slug }],
   queryFn: findOrganization,
 })
}

export function useCreateOrganization(): UseMutationResult<Organization, Error, UseCreateOrganizationProps> {
  const createOrganization: MutationFunction<Organization, UseCreateOrganizationProps> = async (
    { organization: { logo, logo_alt, cover, ...organization } }) => {
    const [newOrganization] = await api.request<Mutation>(CREATE_ORGANIZATION_MUTATION, { objects: [organization] })
    .then(({ insertIntocoursesCollection }) => {
      const { records } = insertIntocoursesCollection || { records: [] }
      return records.map((record) => parseServerCourse(record))
    });

    const imageUploads = [];

    if(logo?.indexOf("tmp/") === 0){
      console.log('upload logo')
      imageUploads.push(supahelpers.functions.updateImage({
        table_name: "organizations",
        column_name: "logo",
        image: logo || "",
        bucket: 'images',
        id: newOrganization.id
      }))
    }

    if(logo_alt?.indexOf("tmp/") === 0){
      console.log('upload logo-alt')
      imageUploads.push(supahelpers.functions.updateImage({
        table_name: "organizations",
        column_name: "logo_alt",
        image: logo_alt || "",
        bucket: 'images',
        id: newOrganization.id
      }))
    }

    if(cover?.indexOf("tmp/") === 0){
      console.log('upload cover')
      imageUploads.push(await supahelpers.functions.updateImage({
        table_name: "organizations",
        column_name: "cover",
        image: cover || "",
        bucket: 'images',
        id: newOrganization.id
      }))
    }

    await Promise.all(imageUploads)

    return parseOrganization({ ...newOrganization})
  }

  return useMutation({
    mutationFn: createOrganization,
  })
}

export function useUpdateOrganization(): UseMutationResult<Organization, Error, UseUpdateOrganizationProps> {

  const updateOrganization: MutationFunction<Organization, UseUpdateOrganizationProps> = async ({ organization }) => {
    const {id, logo_alt, logo, cover, ...organizationData } = organization
    const [updatedOrganization] = await api.request<Mutation>(UPDATE_ORGANIZATION_MUTATION, { set:organizationData, filter: { id: { eq: id } } })
       .then(({ updateorganizationsCollection}) => {
         const { records } = updateorganizationsCollection || { records: [] }
         return records.map((record) => parseServerOrganization(record))
       })

    const imageUploads = [];

    if(logo?.indexOf("tmp/") === 0){
      console.log('upload logo')
      imageUploads.push(supahelpers.functions.updateImage({
        table_name: "organizations",
        column_name: "logo",
        image: logo || "",
        bucket: 'images',
        id
      }))
    }

    if(logo_alt?.indexOf("tmp/") === 0){
      console.log('upload logo-alt')
      imageUploads.push(supahelpers.functions.updateImage({
        table_name: "organizations",
        column_name: "logo_alt",
        image: logo_alt || "",
        bucket: 'images',
        id
      }))
    }

    if(cover?.indexOf("tmp/") === 0){
      console.log('upload cover')
      imageUploads.push(await supahelpers.functions.updateImage({
        table_name: "organizations",
        column_name: "cover",
        image: cover || "",
        bucket: 'images',
        id
      }))
    }

    await Promise.all(imageUploads)

      return parseOrganization({ ...updatedOrganization})
    }
    const queryClient = useQueryClient()

    return useMutation({
      mutationFn: updateOrganization,
      onSuccess: (data) => {
        queryClient.invalidateQueries({ queryKey: ["organization", { id: data.id }]})
        queryClient.invalidateQueries({ queryKey: ["organization", { slug: data.slug }]})

      }
    })
}