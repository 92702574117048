import React from 'react';
import {FaFacebook, FaLinkedinIn } from 'react-icons/fa'
import {SiTrustpilot} from 'react-icons/si'
const navigation = [
    {
        name: 'Facebook',
        href: 'https://www.facebook.com/joinequipt',
        icon: (props: React.SVGProps<SVGSVGElement>) => <FaFacebook {...props} />,
    }/*,
    {
        name: 'Twitter',
        href: '#',
        icon: (props: React.SVGProps<SVGSVGElement>) => <FaTwitter {...props} />,
    }*/,
    {
        name: 'LinkedIn',
        href: 'https://www.linkedin.com/company/joinequipt',
        icon: (props: React.SVGProps<SVGSVGElement>) => <FaLinkedinIn {...props} />,
    },
    {
        name: 'TrustPilot',
        href: 'https://www.trustpilot.com/review/joinequipt.com',
        icon: (props: React.SVGProps<SVGSVGElement>) => <SiTrustpilot {...props} />,
    },
]

export default function Footer(props: { className: string }) {
    // @ts-ignore
    return (
        <footer className={props.className}>
            <div className="w-full relative">
                {/*<div className="absolute top-0 bottom-0 left-0 right-0"><Wave height={40}/></div>*/}
                <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
                    <div className="flex justify-center space-x-6 md:order-2">
                        {navigation.map((item) => (
                          <a key={item.name} href={item.href} className="hover:opacity-50">
                              <span className="sr-only">{item.name}</span>
                              <item.icon className="h-4 w-4" aria-hidden="true" />
                          </a>
                        ))}
                    </div>
                    <div className="mt-8 md:mt-0 md:order-1">
                        <p className="text-center text-base">&copy; 2022 Equipt, Inc. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}
