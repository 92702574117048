import { XMarkIcon } from '@heroicons/react/24/outline'
import React from "react";
import {classNames} from "../helpers";
import { Transition } from "@headlessui/react";

interface Props {
  status: "success" | "danger" | "warning" | "info" | "primary" | undefined
  dismissible?: boolean
  children?: React.ReactNode
}

const Banner: React.FC<Props> = ({ status, dismissible = true, children }) => {
  return (
    <Transition
      show={true}
    >
      <Transition.Child
        enter="transition-all duration-700 delay-1000"
        enterFrom="h-0"
        enterTo="lg:h-12 h-16"
        leave="transition-all duration-500"
        leaveFrom="lg:h-12 h-16"
        leaveTo="h-0"
      >
        <div className="lg:h-12 h-14"/>
      </Transition.Child>
      <div className="fixed w-full z-100 overflow-hidden top-0">
        <Transition.Child
          enter="transition-all duration-700 delay-1000"
          enterFrom="h-0"
          enterTo="lg:h-12 h-16"
          leave="transition-all duration-500"
          leaveFrom="lg:h-12 h-16"
          leaveTo="h-0"
        >
          <div className={classNames("lg:h-12 h-16",
            status === "success" ? "bg-green-600 text-green-50" : "",
            status === "danger" ? "bg-red-600 text-red-50" : "",
            status === "warning" ? "bg-yellow-300 text-yellow-900" : "",
            status === "info" ? "bg-blue-300 text-blue-500" : "",
            status === "primary" ? "bg-purple-700 text-purple-300" : "",
          )}
          >
            <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
              <div className="text-center sm:px-16">
                <p className="font-medium">
                  {children}
                </p>
              </div>
              {dismissible && <div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
                <button
                  type="button"
                  className="flex p-2 rounded-xl hover:bg-white-500 focus:outline-none focus:ring-2 focus:ring-white"
                >
                  <span className="sr-only">Dismiss</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>}

            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition>
  )
}

export default Banner
