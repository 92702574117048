import React from 'react'
import { Link } from 'react-router-dom'
import AvatarLabel from "../Elements/AvatarLabel";
import {ArrowTopRightOnSquareIcon, XMarkIcon} from "@heroicons/react/24/outline";
import jide_image from '../../assets/img/teacher-jide.jpg';
import hubert_image from '../../assets/img/teacher-hubert.jpeg';
import {getMembershipTypeName} from "../../helpers";
import {parseProfile, parseUser} from "../../data/client/factories";


interface Props {
  course: Course
  organization: Organization
  isAdmin: boolean
}

const OrganizationCourseTile: React.FC<Props> = ({ course, organization, isAdmin}) => {
  const {
    id,
    cover,
    description,
    slug,
    title,
    course_modules
  } = course

  const avatars = course_modules.reduce((map, { instructors }) => {
    instructors.forEach((instructor) => {
      const { id, memberships } = parseUser(instructor.user || {});
      const { image, tagline, first_name, last_name } = parseProfile(instructor.user.profile || {})
      let teacherCompany = (memberships || []).find(({ membership_type}) => getMembershipTypeName(membership_type) === 'INSTRUCTOR')?.organization.name || ''
      let description = [];
      if(tagline) description.push(tagline);
      if(teacherCompany) description.push(teacherCompany);
      map.set(id, {
        image: image || "",
        title: first_name+' '+last_name,
        description: `${tagline || 'Instructor'}${teacherCompany ? `, ${teacherCompany}` : ''}`,
        slug: slug || ""
      });
    })
    return map;
  }, new Map( organization.slug === 'clearflame' ? [
    ['1000', {
      image: jide_image,
      title: 'Jide Osan',
      description: `Facilitator, Equipt`,
      slug: ''
    }],
    ['2000', {
      image: hubert_image,
      title: 'Hubert Dagbo',
      description: `Facilitator, Equipt`,
      slug: ''
    }]
  ] : []))

  return (
    <li key={id}
        style={{
          borderColor: organization.theme.course_card_outline_color || 'transparent',
          borderWidth: organization.theme.course_card_outline_color ? 2 : 0,
          borderStyle: organization.theme.course_card_outline_color ? "solid" : "none"
        }}
        className="course-tile col-span-1 bg-white shadow-md relative rounded-lg flex w-full group/tile"
    >
      { isAdmin && <div className="bg-white leading-none p-1 rounded-full absolute -top-4 -right-4  z-100 opacity-0 group-hover/tile:opacity-100">
        <button
          type="button"
          className="text-white lg:text-gray-400 hover:text-gray-300"
          onClick={() => {}}
        >
          <span className="sr-only">Close</span>
          <XMarkIcon className="h-4 w-5" aria-hidden="true" />
        </button>
      </div> }
      <article aria-labelledby={'feed-course-title-' + id} className="relative w-full">
        <div className="flex flex-col justify-between h-full w-full">
          <div className="w-full h-36 bg-center bg-cover rounded-t-lg" style={{backgroundImage: `url(${cover})`}}/>
          <div className=" flex-col items-end flex justify-end p-5">
            <div className="course-tile_content h-full">
              <div className="flex flex-col h-40">
                <h4 style={{
                  color: organization.theme.course_card_label_color,
                  }}
                  className="text-black text-base font-bold uppercase mb-1">{course.event}</h4>
                <h2 id={'course-title-' + id} className="text-black text-xl font-bold leading-6">
                  {title}
                </h2>
                <div
                  style={{lineHeight: "1.2rem"}}
                  className="mt-2 text text-gray-600 space-y-4 w-full text-ellipsis overflow-hidden"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
              <div className="course-tile_instructor">
                <div className="flex space-x-1 mt-4">
                  <AvatarLabel avatars={Array.from(avatars.values())} />
                </div>
              </div>
            </div>
            <div className="course-tile_register">
              {slug.indexOf('http') > -1 ?
                <a
                  style={{
                    backgroundColor: organization.theme.course_card_button_bg_color,
                    color: organization.theme.course_card_button_font_color,
                    transition: "all .15s ease" }}
                  className="tile-btn tile-btn-branded w-full text-orange-500 bg-white border border-orange-300 hover:bg-orange-100"
                  type="button"
                  href={slug}
                >
                  Register <ArrowTopRightOnSquareIcon className="h-5 right-1 absolute"/>
                </a>
                :
                <Link
                style={{
                  backgroundColor: organization.theme.course_card_button_bg_color,
                  color: organization.theme.course_card_button_font_color,
                  transition: "all .15s ease" }}
                className="tile-btn tile-btn-branded w-full text-orange-500 bg-white border border-orange-300 hover:bg-orange-100"
                type="button"
                to={`/course/${slug}`}
              >
                Register
              </Link>
              }
            </div>
          </div>
        </div>
      </article>
    </li>
  )
}

export default OrganizationCourseTile
