/* This example requires Tailwind CSS v2.0+ */
import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import {ErrorMessage, FieldAttributes, useField} from "formik";
import {classNames, generateId} from "../../helpers";
import {FilePond, registerPlugin} from "react-filepond";
import FilePondPluginImageTransform from "filepond-plugin-image-transform"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import {
  ActualFileObject, FetchServerConfigFunction,
  FilePondInitialFile,
  LoadServerConfigFunction,
  ProcessServerConfigFunction,
  RemoveServerConfigFunction, RestoreServerConfigFunction, RevertServerConfigFunction, ServerUrl
} from "filepond";
import {supahelpers} from "../../api_supabase";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginImageTransform, FilePondPluginImageCrop);

interface Props extends FieldAttributes<any> {
  initialValue: string
  bucket: string
  is_public?: boolean
  id: string
  name: string
  imagePreviewHeight: number
  imageCropAspectRatio: string
  layout?: "circle" | "integrated" | "compact" | "integrated circle" | "compact circle" | null | undefined
}

function parseInitialValue(initialValue: string): undefined | FilePondInitialFile {
  console.log('CALL PARSE', initialValue)
  if(initialValue){
    console.log('PARCED!')
    return {
      source: initialValue,
      options: {
        type: "local"
      }
    }
  }
  return undefined
}

interface FilePondServerConfig {
  url?: string
  timeout?: number
  headers?: { [key: string]: string | boolean | number };
  process?: string | ServerUrl | ProcessServerConfigFunction | null;
  revert?: string | ServerUrl | RevertServerConfigFunction | null;
  restore?: string | ServerUrl | RestoreServerConfigFunction | null;
  load?: string | ServerUrl | LoadServerConfigFunction | null;
  fetch?: string | ServerUrl | FetchServerConfigFunction | null;
  remove?: RemoveServerConfigFunction | null;
}

export type ImageUploaderHelpers = {
  reset: () => void;
};

const ImageUploaderInput: React.FC<Props> = forwardRef<ImageUploaderHelpers, Props>((
  { initialValue,
    bucket,
    id,
    name,
    is_public = true,
    layout,
    imagePreviewHeight,
    imageCropAspectRatio,
    ...fieldProps }, ref) => {
  const [imageFile, setImageFile] = useState<(string | Blob | ActualFileObject | FilePondInitialFile | undefined)>(undefined)

  useEffect(() => {
    setImageFile(parseInitialValue(initialValue))
  }, [initialValue])

  const [field, , helpers] = useField({name, id, ...fieldProps});
  const { setValue } = helpers

  useImperativeHandle(
    ref,
    () => ({
      reset() {
        setImageFile(initialValue ? {source: initialValue, options: {type: "local"}} : undefined)
      }
    }),
  )

  const server: FilePondServerConfig = {
    // this uploads the image using firebase
    process: async (fieldName, file, metadata, load, error)  => {
      const generatedFileName = `tmp/${generateId()}.jpeg`

      try {
        await supahelpers.uploadFile({
          file: file as File,
          file_path: generatedFileName,
          bucket
        })

        load(generatedFileName)
      } catch(e: any){
        error(e)
      }
    },

    // this loads an already uploaded image to firebase
    load: async (source, load, error, progress) => {
      // reset our progress
      progress(true, 0, 1024)

      try{
        const { data } = await supahelpers.getFile({ file_path: source, bucket });
        let xhr = new XMLHttpRequest()
        xhr.responseType = 'blob'
        xhr.onload = function() {
          let blob = xhr.response
          load(blob)
        }
        xhr.open('GET', data.publicUrl)
        xhr.send()

      } catch(e: any){
        error(e)
      }
    },
    /*
    remove: async () => {
      console.log('remove ')
    },*/

    revert: async ( uniqueFieldId: any, load: () => void ) => {
      load();
      console.log('revert')
    }
  }

  return(
    <>
      <label
        htmlFor={id}
        className={classNames(
          "absolute z-100 inset-0 w-full h-full flex items-center justify-center text-sm font-medium text-white",
        )}
      >
        <div className="h-full w-full">
          <FilePond
            files={imageFile ? [imageFile] : []}
            onupdatefiles={(files) => {
              setImageFile(files[0]?.file || undefined)
            }}
            onprocessfile={(e, file) => {
              setValue(file.serverId);
            }}
            onremovefile={() => {
              setValue("")
            }}
            allowMultiple={false}
            maxFiles={1}
            maxParallelUploads={1}
            credits={false}
            server={server}
            name="filepond"
            imageTransformOutputMimeType="image/jpeg"
            imagePreviewHeight={imagePreviewHeight}
            allowImageCrop={true}
            imageCropAspectRatio={imageCropAspectRatio}
            stylePanelLayout={layout}

            //allowImageResize={true}

            styleLoadIndicatorPosition={'center bottom'}
            styleProgressIndicatorPosition={'center bottom'}
            styleButtonRemoveItemPosition={'center bottom'}
            styleButtonProcessItemPosition={'center bottom'}
            labelIdle={'Drag & Drop your files or click here'}
          />
        </div>
      </label>
      <input type="hidden" {...field}  />
      <span className="text-sm h-6 w-full block flex justify-center items-center text-red-500 italic input-error" >
        <ErrorMessage name={name}/>
      </span>
    </>
  )
})

export default ImageUploaderInput
