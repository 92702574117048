 /* Created by JideOsan on 10/11/2022. */
import gql from "graphql-tag";
 import {api} from "../../api_supabase";
 import {useMutation} from "@tanstack/react-query";
 import {parseServerCourseModule} from "./factories.gql";
 import {Course_Modules, Mutation} from "../../gql/graphql";

export const CourseModuleFragment = `
created
id
updated
available
courses {
	created
	id
	updated
	attended
	cover
	seo_image
	description
	event
	highlights
	slug
	tagline
	title 
}
modules {
	created
	id
	updated
	cover
	description
	duration
	skills
	tagline
	title 
}
order
`;

export const GET_COURSE_MODULE_QUERY_BY_ID = (id: string) => gql`
  {
    courseModule(courseModule:{ id: "${id}" }) {
      ${CourseModuleFragment}
    }
  }
`;

export const FIND_COURSE_MODULE_QUERY = (id: string, available: boolean, course: Course, instructor: User, module: Module, order: number, subscription_level: number) => gql`
  {
    courseModules(courseModule:{ id: "${id}", available: "${available}", course: "${course}", instructor: "${instructor}", module: "${module}", order: "${order}", subscription_level: "${subscription_level}" }) {
      ${CourseModuleFragment}
    }
  }
`;

export const CREATE_COURSE_MODULES_MUTATION = gql`
  mutation InsertIntocourse_modulesCollection($objects: [course_modulesInsertInput!]!) {
    insertIntocourse_modulesCollection(objects: $objects) {
      affectedCount
      records {
        ${CourseModuleFragment}
      }
    }
  }
`;

export const DELETE_COURSE_MODULE_MUTATION = gql`
  mutation CourseModuleDelete($id: ID!){
     courseModuleDelete(courseModule:{ id: $id }) {
      ${CourseModuleFragment}
    }
  }
`;

export const UPDATE_COURSE_MODULE_MUTATION = gql`
  mutation CourseModuleUpdate($application: String!, $available: Boolean, $course: IdInput!, $instructor: IdInput, $module: IdInput!, $order: Float, $subscription_level: Float){
    courseModuleUpdate(courseModule:{ application: $string, available: $boolean, course: $string, instructor: $string, module: $string, order: $number, subscription_level: $number }) {
      ${CourseModuleFragment}
    }
  }
`;

interface UseCreateCourseModulesProps {
 course_modules: RequireOnly<CourseModule, "course" | "module" | "order">[]
}

export function useCreateCourseModules(){
  function createCourseModule({ course_modules }: UseCreateCourseModulesProps){
   return api.request<Mutation>(CREATE_COURSE_MODULES_MUTATION, {
     objects: course_modules.map(({ course, module}) => {
       return { course: course.id, module: module.id }
    })
   })
     .then(({insertIntocourse_modulesCollection  }) => {
       const { records } = insertIntocourse_modulesCollection || { records: [] };
       return records.map((record: Course_Modules) => parseServerCourseModule(record))
     }
     );
  }

  return useMutation({
   mutationFn: createCourseModule
  })
}
