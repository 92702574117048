/* Created  on 9/20/2023. */
import { parseCourseCustomAttributes,
  parseCourseModuleCustomAttributes,
  parseInstructorCustomAttributes,
  parseJobCustomAttributes,
  parseJobApplicationCustomAttributes,
  parseMembershipCustomAttributes,
  parseModuleCustomAttributes,
  parseOrganizationCustomAttributes,
  parsePlanCustomAttributes,
  parseProfileCustomAttributes,
  parseRegistrationCustomAttributes,
  parseSessionCustomAttributes,
  parseSubscriptionCustomAttributes,
  parseUserCustomAttributes  } from './factories.custom';
type Maybe<T> = T | null;
type MaybePartial<T> = { [P in keyof T]?: Maybe<T[P]> };
const _skipParse = (data: any) => (data?._parse) ? false : data;


export const parseCourse = (data: MaybePartial<Course>): Course => {
  const { created, id, updated, attended, cover, seo_image, organization, description, event, highlights, membership_type, slug, tagline, title } = data
  return Object.assign({
    created: created || "",
    id: id || "",
    updated: updated || "",
    attended: attended || 0,
    cover: cover || "",
    seo_image: seo_image || "",
    organization: _skipParse(organization) || parseOrganization(organization || {}),
    description: description || "",
    event: event || "",
    highlights: highlights || [],
    membership_type: membership_type || 0,
    slug: slug || "",
    tagline: tagline || "",
    title: title || "",
    }, parseCourseCustomAttributes(data)
  )
}


export const parseJob = (data: MaybePartial<Job>): Job => {
  const { created, id, updated, available, description, closed_at, location, organization, posted_at, title, url } = data
  return Object.assign({
    created: created || "",
    id: id || "",
    updated: updated || "",
    available: available || false,
    description: description || "",
    closed_at: closed_at || "",
    location: location || "",
    organization: _skipParse(organization) || parseOrganization(organization || {}),
    posted_at: posted_at || "",
    title: title || "",
    url: url || "",
    }, parseJobCustomAttributes(data)
  )
}


export const parseCourseModule = (data: MaybePartial<CourseModule>): CourseModule => {
  const { created, id, updated, available, course, module, order } = data
  return Object.assign({
    created: created || "",
    id: id || "",
    updated: updated || "",
    available: available || false,
    course: _skipParse(course) || parseCourse(course || {}),
    module: _skipParse(module) || parseModule(module || {}),
    order: order || 0,
    }, parseCourseModuleCustomAttributes(data)
  )
}


export const parseInstructor = (data: MaybePartial<Instructor>): Instructor => {
  const { created, id, updated, course, course_module, featured, organization, membership, user, module, session } = data
  return Object.assign({
    created: created || "",
    id: id || "",
    updated: updated || "",
    course: _skipParse(course) || parseCourse(course || {}),
    course_module: _skipParse(course_module) || parseCourseModule(course_module || {}),
    featured: featured || false,
    organization: _skipParse(organization) || parseOrganization(organization || {}),
    membership: _skipParse(membership) || parseMembership(membership || {}),
    user: _skipParse(user) || parseUser(user || {}),
    module: _skipParse(module) || parseModule(module || {}),
    session: _skipParse(session) || parseSession(session || {}),
    }, parseInstructorCustomAttributes(data)
  )
}


export const parseOrganization = (data: MaybePartial<Organization>): Organization => {
  const { created, id, updated, admin, approval_process, approval_key, benefits, bio, cover, cta_button, cta_button_link,  description, hired, logo, logo_alt, location, size, name, slug, social_linkedin, tagline, theme, type, values, website } = data
  return Object.assign({
    created: created || "",
    id: id || "",
    updated: updated || "",
    admin: _skipParse(admin) || parseUser(admin || {}),
    approval_process: approval_process || "",
    approval_key: approval_key || "",
    benefits: benefits || "",
    bio: bio || "",
    cover: cover || "",
    cta_button: cta_button || "",
    cta_button_link: cta_button_link || "",
    description: description || "",
    hired: hired || 0,
    logo: logo || "",
    logo_alt: logo_alt || "",
    location: location || "",
    size: size || 0,
    name: name || "",
    slug: slug || "",
    social_linkedin: social_linkedin || "",
    tagline: tagline || "",
    theme: theme || "",
    type: type || "",
    values: values || "",
    website: website || "",
    }, parseOrganizationCustomAttributes(data)
  )
}


export const parseMembership = (data: MaybePartial<Membership>): Membership => {
  const { created, id, updated, membership_type, title, organization, user, accepted, rejected } = data
  return Object.assign({
    created: created || "",
    id: id || "",
    updated: updated || "",
    membership_type: membership_type || 0,
    title: title || "",
    organization: _skipParse(organization) || parseOrganization(organization || {}),
    user: _skipParse(user) || parseUser(user || {}),
    accepted: accepted || "",
    rejected: rejected || "",
    }, parseMembershipCustomAttributes(data)
  )
}


export const parseModule = (data: MaybePartial<Module>): Module => {
  const { created, id, updated, cover, description, duration, skills, tagline, title } = data
  return Object.assign({
    created: created || "",
    id: id || "",
    updated: updated || "",
    cover: cover || "",
    description: description || "",
    duration: duration || 0,
    skills: skills || [],
    tagline: tagline || "",
    title: title || "",
    }, parseModuleCustomAttributes(data)
  )
}


export const parseProfile = (data: MaybePartial<Profile>): Profile => {
  const { created, id, updated, bio, featured_membership, first_name, image, is_public, last_name, location, slug, social_linkedin, social_discord, tagline, user } = data
  return Object.assign({
    created: created || "",
    id: id || "",
    updated: updated || "",
    bio: bio || "",
    featured_membership: featured_membership || "",
    first_name: first_name || "",
    image: image || "",
    is_public: is_public || false,
    last_name: last_name || "",
    location: location || "",
    slug: slug || "",
    social_linkedin: social_linkedin || "",
    social_discord: social_discord || "",
    tagline: tagline || "",
    user: _skipParse(user) || parseUser(user || {}),
    }, parseProfileCustomAttributes(data)
  )
}


export const parseRegistration = (data: MaybePartial<Registration>): Registration => {
  const { created, id, updated, session, student } = data
  return Object.assign({
    created: created || "",
    id: id || "",
    updated: updated || "",
    session: _skipParse(session) || parseSession(session || {}),
    student: _skipParse(student) || parseUser(student || {}),
    }, parseRegistrationCustomAttributes(data)
  )
}


export const parsePlan = (data: MaybePartial<Plan>): Plan => {
  const { created, id, updated, description, monthly_price, title, yearly_price } = data
  return Object.assign({
    created: created || "",
    id: id || "",
    updated: updated || "",
    description: description || "",
    monthly_price: monthly_price || 0,
    title: title || "",
    yearly_price: yearly_price || 0,
    }, parsePlanCustomAttributes(data)
  )
}


export const parseUser = (data: MaybePartial<User>): User => {
  const { created, id, updated, accepted_terms, email, privacy_available_to_hire, stripe_customer_id, subscription_level, username } = data
  return Object.assign({
    created: created || "",
    id: id || "",
    updated: updated || "",
    accepted_terms: accepted_terms || "",
    email: email || "",
    privacy_available_to_hire: privacy_available_to_hire || false,
    stripe_customer_id: stripe_customer_id || "",
    subscription_level: subscription_level || 0,
    username: username || "",
    }, parseUserCustomAttributes(data)
  )
}


export const parseSession = (data: MaybePartial<Session>): Session => {
  const { created, id, updated, course, course_module, external_registration_link, files, meeting_link, module, pre_survey_link, post_survey_link, registrations, seats, starts_at, status } = data
  return Object.assign({
    created: created || "",
    id: id || "",
    updated: updated || "",
    course: _skipParse(course) || parseCourse(course || {}),
    course_module: _skipParse(course_module) || parseCourseModule(course_module || {}),
    files: files || [],
    external_registration_link: external_registration_link || "",
    meeting_link: meeting_link || "",
    module: _skipParse(module) || parseModule(module || {}),
    pre_survey_link: pre_survey_link || "",
    post_survey_link: post_survey_link || "",
    registrations: registrations || 0,
    seats: seats || 0,
    starts_at: starts_at || "",
    status: status || "",
    }, parseSessionCustomAttributes(data)
  )
}


export const parseJobApplication = (data: MaybePartial<JobApplication>): JobApplication => {
  const { created, id, updated, applied_at, job, status, user } = data
  return Object.assign({
    created: created || "",
    id: id || "",
    updated: updated || "",
    applied_at: applied_at || "",
    job: _skipParse(job) || parseJob(job || {}),
    status: status || "",
    user: _skipParse(user) || parseUser(user || {}),
    }, parseJobApplicationCustomAttributes(data)
  )
}


export const parseSubscription = (data: MaybePartial<Subscription>): Subscription => {
  const { created, id, updated, active, billing_cycle, user, plan } = data
  return Object.assign({
    created: created || "",
    id: id || "",
    updated: updated || "",
    active: active || false,
    billing_cycle: billing_cycle || 0,
    user: _skipParse(user) || parseUser(user || {}),
    plan: _skipParse(plan) || parsePlan(plan || {}),
    }, parseSubscriptionCustomAttributes(data)
  )
}
