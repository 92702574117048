import {ErrorMessage, FieldAttributes, useField} from "formik";
import React from "react";
import {classNames} from "../../helpers";

interface AuthFieldProps extends FieldAttributes<any> {
  id: string
  name: string
  placeholder: string
  containerClassName?: string
  children?: JSX.Element
}

const AuthInputField: React.FC<AuthFieldProps> = ({ name, id, placeholder, containerClassName, className, children, ...fieldProps }) => {
  const [field, meta] = useField({ name, id, placeholder, ...fieldProps});
  return(
    <div className={classNames(
      containerClassName ? containerClassName : "col-span-12",
      "inline-placeholder relative"
    )}>
      {children}
      <input id={id} placeholder={placeholder} {...field} {...fieldProps}
             className={classNames(className, meta.touched && meta.error ? 'input-has-error' : '')}/>
      <label htmlFor={id} title={placeholder}/>
      <span className="text-sm h-6 w-full block flex justify-center items-center text-red-500 italic input-error" >
        <ErrorMessage name={name}/>
      </span>
    </div>
  )
}

export default AuthInputField
