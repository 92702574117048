 /* Updated by JideOsan on 2/28/2022. */
import gql from "graphql-tag";
import slugify from "slugify";
import {/*CourseModuleFragment, CREATE_COURSE_MODULES_MUTATION,*/ useCreateCourseModules} from "./courseModule.api";
 import {
  MutationFunction,
  useMutation,
  UseMutationResult,
  useQuery, useQueryClient, UseQueryResult,
} from "@tanstack/react-query";
 //import {supabase} from "../../api_supabase";
 import {
  parseCourse,
  parseCourseModule, parseInstructor,
  parseOrganization,
  parseSession,
  parseUser
} from "./factories";
 import { parseTheme } from "./factories.custom"
 import {api, supabase, supahelpers} from "../../api_supabase";
 import { useCreateModules} from "./module.api";
 import {parseServerCourse} from "./factories.gql";
 import {Mutation} from "../../gql/graphql";
 import Airtable, {FieldSet, Record} from "airtable";
 import {SortParameter} from "airtable/lib/query_params";

 import {courses} from "../fakeData";

export const FullCourseFragment = `
created
id
updated
attended
cover
seo_image
organization ${/*populated by organizations*/''}
description
event
highlights
membership_type
slug
tagline
title
course_modulesCollection {
  edges {
    node {
      created
      id
      updated
      available
      module
      order
      sessionsCollection {
        edges {
          node {
            instructors
          }
        }
      }
    }
  }
}
organizations {
	created
	id
	updated
	admin  ${/*populated by organizations.users*/''}
	benefits
	bio
	hired
	logo
	logo_alt
	location
	name
	slug
	social_linkedin
	tagline
	theme
	values 
}
`;

export const CourseFragment = `
created
id
updated
attended
cover
seo_image
organization ${/*populated by organizations*/''}
description
event
highlights
slug
tagline
title
organizations {
	created
	id
	updated
	admin  ${/*populated by organizations.users*/''}
	benefits
	bio
	hired
	logo
	logo_alt
	location
	name
	slug
	social_linkedin
	tagline
	theme
	values 
	users {
	  id
		created
		updated
		username
		subscription_level 
		profiles {
      first_name
      last_name
      image
      slug
      tagline
      id
      social_linkedin
		  social_discord
    }
	}
}
`;

export const GET_COURSE_QUERY_BY_ID = (id: string) => gql`
  {
    course(course:{ id: "${id}" }) {
      ${CourseFragment}
    }
  }
`;

 export const GET_ALL_COURSES_QUERY = () => gql`
  {
    courses {
      ${CourseFragment}
    }
  }
`;

 export const FIND_COURSE_QUERY = gql`
  query CourseCollection($filter: coursesFilter) {
    coursesCollection(filter: $filter) {
      edges {
        node {
          ${CourseFragment}
        }
      }
    }
  }
`;

 export const FIND_COURSE_BY_SLUG_QUERY = (slug: string) => gql`
  {
    courses(course:{slug: "${slug}" }) {
      ${CourseFragment}
    }
  }
`;

export const CREATE_COURSES_MUTATION = gql`
  mutation InsertIntocoursesCollection($objects: [coursesInsertInput!]!) {
    insertIntocoursesCollection(objects: $objects) {
      affectedCount
      records {
        ${CourseFragment}
      }
    }
  }
`;

export const DELETE_COURSE_MUTATION = gql`
  mutation CourseDelete($id: ID!){
     courseDelete(course:{ id: $id }) {
      ${CourseFragment}
    }
  }
`;

export const UPDATE_COURSE_MUTATION = gql`
  mutation UpdatecoursesCollection($set: coursesUpdateInput!, $filter: coursesFilter) {
    updatecoursesCollection(set:$set, filter:$filter) {
      affectedCount
      records {
        ${CourseFragment}
      }
    }
  }
`;


interface UseCreateCourseProps {
  course: RequireOnly<Course, "title">,
  course_modules: { module: RequireOnly<Module, "title" | "duration">, order: number }[]
}

 interface UseUpdateCourseProps {
   course: RequireOnly<Course, "id">,
 }

export function useCreateCourse(): UseMutationResult<Course, Error, UseCreateCourseProps> {
  const createModules = useCreateModules();
  const createCourseModules = useCreateCourseModules();

  const createCourse: MutationFunction<Course, UseCreateCourseProps> = async ({ course : { cover, slug, organization, ...course}, course_modules }) => {
    let courseData: any = { ...course }
    if(organization) courseData.organization = organization.id

    const [newCourse] = await api.request<Mutation>(CREATE_COURSES_MUTATION, { objects: [courseData] })
      .then(({ insertIntocoursesCollection }) => {
        const { records } = insertIntocoursesCollection || { records: [] }
        return records.map((record) => parseServerCourse(record))
      });

    if(cover?.indexOf("tmp/") === 0){
      await supahelpers.functions.updateImage({
        table_name: "courses",
        column_name: "cover",
        image: cover || "",
        bucket: 'images',
        id: newCourse.id
      })
    }

    const [updatedCourse] = await api.request<Mutation>(UPDATE_COURSE_MUTATION, {
      set:{ slug: slugify(`${newCourse.title}-${newCourse.id}`, { lower: true })},
      filter: { id: { eq: newCourse.id } }
    }).then(({  updatecoursesCollection }) => {
      const { records } = updatecoursesCollection || { records: [] }
      return records.map((record) => parseServerCourse(record))
    });

    const courseModules = await Promise.all(course_modules.map(async ({module, order}) => {
      const [newModule] = await createModules.mutateAsync({modules: [module]})

      const [newCourseModules] = await createCourseModules.mutateAsync({
        course_modules: [{course: newCourse, module: newModule, order}]
      })

      return newCourseModules
    }))

    console.log('NEW COURSE MODULE', courseModules)

    return parseCourse({ ...updatedCourse, course_modules: courseModules })
  }

  return useMutation({
    mutationFn: createCourse,
  })
}

export function useGetCourse({ id, slug } : { id?: string, slug?: string }): UseQueryResult<Course> {

   async function findCourse(): Promise<Course> {

     if(!id && !slug) return parseCourse({})
      /*
     const [fetchedCourse] = await api.request<Query>(FIND_COURSE_QUERY, {
       filter: id ? { id: { eq: id } } : { slug:{ eq: slug } }
     }).then(({  coursesCollection }) => {
       const { edges } = coursesCollection || { edges: [] }
       return edges.map(({ node }) => parseServerCourse(node))
     });*/

     const { data } = await supabase
       .from('courses')
       .select('*, organization(*), course_modules(*, modules(*), sessions!session_course_module(*, instructors!instructors_session_fkey(id, featured, user(id, profiles(id, bio, image, tagline, first_name, last_name)), memberships!instructor_membership_organization_user(id, title, organization(id, name, logo)))))')
       .eq(id ? 'id' : 'slug', id ? id : slug);

     let fetchedCourse = (data || [])[0];

     return parseCourse(fetchedCourse);
   }

   return useQuery<Course>({
     queryKey: ["course", { id, slug } ], // {id: undefined, slug}, {id, slug: undefined}
     queryFn: findCourse,
   })
 }

export function useUpdateCourse(): UseMutationResult<Course, Error, UseUpdateCourseProps> {

   const updateCourse: MutationFunction<Course, UseUpdateCourseProps> = async ({ course : { id, cover, created, updated, slug, ...course} }) => {

     const [updatedCourse] = await api.request<Mutation>(UPDATE_COURSE_MUTATION, {
       set: course,
       filter: { id: { eq: id } }
     }).then(({  updatecoursesCollection }) => {
       const { records } = updatecoursesCollection || { records: [] }
       return records.map((record) => parseServerCourse(record))
     });

     if(cover?.indexOf("tmp/") === 0){
       await supahelpers.functions.updateImage({
         table_name: "courses",
         column_name: "cover",
         image: cover || "",
         bucket: 'images',
         id: id
       })
     }

     return parseCourse({ ...updatedCourse })
   }

  const queryClient = useQueryClient()

   return useMutation({
     mutationFn: updateCourse,
     onSuccess: (data) => {
       queryClient.invalidateQueries({
         predicate: (query) =>
           // @ts-ignore
           query.queryKey[0] === 'course' && (query.queryKey[1]?.slug === data.slug || query.queryKey[1]?.id === data.id),
       })
     }
   })
 }


 interface EventsFieldSet extends FieldSet {
  id: string
  shown: boolean
  title: string
  attending: number
  attending_background: string
  background: string
  background_color: string[]
  company: string
  company_logo: string
  date: string
  description: string
  instructor: string
  instructor_tagline: string
  instructor_image: string
  register_button: string
  register_button_text: string
  url: string
  recording_url: string
}

const parseRecordFields = (fields: Partial<EventsFieldSet>) => {
  return {
    id: "",
    shown: false,
    title: "",
    attending: 50,
    attending_background: "",
    background: "",
    background_color: [],
    company: "",
    company_logo: "",
    date: Date.now().toLocaleString(),
    description: "",
    instructor: "",
    instructor_image: "",
    instructor_tagline: "",
    register_button: "",
    register_button_text: "",
    url: "",
    recording_url: "",
    ...fields,
  }
}

export function useFetchCourses({ organization_id, key, limit, sort_by }: { organization_id ? : string, key?: string, limit?: number, sort_by?: string }) {

  async function fetchCourses(): Promise<Course[]> {
    let query = supabase
    .from('courses')
    .select('*, course_modules(id, instructors!instructor_course_module(user(id, profiles(id, image, tagline, first_name, last_name, bio, memberships(id), slug)))), organization(id, slug)');

    if(organization_id) query = query.eq('organization', organization_id);
    if(limit) query = query.limit(limit)
    if(sort_by) query = query.order(sort_by, { ascending: false })

    const { data } = await query;
    return (data || []).map(({ course_modules, ...course}) => {

      return parseCourse({
        course_modules: course_modules.map(({instructors, ...course_module}: any) => parseCourseModule({
          instructors: instructors.map(({user, ...instructor}: any) => parseInstructor({
            user: parseUser({ ...user, profile: user.profiles[0] || {} }),
            ...instructor
          })),
          ...course_module
        })),
        ...course
      })
    });
  }

  let queryKey = [];
  if(key) queryKey.push(key);
  if(organization_id) queryKey.push(organization_id)

  return useQuery<Course[]>({
    queryKey,
    queryFn: fetchCourses,
    initialData: []
  })
}

export function useFetchAirTableCourses({ key, filterByFormula = "", sort = [] }: { key:string, filterByFormula?: string, sort?:  SortParameter<EventsFieldSet>[] | undefined }){

  async function fetchCourses(){
    return (await new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_API_KEY })
      .base('app15IosigQN12Wu1').table<EventsFieldSet>('Table 1')
      .select({filterByFormula, sort})
      .all()).map(function(record: Record<EventsFieldSet>) {
          record.fields = parseRecordFields(record.fields);
          let instructor_name = record.fields.instructor.split(" ")
          const first_name = instructor_name.shift();
          const last_name = instructor_name.join(" ");
          return (
            parseCourse({
              title: record.fields.title,
              id: record.id,
              attended: record.fields.attending,
              cover: record.fields.background,
              course_modules: [parseCourseModule({
                sessions: [parseSession({
                  starts_at: record.fields.date,
                  instructors: [parseInstructor({
                    user: parseUser({
                      first_name: first_name,
                      last_name: last_name,
                      image: record.fields.instructor_image,
                      tagline: record.fields.instructor_tagline
                    }),
                  })]
                })],
                instructors: [parseInstructor({
                  user: parseUser({
                    first_name: first_name,
                    last_name: last_name,
                    image: record.fields.instructor_image,
                    tagline: record.fields.instructor_tagline
                  }),
                })]
              })],
              organization: parseOrganization({
                id: record.fields.id,
                logo: record.fields.company_logo,
                logo_alt: record.fields.company_logo,
                name: record.fields.company,
                theme: parseTheme({
                  img_bg_color_to: record.fields.background_color[0],
                  img_bg_color_from: record.fields.background_color[1],
                  img_bg_color_opacity: '.7',
                  img_bg_color_split: [1, 5, 6],
                  img_bg_color_darkness: ".5",
                  hired_count_bg_color: record.fields.attending_background,
                  hired_count_text_color: "#FFFFFF",
                  cta_bg_color: record.fields.register_button,
                  cta_text_color: record.fields.register_button_text,
                }),
              }),
              description: record.fields.description,
              slug: record.fields.recording_url || record.fields.url,
              tagline: record.fields.description,
              attendees: []
            })
          );

        });
  }

  return useQuery<Course[]>({
      queryKey: ["coursesFeed", key],
      queryFn: fetchCourses,
      initialData: []
    })
}

