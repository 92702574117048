import './Authentication.css'
import {useLocation, Outlet, useNavigate, Link} from "react-router-dom"
import Footer from "../components/Footer";
import React, { useEffect } from "react";
import {classNames, UserIsAuthenticated} from "../helpers";
import bg from "../assets/img/registration-image.jpg"
import {ReactComponent as EquiptLogo} from "../assets/img/logo-white.svg";

const testimonials = [
  {
    "name": "Ryley",
    "title": <>Equipt Student</>,
    "picture": "https://images.unsplash.com/photo-1532417344469-368f9ae6d187?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
    "quote": `You’ve given me access to professionals at companies that are typically out of reach.`
  },
]

interface Props {
  user: User
}

const Authentication: React.FC<Props> = ({ user}) => {
  let location = useLocation();
  let navigate = useNavigate();
  let page = location.pathname.split('/')[1]

  useEffect(() => {
    if(user.id && page !== 'confirm' && page !== 'reset'){
      const state = location.state as { from: string };
      const from = state?.from || '/dashboard';
      const nextLocation = from === "/logout" || from === "/login"? '/dashboard' : from
      navigate(nextLocation)
    }
  }, [user.id, navigate, location.state, page])

  return (
    <>
      <div className="relative">
        <div className={classNames(
          "flex flex-col justify-center py-12 sm:px-6 lg:px-8 pb-28 md:pb-16 min-h-screen bg-gray-100 w-full",
          page === "register" ? "lg:w-1/2" : ""
        )}
             style={{transition: "width .5s ease" }}>
          <div className="mt-8 mx-auto w-full max-w-md">
            <div id="auth-form" className="bg-white py-5 sm:py-10 pb-4 shadow-md rounded-lg mx-3 px-5 sm:px-10">
              <Outlet />
            </div>
          </div>
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            {page === "forgot" ?
              <p className="mt-2 text-center text-sm text-gray-400">
                Remember your password?
                <Link to="/login" className="ml-1 font-bold text-gray-400 hover:text-brand-primary">
                  LOG IN
                </Link>
              </p>
              :
              !UserIsAuthenticated(user) && <p className="mt-2 text-center text-sm text-gray-400">
                Forgot your password?
                <Link to="/forgot" className="ml-1 font-bold text-gray-400 hover:text-brand-primary">
                  RESET IT
                </Link>
              </p>
            }

            {page === "register" ?
              <p className="mt-2 text-center text-sm text-gray-400">
                Already have an account?
                <Link to="/login" className="ml-1 font-bold text-gray-400 hover:text-brand-primary">
                  LOG IN
                </Link>
              </p>
              :
              !UserIsAuthenticated(user) && <p className="mt-2 text-center text-sm text-gray-400">
                Don’t have an account?
                <Link to="/register" className="ml-1 font-bold text-gray-400 hover:text-brand-primary">
                  SIGN UP
                </Link>
              </p>
            }
          </div>
        </div>
        <div className="w-full absolute inset-0  min-h-screen -z-1">
          <div className="absolute right-0 top-0 bottom-0 w-1/2 bg-cover h-full bg-bottom" style={{backgroundImage: `url('${bg}')`}}/>
          <div className="absolute h-full w-full inset-0 flex bg-blue-700/90" />
          <div className="absolute h-full w-full inset-0 flex bg-black/30" />
          <div className="absolute right-0 top-0 bottom-0 w-1/2 flex flex-col min-h-screen justify-center p-10 xl:p-20 overflow-hidden">
            <div className="flex flex-col grow justify-center">
              <div className="w-full flex  flex-col grow justify-center items-center">
                <div className="w-full max-w-3xl mx-auto text-center pt-20">
                  <EquiptLogo
                    className={classNames("nav-logo block h-16 mb-8 w-full text-center")}
                  />
                  <h6 className="content_subtitle text-blue-300">Join US</h6>
                  <h1 className="uppercase font-heading font-bold block text-4xl 3xl:text-5xl mb-6 text-white">WHEN IT COMES TO <br/> YOUR CAREER, <br />
                    <span className="text-highlighted from-blue-200/50 to-blue-100/20 ">DON’T GO IT ALONE </span></h1>
                </div>
                <div className="w-full max-w-xl px-10 mx-auto text-center">
                  <div className="text-base xl:text-base 3xl:text-xl text-blue-200 font-medium leading-6 text-white">
                   Sign up today to start networking, learning, and collaborating with tech professionals and recruiters through live & interactive online workshops and events. You will gain access to our discord community, live events, jobs from partner companies, and self-paced learning content.
                  </div>
                </div>
              </div>
              <div className="w-full flex items-center justify-center justify-self-start h-lg my-5">
                <div className={classNames("bg-orange-500/90 text-white",
                  "shadow-xl",
                  "transition ease-in-out duration-500",
                  "max-w-lg h-36 rounded-2xl p-5 flex flex-col relative shadow-xl")}>
                  <div className={classNames("text-gray-900/10", "absolute font-heading font-normal")} style={{fontSize: "400px", lineHeight:'300px'}}>“</div>
                  <div className="text-base 3xl:text-xl italic p-2">
                    {testimonials[0].quote}
                  </div>
                  <div className="flex mt-auto">
                    <div
                      className="h-10 w-10 rounded-full inline bg-center bg-cover"
                      style={{backgroundImage: `url(${testimonials[0].picture})`}}
                    />
                    <div className="leading-none flex flex-col justify-center pl-2">
                      <div className="font-semibold mb-.5">{testimonials[0].name}</div>
                      <div className="font-medium opacity-80">{testimonials[0].title}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer className="bg-brand-secondary text-white absolute -bottom-1 w-full"/>
    </>
  )
}

export default Authentication
