import {useParams} from "react-router-dom";
import {organizations} from "../../data/fakeData";
import React, {useState} from "react";
import { Dropdown, Option } from "../Elements/Dropdown";

import PeopleDropdown from "../Elements/PeopleDropdown"

import OrganizationCourseTile from "./OrganizationCourseTile";
import {useGetOrganization} from "../../data/client/organization.api";
import {useFetchCourses} from "../../data/client/course.api";
import {classNames, getMembershipTypeName} from "../../helpers";
import {PlusIcon} from "@heroicons/react/24/outline";
import ManageEvent from "./../Course/ManageEvent";
import {parseUser} from "../../data/client/factories";

const skillsOptions = [
  { name: 'Engineering', value: 'engineering' }
]

const departmentOptions = [
  { name: 'Meet Up', value: 'meetup' },
]

function getInstructorOptions(attr: string, courses: Course[]): { name: string, value: User }[] {
  const options = new Map<string, User>();
  switch (attr){
    case "instructor":
      courses.forEach(({ course_modules }) => {
        course_modules.forEach(( { instructors} ) => {
          instructors.forEach(( instructor) => {
            //TODO: Should we limit this to memebers of the organization?
            options.set(`${instructor.user.profile?.first_name} ${instructor.user.profile?.last_name}`, parseUser(instructor.user))
          })
        })
      });
      break;
    default: break;
  }
  return Array.from(options.entries()).map(([name, value]) => ({ name, value }))
}

export default function OrganizationCourses({ user }: { user?: User}) {
  const { organization_id } = useParams<{organization_id: string}>();
  const [openCreateEvent, setOpenCreateEvent] = useState(false)
  const { data: organization } = organization_id == 'clearflame' ?
    { data: organizations.find(({slug}) => organization_id === slug)}
    : useGetOrganization({ slug: organization_id });
  const [selectedSkill, setSelectedSkill] = useState<Option>({name: "", value: undefined})
  const [selectedDepartment, setSelectedDepartment] = useState<Option>({name: "", value: undefined})
  if(!organization){
    return <div className="relative overflow-hidden h-screen"/>
  }

  const { data: organizationCourses } = useFetchCourses({ organization_id: organization.id})
  const isAdmin = !!user?.memberships.find((membership) => ( membership.organization == organization.id && getMembershipTypeName(membership.membership_type) == "ADMIN"))

  const dropdownStyles = {
    backgroundColor: organization.theme.dropdown_bg_color,
    labelColor: organization.theme.dropdown_label_color,
    borderColor: organization.theme.dropdown_outline_color
  }
  return (
    <div className="mx-12 pb-28 px-6">
      {user && isAdmin && <ManageEvent user={user} organization={organization} open={openCreateEvent} setOpen={setOpenCreateEvent} />}
      <div className="grid gap-8 grid-cols-3 w-11/12 mx-auto items-center py-10">
        <Dropdown selected={selectedSkill} setSelected={setSelectedSkill} options={skillsOptions} labelText="Skills" {...dropdownStyles}  />
        <PeopleDropdown options={getInstructorOptions("instructor", organizationCourses)} label="Instructor" {...dropdownStyles} />
        <Dropdown selected={selectedDepartment} setSelected={setSelectedDepartment} options={departmentOptions} labelText="Event Type" {...dropdownStyles}/>
      </div>
      <div className="grid gap-12 grid-cols-3 w-11/12 mx-auto items-center py-10">
        {isAdmin && <button
          type="button"
          onClick={() => setOpenCreateEvent(true)}
          className={classNames(
            "organization_course-tile course-tile relative block w-full rounded-lg border-2 border-dashed border-gray-300 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
            organizationCourses.length === 0 ? "col-span-3" : "col-span-1"
          )}
        >
          <div className="relative w-20 h-20 mx-auto">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1"
                 stroke="currentColor" className="w-20 h-20  text-gray-400">
              <path strokeLinecap="round" strokeLinejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"/>
            </svg>
            <div className="rounded-full h-6 w-6 absolute -right-4 bottom-0"><PlusIcon className="p-.5 text-gray-400"/></div>
          </div>

          <span className="mt-1 block font-semibold text-gray-700">Create an Event</span>
          { organizationCourses.length === 0 && <div className="text-xs">Click here to create an event and grow your following.</div> }

        </button>}

        {organizationCourses.map((course, index) => <OrganizationCourseTile course={course} organization={organization} isAdmin={isAdmin} key={`org-course-tile-${index}`} />)}
      </div>

    </div>
  )
}
